import React from "react";
import { Button } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import CONFIG from "../../../../utils/config";
import { isCurrentKwipStepDisabled } from "../../../../utils/functions";

function StepButton({
  kwipObject,
  currentStep,
  isStepLoading,
  prev,
  next,
  handleCancel,
}) {
  const {
    LABELS: {
      NOTIFICATIONS: { PREV, NEXT, CANCEL, SAVE },
    },
  } = CONFIG;

  const disabledObj = isCurrentKwipStepDisabled(currentStep, kwipObject);

  return (
    <div className="footer-btns">
      {currentStep !== 0 && (
        <Button className="primary-outline" onClick={() => prev()}>
          {PREV}
        </Button>
      )}
      <Button
        className="primary-solid position-relative"
        onClick={() => next()}
        disabled={disabledObj.next || disabledObj.save || isStepLoading}
      >
        {currentStep === 3 ? SAVE : NEXT}
        {isStepLoading && <LoadingOutlined className="loading" />}
      </Button>
      {currentStep === 0 && (
        <Button
          className="primary-outline"
          onClick={() => handleCancel()}
          disabled={isStepLoading}
        >
          {CANCEL}
        </Button>
      )}
    </div>
  );
}

export default StepButton;
