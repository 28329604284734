import {
  Button,
  Modal,
  Row,
  Col,
  Form,
  Input,
  Radio,
  Space,
  Select,
  DatePicker,
} from "antd";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import profileUpload from "../../../assets/images/upload-pic.png";
import {
  EditOutlined,
  DeleteOutlined,
  SlidersOutlined,
} from "@ant-design/icons";
import ErrorMessage from "../../../components/validation/components/errorMessage";
import CONFIG from "../../../utils/config";
import Validation from "../../../components/validation";
import {
  dateArray,
  getCreateUserPayload,
  getEditUserPayload,
  getRefInitialValue,
  isErrorPresent,
  monthsArray,
} from "../../../utils/functions";
import { useDispatch } from "react-redux";
import { closeModal } from "../../../redux-toolkit/slice/modal";
import {
  createUser,
  editUser,
  fetchMusicList,
} from "../../../redux-toolkit/slice/users/asyncThunks";
import adminDataSelector from "../../../redux-toolkit/selectors/adminData";
import { useSelector } from "react-redux";
import ConfirmModal from "../confirm";
import dayjs from "dayjs";
import userDataSelector from "../../../redux-toolkit/selectors/userData";
import SongList from "./components/songList";
import ImageAdjustment from "../imageAdjustment";

function UserModal({ userData }) {
  const {
    LABELS: {
      USER: {
        FIRST_NAME,
        LAST_NAME,
        ALIAS,
        EMAIL,
        TITLE,
        MOBILE,
        ADMIN,
        SUPER_ADMIN,
        USER,
      },
      VALIDATION: { REQUIRED: REQUIRED_RULE, EMAIL_VALIDATION },
    },
    ERRORS: { REQUIRED, EMAIL: EMAIL_ERROR },
  } = CONFIG;

  const VALIDATION_RULES = {
    firstName: [{ rule: REQUIRED_RULE, errorMessage: REQUIRED }],
    lastName: [{ rule: REQUIRED_RULE, errorMessage: REQUIRED }],
    email: [
      { rule: REQUIRED_RULE, errorMessage: REQUIRED },
      { rule: EMAIL_VALIDATION, errorMessage: EMAIL_ERROR },
    ],
  };

  const profilePhotoRef = useRef(null);
  const profilePhotoRefArray = useRef(getRefInitialValue(userData));

  const dispatch = useDispatch();

  const { adminDetails } = adminDataSelector(useSelector);

  const { usersMusicList } = userDataSelector(useSelector);

  const { email: adminEmail } = adminDetails || {};

  const [userDetails, setUserDetails] = useState({
    role: "user",
    is_active: true,
    profile: [],
  });

  const [showDeleteModal, setShowDeleteModal] = useState(null);
  const [birthdayObj, setBirthday] = useState({ date: null, month: null });
  const [selectedMusic, setSelectedMusic] = useState({ playing: false });
  const [adjustmentModal, setAdjustmentModal] = useState(null);

  const {
    id,
    role,
    alias,
    email,
    title,
    mobile,
    profile,
    lastName,
    firstName,
    anniversary,
    music_settings,
  } = userDetails || {};

  const isChangeDisabled = useMemo(
    () => (userData ? adminEmail === userData?.email : false),
    [userData, adminEmail]
  );

  const handleProfilePhotoChange = ({ target: { files } }, index) => {
    let file = files[0];

    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onloadend = () => {
        let url = reader.result;

        const newProfile = {
          file,
          url,
          is_active: userDetails.profile.length ? false : true,
        };

        if (typeof index === "number")
          setUserDetails((prev) => ({
            ...prev,
            profile: prev.profile.map((data, idx) =>
              idx === index
                ? { ...newProfile, is_active: data.is_active }
                : data
            ),
          }));
        else {
          profilePhotoRefArray.current.push(React.createRef());
          const newProfilesArray = [...userDetails?.profile, newProfile];
          setUserDetails({ ...userDetails, profile: newProfilesArray });
        }
      };
    }
  };

  const handleProfileClick = (index) => {
    if (typeof index === "number")
      profilePhotoRefArray.current[index].current.click();
    else profilePhotoRef?.current.click();
  };

  const handleProfileDelete = (deleteUrl, isActiveImageDeleted) => {
    setShowDeleteModal({ deleteUrl, isActiveImageDeleted });
  };

  const handleConfirmProfileDelete = () => {
    const { deleteUrl, isActiveImageDeleted } = showDeleteModal;

    setUserDetails((prev) => ({
      ...prev,
      profile: isActiveImageDeleted
        ? prev.profile
            .filter(({ url }) => url !== deleteUrl)
            .map((obj, index) => {
              if (index) return obj;
              else return { ...obj, is_active: true };
            })
        : prev.profile?.filter(({ url }) => url !== deleteUrl),
    }));
    handleClose();
  };

  const handleChange = ({ target: { name, value } }) => {
    setUserDetails({ ...userDetails, [name]: value });
  };

  const handleCloseModal = () => {
    dispatch(closeModal());
  };

  const handleClose = () => {
    setShowDeleteModal(null);
  };

  const handleSave = () => {
    let payload = userData
      ? getEditUserPayload({
          ...userData,
          ...userDetails,
          birthdayObj,
          selectedMusic,
        })
      : getCreateUserPayload({ ...userDetails, birthdayObj, selectedMusic });

    userData
      ? dispatch(
          editUser({
            payload,
            callback: handleCloseModal,
          })
        )
      : dispatch(
          createUser({
            payload,
            callback: handleCloseModal,
          })
        );
  };

  const handleActiveImage = (index) => {
    setUserDetails((prev) => ({
      ...prev,
      profile: prev.profile.map((item, idx) => {
        if (idx === index) item.is_active = true;
        else item.is_active = false;
        return item;
      }),
    }));
  };

  const handleNicknameFocus = () => {
    !userDetails.alias &&
      setUserDetails({ ...userDetails, alias: firstName + " " + lastName });
  };

  const handleAnniversaryChange = (anniversary) => {
    setUserDetails((prev) => ({ ...prev, anniversary }));
  };

  const handleSelectChange = (value, name) => {
    setBirthday((prev) => ({ ...prev, [name]: value }));
  };

  const handleAdjustmentSave = (index) => (url) => {
    setUserDetails((prev) => ({
      ...prev,
      profile: prev.profile.map((data, idx) =>
        idx === index ? { ...data, url } : data
      ),
    }));
  };

  const handleAdjustment = (imgSrc, index) => {
    setAdjustmentModal({ imgSrc, saveCallback: handleAdjustmentSave(index) });
  };

  useEffect(() => {
    if (userData) {
      setUserDetails({
        id: userData.id,
        name: userData.name,
        alias: userData.alias,
        email: userData.email,
        title: userData.title,
        mobile: userData.mobile,
        firstName: userData.first_name,
        lastName: userData.last_name,
        is_active: userData.is_active,
        role: userData.role,
        anniversary: userData.anniversary,
        music_settings: userData.music_settings,
        profile: userData.profile_photo.map(({ image, is_active }) => ({
          url: image,
          is_active,
        })),
      });
      if (userData.birthday) {
        let [month, date] = userData.birthday.split("-");
        setBirthday({ date, month });
      }
    }
  }, [userData]);

  useEffect(() => {
    if (!usersMusicList[id] && id) dispatch(fetchMusicList({ id }));
  }, [dispatch, id, usersMusicList]);

  return (
    <Validation validationRules={VALIDATION_RULES} values={userDetails}>
      {(errorObj) => {
        return (
          <Modal
            key={"Create New User"}
            title={userData ? userData.name : "Create New User"}
            centered
            className="common-modal"
            open={true}
            width={700}
            onCancel={handleCloseModal}
            footer={
              <div className="footer-btns">
                <Button
                  className="primary-solid"
                  disabled={isErrorPresent(errorObj)}
                  onClick={handleSave}
                >
                  Save
                </Button>
                <Button className="primary-outline" onClick={handleCloseModal}>
                  Cancel
                </Button>
              </div>
            }
          >
            <Form name="form_item_path" layout="vertical">
              <Row gutter={[16, 16]}>
                <Col xs={24}>
                  <Row gutter={[12, 12]} className="mb-1">
                    <Col xs={7} md={6}>
                      <Swiper
                        modules={[Pagination]}
                        pagination={{ clickable: true }}
                      >
                        {profile?.map(({ file, url, is_active }, index) => (
                          <SwiperSlide key={url + index}>
                            <div className="profile-pic">
                              <img
                                src={url}
                                alt="profile"
                                width="100%"
                                height="100%"
                              />
                              <EditOutlined
                                className="editIcon"
                                onClick={() => handleProfileClick(index)}
                              />
                              <DeleteOutlined
                                className="deleteIcon"
                                onClick={() =>
                                  handleProfileDelete(url, is_active)
                                }
                              />
                              {file && (
                                <SlidersOutlined
                                  className="sliderIcon"
                                  onClick={() => handleAdjustment(url, index)}
                                />
                              )}
                              <input
                                ref={profilePhotoRefArray.current[index]}
                                type="file"
                                style={{ display: "none" }}
                                onChange={(e) =>
                                  handleProfilePhotoChange(e, index)
                                }
                              />
                              <div
                                className={`circle ${
                                  is_active ? "active" : ""
                                }`}
                                onClick={() => handleActiveImage(index)}
                              />
                            </div>
                          </SwiperSlide>
                        ))}

                        <SwiperSlide key={profileUpload}>
                          <div style={{ width: "100%" }}>
                            <img
                              width="100%"
                              height="100%"
                              src={profileUpload}
                              alt="profile"
                              onClick={() => handleProfileClick()}
                            />
                            <input
                              ref={profilePhotoRef}
                              type="file"
                              style={{ display: "none" }}
                              onChange={handleProfilePhotoChange}
                            />
                          </div>
                        </SwiperSlide>
                      </Swiper>
                    </Col>
                    <Col xs={24} md={18}>
                      <Row gutter={[16, 16]} className="mb-2">
                        <Col xs={24} sm={12} md={12} xl={12}>
                          <Form.Item
                            label="First Name"
                            className="mb-0 no-form-item"
                            children={null}
                            required
                          />
                          <Input
                            placeholder="Enter your first name. . ."
                            value={firstName}
                            name={FIRST_NAME}
                            disabled={userData?.source_type === "Salesforce"}
                            onChange={handleChange}
                          />
                          {userData?.source_type !== "Salesforce" && (
                            <ErrorMessage
                              errorObject={errorObj}
                              selector={FIRST_NAME}
                            />
                          )}
                        </Col>
                        <Col xs={24} sm={12} md={12} xl={12}>
                          <Form.Item
                            label="Last Name"
                            className="mb-0 no-form-item"
                            children={null}
                            required
                          />
                          <Input
                            placeholder="Enter your last name. . ."
                            value={lastName}
                            name={LAST_NAME}
                            disabled={userData?.source_type === "Salesforce"}
                            onChange={handleChange}
                          />
                          {userData?.source_type !== "Salesforce" && (
                            <ErrorMessage
                              errorObject={errorObj}
                              selector={LAST_NAME}
                            />
                          )}
                        </Col>
                      </Row>
                      <Form.Item
                        label="Display Name"
                        className="mb-0 no-form-item"
                        children={null}
                      />
                      <div className="mb-1">
                        <Input
                          placeholder="Enter your nickname here. . ."
                          value={alias}
                          name={ALIAS}
                          onChange={handleChange}
                          onFocus={handleNicknameFocus}
                        />
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col xs={24}>
                  <Row gutter={[16, 16]}>
                    <Col xs={24} xl={24}>
                      <Form.Item
                        label="Email"
                        className="mb-0 no-form-item"
                        children={null}
                        required
                      />
                      <Input
                        placeholder="Enter your email here. . ."
                        value={email}
                        disabled={userData?.source_type === "Salesforce"}
                        name={EMAIL}
                        onChange={handleChange}
                      />
                      {userData?.source_type !== "Salesforce" && (
                        <ErrorMessage errorObject={errorObj} selector={EMAIL} />
                      )}
                    </Col>
                    <Col xs={12} xl={12}>
                      <Form.Item
                        label="Title"
                        className="mb-0 no-form-item"
                        children={null}
                      />
                      <Input
                        placeholder="Enter your title here. . ."
                        value={title}
                        name={TITLE}
                        disabled={userData?.source_type === "Salesforce"}
                        onChange={handleChange}
                      />
                    </Col>
                    <Col xs={12} xl={12}>
                      <Form.Item
                        label="Mobile"
                        className="mb-0 no-form-item"
                        children={null}
                      />
                      <Input
                        placeholder="Enter your mobile number. . ."
                        type="number"
                        value={mobile}
                        name={MOBILE}
                        disabled={userData?.source_type === "Salesforce"}
                        onChange={handleChange}
                      />
                    </Col>
                    <Col sm={12}>
                      <Form.Item
                        label="Work Anniversary"
                        className="mb-0 no-form-item"
                        children={null}
                      />
                      <DatePicker
                        className="anniversary_picker"
                        placeholder="Select Anniversary Date"
                        onChange={handleAnniversaryChange}
                        format="MMMM DD, YYYY"
                        value={anniversary ? dayjs(anniversary) : null}
                      />
                    </Col>
                    <Col sm={12}>
                      <Form.Item
                        label="Birthday"
                        className="mb-0 no-form-item"
                        children={null}
                      />
                      <div className="d-flex birthday_picker">
                        <span className="d-flex align-items-center">
                          Month:{" "}
                          <Select
                            options={monthsArray()}
                            value={birthdayObj.month}
                            className="ml-1"
                            onChange={(value) =>
                              handleSelectChange(value, "month")
                            }
                          />
                        </span>
                        <span className="ml-3 d-flex align-items-center">
                          Date:
                          <Select
                            options={dateArray()}
                            className="ml-1"
                            value={birthdayObj.date}
                            onChange={(value) =>
                              handleSelectChange(value, "date")
                            }
                          />
                        </span>
                      </div>
                    </Col>
                    <SongList
                      user_id={id}
                      selectedMusic={selectedMusic}
                      setSelectedMusic={setSelectedMusic}
                      music_settings={music_settings}
                      musicList={usersMusicList[id]}
                      setIsEdited={() => {}}
                    />
                    <Col xs={24} xl={24}>
                      <div className="d-flex align-items-center">
                        <Form.Item
                          label="Role"
                          className="mb-0 no-form-item user-role"
                          children={null}
                        />
                        {isChangeDisabled && (
                          <span className="error error-setting">
                            (change restricted)
                          </span>
                        )}
                      </div>
                      <Radio.Group
                        direction="vertical"
                        name="role"
                        onChange={handleChange}
                        value={role}
                        disabled={isChangeDisabled}
                      >
                        <Space direction="horizontal">
                          <Radio value={"super_admin"}>{SUPER_ADMIN}</Radio>
                          <Radio value={"admin"}>{ADMIN}</Radio>
                          <Radio value={"user"}>{USER}</Radio>
                        </Space>
                      </Radio.Group>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>
            {showDeleteModal && (
              <ConfirmModal
                title="Confirm Delete"
                content="Deleting your profile picture is irreversible. Are you sure you want to proceed?"
                handleConfirm={handleConfirmProfileDelete}
                handleClose={handleClose}
              />
            )}
            {adjustmentModal && (
              <ImageAdjustment
                imgSrc={adjustmentModal.imgSrc}
                handleCloseModal={() => setAdjustmentModal(null)}
                saveCallback={adjustmentModal.saveCallback}
              />
            )}
          </Modal>
        );
      }}
    </Validation>
  );
}

export default UserModal;
