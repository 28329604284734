import { Col, Row, Button } from "antd";
import React, { useEffect, useRef, useState } from "react";
import FiscalYear from "./components/fiscalYear";
import TimeZone from "./components/timeZone";
import Currency from "./components/currency";
import "./style.scss";
import layoutDataSelector from "../../redux-toolkit/selectors/layoutData";
import { useSelector, useDispatch } from "react-redux";
import { getSettingPayload } from "../../utils/functions";
import { editAdmin } from "../../redux-toolkit/slice/admin/asyncThunk";
import SessionTimeout from "./components/sessionTimeout";

function Setting() {
  const dispatch = useDispatch();

  const { adminDetails } = layoutDataSelector(useSelector);

  const [isDirty, setIsDirty] = useState(false);
  const [settingState, setSettingState] = useState({ timezone: new Date() });

  const handleSave = () => {
    let payload = getSettingPayload(settingState);
    dispatch(
      editAdmin({
        payload: { ...payload, id: adminDetails.id },
        callback: () => setIsDirty(false),
      })
    );
  };

  const handleSettingChange = (data) => {
    setSettingState(data);
    setIsDirty(true);
  };

  useEffect(() => {
    if (adminDetails) {
      let { currency, financial_year, timezone, refresh_token_expiry_hours } =
        adminDetails;
      setSettingState({
        currency,
        timezone: timezone ?? new Date(),
        financial_year,
        refresh_token_expiry_hours: refresh_token_expiry_hours,
      });
    }
  }, [adminDetails]);

  return (
    <div className="userContainer">
      <Row className="filter-head">
        <Col span={12}>
          <h2>GENERAL SETTINGS</h2>
        </Col>
      </Row>
      <Row className="year-setting" gutter={[40]}>
        <Col span={6}>
          <label>
            FISCAL YEAR{" "}
            <span className="disclaimer-fiscal">(starting month)</span>
          </label>
          <FiscalYear
            financialYear={settingState.financial_year}
            setSettingState={handleSettingChange}
          />
        </Col>
        <Col span={9}>
          <label> TIME ZONE</label>
          <TimeZone
            timezone={settingState.timezone}
            setSettingState={handleSettingChange}
          />
        </Col>
        <Col span={6}>
          <label> CURRENCY</label>
          <div>
            <Currency
              currency={settingState.currency}
              setSettingState={handleSettingChange}
            />
          </div>
        </Col>
      </Row>
      <Row className="session-setting" gutter={[40]}>
        <Col span={15} className="d-flex flex-column">
          <label>Session Duration </label>
          <span>
            Once logged in, users will remain signed in until they explicitly
            sign out. This setting allows you to force users to log in after a
            certain amount of time has elapsed since their last login.
          </span>
          <SessionTimeout
            expiryHours={settingState.refresh_token_expiry_hours}
            setSettingState={handleSettingChange}
          />
        </Col>
      </Row>
      <div className="setting-buttons">
        <div className="d-flex">
          <Button
            className="primary-solid"
            disabled={!isDirty}
            onClick={handleSave}
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Setting;
