import {
  Col,
  ColorPicker,
  Form,
  Input,
  Popover,
  Radio,
  Row,
  Select,
  Switch,
} from "antd";
import React, { useCallback, useMemo, useState } from "react";
import { HolderOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import Validation from "../../../../components/validation";
import CONFIG from "../../../../utils/config";
import ErrorMessage from "../../../../components/validation/components/errorMessage";
import {
  PictureOutlined,
  CheckCircleOutlined,
  EditOutlined,
  DeleteOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import Icon from "../../../../components/icon";

function Heading({
  contentDetails,
  setContentDetails,
  mode,
  handleModeChange,
}) {
  const {
    LABELS: {
      VALIDATION: { REQUIRED: REQUIRED_RULE },
    },
    ERRORS: { REQUIRED },
    TICKER_POSITION_OPTIONS,
    TICKER_LOGOS,
    TICKER_LOGO_COLOR,
  } = CONFIG;

  const VALIDATION_RULES = {
    title1: [{ rule: REQUIRED_RULE, errorMessage: REQUIRED }],
    headlineList: [{ rule: REQUIRED_RULE, errorMessage: REQUIRED }],
    image: [{ rule: REQUIRED_RULE, errorMessage: REQUIRED }],
  };

  const [showModeError, setShowShowModeError] = useState(null);
  const [logoMode, setLogoMode] = useState("logo");
  const [isHeadlineBeingEdited, setIsHeadlineBeingEdited] = useState(null);

  const handleBgColorChange = (_, color) => {
    setContentDetails((prev) => ({ ...prev, bgColor: color }));
  };

  const handleLogoColor = useCallback(
    (color) => {
      setContentDetails((prev) => ({ ...prev, logoColor: color }));
    },
    [setContentDetails]
  );

  const handleToggle = (value, name) => {
    setContentDetails((prev) => ({ ...prev, [name]: value }));
  };

  const handleContentChange = ({ target: { name, value } }) => {
    setContentDetails((prev) => ({ ...prev, [name]: value }));
    setShowShowModeError(null);
  };

  const handleAddToList = () => {
    if (contentDetails.headline) {
      setContentDetails((prev) => ({
        ...prev,
        headline: null,
        headlineList: prev.headlineList
          ? [...prev.headlineList, { message: prev.headline, id: Date.now() }]
          : [{ message: prev.headline, id: Date.now() }],
      }));
    }
  };

  const handleDelete = (deleteIndex) => {
    setContentDetails((prev) => ({
      ...prev,
      headlineList: prev.headlineList.filter(
        (_, index) => index !== deleteIndex
      ),
    }));
  };

  const dragEnded = ({ source, destination }) => {
    let { index: sourceIndex } = source,
      { index: destinationIndex } = destination;

    let tempArr = [...contentDetails.headlineList];

    let splicedItem = tempArr.splice(sourceIndex, 1)[0];

    tempArr.splice(destinationIndex, 0, splicedItem);

    setContentDetails((prev) => ({
      ...prev,
      headlineList: tempArr,
    }));
  };

  const handleModeClick = (errorObj) => {
    errorObj.title1.errorMessage ||
    errorObj.headlineList.errorMessage ||
    errorObj.image.errorMessage
      ? setShowShowModeError(true)
      : handleModeChange("style");
  };

  const handleListEdit = (index, message) => {
    setContentDetails((prev) => ({ ...prev, headline: message }));
    setIsHeadlineBeingEdited(index);
  };

  const handleEditHeadline = () => {
    if (contentDetails.headline) {
      setContentDetails((prev) => {
        let tempHeadlineList = prev.headlineList;
        tempHeadlineList[isHeadlineBeingEdited] = {
          ...tempHeadlineList[isHeadlineBeingEdited],
          message: contentDetails.headline,
        };
        return {
          ...prev,
          headline: null,
          headlineList: tempHeadlineList,
        };
      });
      setIsHeadlineBeingEdited(null);
    }
  };

  const handleEditDelete = () => {
    setContentDetails((prev) => ({ ...prev, headline: "" }));
    setIsHeadlineBeingEdited(null);
  };

  const handleImageSelect = useCallback(
    (url) => {
      setContentDetails((prev) => ({ ...prev, image: url }));
    },
    [setContentDetails]
  );

  const content = useMemo(
    () => (
      <div className="ticker-container">
        <Radio.Group
          onChange={({ target: { value } }) => {
            setLogoMode(value);
          }}
          value={logoMode}
          style={{ marginBottom: 8 }}
        >
          <Radio.Button value="logo">Logo</Radio.Button>
          <Radio.Button value="logoColor">Logo Color</Radio.Button>
        </Radio.Group>

        {logoMode === "logo" ? (
          <div className="mt-1">
            {Array.from(
              { length: Math.ceil(Object.keys(TICKER_LOGOS).length / 4) },
              (_, rowIndex) => {
                return (
                  <div className="ticker-container-icons d-flex">
                    {Object.keys(TICKER_LOGOS)
                      .slice(rowIndex * 4, (rowIndex + 1) * 4)
                      .map((SVG) => {
                        return (
                          <div
                            className={`${
                              SVG === contentDetails?.image
                                ? "selected-ticker"
                                : ""
                            } ticker-margin`}
                          >
                            <div
                              className="ticker-icon"
                              onClick={() => handleImageSelect(SVG)}
                            >
                              <Icon
                                Component={TICKER_LOGOS[SVG]}
                                fill={contentDetails.logoColor}
                              />
                            </div>
                          </div>
                        );
                      })}
                  </div>
                );
              }
            )}
          </div>
        ) : (
          <div className="d-flex mt-1">
            {TICKER_LOGO_COLOR.map((color) => (
              <div
                className={`${
                  contentDetails.logoColor === color
                    ? "color-palet-container"
                    : ""
                } mr-1`}
              >
                <div
                  key={color}
                  className="color-palet"
                  style={{ background: color }}
                  onClick={() => {
                    handleLogoColor(color);
                  }}
                />
              </div>
            ))}
          </div>
        )}
      </div>
    ),
    [
      logoMode,
      TICKER_LOGOS,
      TICKER_LOGO_COLOR,
      contentDetails?.image,
      contentDetails.logoColor,
      handleImageSelect,
      handleLogoColor,
    ]
  );

  return (
    <Validation
      validationRules={VALIDATION_RULES}
      values={{ ...contentDetails }}
    >
      {(errorObj) => {
        return (
          <React.Fragment>
            <Form layout="vertical" autoComplete="off" className="w-100">
              <Row>
                <Col md={18}>
                  <Row gutter={[4, 4]}>
                    <Col md={6}>
                      <Form.Item label="Ticker Title Line 1" required>
                        <Input
                          placeholder="Type Title for line 1"
                          name={"title1"}
                          maxLength={12}
                          value={contentDetails.title1}
                          onChange={handleContentChange}
                        />
                        <ErrorMessage
                          errorObject={errorObj}
                          selector={"title1"}
                          controlledShowError={showModeError}
                        />
                      </Form.Item>
                    </Col>
                    <Col
                      md={4}
                      className="d-flex align-items-center title-limit"
                    >
                      (Maximum 12 characters)
                    </Col>
                    <Col md={6}>
                      <Form.Item label="Ticker Title Line 2">
                        <Input
                          placeholder="Type Title for line 2"
                          name={"title2"}
                          maxLength={12}
                          value={contentDetails.title2}
                          onChange={handleContentChange}
                        />
                      </Form.Item>
                    </Col>
                    <Col
                      md={4}
                      className="d-flex align-items-center title-limit"
                    >
                      (Maximum 12 characters)
                    </Col>
                  </Row>
                </Col>
                <Col md={6}>
                  <div className="ticker-buttons">
                    <div
                      className={`ticker-buttons-item ${
                        mode === "heading" ? "active" : ""
                      }`}
                    >
                      Heading
                    </div>
                    <div
                      className={`ticker-buttons-item ${
                        mode === "style" ? "active" : ""
                      }`}
                      onClick={() => handleModeClick(errorObj)}
                    >
                      Preview
                    </div>
                  </div>
                </Col>
              </Row>
              <Row className="mb-3">
                <div className="formatting mt-1 w-100">
                  <div className="content-name custom-width-500">
                    BACKGROUND:
                    <ColorPicker
                      value={contentDetails.bgColor}
                      onChange={handleBgColorChange}
                    >
                      <div
                        style={{ backgroundColor: contentDetails.bgColor }}
                        className="background-color-item ml-2"
                      />
                    </ColorPicker>
                  </div>
                  <div className="content-name ml-4">
                    COLOR:
                    <Switch
                      className="ml-1"
                      checkedChildren="BLACK"
                      unCheckedChildren="WHITE"
                      checked={contentDetails.color}
                      onChange={(value) => handleToggle(value, "color")}
                    />
                  </div>
                  <div className="content-name ml-4">
                    LOGO:
                    <Popover
                      content={content}
                      trigger="click"
                      placement="bottom"
                    >
                      <div className="ticker-image cursor-pointer">
                        <PictureOutlined className="ml-0" /> Select Logo and
                        Color
                      </div>
                    </Popover>
                  </div>
                  <div className="content-name ml-4">
                    ALWAYS ON TOP:
                    <Switch
                      className="ml-1"
                      checked={contentDetails.alwaysOnTop}
                      onChange={(value) => handleToggle(value, "alwaysOnTop")}
                    />
                  </div>
                  <div className="ml-4">
                    <Select
                      value={contentDetails.position}
                      className="ml-1 custom-width-200"
                      placeholder="Select Ticker Position"
                      options={TICKER_POSITION_OPTIONS}
                    />
                  </div>
                </div>
                <ErrorMessage
                  errorObject={errorObj}
                  selector={"image"}
                  controlledShowError={showModeError}
                />
              </Row>
              <Form.Item label="Headline Content">
                <div className="d-flex align-items-center">
                  <Input
                    placeholder="Type Headline here. . ."
                    name={"headline"}
                    value={contentDetails.headline}
                    onChange={handleContentChange}
                  />
                  {typeof isHeadlineBeingEdited === "number" ? (
                    <div className="d-flex headline-icon-size">
                      <CheckCircleOutlined
                        className="ml-2"
                        onClick={handleEditHeadline}
                      />
                      <CloseCircleOutlined
                        className="ml-1"
                        onClick={handleEditDelete}
                      />
                    </div>
                  ) : (
                    <PlusCircleOutlined
                      className="ml-2"
                      onClick={handleAddToList}
                    />
                  )}
                </div>
                <ErrorMessage errorObject={errorObj} selector={"headline"} />
              </Form.Item>
            </Form>
            <DragDropContext onDragEnd={dragEnded}>
              <Droppable droppableId="headline-list">
                {(provided) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    className="radio-list"
                  >
                    {contentDetails.headlineList?.map(
                      ({ message, id }, index) => (
                        <Draggable draggableId={id + ""} index={index} key={id}>
                          {(_provided) => (
                            <div
                              ref={_provided.innerRef}
                              className="radio-list-item"
                              key={id}
                              {..._provided.draggableProps}
                            >
                              <div className="d-flex align-items-center">
                                <HolderOutlined
                                  className="cursor-pointer"
                                  {..._provided.dragHandleProps}
                                />
                                <div
                                  className="ml-1"
                                  dangerouslySetInnerHTML={{ __html: message }}
                                />
                              </div>
                              <div className="list-item-action">
                                <EditOutlined
                                  className="mr-1"
                                  onClick={() => handleListEdit(index, message)}
                                />
                                <DeleteOutlined
                                  onClick={() => handleDelete(index)}
                                />
                              </div>
                            </div>
                          )}
                        </Draggable>
                      )
                    )}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
            <ErrorMessage
              errorObject={errorObj}
              selector={"headlineList"}
              controlledShowError={showModeError}
            />
          </React.Fragment>
        );
      }}
    </Validation>
  );
}

export default Heading;
