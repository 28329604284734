import React from "react";
import { Col, Row } from "antd";
import user from "../../../../assets/images/dummyUser.png";

function FiveUser({
  bgImage,
  bgColor,
  bgImageBlur = 4,
  titleBackgroundColor,
  titleColor,
  bodyBackgroundColor,
  bodyColor,
}) {
  return (
    <div
      className="template-modal"
      style={
        bgImage
          ? { backgroundImage: `url(${bgImage})` }
          : { backgroundColor: bgColor }
      }
    >
      <Row
        className="template-row"
        style={{ backdropFilter: `blur(${bgImageBlur}px)` }}
        gutter={[8, 8]}
      >
        <Col md={24}>
          <h3
            style={{
              backgroundColor: titleBackgroundColor,
              color: titleColor,
              margin: 0,
            }}
            className="title"
          >
            Title
          </h3>
        </Col>
        <div className="tempalte-profile-pic five-profile-pic">
          <div className="tempalte-profile-pic-box">
            <img className="template-profile" src={user} alt="profile" />
          </div>
          <div className="tempalte-profile-pic-box">
            <img className="template-profile" src={user} alt="profile" />
          </div>
          <div className="tempalte-profile-pic-box">
            <img className="template-profile" src={user} alt="profile" />
          </div>
          <div className="tempalte-profile-pic-box">
            <img className="template-profile" src={user} alt="profile" />
          </div>
          <div className="tempalte-profile-pic-box">
            <img className="template-profile" src={user} alt="profile" />
          </div>
        </div>
        <Col md={24}>
          <p
            style={{
              backgroundColor: bodyBackgroundColor,
              color: bodyColor,
              height: "100px",
            }}
            className="description"
          >
            Message Text
          </p>
        </Col>
      </Row>
    </div>
  );
}

export default FiveUser;
