import { Col, ColorPicker, Row, Slider } from "antd";
import React, { useRef, useState } from "react";
import {
  PlusOutlined,
  BgColorsOutlined,
  FontColorsOutlined,
} from "@ant-design/icons";
import opacity from "../../../../assets/images/opacity-icon.png";

function EditingModules({ editState, setEditState, setIsChangeMade }) {
  const bgImageRef = useRef();

  const [showSlider, setShowSlider] = useState(false);

  const handleAddBgImageClick = () => {
    bgImageRef.current.click();
  };

  const handleBgImageChange = ({ target: { files } }) => {
    let file = files[0];

    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onloadend = () => {
      let url = reader.result;
      setEditState((prev) => ({ ...prev, bgImage: url }));
      setIsChangeMade(true);
    };
  };

  const handleBgColorChange = (code, key) => {
    if (key === "bgColor")
      setEditState((prev) => ({ ...prev, bgImage: null, [key]: code }));
    else setEditState((prev) => ({ ...prev, [key]: code }));
    setIsChangeMade(true);
  };

  const handleOpacityClick = () => {
    setShowSlider(!showSlider);
  };

  const handleSliderChange = (value) => {
    setEditState((prev) => ({ ...prev, bgImageBlur: value }));
    setIsChangeMade(true);
  };

  return (
    <Row justify="space-between" className="preview-edit">
      <Col>
        <h4>Background</h4>
        <div className={`background-container ${!showSlider && "mb-3"}`}>
          <div className="background-item" onClick={handleAddBgImageClick}>
            <PlusOutlined />
            <input
              ref={bgImageRef}
              type="file"
              style={{ display: "none" }}
              onChange={handleBgImageChange}
            />
          </div>
          <ColorPicker
            value={editState?.bgColor}
            onChange={(_, color) => handleBgColorChange(color, "bgColor")}
          >
            <div className="background-item">
              <BgColorsOutlined />
            </div>
          </ColorPicker>
          <div className="background-item" onClick={handleOpacityClick}>
            <img src={opacity} alt="shadow" />
          </div>
        </div>
        {showSlider && (
          <Slider
            className="blur-slider"
            min={0}
            max={10}
            value={editState?.bgImageBlur ?? 4}
            onChange={handleSliderChange}
          />
        )}
      </Col>
      <Col>
        <h4>Title</h4>
        <div className="background-container">
          <ColorPicker
            value={editState?.titleColor}
            onChange={(_, color) => handleBgColorChange(color, "titleColor")}
          >
            <div className="background-item color">
              <FontColorsOutlined />
            </div>
          </ColorPicker>
          <ColorPicker
            value={editState?.titleBackgroundColor}
            onChange={(_, color) =>
              handleBgColorChange(color, "titleBackgroundColor")
            }
          >
            <div className="background-item">
              <BgColorsOutlined />
            </div>
          </ColorPicker>
        </div>
      </Col>
      <Col>
        <h4>Body</h4>
        <div className="background-container">
          <ColorPicker
            value={editState?.bodyColor}
            onChange={(_, color) => handleBgColorChange(color, "bodyColor")}
          >
            <div className="background-item color">
              <FontColorsOutlined />
            </div>
          </ColorPicker>
          <ColorPicker
            value={editState?.bodyBackgroundColor}
            onChange={(_, color) =>
              handleBgColorChange(color, "bodyBackgroundColor")
            }
          >
            <div className="background-item">
              <BgColorsOutlined />
            </div>
          </ColorPicker>
        </div>
      </Col>
    </Row>
  );
}

export default EditingModules;
