import { createAsyncThunk } from "@reduxjs/toolkit";

import CONFIG from "../../../utils/config";
import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from "../../../api/baseAPI";
import { beginAPICall, endAPICall } from "../loading";
import { closeModal, openModal } from "../modal";
import { errorNotification } from "../../../utils/functions";
import { fetchUsers } from "../users/asyncThunks";

const initialObject = {
  loadOrNot: true,
  callback: () => {},
};

export const fetchTeams = createAsyncThunk(
  "teams/fetchTeams",
  async ({ loadOrNot, callback } = initialObject, { dispatch }) => {
    let {
      API_URLS: { FETCH_ALL_TEAMS },
    } = CONFIG;
    try {
      loadOrNot && dispatch(beginAPICall());
      let { data } = await getRequest(FETCH_ALL_TEAMS, { service: "ACCOUNT" });
      return data;
    } catch (error) {
      errorNotification({
        title: "API Error",
        message: "Failed to fetch teams",
      });
      throw new Error();
    } finally {
      loadOrNot && dispatch(endAPICall());
      callback();
    }
  }
);

export const createTeams = createAsyncThunk(
  "teams/createTeams",
  async (payload, { dispatch }) => {
    let {
      API_URLS: { CREATE_TEAM },
      MODALS: { ERROR },
    } = CONFIG;

    try {
      dispatch(beginAPICall());
      let {
        data: { data },
      } = await postRequest(CREATE_TEAM, payload, {
        service: "ACCOUNT",
      });
      if (data.source_type === "Salesforce") dispatch(fetchUsers());
      dispatch(closeModal());
      return data;
    } catch ({ response }) {
      const { status } = response;

      if (status === 403) {
        dispatch(
          openModal({
            key: ERROR.key,
            props: {
              content:
                "No users found matching the specified filter expression. Please adjust your filters and try again.",
              heading: "Team Creation Restricted",
            },
          })
        );
      } else {
        errorNotification({
          title: "Error API",
          message: "Failed to create team",
        });
        dispatch(closeModal());
      }
      throw new Error();
    } finally {
      dispatch(endAPICall());
    }
  }
);

export const updateTeams = createAsyncThunk(
  "teams/updateTeams",
  async ({ payload: { id, ...payload }, isSynced }, { dispatch }) => {
    let {
      API_URLS: { UPDATE_TEAM },
      MODALS: { ERROR },
    } = CONFIG;

    try {
      dispatch(beginAPICall());
      let {
        data: { data },
      } = await putRequest(UPDATE_TEAM + id + "/", payload, {
        service: "ACCOUNT",
      });
      if (data.source_type === "Salesforce") dispatch(fetchUsers());
      dispatch(closeModal());
      return data;
    } catch ({ response }) {
      const { status } = response;

      if (status === 403) {
        dispatch(
          openModal({
            key: ERROR.key,
            props: {
              content:
                "No users found matching the specified filter expression. Please adjust your filters and try again.",
              heading: "Team Creation Restricted",
            },
          })
        );
      } else {
        isSynced
          ? errorNotification({
              title: "Error API",
              message: "Failed to sync team",
            })
          : errorNotification({
              title: "Error API",
              message: "Failed to edit team",
            });
        dispatch(closeModal());
      }
      throw new Error();
    } finally {
      dispatch(endAPICall());
    }
  }
);

export const deleteTeams = createAsyncThunk(
  "teams/deleteTeams",
  async ({ data: { id, name }, callback = () => {} }, { dispatch }) => {
    let {
      API_URLS: { DELETE_TEAM },
      MODALS: { RESTRICT_DELETE },
    } = CONFIG;

    try {
      dispatch(beginAPICall());
      let {
        data: { data },
      } = await deleteRequest(DELETE_TEAM + id + "/", {
        service: "ACCOUNT",
      });
      if (data.source_type === "Salesforce") dispatch(fetchUsers());
      callback();
      return id;
    } catch ({ response }) {
      const { status, data } = response;
      if (status === 403) {
        delete data.message;
        dispatch(
          openModal({
            key: RESTRICT_DELETE.key,
            props: {
              modalData: { title: name, metadata: data, service: "Team" },
            },
          })
        );
      } else {
        errorNotification({
          title: "Error API",
          message: "Failed to delete team",
        });
        callback();
      }
      throw new Error();
    } finally {
      dispatch(endAPICall());
    }
  }
);
