import React from "react";
import { useDispatch } from "react-redux";
import { closeModal } from "../../../redux-toolkit/slice/modal";
import { Button, Modal } from "antd";

function ConfirmModal({ title, content, handleConfirm, handleClose }) {
  const dispatch = useDispatch();

  const handleCloseModal = () => {
    dispatch(closeModal());
  };

  return (
    <Modal
      key={title}
      title={title}
      centered
      className="common-modal"
      open={true}
      width={510}
      onCancel={handleClose || handleCloseModal}
      footer={
        <div className="footer-btns">
          {handleConfirm && (
            <Button className="primary-solid" onClick={handleConfirm}>
              Confirm
            </Button>
          )}
          <Button className="primary-outline" onClick={handleCloseModal}>
            Cancel
          </Button>
        </div>
      }
    >
      {content}
    </Modal>
  );
}

export default ConfirmModal;
