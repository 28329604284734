import React from "react";
import TimezoneSelect from "react-timezone-select";

function TimeZone({
  timezone = new Date(),
  setSettingState = () => {},
  className = "",
  disabled = false,
}) {
  const onChange = ({ value }) => {
    !disabled && setSettingState((prev) => ({ ...prev, timezone: value }));
  };

  return (
    <TimezoneSelect
      classNamePrefix="select-menu"
      value={timezone}
      onChange={onChange}
      className={className}
      isDisabled={disabled}
    />
  );
}

export default TimeZone;
