import {
  Checkbox,
  Col,
  Form,
  Radio,
  Row,
  Select,
  Space,
  Collapse,
  Tooltip,
} from "antd";
import React, { useMemo, useState } from "react";
import {
  PlusCircleFilled,
  MinusCircleFilled,
  DeleteOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import {
  createId,
  extraFiltersForFilterCriteria,
  filterFields,
  generateMultiSelectTitle,
  getMappingText,
  getNotificationOperation,
  isErrorPresent,
  isSubstring,
  onlyOnceNotificationDisabled,
  optionCreator,
  updatedValidationForStepTwo,
} from "../../../../../utils/functions";
import CONFIG from "../../../../../utils/config";
import ValueSelector from "./components/valueSelector";
import Validation from "../../../../../components/validation";
import ErrorMessage from "../../../../../components/validation/components/errorMessage";

function SecondStep({
  fields,
  notificationObject,
  setIsEdited,
  setNotificationObject,
}) {
  const {
    NOTIFICATION_OPERATION,
    LABELS: {
      VALIDATION: { REQUIRED: REQUIRED_RULE },
    },
    ERRORS: { REQUIRED },
  } = CONFIG;

  const { object, filterOptions } = notificationObject;

  const [currentFilterOption, setCurrentFilterOption] = useState({});
  const [showError, setShowError] = useState(false);
  const [selectedCheckbox, setSelectedCheckbox] = useState([]);
  const [reInitialize, setReInitialize] = useState(false);

  const handleCurrentFilterFieldChange = (field) => {
    setCurrentFilterOption({ field });
    setShowError(false);
  };

  const handleValueChange = (value) => {
    setCurrentFilterOption((prev) => ({
      ...prev,
      value: TYPE === "select" ? [...(prev.value ?? []), value] : value,
    }));
    setShowError(false);
  };

  const handleOpertorChange = (operator) => {
    setCurrentFilterOption({
      ...currentFilterOption,
      operator,
      value: undefined,
    });
    setShowError(false);
  };

  const handleAddOperation = (errorObject) => {
    setIsEdited(true);
    setShowError(true);
    if (isErrorPresent(errorObject)) return;
    else {
      setNotificationObject({
        ...notificationObject,
        filterOptions: [
          ...(notificationObject.filterOptions ?? []),
          { ...currentFilterOption, data_type: TYPE },
        ],
      });
      setCurrentFilterOption({});
      setReInitialize(true);
      setShowError(false);
    }
  };

  const handleCheckboxSelection = (isChecked, item) => {
    if (isChecked) setSelectedCheckbox((prev) => [...prev, item]);
    else
      setSelectedCheckbox((prev) =>
        prev.filter((prevItem) => prevItem !== item)
      );
  };

  const handleOperationDelete = (id) => {
    setIsEdited(true);
    setNotificationObject({
      ...notificationObject,
      filterOptions: notificationObject.filterOptions.filter(
        ({ field }) => !id.includes(field)
      ),
    });
    setSelectedCheckbox((prev) =>
      prev.filter((prevItem) => !id.includes(prevItem))
    );
  };

  const handleRadioChange = ({ target: { value } }) => {
    setIsEdited(true);
    setNotificationObject({
      ...notificationObject,
      evaluation: { criteria: value, onlyOnce: false },
    });
  };

  const handleOnlyOnceChange = ({ target: { checked } }) => {
    setIsEdited(true);
    setNotificationObject({
      ...notificationObject,
      evaluation: { ...notificationObject.evaluation, onlyOnce: checked },
    });
  };

  const handleDeselect = (deselectKey) => {
    setCurrentFilterOption((prev) => ({
      ...prev,
      value: prev.value.filter((key) => deselectKey !== key),
    }));
  };

  const VALIDATION_RULES = {
    field: [{ rule: REQUIRED_RULE, errorMessage: REQUIRED }],
    value: [{ rule: REQUIRED_RULE, errorMessage: REQUIRED }],
    operator: [{ rule: REQUIRED_RULE, errorMessage: REQUIRED }],
  };

  const {
    OPERATORS,
    TYPE,
    OPTIONS = [],
  } = useMemo(
    () =>
      getNotificationOperation(
        NOTIFICATION_OPERATION,
        currentFilterOption.field,
        fields
      ) ?? { OPERATORS: [], TYPE: null, OPTIONS: [] },
    [NOTIFICATION_OPERATION, currentFilterOption, fields]
  );

  const filteredFieldOption = useMemo(() => {
    return extraFiltersForFilterCriteria(
      optionCreator(filterFields(fields), "label", "name"),
      object
    ).filter(
      ({ value }) => !filterOptions?.some(({ field }) => field === value)
    );
  }, [fields, object, filterOptions]);

  return (
    <Validation
      validationRules={updatedValidationForStepTwo(VALIDATION_RULES, TYPE)}
      values={currentFilterOption}
      reIntialize={reInitialize}
      setReInitialize={setReInitialize}
    >
      {(errorObj) => {
        return (
          <Form name="form_item_path" layout="vertical">
            <Row gutter={[16, 16]} className="custom-height-100">
              <Col xs={12} sm={10} md={12} lg={8} xl={8}>
                <Form.Item label="Field" className="mb-0">
                  <Select
                    showSearch
                    placeholder="Search to Select"
                    optionFilterProp="children"
                    value={currentFilterOption.field}
                    filterOption={(input, option) =>
                      isSubstring(input, option.label)
                    }
                    options={filteredFieldOption}
                    onSelect={handleCurrentFilterFieldChange}
                  />
                  <ErrorMessage
                    errorObject={errorObj}
                    selector={"field"}
                    controlledShowError={showError}
                  />
                </Form.Item>
              </Col>
              <Col xs={12} sm={10} md={12} lg={8} xl={8}>
                <Form.Item label="Operator" className="mb-0">
                  <Select
                    showSearch
                    placeholder="Search to Select"
                    optionFilterProp="children"
                    value={currentFilterOption.operator}
                    filterOption={(input, option) =>
                      isSubstring(input, option.label)
                    }
                    options={optionCreator(OPERATORS)}
                    onSelect={handleOpertorChange}
                  />
                  <ErrorMessage
                    errorObject={errorObj}
                    selector={"operator"}
                    controlledShowError={showError}
                  />
                </Form.Item>
              </Col>
              <Col xs={12} sm={10} md={12} lg={7} xl={7}>
                <Form.Item label="Value" className="mb-0">
                  <ValueSelector
                    type={TYPE}
                    options={OPTIONS}
                    selectorValue={currentFilterOption.value}
                    handleValueChange={handleValueChange}
                    handleDeselect={handleDeselect}
                  />
                  <ErrorMessage
                    errorObject={errorObj}
                    selector={"value"}
                    controlledShowError={showError}
                  />
                </Form.Item>
              </Col>
              <Col className="plus-icon" xl={1}>
                <Form.Item>
                  <label style={{ height: "22px", display: "inline-block" }} />
                  <PlusCircleFilled
                    onClick={() => handleAddOperation(errorObj)}
                  />
                  <p className="no-errorMessage"></p>
                </Form.Item>
              </Col>
            </Row>
            <div className="notification-fields mb-3">
              <h4>
                {filterOptions?.length ?? 0} Selected Operations
                {selectedCheckbox.length ? (
                  <DeleteOutlined
                    onClick={() => handleOperationDelete(selectedCheckbox)}
                  />
                ) : null}
              </h4>
              <div className="notification-fields-list">
                {filterOptions?.map((data, index) => {
                  const { fieldText, operatorText, valueText } = getMappingText(
                    data,
                    fields
                  );

                  const { is_deletable } = data;
                  const key = createId(data, "field", index);
                  return (
                    <Row gutter={[16, 16]} key={key}>
                      <Col xs={10} sm={16} md={12} lg={8} xl={8}>
                        <Checkbox
                          disabled={!(is_deletable ?? true)}
                          checked={selectedCheckbox.includes(data.field)}
                          onChange={({ target: { checked } }) => {
                            handleCheckboxSelection(checked, data.field);
                          }}
                        >
                          <span>{fieldText}</span>
                        </Checkbox>
                      </Col>
                      <Col xs={10} sm={16} md={12} lg={8} xl={8}>
                        <span>{operatorText}</span>
                      </Col>
                      <Col xs={10} sm={16} md={12} lg={7} xl={7}>
                        <span>
                          {Array.isArray(valueText) ? (
                            <Tooltip
                              title={generateMultiSelectTitle(valueText)}
                            >
                              <span className="multi-select-box">
                                {valueText.length} selected values
                              </span>
                            </Tooltip>
                          ) : (
                            valueText
                          )}
                        </span>
                      </Col>
                      <Col xs={1} sm={1} md={1} lg={1} xl={1}>
                        {!(is_deletable ?? true) ? (
                          <Tooltip
                            title={
                              "Filter Expression from Metric can't be deleted from Threshold Creation"
                            }
                            key={key + "tooltip"}
                          >
                            <QuestionCircleOutlined />
                          </Tooltip>
                        ) : (
                          <MinusCircleFilled
                            onClick={() => handleOperationDelete(data.field)}
                          />
                        )}
                      </Col>
                    </Row>
                  );
                })}
              </div>
            </div>
            <Collapse
              items={[
                {
                  key: "1",
                  label: "Advanced Options",
                  children: (
                    <div>
                      <div className="d-flex">
                        <Radio.Group
                          onChange={handleRadioChange}
                          value={notificationObject?.evaluation?.criteria}
                        >
                          <Space direction="vertical">
                            <Radio value="default">
                              When Record is created, or when a record is
                              edited...
                            </Radio>
                            <Checkbox
                              className="d-flex ml-3"
                              disabled={onlyOnceNotificationDisabled(
                                notificationObject
                              )}
                              checked={notificationObject?.evaluation?.onlyOnce}
                              onChange={handleOnlyOnceChange}
                            >
                              Trigger only the first time
                            </Checkbox>
                            <Radio className="d-flex" value="create">
                              Only when a record is created.
                            </Radio>
                          </Space>
                        </Radio.Group>
                      </div>
                    </div>
                  ),
                },
              ]}
            />
          </Form>
        );
      }}
    </Validation>
  );
}

export default SecondStep;
