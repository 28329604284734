import React from "react";

function Notification({ src, title, message }) {
  return (
    <div className="alerts-box">
      <div className="alert-icon">
        <img src={src} alt="alert" />
      </div>
      <div className="alert-content">
        <h4>{title}</h4>
        <h5>{message}</h5>
      </div>
    </div>
  );
}

export default Notification;
