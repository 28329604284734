import { createSlice } from "@reduxjs/toolkit";

import INITIAL_STATE from "../../initialState";
import {
  createTeams,
  deleteTeams,
  fetchTeams,
  updateTeams,
} from "./asyncThunk";

export const teamsSlice = createSlice({
  name: "teams",
  initialState: INITIAL_STATE.TEAMS,
  reducers: {
    clearTeam: (state) => {
      state.groups = undefined;
      state.teamsList = undefined;
      state.error = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTeams.fulfilled, (state, { payload }) => {
      state.error = false;
      state.teamsList = payload;
    });

    builder.addCase(fetchTeams.rejected, (state, { error }) => {
      state.error = error;
    });

    builder.addCase(createTeams.fulfilled, (state, { payload }) => {
      state.error = false;
      state.teamsList.push(payload);
    });

    builder.addCase(createTeams.rejected, (state, { error }) => {
      state.error = error;
    });

    builder.addCase(updateTeams.fulfilled, (state, { payload }) => {
      state.error = false;
      let editedIndex = state.teamsList.findIndex(
        ({ id }) => id === payload.id
      );
      state.teamsList[editedIndex] = payload;
    });

    builder.addCase(updateTeams.rejected, (state, { error }) => {
      state.error = error;
    });

    builder.addCase(deleteTeams.fulfilled, (state, { payload }) => {
      state.error = false;
      state.teamsList = state.teamsList.filter(({ id }) => id !== payload);
    });

    builder.addCase(deleteTeams.rejected, (state, { error }) => {
      state.error = error;
    });
  },
});

export const { clearTeam } = teamsSlice.actions;

export default teamsSlice.reducer;
