import { Modal, Steps } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeModal } from "../../../redux-toolkit/slice/modal";
import StepButton from "./components/stepButton";
import CONFIG from "../../../utils/config";
import notificationDataSelector from "../../../redux-toolkit/selectors/notification";
import { kwipPayload, modifiedKwipData } from "../../../utils/functions";
import {
  createKwip,
  editKwip,
} from "../../../redux-toolkit/slice/kwips/asyncThunk";

function KwipModal({ kwipData, usersList, teamsList }) {
  const { KWIP_STEPS } = CONFIG;

  const dispatch = useDispatch();

  const { notificationList } = notificationDataSelector(useSelector);

  const [currentStep, setCurrent] = useState(0);
  const [kwipObject, setKwipObject] = useState({});
  const [isEdited, setIsEdited] = useState(false);

  const handleSave = () => {
    let payload = kwipPayload({ ...kwipObject });
    if (kwipObject.id)
      isEdited
        ? dispatch(editKwip({ payload, callback: handleCloseModal }))
        : handleCloseModal();
    else dispatch(createKwip({ payload, callback: handleCloseModal }));
  };

  const next = () => {
    switch (currentStep) {
      case 3:
        return handleSave();
      default:
        setCurrent(currentStep + 1);
    }
  };

  const prev = () => {
    setCurrent(currentStep - 1);
  };

  const handleCloseModal = () => {
    dispatch(closeModal());
  };

  useEffect(() => {
    kwipData && setKwipObject(modifiedKwipData(kwipData));
  }, [kwipData]);

  return (
    <Modal
      title={kwipData ? kwipData.name : "Create Kwip"}
      centered
      open={true}
      onOk={() => {}}
      onCancel={handleCloseModal}
      width={900}
      footer={
        <StepButton
          currentStep={currentStep}
          isStepLoading={false}
          kwipObject={kwipObject}
          prev={prev}
          next={next}
          handleCancel={handleCloseModal}
        />
      }
    >
      <Steps
        current={currentStep}
        percent={60}
        labelPlacement="vertical"
        items={KWIP_STEPS({})}
      />
      <div className="steps-content">
        {
          KWIP_STEPS({
            kwipObject,
            notificationList,
            usersList,
            teamsList,
            setIsEdited,
            setKwipObject,
          })[currentStep]?.content
        }
      </div>
      <div className="mb-3" />
    </Modal>
  );
}

export default KwipModal;
