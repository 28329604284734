import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { closeModal } from "../../../redux-toolkit/slice/modal";
import { Modal, Steps } from "antd";
import "./style.scss";
import CONFIG from "../../../utils/config";
import {
  createLeaderboard,
  editLeaderboard,
} from "../../../redux-toolkit/slice/leaderboard/asyncThunk";
import StepButton from "./components/stepButton";

function LeaderboardModal({ leaderboardData, usersList, groups }) {
  const { LEADERBOARD_STEPS } = CONFIG;

  const dispatch = useDispatch();

  const [currentStep, setCurrent] = useState(0);
  const [modalData, setModalData] = useState(leaderboardData ?? {});
  const [additionalUsers, setAdditionalUsers] = useState([]);

  const handleCloseModal = () => {
    dispatch(closeModal());
  };

  const handleSave = () => {
    leaderboardData
      ? dispatch(
          editLeaderboard({
            payload: {
              id: leaderboardData.id,
              ...modalData,
              additional_users: additionalUsers,
            },
          })
        )
      : dispatch(
          createLeaderboard({
            payload: { ...modalData, additional_users: additionalUsers },
          })
        );
  };

  const next = () => {
    switch (currentStep) {
      case 1: {
        handleSave();
        break;
      }
      default:
        setCurrent(currentStep + 1);
    }
  };

  const prev = () => {
    setCurrent(currentStep - 1);
  };

  useEffect(() => {
    setAdditionalUsers(leaderboardData?.additional_users ?? []);
  }, [leaderboardData]);

  return (
    <Modal
      key={leaderboardData ? leaderboardData.title : "Create New Leaderboard"}
      title={leaderboardData ? leaderboardData.title : "Create New Leaderboard"}
      centered
      open={true}
      width={900}
      onCancel={handleCloseModal}
      footer={
        <StepButton
          additionalUsers={additionalUsers}
          leaderboardData={modalData}
          currentStep={currentStep}
          prev={prev}
          next={next}
          handleCancel={handleCloseModal}
        />
      }
    >
      <Steps
        current={currentStep}
        percent={60}
        labelPlacement="vertical"
        items={LEADERBOARD_STEPS({})}
      />
      {
        LEADERBOARD_STEPS({
          modalData,
          setModalData,
          usersList,
          groups,
          additionalUsers,
          setAdditionalUsers,
        })[currentStep]?.content
      }
    </Modal>
  );
}

export default LeaderboardModal;
