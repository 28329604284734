import { Button, Modal, Slider } from "antd";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { FileImageOutlined } from "@ant-design/icons";
import { extractNumberFromPx } from "../../../utils/functions";
import html2canvas from "html2canvas";

function ImageAdjustment({ imgSrc, saveCallback, handleCloseModal }) {
  const imgRef = useRef(null);
  const containerRef = useRef(null);
  const dragStartRef = useRef(null);
  const cropRef = useRef(null);

  const [style, setStyle] = useState({});
  const [sliderValue, setSliderValue] = useState(0);

  const adjustImage = useCallback(() => {
    const img = imgRef.current;
    const container = containerRef.current;
    if (!img || !container) return;

    const aspectRatio = img.naturalWidth / img.naturalHeight;
    let baseWidth, baseHeight;
    if (img.naturalHeight < img.naturalWidth) {
      baseHeight = 200;
      baseWidth = 200 * aspectRatio;
    } else {
      baseWidth = 200;
      baseHeight = 200 / aspectRatio;
    }

    const scaledHeight = baseHeight * (1 + sliderValue / 10);
    const scaledWidth = baseWidth * (1 + sliderValue / 10);

    const newTop = (container.clientHeight - scaledHeight) / 2;
    const newLeft = (container.clientWidth - scaledWidth) / 2;

    setStyle({
      height: `${scaledHeight}px`,
      width: `${scaledWidth}px`,
      top: `${newTop}px`,
      left: `${newLeft}px`,
      position: "absolute",
    });
  }, [sliderValue]);

  const startDrag = (e) => {
    dragStartRef.current = {
      startClientX: e.clientX,
      startClientY: e.clientY,
      startLeft: style.left,
      startTop: style.top,
    };
  };

  const onDrag = (e) => {
    if (!dragStartRef.current) return;
    const diffX = e.clientX - dragStartRef.current.startClientX;
    const diffY = e.clientY - dragStartRef.current.startClientY;
    setStyle((prevStyle) => ({
      ...prevStyle,
      left: `${+extractNumberFromPx(dragStartRef.current.startLeft) + diffX}px`,
      top: `${+extractNumberFromPx(dragStartRef.current.startTop) + diffY}px`,
    }));
  };

  const endDrag = () => {
    dragStartRef.current = null;
  };

  const captureScreenshot = async () => {
    return await html2canvas(cropRef.current, {
      useCORS: false, // in case you have images stored in your application
    });
  };

  const handleSave = async () => {
    const canvas = await captureScreenshot();
    let dataURI = canvas.toDataURL("capturedIamge/png");
    saveCallback(dataURI);
    handleCloseModal();
  };

  useEffect(() => {
    const img = imgRef.current;
    if (img.complete) {
      adjustImage();
    } else {
      img.addEventListener("load", adjustImage);
      return () => img.removeEventListener("load", adjustImage);
    }
  }, [adjustImage, imgSrc, sliderValue]);

  return (
    <Modal
      key={"Image Adjustment"}
      title={"Image Adjustment"}
      centered
      className="common-modal"
      open={true}
      width={461}
      onCancel={handleCloseModal}
      footer={
        <div className="footer-btns">
          <Button className="primary-solid" onClick={handleSave}>
            Save
          </Button>
          <Button className="primary-outline" onClick={handleCloseModal}>
            Cancel
          </Button>
        </div>
      }
    >
      <div
        id="img_container"
        className="img-container"
        ref={containerRef}
        onMouseDown={startDrag}
        onMouseMove={onDrag}
        onMouseUp={endDrag}
        onMouseLeave={endDrag}
      >
        <div className="img-crop" ref={cropRef}>
          <img
            id="img_zoom"
            className="img-zoom"
            ref={imgRef}
            src={imgSrc}
            alt="zoom-img"
            style={style}
          />
        </div>
        <div id="img_shadow" className="img-shadow">
          <div className="w-100 h-100" />
        </div>
      </div>
      <div className="d-flex flex-column align-items-center mt-3">
        <div> Drag and Resize to adjust profile image</div>
      </div>
      <div className="position-relative">
        <FileImageOutlined className="small-icon" />
        <Slider
          className="adjustment-slider"
          value={sliderValue}
          min={0}
          max={50}
          tooltip={{
            open: false,
          }}
          onChange={setSliderValue}
        />
        <FileImageOutlined className="big-icon" />
      </div>
    </Modal>
  );
}

export default ImageAdjustment;
