import { Button, Modal, Table } from "antd";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { closeModal } from "../../../redux-toolkit/slice/modal";
import { useDispatch } from "react-redux";
import {
  columnAndComponentFunctionReference,
  filterTableColumn,
  generateMetricPreviewPayload,
  isPreviewValueChangeAllowed,
  metricPreviewTableData,
  withKeyDataSet,
} from "../../../utils/functions";
import CONFIG from "../../../utils/config";
import userDataSelector from "../../../redux-toolkit/selectors/userData";
import { useSelector } from "react-redux";
import { fetchUsers } from "../../../redux-toolkit/slice/users/asyncThunks";
import { updateMetricPreview } from "../../../redux-toolkit/slice/metrices/asyncThunk";

function MetricPreview({ metricData }) {
  const {
    METRIC_PREVIEW_COLUMN,
    LABELS: { ID },
  } = CONFIG;

  const { decimal_places } = useMemo(() => metricData, [metricData]);

  const prevTableEditValue = useRef({});

  const dispatch = useDispatch();

  const { usersList } = userDataSelector(useSelector);

  const [tableData, setTableData] = useState([]);
  const [editColumnId, setEditColumnId] = useState(null);
  const [isTableEdited, setIsTableEdited] = useState(false);

  const handleCloseModal = () => {
    dispatch(closeModal());
  };

  const handleSave = () => {
    const payload = generateMetricPreviewPayload(tableData, metricData);
    dispatch(updateMetricPreview({ payload }));
  };

  const handleEdit = useCallback(({ id }) => {
    setEditColumnId(id);
    if (prevTableEditValue?.current?.index !== undefined) {
      setTableData((prev) =>
        prev.map((data, index) => {
          if (index === prevTableEditValue.current.index) {
            let tempObj = { ...prevTableEditValue.current };
            delete tempObj.index;
            return { ...data, ...tempObj, isEditedViaWeb: false };
          } else return data;
        })
      );
    }
  }, []);

  const handleSaveRecord = useCallback(() => {
    setEditColumnId(null);
    prevTableEditValue.current = {};
    setIsTableEdited(true);
  }, []);

  const setPrevTableEditValue = (key, value, index) => {
    if (prevTableEditValue.current.index !== index)
      prevTableEditValue.current = {};

    if (prevTableEditValue.current[key] !== undefined) return;

    prevTableEditValue.current[key] = value;
    prevTableEditValue.current.index = index;
  };

  const handleTableEntryChange = useCallback(
    ({ startSpecial, number, endSpecial }, key, index) => {
      if (isPreviewValueChangeAllowed(number, decimal_places)) {
        setTableData((prev) =>
          prev.map((data, idx) => {
            if (idx === index) {
              setPrevTableEditValue(key, data[key], index);
              return {
                ...data,
                [key]: startSpecial + number + endSpecial,
                isEditedViaWeb: true,
              };
            }
            return data;
          })
        );
      }
    },
    [decimal_places]
  );

  const tableColumn = useMemo(
    () =>
      filterTableColumn(
        columnAndComponentFunctionReference(METRIC_PREVIEW_COLUMN, {
          editColumnId,
          handleEdit,
          handleSaveRecord,
          handleTableEntryChange,
        }),
        metricData.timeframes
      ),
    [
      handleEdit,
      editColumnId,
      handleSaveRecord,
      metricData.timeframes,
      METRIC_PREVIEW_COLUMN,
      handleTableEntryChange,
    ]
  );

  useEffect(() => {
    !usersList && dispatch(fetchUsers());
  }, [usersList, dispatch]);

  useEffect(() => {
    setTableData(metricPreviewTableData(usersList, metricData));
  }, [metricData, usersList]);

  return (
    <Modal
      key={metricData.name}
      title={metricData.name}
      centered
      className="common-modal"
      open={true}
      width={"90%"}
      onCancel={handleCloseModal}
      footer={
        <div className="footer-btns">
          <Button
            className="primary-solid"
            onClick={handleSave}
            disabled={!isTableEdited}
          >
            Save
          </Button>
          <Button className="primary-outline" onClick={handleCloseModal}>
            Cancel
          </Button>
        </div>
      }
    >
      <Table
        className="cmn-table"
        columns={tableColumn}
        dataSource={withKeyDataSet(ID, tableData || [])}
        scroll={{ y: "calc(70vh - 60px)" }}
        pagination={false}
      />
    </Modal>
  );
}

export default MetricPreview;
