import React, { useEffect, useMemo, useState } from "react";
import { Button, Col, Form, Row, Select } from "antd";
import {
  filterCreditToOption,
  filterFields,
  filterUserRecognitions,
  isSubstring,
  thresholdUserRecognitionOption,
} from "../../../../../utils/functions";
import CONFIG from "../../../../../utils/config";
import ErrorMessage from "../../../../../components/validation/components/errorMessage";
import { CloseCircleFilled } from "@ant-design/icons";

function ForthStep({
  fields,
  notificationObject,
  setIsEdited,
  setNotificationObject,
}) {
  const {
    ERRORS: { MERGE_FIELD },
  } = CONFIG;

  const [currentField, setCurrentField] = useState(null);
  const [fieldError, setFieldError] = useState({
    field: { errorMessage: MERGE_FIELD, showError: false },
  });

  const handleCurrentFieldChange = (key, { label, data_type }) => {
    setCurrentField({ id: key, label, data_type });
    setFieldError({
      ...fieldError,
      field: { ...fieldError.field, showError: false },
    });
  };

  const handleInsertClick = () => {
    if (currentField) {
      setNotificationObject((prev) => ({
        ...prev,
        recognitionFields: [...(prev?.recognitionFields ?? []), currentField],
      }));
      setIsEdited(true);
      setCurrentField(null);
    } else
      setFieldError({
        ...fieldError,
        field: { ...fieldError.field, showError: true },
      });
  };

  const handleDeselect = (deletedId) => {
    setNotificationObject((prev) => ({
      ...prev,
      recognitionFields: prev.recognitionFields.filter(
        ({ id }) => id !== deletedId
      ),
    }));
    setIsEdited(true);
  };

  let recognitionFileds = useMemo(() => {
    let fieldsArray = thresholdUserRecognitionOption(
      filterUserRecognitions(filterFields(fields))
    );

    if (notificationObject.object === "Opportunity")
      fieldsArray.splice(1, 0, {
        label: "Account Name",
        value: "account_name",
        key: "account_name",
        data_type: "string",
      });

    return filterCreditToOption(
      fieldsArray,
      notificationObject?.recognitionFields ?? [],
      "id"
    );
  }, [fields, notificationObject]);

  useEffect(() => {
    if (
      !notificationObject?.recognitionFields &&
      !notificationObject?.recognitionFields?.length
    )
      switch (notificationObject.object) {
        case "Opportunity": {
          setNotificationObject((prev) => ({
            ...prev,
            recognitionFields: [
              { id: "Name", label: "Name", data_type: "string" },
              {
                id: "account_name",
                label: "Account Name",
                data_type: "string",
              },
              {
                id: "CloseDate",
                label: "Close Date",
                data_type: "date",
              },
            ],
          }));
          return;
        }

        case "Task": {
          setNotificationObject((prev) => ({
            ...prev,
            recognitionFields: [
              { id: "Status", label: "Status", data_type: "picklist" },
            ],
          }));
          return;
        }

        default:
          (() => {})();
      }
  }, []);

  return (
    <Form name="form_item_path" layout="vertical" onFinish={() => {}}>
      <Row gutter={[16, 16]}>
        <Col
          xs={20}
          sm={4}
          md={6}
          lg={12}
          xl={12}
          className="d-flex flex-column"
        >
          <Form.Item label="Recognition Fields">
            <Select
              showSearch
              placeholder="Search Fields to broadcast in Kwips"
              optionFilterProp="children"
              value={currentField}
              filterOption={(input, option) => isSubstring(input, option.label)}
              options={recognitionFileds}
              onSelect={handleCurrentFieldChange}
            />
            <ErrorMessage errorObject={fieldError} selector={"field"} />
          </Form.Item>
          <Button className="primary-solid w-25" onClick={handleInsertClick}>
            Add
          </Button>
        </Col>
        <Col xs={20} sm={16} md={12} lg={12} xl={12}>
          <div className="credit-list-scroll">
            {notificationObject?.recognitionFields?.map(({ id, label }) => {
              return (
                <div className="member-list recognition-fields" key={id}>
                  <span>{label}</span>
                  <span className="select-dropdown">
                    <CloseCircleFilled onClick={() => handleDeselect(id)} />
                  </span>
                </div>
              );
            })}
          </div>
        </Col>
      </Row>
    </Form>
  );
}

export default ForthStep;
