import React from "react";
import { Button } from "antd";
import CONFIG from "../../../../utils/config";
import { isCurrentLeaderboardStepDisabled } from "../../../../utils/functions";

function StepButton({
  additionalUsers,
  leaderboardData,
  currentStep,
  prev,
  next,
  handleCancel,
}) {
  const {
    LABELS: {
      NOTIFICATIONS: { PREV, NEXT, CANCEL, SAVE },
    },
  } = CONFIG;

  const disabledObj = isCurrentLeaderboardStepDisabled(
    currentStep,
    leaderboardData,
    additionalUsers
  );

  return (
    <div className="footer-btns">
      {currentStep !== 0 && (
        <Button className="primary-outline" onClick={() => prev()}>
          {PREV}
        </Button>
      )}
      <Button
        className="primary-solid position-relative"
        onClick={() => next()}
        disabled={disabledObj.next || disabledObj.save}
      >
        {currentStep === 1 ? SAVE : NEXT}
      </Button>
      {currentStep === 0 && (
        <Button className="primary-outline" onClick={() => handleCancel()}>
          {CANCEL}
        </Button>
      )}
    </div>
  );
}

export default StepButton;
