import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import dashboardDataSelector from "../../../redux-toolkit/selectors/dashboardData";
import { formatChartData, getKwipSendData } from "../../../utils/functions";
import LineGraph from "../../../components/lineGraph";
import { Card, Col, Row } from "antd";

function KwipsSend({ prevCurrentFilter }) {
  const { currentFilter, kwipLogs } = dashboardDataSelector(useSelector);

  const lineChartData = useMemo(() => {
    if (prevCurrentFilter === currentFilter)
      return formatChartData(getKwipSendData(kwipLogs, "Kwips") ?? [], 6);

    return formatChartData(
      getKwipSendData(kwipLogs, "Kwips") ?? [],
      currentFilter === 6 ? prevCurrentFilter : currentFilter
    );
  }, [kwipLogs, currentFilter, prevCurrentFilter]);

  const lineChartOption = useMemo(
    () => ({
      xField: "date",
      yField: "count",
      seriesField: "category",
      smooth: true,
    }),
    []
  );

  return (
    <div>
      <Row className="mt-2" gutter={16}>
        <Col xl={24}>
          <Card
            title={
              <div className="weight-boldest">Kwips Sent and Received</div>
            }
            bordered={false}
          >
            <div style={{ height: "350px", margin: "30px 0" }}>
              <LineGraph data={lineChartData} options={lineChartOption} />
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default KwipsSend;
