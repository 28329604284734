import React, { useEffect, useMemo, useState } from "react";
import { Form, Input, Col, Row, Button, Card } from "antd";
import logo from "../../assets/images/logo.png";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAdminDetails,
  passwordStatus,
  setPassword,
} from "../../redux-toolkit/slice/admin/asyncThunk";
import { useNavigate } from "react-router-dom";
import CONFIG from "../../utils/config";
import loadingDataSelector from "../../redux-toolkit/selectors/loadingData";
import Overlay from "../../components/overlay";
import Loader from "../../components/loader";
import ErrorMessage from "../../components/validation/components/errorMessage";
import adminDataSelector from "../../redux-toolkit/selectors/adminData";
import { setToken } from "../../utils/functions";

const location = window.location;

const initialError = {
  password: { errorMessage: "Password is required", showError: false },
  confirmPassword: {
    errorMessage: "Passwords do not match",
    showError: false,
  },
};

function Password() {
  const {
    ROUTES: { LOGIN },
    TOKEN: { ACCESS_TOKEN, REFRESH_TOKEN },
  } = CONFIG;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { loading } = loadingDataSelector(useSelector);
  const {
    adminDetails: { isPasswordSecured, id } = {},
    passwordStatus: { message, status } = {},
  } = adminDataSelector(useSelector);

  const [errorObject, setErrorObject] = useState(initialError);
  const [userPassword, setUserPassword] = useState({
    value: null,
    confirmValue: null,
  });

  const token = useMemo(
    () => new URLSearchParams(location.search).get("token"),
    []
  );

  const userId = useMemo(
    () => new URLSearchParams(location.search).get("id"),
    []
  );

  const handleChange = ({ target: { name, value } }) => {
    setUserPassword({ ...userPassword, [name]: value });
    setErrorObject(initialError);
  };

  const handleSubmit = () => {
    if (!userPassword.value) {
      setErrorObject({
        ...errorObject,
        password: { ...errorObject.password, showError: true },
      });
      return;
    }

    if (userPassword.value !== userPassword.confirmValue) {
      setErrorObject({
        ...errorObject,
        confirmPassword: { ...errorObject.confirmPassword, showError: true },
      });
      return;
    }

    dispatch(
      setPassword({
        payload: {
          id: id ?? userId,
          token,
          password: userPassword.value,
          type: token ? "forget" : "new",
        },
        callback: () => {
          if (token) {
            setToken(ACCESS_TOKEN, "");
            setToken(REFRESH_TOKEN, "");
            navigate(LOGIN.path);
          } else dispatch(fetchAdminDetails());
        },
      })
    );
  };

  useEffect(() => {
    if (!token && isPasswordSecured !== false) {
      navigate(LOGIN.path);
    } else if (token) dispatch(passwordStatus({ payload: { token, userId } }));
  }, [LOGIN, token, dispatch, navigate, userId, isPasswordSecured]);

  return status || isPasswordSecured === false ? (
    <div className="form-outer-box">
      <Overlay show={loading}>
        <Loader />
      </Overlay>
      <Row>
        <Col span={12}>
          <img width="120px" src={logo} alt="logo" />
        </Col>
      </Row>
      <Row className="form-row" justify={"center"}>
        <Col lg={10}>
          <Form
            className="form-shadow"
            name="trigger"
            style={{
              maxWidth: 600,
            }}
            layout="vertical"
            autoComplete="off"
          >
            <h4>Create New Password</h4>
            <Form.Item hasFeedback label="Create password">
              <Input.Password
                name="value"
                placeholder="Enter Password"
                value={userPassword.value}
                onChange={handleChange}
              />
              <ErrorMessage errorObject={errorObject} selector={"password"} />
            </Form.Item>
            <Form.Item hasFeedback label="Confirm password">
              <Input.Password
                name="confirmValue"
                placeholder="Confirm Password"
                value={userPassword.confirmValue}
                onChange={handleChange}
              />
              <ErrorMessage
                errorObject={errorObject}
                selector={"confirmPassword"}
              />
            </Form.Item>
            <Button
              block
              type="primary"
              htmlType="submit"
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </Form>
        </Col>
      </Row>
    </div>
  ) : (
    <div className="form-outer-box">
      <Overlay show={loading}>
        <Loader />
      </Overlay>
      <Row className="form-row" justify={"center"}>
        <Card title={message} bordered={false} className="card-no-body" />
      </Row>
    </div>
  );
}

export default Password;
