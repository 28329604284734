import { Button, Col, Row, Table } from "antd";
import React, { useCallback, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { openModal } from "../../redux-toolkit/slice/modal";
import CONFIG from "../../utils/config";
import { fetchMetrices } from "../../redux-toolkit/slice/metrices/asyncThunk";
import metricesDataSelector from "../../redux-toolkit/selectors/metricesData";
import { useSelector } from "react-redux";
import { fetchTeams } from "../../redux-toolkit/slice/teams/asyncThunk";
import teamsDataSelector from "../../redux-toolkit/selectors/teamData";
import leaderboardDataSelector from "../../redux-toolkit/selectors/leaderboardData";
import {
  deleteLeaderboard,
  fetchLeaderboard,
} from "../../redux-toolkit/slice/leaderboard/asyncThunk";
import {
  columnAndComponentFunctionReference,
  withKeyDataSet,
} from "../../utils/functions";
import { fetchUsers } from "../../redux-toolkit/slice/users/asyncThunks";

function Leaderboard() {
  const {
    MODALS: { LEADERBOARD, LEADERBOARD_PREVIEW },
    LEADERBOARD_COLUMN,
    LABELS: { ID },
    LEADERBOARD_CALCULATION_OPTIONS,
    LEADERBOARD_TIMEFRAME_OPTIONS,
  } = CONFIG;

  const dispatch = useDispatch();

  const { metricesList } = metricesDataSelector(useSelector);
  const { leaderboardList } = leaderboardDataSelector(useSelector);
  const { usersList, teamsList } = teamsDataSelector(useSelector);

  const handleAddNewLeaderboard = () => {
    dispatch(
      openModal({
        key: LEADERBOARD.key,
        props: {
          usersList,
          teamsList,
          groups: teamsList,
        },
      })
    );
  };

  const handleEdit = useCallback(
    (data) => {
      dispatch(
        openModal({
          key: LEADERBOARD.key,
          props: {
            leaderboardData: data,
            usersList,
            teamsList,
            groups: teamsList,
          },
        })
      );
    },
    [LEADERBOARD.key, dispatch, teamsList, usersList]
  );

  const handleDelete = useCallback(
    (data) => {
      dispatch(deleteLeaderboard({ id: data.id }));
    },
    [dispatch]
  );

  const handlePreview = useCallback(
    (record) => {
      dispatch(
        openModal({
          key: LEADERBOARD_PREVIEW.key,
          props: { leaderboardData: record },
        })
      );
    },
    [LEADERBOARD_PREVIEW, dispatch]
  );

  useEffect(() => {
    !metricesList && dispatch(fetchMetrices());
  }, [dispatch, metricesList]);

  useEffect(() => {
    !teamsList &&
      dispatch(
        fetchTeams({
          showNotification: false,
          loadOrNot: true,
          callback: () => {},
        })
      );
  }, [teamsList, dispatch]);

  useEffect(() => {
    if (!usersList) dispatch(fetchUsers());
  }, [usersList, dispatch]);

  useEffect(() => {
    !leaderboardList && dispatch(fetchLeaderboard());
  }, [dispatch, leaderboardList]);

  const tableColumn = useMemo(
    () =>
      columnAndComponentFunctionReference(LEADERBOARD_COLUMN, {
        teamsList,
        metricesList,
        handleEdit,
        handleDelete,
        handlePreview,
        calculationList: LEADERBOARD_CALCULATION_OPTIONS,
        timeframeList: LEADERBOARD_TIMEFRAME_OPTIONS,
      }),
    [
      teamsList,
      metricesList,
      handleEdit,
      handleDelete,
      handlePreview,
      LEADERBOARD_COLUMN,
      LEADERBOARD_CALCULATION_OPTIONS,
      LEADERBOARD_TIMEFRAME_OPTIONS,
    ]
  );

  return (
    <div className="userContainer">
      <Row className="filter-head">
        <Col span={12}>
          <h2>LEADERBOARDS</h2>
        </Col>
        <Col style={{ justifyContent: "end", display: "flex" }} span={12}>
          <Button className="primary-outline" onClick={handleAddNewLeaderboard}>
            Add Leaderboard
          </Button>
        </Col>
      </Row>

      <Table
        className="cmn-table"
        columns={tableColumn}
        dataSource={withKeyDataSet(ID, leaderboardList || [])}
        scroll={{ y: "calc(70vh - 60px)" }}
        pagination={{}}
      />
    </div>
  );
}

export default Leaderboard;
