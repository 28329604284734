import React from "react";
import Marquee from "react-fast-marquee";

const Marque = ({ messages, bgColor }) => {
  return (
    <Marquee className="marquee" pauseOnHover speed={80}>
      <div className="d-flex">
        {messages?.map(({ message }) => (
          <p className="mr-5"> {message} </p>
        ))}
      </div>
    </Marquee>
  );
};

export default Marque;
