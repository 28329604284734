import { Button, Modal, Radio, Space } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { closeModal } from "../../../redux-toolkit/slice/modal";
import { useDispatch, useSelector } from "react-redux";
import CONFIG from "../../../utils/config";
import {
  editUser,
  sendMail,
} from "../../../redux-toolkit/slice/users/asyncThunks";
import { getEditUserPayload } from "../../../utils/functions";
import adminDataSelector from "../../../redux-toolkit/selectors/adminData";

function UserSetting({ userData }) {
  const {
    LABELS: {
      USER: {},
    },
  } = CONFIG;

  const dispatch = useDispatch();

  const { adminDetails } = adminDataSelector(useSelector);

  const [userRole, setUserRole] = useState();

  const { email: adminEmail } = adminDetails || {};
  const { name, email: userEmail } = userData;

  const isChangeDisabled = useMemo(
    () => adminEmail === userEmail,
    [userEmail, adminEmail]
  );

  const handleCloseModal = () => {
    dispatch(closeModal());
  };

  const handleChange = ({ target: { value } }) => {
    !isChangeDisabled && setUserRole(value);
  };

  const handleSave = () => {
    !isChangeDisabled &&
      dispatch(
        editUser({
          payload: {
            ...getEditUserPayload(userData),
            role: userRole,
            isAppUser: userRole === "super_admin" ? true : userData.isAppUser,
          },
          callback: () => {
            userRole === "super_admin" &&
              dispatch(
                sendMail({ payload: { ...userData, wantsToSendEmail: true } })
              );
            handleCloseModal();
          },
        })
      );
  };

  useEffect(() => {
    userData.role && setUserRole(userData.role);
  }, [userData]);

  return (
    <Modal
      key={name}
      title={name}
      centered
      className="common-modal"
      open={true}
      width={450}
      onCancel={handleCloseModal}
      footer={
        <div className="footer-btns">
          <Button className="primary-solid" onClick={handleSave}>
            Save
          </Button>
          <Button className="primary-outline" onClick={handleCloseModal}>
            Cancel
          </Button>
        </div>
      }
    >
      <p style={{ marginTop: "0" }}>
        Select Role for the user :-{" "}
        {isChangeDisabled && (
          <span className="error error-setting">(change is restricted)</span>
        )}
      </p>
    </Modal>
  );
}

export default UserSetting;
