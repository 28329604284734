import React from "react";

export default function ErrorMessage({
  mainClassName,
  errorObject,
  selector,
  controlledShowError,
}) {
  let { errorMessage, showError } = errorObject[selector] || {};

  if (controlledShowError)
    return (
      <div className={`errorMessage ${mainClassName ?? ""}`}>
        {errorMessage}
      </div>
    );
  else if (controlledShowError === false) return null;

  return (
    showError && (
      <div className={`errorMessage ${mainClassName ?? ""}`}>
        {errorMessage}
      </div>
    )
  );
}
