import React, { useMemo } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

function LineGraph({ data, options }) {
  const chartData = useMemo(() => {
    let reducedObj = data.reduce((acc, obj) => {
      if (acc[obj[options.xField]]) {
        acc[obj[options.xField]][obj[options.seriesField]] =
          obj[options.yField];
      } else {
        acc[obj[options.xField]] = {
          name: obj[options.xField],
          [obj[options.seriesField]]: obj[options.yField],
        };
      }
      return acc;
    }, {});
    return Object.values(reducedObj);
  }, [data]);

  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart
        width={500}
        height={300}
        data={chartData}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3" />
        <Legend />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Line
          type="monotone"
          dataKey="Kwips Sent"
          stroke="#8884d8"
          activeDot={{ r: 8 }}
        />
        <Line type="monotone" dataKey="Kwips Received" stroke="#82ca9d" />
      </LineChart>
    </ResponsiveContainer>
  );
}

export default LineGraph;
