import { Col, Form, Input, Row, Select } from "antd";
import React from "react";
import { optionCreator } from "../../../../utils/functions";

function StepOne({ kwipObject, notificationList, setKwipObject, setIsEdited }) {
  const handleInputChange = ({ target: { value } }) => {
    setKwipObject((prev) => ({ ...prev, name: value }));
    setIsEdited(true);
  };

  const handleSelectChange = (key, value) => {
    setIsEdited(true);
    setKwipObject((prev) => ({ ...prev, [key]: value }));
  };

  return (
    <Form name="form_item_path" layout="vertical" onFinish={() => {}}>
      <Row className="mb-3 mt-3">
        <Col xs={20} sm={4} md={6} lg={8} xl={12}>
          <Form.Item
            label="Kwip Name"
            className="mb-0 no-form-item"
            children={null}
            required
          />
          <Input
            placeholder="Enter kwip name here. . ."
            name="titleName"
            value={kwipObject?.name}
            onChange={handleInputChange}
          />
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col xs={20} sm={4} md={6} lg={8} xl={12}>
          <Form.Item
            label="Kwip Type"
            className="mb-0 no-form-item"
            children={null}
            required
          />
          <Select
            style={{ width: "100%" }}
            value={kwipObject?.type}
            options={[{ label: "Salesforce", value: "salesforce" }]}
            onChange={(value) => handleSelectChange("type", value)}
          />
        </Col>
        {kwipObject?.type === "salesforce" && (
          <Col xs={20} sm={4} md={6} lg={8} xl={12}>
            <Form.Item
              label="Salesforce Threshold"
              className="mb-0 no-form-item"
              children={null}
              required
            />
            <Select
              style={{ width: "100%" }}
              value={kwipObject?.notification}
              options={optionCreator(notificationList, "event_name", "id")}
              onChange={(value) => handleSelectChange("notification", value)}
            />
          </Col>
        )}
      </Row>
    </Form>
  );
}

export default StepOne;
