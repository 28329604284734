import React from "react";
import { Col, Row } from "antd";
import user from "../../../../assets/images/dummyUser.png";

function ThreeUser({
  bgImage,
  bgColor,
  bgImageBlur = 4,
  titleBackgroundColor,
  titleColor,
  bodyBackgroundColor,
  bodyColor,
}) {
  return (
    <div
      className="template-modal"
      style={
        bgImage
          ? { backgroundImage: `url(${bgImage})` }
          : { backgroundColor: bgColor }
      }
    >
      <Row
        className="template-row"
        style={{ backdropFilter: `blur(${bgImageBlur}px)` }}
        gutter={[16, 16]}
      >
        <Col sm={12} md={11}>
          <Row gutter={[8, 8]} style={{ justifyContent: "center" }}>
            <Col md={12}>
              <div className="tempalte-profile-pic">
                <div className="tempalte-profile-pic-box">
                  <img className="template-profile" src={user} alt="user" />
                </div>
              </div>
            </Col>
            <Col md={12}>
              <div className="tempalte-profile-pic">
                <div className="tempalte-profile-pic-box">
                  <img className="template-profile" src={user} alt="user" />
                </div>
              </div>
            </Col>
            <Col md={12}>
              <div className="tempalte-profile-pic">
                <div className="tempalte-profile-pic-box">
                  <img className="template-profile" src={user} alt="profile" />
                </div>
              </div>
            </Col>
          </Row>
        </Col>
        <Col sm={12} md={13}>
          <h3
            style={{
              backgroundColor: titleBackgroundColor,
              color: titleColor,
            }}
            className="title"
          >
            Title
          </h3>
          <p
            style={{
              backgroundColor: bodyBackgroundColor,
              color: bodyColor,
              height: "210px",
            }}
            className="description"
          >
            Message Text
          </p>
        </Col>
      </Row>
    </div>
  );
}

export default ThreeUser;
