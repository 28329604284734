import { createAsyncThunk } from "@reduxjs/toolkit";

import CONFIG from "../../../utils/config";
import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from "../../../api/baseAPI";
import { beginAPICall, endAPICall } from "../loading";
import { closeModal, openModal } from "../modal";
import { errorNotification } from "../../../utils/functions";

export const fetchNotifications = createAsyncThunk(
  "notification/fetchNotifications",
  async (_, { dispatch }) => {
    let {
      API_URLS: { FETCH_NOTIFICATIONS },
    } = CONFIG;
    try {
      dispatch(beginAPICall());
      let { data } = await getRequest(FETCH_NOTIFICATIONS, {
        service: "SALESFORCE",
      });
      return data;
    } catch ({ response }) {
      const { data } = response;
      errorNotification({
        title: "API Error",
        message: "Failed to fetch Thresholds",
      });
      throw new Error(JSON.stringify(data));
    } finally {
      dispatch(endAPICall());
    }
  }
);

export const fetchObjects = createAsyncThunk(
  "notification/fetchObjects",
  async (_, { dispatch }) => {
    let {
      API_URLS: { FETCH_NOTIFICATION_OBJECTS },
    } = CONFIG;
    try {
      dispatch(beginAPICall());
      let {
        data: {
          data: { sobjects },
        },
      } = await getRequest(FETCH_NOTIFICATION_OBJECTS, {
        service: "SALESFORCE",
      });
      return sobjects;
    } catch ({ response }) {
      const { data } = response;
      errorNotification({
        title: "API Failed",
        message: "Failed to fetch objects from Salesforce",
      });
      throw new Error(JSON.stringify(data));
    } finally {
      dispatch(endAPICall());
    }
  }
);

export const fetchFields = createAsyncThunk(
  "notification/fetchFields",
  async ({ object, callback }) => {
    let {
      API_URLS: { FETCH_NOTIFICATION_FIELDS },
    } = CONFIG;

    try {
      let {
        data: {
          data: { fields },
        },
      } = await postRequest(
        FETCH_NOTIFICATION_FIELDS,
        { object },
        {
          service: "SALESFORCE",
        }
      );
      return fields;
    } catch ({ response }) {
      const { data } = response;
      errorNotification({
        title: "API Error",
        message: "Failed to fetch Salesforce Fields",
      });
      throw new Error(JSON.stringify(data));
    } finally {
      callback();
    }
  }
);

export const createNotification = createAsyncThunk(
  "notification/createNotification",
  async ({ payload }, { dispatch }) => {
    let {
      API_URLS: { CREATE_NOTIFICATION },
    } = CONFIG;

    try {
      dispatch(beginAPICall());
      let {
        data: { data },
      } = await postRequest(
        CREATE_NOTIFICATION,
        { ...payload },
        {
          service: "SALESFORCE",
        }
      );
      return data;
    } catch ({ response }) {
      const { data } = response;
      errorNotification({
        title: "API Error",
        message: "Failed to create Thresholds",
      });
      throw new Error(data);
    } finally {
      dispatch(endAPICall());
      dispatch(closeModal());
    }
  }
);

export const editNotification = createAsyncThunk(
  "notification/editNotification",
  async ({ payload: { id, ...payload } }, { dispatch }) => {
    let {
      API_URLS: { EDIT_NOTIFICATION },
    } = CONFIG;

    try {
      dispatch(beginAPICall());
      let {
        data: { data },
      } = await putRequest(
        EDIT_NOTIFICATION + id + "/",
        { ...payload },
        {
          service: "SALESFORCE",
        }
      );
      return data;
    } catch ({ response }) {
      const { data } = response;
      errorNotification({
        title: "API Error",
        message: "Failed to edit Thresholds",
      });
      throw new Error(JSON.stringify(data));
    } finally {
      dispatch(endAPICall());
      dispatch(closeModal());
      dispatch(fetchNotifications());
    }
  }
);

export const deleteNotification = createAsyncThunk(
  "notification/deleteNotification",
  async ({ payload: { event_name, id } }, { dispatch }) => {
    let {
      API_URLS: { DELETE_NOTIFICATION },
      MODALS: { RESTRICT_DELETE },
    } = CONFIG;

    try {
      dispatch(beginAPICall());
      await deleteRequest(DELETE_NOTIFICATION + id + "/", {
        service: "SALESFORCE",
      });
      return id;
    } catch ({ response }) {
      const { data, status } = response;
      if (status === 403) {
        delete data.message;
        dispatch(
          openModal({
            key: RESTRICT_DELETE.key,
            props: {
              modalData: {
                title: event_name,
                metadata: data,
                service: "Threshold",
              },
            },
          })
        );
      } else {
        errorNotification({
          title: "API Error",
          message: "Failed to delete Thresholds",
        });
      }
      throw new Error(JSON.stringify(data));
    } finally {
      dispatch(endAPICall());
      dispatch(fetchNotifications());
    }
  }
);
