import { Select } from "antd";
import React from "react";
import CONFIG from "../../../../utils/config";

function SessionTimeout({ expiryHours, setSettingState }) {
  const { SESSION_TIMEOUT_OPTIONS } = CONFIG;

  const handleChange = (value) => {
    setSettingState((prev) => ({ ...prev, refresh_token_expiry_hours: value }));
  };

  return (
    <div className="sessionTimeout">
      <Select
        placeholder="Session Timeout"
        options={SESSION_TIMEOUT_OPTIONS}
        value={expiryHours}
        onChange={handleChange}
      />
    </div>
  );
}

export default SessionTimeout;
