/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-labels */
import { useCallback, useEffect, useState } from "react";
import {
  booleanStringStatus,
  isFalse,
  isVerfiedEmail,
} from "../../utils/functions";
import CONFIG from "../../utils/config";

function Validation({
  validationRules,
  values,
  children,
  reIntialize,
  setReInitialize = () => {},
}) {
  const {
    LABELS: {
      VALIDATION: { REQUIRED, STRING_STATUS, EMAIL_VALIDATION },
    },
  } = CONFIG;

  const [errors, setErrors] = useState({});
  const [initialValues] = useState(values);
  const [showError, setShowError] = useState({});

  const validateForm = useCallback(
    (reIntialize) => {
      const updatedErrors = {};
      const updatedShowError = reIntialize ? {} : { ...showError };

      for (const fieldName in validationRules) {
        const fieldValue = values[fieldName];
        if (initialValues[fieldName] !== fieldValue)
          updatedShowError[fieldName] = true;

        const fieldErrors = [];

        for (const ruleObj of validationRules[fieldName]) {
          switch (ruleObj.rule) {
            case REQUIRED: {
              if (isFalse(fieldValue)) fieldErrors.push(ruleObj.errorMessage);
              break;
            }
            case STRING_STATUS: {
              if (booleanStringStatus(fieldValue) !== "success")
                fieldErrors.push(ruleObj.errorMessage);
              break;
            }
            case EMAIL_VALIDATION: {
              if (!isVerfiedEmail(fieldValue)) {
                fieldErrors.push(ruleObj.errorMessage);
              }
              break;
            }
            default:
              break;
          }
        }
        updatedErrors[fieldName] = {
          errorMessage: fieldErrors[0] || null,
          showError: updatedShowError[fieldName],
        };
      }

      setErrors(updatedErrors);
      setShowError(updatedShowError);
      setReInitialize(false);
    },
    [REQUIRED, initialValues, showError, validationRules, values]
  );

  useEffect(() => {
    validateForm(reIntialize);
  }, [values]);

  return children(errors);
}

export default Validation;
