/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Col,
  Collapse,
  InputNumber,
  Radio,
  Row,
  Select,
  Space,
} from "antd";
import { useSearchParams } from "react-router-dom";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import salesforcelogo from "../../assets/images/salesforce_logo.png";
import CONFIG from "../../utils/config";
import { useDispatch, useSelector } from "react-redux";
import {
  editSalesforceAPICreditData,
  fetchSalesforceAPICreditData,
  fetchSalesforceOrgDetails,
  sendAdminCodeForIntegration,
} from "../../redux-toolkit/slice/admin/asyncThunk";
import { generateHoursArray, getToken } from "../../utils/functions";
import layoutDataSelector from "../../redux-toolkit/selectors/layoutData";
import { useNavigate } from "react-router-dom";
import { openModal } from "../../redux-toolkit/slice/modal";
import TimeZone from "../setting/components/timeZone";

function SalesforceIntegration() {
  const {
    AUTHORISATION_URL,
    ROUTES: { SALESFORCE_INTEGRATION, LOGIN },
    LABELS: {
      LOGIN: { CODE, ADMIN_EMAIL, ADMIN_ID },
    },
    MODALS: { CONFIRM },
    SALESFORCE_DETAILS_ARRAY,
    MONTHS,
    MERIDIAN_LIST,
  } = CONFIG;

  const email = useMemo(() => getToken(ADMIN_EMAIL), [ADMIN_EMAIL]);
  const id = useMemo(() => getToken(ADMIN_ID), [ADMIN_ID]);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { adminDetails, salesforceOrgDetails, salesforceAPICreditData } =
    layoutDataSelector(useSelector);

  const [searchParams, setSearchParams] = useSearchParams();

  const {
    is_salesforce_authenticated,
    salesforce_data,
    timezone: adminTimezone,
  } = adminDetails || {};

  const { username: salesforceUsername } = salesforce_data || {};

  const [isDirty, setIsDirty] = useState(false);
  const [apiCreditManagement, setApiCreditManagement] = useState({
    sync_custom_window: {},
  });

  const handleClick = () => {
    window.location.href = AUTHORISATION_URL(SALESFORCE_INTEGRATION.key);
  };

  const handleSave = () => {
    if (startDisabled) return;

    dispatch(editSalesforceAPICreditData({ payload: apiCreditManagement }));
    if (apiCreditManagement.metrics_sync_window === "all_day")
      setApiCreditManagement((prev) => ({
        ...prev,
        sync_custom_window: { timezone: adminTimezone },
      }));
    setIsDirty(false);
  };

  const handleRadioChange = ({ target: { value } }) => {
    setIsDirty(true);
    setApiCreditManagement((prev) => ({
      ...prev,
      metrics_sync_window: value,
      sync_custom_window: isCustomTimeComplete
        ? prev.sync_custom_window
        : { timezone: adminTimezone },
    }));
  };

  const handleAuthorisedCode = useCallback(
    (code) => {
      dispatch(
        sendAdminCodeForIntegration({
          code,
          custom_admin_email: email,
          custom_admin_id: id,
          reAuthError: () => {
            dispatch(
              openModal({
                key: CONFIRM.key,
                props: {
                  title: "Re-Authenticate Error",
                  content:
                    "You are trying to authenticate with another Organisation. Please re-authenticate with the same Organisation.",
                },
              })
            );
          },
        })
      );
    },
    [dispatch, email, id]
  );

  const handleChange = (value, key) => {
    setIsDirty(true);
    setApiCreditManagement((prev) => ({ ...prev, [key]: value }));
  };

  const handleCustomSyncTime = (value, type) => {
    let timeKey = type.includes("start") ? "start" : "end";
    let timeValue = apiCreditManagement?.sync_custom_window?.[timeKey] || " ";
    let [hour, meridian] = timeValue?.split(" ");
    let finalTime = type.includes("meridian")
      ? `${hour} ${value}`
      : `${value} ${meridian}`;

    setIsDirty(true);
    setApiCreditManagement((prev) => ({
      ...prev,
      sync_custom_window: {
        ...prev.sync_custom_window,
        [timeKey]: finalTime,
      },
    }));
  };

  const handleTimezoneChange = (fnc) => {
    let tempSyncCustomWindow = fnc(apiCreditManagement.sync_custom_window);
    setApiCreditManagement((prev) => ({
      ...prev,
      sync_custom_window: tempSyncCustomWindow,
    }));
    setIsDirty(true);
  };

  const updateInitialState = (data) => {
    let isCustomTimeApplied = Boolean(data?.sync_custom_window?.start);
    setApiCreditManagement({
      ...data,
      sync_custom_window: isCustomTimeApplied
        ? data.sync_custom_window
        : { timezone: adminTimezone },
    });
  };

  useEffect(() => {
    const authorizationCode = searchParams.get(CODE);

    authorizationCode && handleAuthorisedCode(authorizationCode);

    if (authorizationCode) setSearchParams({});
  }, [CODE, handleAuthorisedCode]);

  useEffect(() => {
    if (!email || !id) navigate(LOGIN.path);
  }, [email, id, LOGIN, navigate]);

  useEffect(() => {
    if (!salesforceOrgDetails && is_salesforce_authenticated)
      dispatch(fetchSalesforceOrgDetails());
  }, [salesforceOrgDetails, is_salesforce_authenticated]);

  useEffect(() => {
    salesforceAPICreditData
      ? updateInitialState(salesforceAPICreditData)
      : dispatch(fetchSalesforceAPICreditData());
  }, [salesforceAPICreditData, dispatch]);

  const collapseItems = useMemo(
    () => [
      {
        key: "user_salesforce_details",
        label: "SALESFORCE DETAILS",
        children: SALESFORCE_DETAILS_ARRAY.map(
          ({ label, selector, children }) =>
            children ? (
              <React.Fragment key={label}>
                <p key={label} className="mb-0">
                  <span className="mr-1">{label} </span>
                </p>
                <div className="">
                  {children.map(({ label, selector }) => (
                    <p key={label} className="ml-2 mb-0 mt-1">
                      <span className="mr-1">{label}: </span>
                      {salesforceOrgDetails &&
                        salesforceOrgDetails[selector]?.toString()}
                    </p>
                  ))}
                </div>
              </React.Fragment>
            ) : (
              <p key={label}>
                <span className="mr-1">{label}: </span>
                {salesforceOrgDetails &&
                  (selector === "FiscalYearStartMonth"
                    ? MONTHS[salesforceOrgDetails[selector]]?.toString()
                    : salesforceOrgDetails[selector]?.toString())}
              </p>
            )
        ),
      },
    ],
    [salesforceOrgDetails]
  );

  const isCustomTimeComplete = useMemo(() => {
    let { start, end, timezone } =
      apiCreditManagement?.sync_custom_window || {};
    let [startTime, startMeridian] = start?.split(" ") || [];
    let [endTime, endMeridian] = end?.split(" ") || [];
    return startTime && startMeridian && endTime && endMeridian && timezone;
  }, [apiCreditManagement]);

  const startDisabled = useMemo(() => {
    if (isDirty) {
      if (apiCreditManagement?.metrics_sync_window === "custom") {
        return !isCustomTimeComplete;
      } else return false;
    } else return true;
  }, [isDirty, isCustomTimeComplete]);

  return (
    <div className="userContainer h-100 scroll">
      <Row className="filter-head">
        <Col span={12}>
          <h2>SALESFORCE INTEGRATION</h2>
        </Col>
      </Row>
      <div className="integration-container">
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Button
            className="authenticate-btn"
            onClick={handleClick}
            disabled={is_salesforce_authenticated}
          >
            <img width="30x" src={salesforcelogo} alt="salesforcelogo" />
            Authenticate with OAuth
          </Button>
          {is_salesforce_authenticated ? (
            <span>
              Authenticated with {salesforceUsername},{" "}
              <span className="reAuth cursor-pointer" onClick={handleClick}>
                re-authenticate
              </span>
            </span>
          ) : (
            !!salesforceUsername && (
              <span>Previously Authenticated with {salesforceUsername}</span>
            )
          )}
        </div>
        {is_salesforce_authenticated && (
          <div className="organisation-details">
            <Collapse items={collapseItems} defaultActiveKey={["1"]} />
          </div>
        )}
      </div>
      <div className="credit-management">
        <h1>API Credits Management</h1>
        <div className="metric">
          <label>
            Syncing Window{" "}
            <span>(impacts Metrics, Thresholds & Team updates)</span>
          </label>
          <div className="metric-content">
            <div className="metric-sync_window">
              <Radio.Group
                value={apiCreditManagement.metrics_sync_window}
                onChange={handleRadioChange}
              >
                <Space direction="vertical">
                  <Radio value="all_day">All day</Radio>
                  <Radio value="custom">
                    Limited time period (recommended to save API calls)
                  </Radio>
                </Space>
              </Radio.Group>
              <div className="metric-custom_time">
                <div className="item">
                  <label>
                    Start<sup>*</sup>
                  </label>
                  <div className="item-content">
                    <Select
                      placeholder="Time"
                      style={{ width: "85px", marginRight: "4px" }}
                      options={generateHoursArray()}
                      value={
                        apiCreditManagement?.sync_custom_window?.start?.split(
                          " "
                        )?.[0]
                      }
                      onSelect={(value) => {
                        handleCustomSyncTime(value, "start");
                      }}
                      disabled={
                        apiCreditManagement.metrics_sync_window !== "custom"
                      }
                    />
                    <Select
                      options={MERIDIAN_LIST}
                      style={{ width: "60px" }}
                      value={
                        apiCreditManagement?.sync_custom_window?.start?.split(
                          " "
                        )?.[1]
                      }
                      onSelect={(value) => {
                        handleCustomSyncTime(value, "start_meridian");
                      }}
                      disabled={
                        apiCreditManagement.metrics_sync_window !== "custom"
                      }
                    />
                  </div>
                </div>
                <div className="item">
                  <label>
                    End <sup>*</sup>
                  </label>
                  <div className="item-content">
                    <Select
                      placeholder="Time"
                      style={{ width: "85px", marginRight: "4px" }}
                      options={generateHoursArray()}
                      onSelect={(value) => {
                        handleCustomSyncTime(value, "end");
                      }}
                      value={
                        apiCreditManagement?.sync_custom_window?.end?.split(
                          " "
                        )?.[0]
                      }
                      disabled={
                        apiCreditManagement.metrics_sync_window !== "custom"
                      }
                    />
                    <Select
                      options={MERIDIAN_LIST}
                      style={{ width: "60px" }}
                      value={
                        apiCreditManagement?.sync_custom_window?.end?.split(
                          " "
                        )?.[1]
                      }
                      onSelect={(value) => {
                        handleCustomSyncTime(value, "end_meridian");
                      }}
                      disabled={
                        apiCreditManagement.metrics_sync_window !== "custom"
                      }
                    />
                  </div>
                </div>
                <div className="item">
                  <label>
                    Timezone<sup>*</sup>
                  </label>
                  <div className="item-content item-timezone">
                    <TimeZone
                      disabled={
                        apiCreditManagement.metrics_sync_window !== "custom"
                      }
                      className="w-100 salesforce-timezone"
                      timezone={
                        apiCreditManagement?.sync_custom_window?.timezone ?? ""
                      }
                      setSettingState={handleTimezoneChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="metric mt-3">
          <label>Update Frequency</label>
          <div className="metric-content">
            <div className="metric-update_time">
              Update metrics and leaderboards every
              <InputNumber
                size="small"
                min={1}
                max={9999}
                className="ml-2 mr-2"
                value={apiCreditManagement.metrics_sync_interval}
                onChange={(value) => {
                  handleChange(value, "metrics_sync_interval");
                }}
              />
              minutes
            </div>
          </div>
          <div className="metric-content">
            <div className="metric-update_time">
              Update team membership every
              <InputNumber
                size="small"
                min={1}
                max={9999}
                className="ml-2 mr-2"
                value={apiCreditManagement.teams_sync_interval}
                onChange={(value) => {
                  handleChange(value, "teams_sync_interval");
                }}
              />
              hours
            </div>
          </div>
        </div>
      </div>
      <div className="setting-buttons">
        <div className="d-flex">
          <Button
            className="primary-solid"
            disabled={startDisabled}
            onClick={handleSave}
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  );
}

export default SalesforceIntegration;
