import { Button, Modal } from "antd";
import React from "react";
import { closeModal, openModal } from "../../../redux-toolkit/slice/modal";
import { useDispatch, useSelector } from "react-redux";
import layoutDataSelector from "../../../redux-toolkit/selectors/layoutData";
import CONFIG from "../../../utils/config";
import { useNavigate } from "react-router-dom";

function SalesforceUserDelete() {
  const {
    ROUTES: { TEAMS },
  } = CONFIG;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleCloseModal = () => {
    dispatch(closeModal());
  };

  const redirect = () => {
    navigate(TEAMS.path);
    handleCloseModal();
  };

  const handleRedirect = () => {
    redirect();
  };

  return (
    <Modal
      key={"Salesforce User Delete"}
      title={"Salesforce User Delete"}
      centered
      className="common-modal"
      open={true}
      width={510}
      onCancel={handleCloseModal}
      footer={
        <div className="footer-btns">
          <Button className="primary-solid" onClick={handleRedirect}>
            Redirect
          </Button>
          <Button className="primary-outline" onClick={handleCloseModal}>
            Cancel
          </Button>
        </div>
      }
    >
      Please proceed to Team Management to delete the specified Salesforce user
      account.
    </Modal>
  );
}

export default SalesforceUserDelete;
