import { Col, Form, Input, Row, Select } from "antd";
import React from "react";
import {
  filterMetricListForThreshold,
  isSubstring,
  optionCreator,
} from "../../../../../utils/functions";
import metricesDataSelector from "../../../../../redux-toolkit/selectors/metricesData";
import { useSelector } from "react-redux";
import CONFIG from "../../../../../utils/config";

function FirstStep({
  notificationObject,
  setIsEdited,
  setNotificationObject,
  setIsMetricChanged,
}) {
  const { TRIGGER_TYPES } = CONFIG;

  const { metricesList } = metricesDataSelector(useSelector);

  const handleInputChange = ({ target: { name, value } }) => {
    setIsEdited(true);
    setNotificationObject((prev) => ({ ...prev, [name]: value }));
  };

  const handleTypeChange = (key) => {
    if (key !== notificationObject.metric_id) {
      setIsEdited(true);
      const metric = metricesList.find((metric) => metric.id === key);
      setIsMetricChanged(true);
      setNotificationObject((prev) => ({
        ...prev,
        metric_id: key,
        type: metric.type,
        object: metric.metrics_metadata.salesforce_object,
        creditTo: metric.metrics_metadata.creditTo,
        filterOptions: metric.metrics_metadata.field_criteria.map((data) => ({
          ...data,
          is_deletable: false,
        })),
      }));
    }
  };

  const handleThresholdTypeChange = (key) => {
    setIsEdited(true);
    setNotificationObject((prev) => ({
      ...prev,
      threshold_type: key,
    }));
  };

  return (
    <Form name="form_item_path" layout="vertical" onFinish={() => {}}>
      <Row gutter={[16, 16]}>
        <Col xs={20} sm={4} md={6} lg={8} xl={8}>
          <Form.Item
            label="Name"
            className="mb-0 no-form-item"
            children={null}
            required
          />
          <Input
            placeholder="Enter your name here. . ."
            name="titleName"
            value={notificationObject.titleName}
            onChange={handleInputChange}
          />
        </Col>
        <Col xs={20} sm={4} md={6} lg={8} xl={8}>
          <Form.Item
            label="Type"
            className="mb-0 no-form-item"
            children={null}
            required
          />
          <Select
            showSearch
            placeholder="Search to Select Threshold Type"
            style={{ width: "100%" }}
            value={notificationObject.threshold_type}
            filterOption={(input, option) => isSubstring(input, option.label)}
            options={TRIGGER_TYPES}
            onSelect={handleThresholdTypeChange}
          />
        </Col>
        <Col xs={20} sm={4} md={6} lg={8} xl={8}>
          <Form.Item
            label="Based on Metric"
            className="mb-0 no-form-item"
            children={null}
            required
          />
          <Select
            showSearch
            placeholder="Search to Select Metrics"
            style={{ width: "100%" }}
            disabled={!notificationObject?.threshold_type}
            value={notificationObject.metric_id}
            filterOption={(input, option) => isSubstring(input, option.label)}
            options={optionCreator(
              filterMetricListForThreshold(
                metricesList,
                notificationObject?.threshold_type
              ),
              "name",
              "id"
            )}
            onSelect={handleTypeChange}
          />
        </Col>
      </Row>
    </Form>
  );
}

export default FirstStep;
