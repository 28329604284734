import { createSlice } from "@reduxjs/toolkit";

import INITIAL_STATE from "../../initialState";
import {
  createNotification,
  deleteNotification,
  editNotification,
  fetchFields,
  fetchNotifications,
  fetchObjects,
} from "./asyncThunk";
import {
  filterFields,
  filterObjectWithChangeEvent,
  mapChangedFieldType,
} from "../../../utils/functions";

export const notificationSlice = createSlice({
  name: "notification",
  initialState: INITIAL_STATE.NOTIFICATION,
  reducers: {
    clearNotification: (state) => {
      state.notificationList = undefined;
      state.objects = undefined;
      state.fields = undefined;
      state.error = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchObjects.fulfilled, (state, { payload }) => {
      state.error = false;
      state.objects = filterObjectWithChangeEvent(payload);
    });

    builder.addCase(fetchObjects.rejected, (state, { error }) => {
      state.error = true;
    });

    builder.addCase(fetchFields.fulfilled, (state, { payload }) => {
      state.error = false;
      state.fields = mapChangedFieldType(payload);
    });

    builder.addCase(fetchFields.rejected, (state, { error }) => {
      state.error = true;
    });

    builder.addCase(fetchNotifications.fulfilled, (state, { payload }) => {
      state.error = false;
      state.notificationList = payload?.map((notification) => ({
        ...notification,
        key: notification.id,
      }));
    });

    builder.addCase(fetchNotifications.rejected, (state, { error }) => {
      state.error = true;
    });

    builder.addCase(createNotification.fulfilled, (state, { payload }) => {
      state.error = false;
      state.notificationList.push({ ...payload, key: payload.id });
    });

    builder.addCase(createNotification.rejected, (state, { error }) => {
      state.error = true;
    });

    builder.addCase(editNotification.fulfilled, (state, { payload }) => {
      state.error = false;
      let editedIndex = state.notificationList.findIndex(
        ({ id }) => id === payload.id
      );
      state.notificationList[editedIndex] = { ...payload, key: payload.id };
    });

    builder.addCase(editNotification.rejected, (state, { error }) => {
      state.error = true;
    });

    builder.addCase(deleteNotification.fulfilled, (state, { payload }) => {
      state.error = false;

      let editedIndex = state.notificationList.findIndex(
        ({ id }) => id === payload
      );

      state.notificationList.splice(editedIndex, 1);
    });

    builder.addCase(deleteNotification.rejected, (state, { error }) => {
      state.error = true;
    });
  },
});

export const { clearNotification } = notificationSlice.actions;

export default notificationSlice.reducer;
