import React from "react";
import { useSelector } from "react-redux";
import modalDataSelector from "../redux-toolkit/selectors/modalData";
import CONFIG from "../utils/config";

function Modals() {
  const { MODALS } = CONFIG;

  const { key, props } = modalDataSelector(useSelector);

  let Component = key ? MODALS[key].component : () => {};

  return <Component {...props} />;
}

export default Modals;
