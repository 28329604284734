import { createSlice } from "@reduxjs/toolkit";

import INITIAL_STATE from "../../initialState";
import {
  fetchKwipDashboardDetails,
  fetchKwipsLogs,
  fetchTickerDashboardDetails,
  fetchTickerLogs,
} from "./asyncThunk";

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: INITIAL_STATE.DASHBOARD_DETAILS,
  reducers: {
    changeDateFilter: (state, { payload }) => {
      let { type, value } = payload;
      state[type] = value;
    },
    clearDashboard: (state) => {
      state.currentFilter = 0;
      state.kwipLogs = undefined;
      state.dateFilter = [];
      state.tickerLogs = undefined;
      state.details = undefined;
      state.error = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchKwipDashboardDetails.fulfilled,
      (state, { payload }) => {
        state.error = false;
        let { data, ...rest } = payload;
        state.details = { ...(state.details ?? {}), ...rest };
        state.kwipLogs = data;
      }
    );
    builder.addCase(fetchKwipDashboardDetails.rejected, (state, { error }) => {
      state.error = true;
    });

    builder.addCase(
      fetchTickerDashboardDetails.fulfilled,
      (state, { payload }) => {
        state.error = false;
        let { data, ...rest } = payload;
        state.details = { ...(state.details ?? {}), ...rest };
        state.tickerLogs = data;
      }
    );
    builder.addCase(
      fetchTickerDashboardDetails.rejected,
      (state, { error }) => {
        state.error = true;
      }
    );

    builder.addCase(fetchKwipsLogs.fulfilled, (state, { payload }) => {
      state.error = false;
      state.kwipLogs = payload;
    });
    builder.addCase(fetchKwipsLogs.rejected, (state, { error }) => {
      let errorObject = JSON.parse(error.message);
      state.error = errorObject;
    });

    builder.addCase(fetchTickerLogs.fulfilled, (state, { payload }) => {
      state.error = false;
      state.tickerLogs = payload;
    });
    builder.addCase(fetchTickerLogs.rejected, (state, { error }) => {
      let errorObject = JSON.parse(error.message);
      state.error = errorObject;
    });
  },
});

export const { changeDateFilter, clearDashboard } = dashboardSlice.actions;

export default dashboardSlice.reducer;
