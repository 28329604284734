import { Row } from "antd";
import React from "react";

function Billing() {
  return (
    <div>
      <div className="userContainer integration-container pt-0 mt-3">
        <Row className="filter-head">
          <h2>Billing</h2>
        </Row>
      </div>
    </div>
  );
}

export default Billing;
