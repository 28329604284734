import React, { useCallback, useEffect, useRef } from "react";
import { Layout } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import CONFIG from "../../utils/config";
import logo from "../../assets/images/logo.svg";
import salesForce from "../../assets/images/sales-force.png";
import { errorNotification, getToken } from "../../utils/functions";
import { sendAdminCode } from "../../redux-toolkit/slice/admin/asyncThunk";
import adminDataSelector from "../../redux-toolkit/selectors/adminData";

const { Header } = Layout;

function RedirectURL() {
  const {
    ROUTES: { LOGIN, DASHBOARD },
    ERRORS: { AUTH_API },
    TOKEN: { ACCESS_TOKEN },
    LABELS: { REDIRECT_URL },
  } = CONFIG;

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isAPICallMade = useRef(false);

  const authToken = getToken(ACCESS_TOKEN);

  const { adminDetails, codeFetched, error } = adminDataSelector(useSelector);

  const handleAuthorisedCode = useCallback(
    async (authorizationCode) => {
      isAPICallMade.current = true;
      try {
        dispatch(sendAdminCode(authorizationCode));
      } catch (error) {
        navigate(LOGIN.path);
      }
    },
    [LOGIN, dispatch, navigate]
  );

  useEffect(() => {
    const authorizationCode = new URLSearchParams(location.search).get("code");

    if (authorizationCode)
      !isAPICallMade.current && handleAuthorisedCode(authorizationCode);
    else navigate(LOGIN.path);
  }, [location.search, LOGIN, handleAuthorisedCode, navigate]);

  useEffect(() => {
    if (adminDetails || authToken || codeFetched) navigate(DASHBOARD.path);
  }, [adminDetails, authToken, DASHBOARD, codeFetched, navigate]);

  useEffect(() => {
    if (error) {
      errorNotification(error);
      navigate(LOGIN.path);
    }
  }, [error, AUTH_API, LOGIN, navigate]);

  return (
    <React.Fragment>
      <Header>
        <img src={logo} alt="logo" />
      </Header>
      <div className="redirect-page">
        <div>
          <img src={salesForce} alt="salesForce" />
          <h4>
            {REDIRECT_URL.TITLE}
            <div className="snippet" data-title="dot-typing">
              <div className="stage">
                <div className="dot-typing"></div>
              </div>
            </div>
          </h4>
        </div>
      </div>
    </React.Fragment>
  );
}

export default RedirectURL;
