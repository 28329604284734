import React, { useState } from "react";
import { Button, Card, Col, DatePicker, Row } from "antd";

import KwipsTiles from "./components/tiles";
import KwipsSend from "./components/kwipsSend";
import { ReactComponent as User } from "../../assets/images/user.svg";
import CONFIG from "../../utils/config";
import dayjs from "dayjs";
import { useDispatch } from "react-redux";
import { changeDateFilter } from "../../redux-toolkit/slice/dashboard";
import { useSelector } from "react-redux";
import dashboardDataSelector from "../../redux-toolkit/selectors/dashboardData";
import Icon from "../../components/icon";

const { RangePicker } = DatePicker;

function Dashboard() {
  const { DASHBOARD_FILTERS } = CONFIG;

  const dispatch = useDispatch();

  const { currentFilter, dateFilter, details } =
    dashboardDataSelector(useSelector);

  const { live_user_count } = details || {};

  const [customFilterApplied, setCustomFilterApplied] = useState(0);
  const [prevCurrentFilter, setPrevCurrentFilter] = useState(null);

  const handleFilter = (value) => {
    value === 6 && setPrevCurrentFilter(currentFilter);
    dispatch(
      changeDateFilter({
        type: "currentFilter",
        value,
      })
    );
  };

  const handleRangeSelect = (timeArray) => {
    dispatch(
      changeDateFilter({
        type: "dateFilter",
        value: [
          dayjs(timeArray[0].startOf("day").toISOString()),
          dayjs(timeArray[1].endOf("day").minute(59).toISOString()),
        ],
      })
    );
  };

  const handleCustomApply = () => {
    setCustomFilterApplied((prev) => prev + 1);
    setPrevCurrentFilter(6);
  };

  return (
    <div className="userContainer integration-container pt-0 mt-3">
      <Row className="filter-head">
        <h2>DASHBOARD</h2>
      </Row>
      <Row gutter={16}>
        <Col sm={11} lg={6} xl={6}>
          <Card
            title={
              <div className="d-flex align-items-center weight-boldest">
                <Icon className="mr-1" Component={User} fill={"#12B576"} />
                CONNECTED USERS
              </div>
            }
            bordered={false}
          >
            <p className="connected-user">
              Live
              <span className="user-count">{live_user_count ?? 0}</span>
            </p>
          </Card>
        </Col>
      </Row>
      <div className="list-tabs d-flex flex-column">
        <div className="menu-items">
          {DASHBOARD_FILTERS.map(({ label, key }) => (
            <span
              key={key}
              className={`menu-item ${currentFilter === key ? "active" : ""}`}
              onClick={() => handleFilter(key)}
            >
              {label}
            </span>
          ))}
        </div>
        {currentFilter === 6 && (
          <div className="custom-filter d-flex justify-content-between">
            <RangePicker
              allowClear={false}
              className="mr-2"
              value={dateFilter}
              onChange={handleRangeSelect}
            />
            <Button
              className="apply primary-outline"
              onClick={handleCustomApply}
              disabled={!dateFilter.length}
            >
              Apply
            </Button>
          </div>
        )}
      </div>
      <Row className="dashboard-cards" gutter={16}>
        <KwipsTiles
          customFilterApplied={customFilterApplied}
          setCustomFilterApplied={setCustomFilterApplied}
        />
      </Row>
      <KwipsSend prevCurrentFilter={prevCurrentFilter} />
    </div>
  );
}

export default Dashboard;
