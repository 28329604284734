import { Button, Modal, Input, Form, Row, Col } from "antd";
import React, { useEffect, useMemo, useRef, useState } from "react";
import ReactHowler from "react-howler";
import {
  getFilteredAudioFiles,
  getUploadMusicPayload,
} from "../../../utils/functions";
import cross from "../../../assets/images/cross.png";
import play from "../../../assets/images/play-circle.png";
import pause from "../../../assets/images/pause-circle.png";
import { uploadMusicList } from "../../../redux-toolkit/slice/users/asyncThunks";
import { useDispatch } from "react-redux";

function UploadSongModal({ user_id, handleCloseModal, musicList }) {
  const dispatch = useDispatch();
  const inputFileRef = useRef();
  const confirmAllowedRef = useRef(false);

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [dragging, setDragging] = useState(false);
  const [isEdited, setIsEdited] = useState(false);

  const processFiles = async (files) => {
    const filteredAudioFiles = getFilteredAudioFiles(files, selectedFiles);

    if (filteredAudioFiles === "skip") return;

    const promises = filteredAudioFiles.map((file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () =>
          resolve({ file, audio: reader.result, label: "", playing: false });
        reader.onerror = (error) => reject(error);
      });
    });

    try {
      const payload = await Promise.all(promises);
      setSelectedFiles((prevFiles) => [...prevFiles, ...payload]);
      setIsEdited(true);
    } catch (error) {
      console.error("Error processing files", error);
    }
  };

  const handleFileChange = async (event) => {
    await processFiles(event.target.files);
  };

  const handleDrop = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    setDragging(false);
    if (event.dataTransfer.files && event.dataTransfer.files.length > 0) {
      await processFiles(event.dataTransfer.files);
      event.dataTransfer.clearData();
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (!dragging) {
      setDragging(true);
    }
  };

  const handleDragLeave = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setDragging(false);
  };

  const handleSelectFile = () => {
    inputFileRef.current.click();
  };

  const handleLabelChange = (event, index) => {
    const { value } = event.target;
    setSelectedFiles((prevFiles) =>
      prevFiles.map((file, i) =>
        i === index ? { ...file, label: value } : file
      )
    );
  };

  const handleSongPlay = (index) => {
    setSelectedFiles((prevFiles) =>
      prevFiles.map((file, i) =>
        i === index
          ? { ...file, playing: !file.playing }
          : { ...file, playing: false }
      )
    );
  };

  const handleUpload = () => {
    let payload = getUploadMusicPayload(selectedFiles, user_id);
    dispatch(uploadMusicList({ payload, callback: handleCloseModal }));
  };

  const HandleRemoveFile = (index) => {
    setSelectedFiles(selectedFiles.filter((_, i) => i !== index));
    setIsEdited(true);
  };

  const isConfirmDisabled = useMemo(() => {
    if (isEdited) {
      if (selectedFiles.length === 0) {
        if (confirmAllowedRef.current) return false;
        return true;
      }
      return selectedFiles.some((file) => file.label === "");
    } else return true;
  }, [isEdited, selectedFiles]);

  useEffect(() => {
    if (musicList?.length) {
      setSelectedFiles(
        musicList.reduce((result, { label, source, kwipo_song }) => {
          if (kwipo_song) return result;
          else {
            confirmAllowedRef.current = true;
            return [...result, { label, audio: source, playing: false }];
          }
        }, [])
      );
    }
  }, [musicList]);

  return (
    <Modal
      key={"Upload Song"}
      title={"Upload Song"}
      centered
      className="common-modal"
      open={true}
      width={750}
      onCancel={handleCloseModal}
      footer={
        <div className="footer-btns">
          <Button
            className="primary-solid"
            onClick={handleUpload}
            disabled={isConfirmDisabled}
          >
            Confirm
          </Button>
          <Button className="primary-outline" onClick={handleCloseModal}>
            Cancel
          </Button>
        </div>
      }
    >
      <div
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        className="upload-outer"
      >
        <div className="drag-here">
          <h5>Drag your songs here</h5>
          <h6>.mp3, wav, max.100MB</h6>
        </div>
        <span>or</span>
        <div
          className="upload-btn-wrapper cursor-pointer"
          onClick={handleSelectFile}
        >
          <button className="upload-btn">SELECT FILES</button>
          <input
            ref={inputFileRef}
            type="file"
            accept="audio/mpeg, audio/wav, audio/x-wav, audio/aac"
            multiple
            onChange={handleFileChange}
            style={{ display: "none" }}
            id="fileInput"
          />
        </div>
      </div>

      {selectedFiles.map(({ file, audio, playing, label }, index) => (
        <Row className="song-list-row" key={file?.toString() ?? label + index}>
          <Col xl={12}>
            <Form.Item label="Song Name" className="m-0">
              <Input
                value={label}
                onChange={(event) => handleLabelChange(event, index)}
              />
            </Form.Item>
          </Col>
          <Col xl={10}>
            <ReactHowler
              src={audio}
              playing={playing}
              onEnd={() => {
                handleSongPlay(index);
              }}
            />
            <div className="song-list-name">
              <span className="d-flex align-items-center">
                <img
                  src={playing ? pause : play}
                  alt="cross"
                  className="cursor-pointer"
                  onClick={() => handleSongPlay(index)}
                />
                <span className="text-ellipse">{file?.name ?? label}</span>
              </span>
              <img
                src={cross}
                alt="cross"
                className="cursor-pointer"
                onClick={() => HandleRemoveFile(index)}
              />
            </div>
          </Col>
        </Row>
      ))}
    </Modal>
  );
}

export default UploadSongModal;
