import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import dashboardDataSelector from "../../../redux-toolkit/selectors/dashboardData";
import { fetchKwipDashboardDetails } from "../../../redux-toolkit/slice/dashboard/asyncThunk";
import { Card, Col } from "antd";
import { kwipsSentFilterPayload } from "../../../utils/functions";
import Icon from "../../../components/icon";
import { ReactComponent as List } from "../../../assets/images/list.svg";
import { ReactComponent as LikeView } from "../../../assets/images/like-view.svg";
import { ReactComponent as User } from "../../../assets/images/user.svg";

function Tiles({ customFilterApplied, setCustomFilterApplied }) {
  const dispatch = useDispatch();

  const { currentFilter, dateFilter, details } =
    dashboardDataSelector(useSelector);

  const { kwips_received, kwips_sent, likes, views, users_with_first_login } =
    details || {};

  useEffect(() => {
    if (currentFilter !== 6 || customFilterApplied) {
      dispatch(
        fetchKwipDashboardDetails({
          payload: {
            timeframes: currentFilter,
            offsetTime: new Date().getTimezoneOffset(),
            ...kwipsSentFilterPayload(dateFilter),
          },
        })
      );
      if (currentFilter === 6) setCustomFilterApplied((prev) => prev - 1);
    }
  }, [
    dispatch,
    dateFilter,
    currentFilter,
    customFilterApplied,
    setCustomFilterApplied,
  ]);

  return (
    <>
      <Col sm={11} lg={6} xl={6}>
        <Card
          title=<div className="d-flex align-items-center weight-boldest">
            <Icon className="mr-1" Component={List} fill={"#12B576"} />
            KWIPS
          </div>
          bordered={false}
        >
          <p className="connected-user">
            <span>Received</span>
            <span className="user-count">{kwips_received ?? 0}</span>
          </p>
          <p className="connected-user">
            <span>Sent</span>
            <span className="user-count">{kwips_sent ?? 0}</span>
          </p>
        </Card>
      </Col>
      <Col sm={11} lg={6} xl={6}>
        <Card
          title=<div className="d-flex align-items-baseline weight-boldest">
            <Icon
              className="mr-1"
              Component={LikeView}
              fill={"#12B576"}
              height={20}
              width={20}
            />
            REACTIONS
          </div>
          bordered={false}
        >
          <p className="connected-user">
            <span>Likes</span>
            <span className="user-count">{likes ?? 0}</span>
          </p>
          <p className="connected-user">
            <span>Views</span>
            <span className="user-count">{views ?? 0}</span>
          </p>
        </Card>
      </Col>
      <Col sm={11} lg={6} xl={6}>
        <Card
          className="h-149"
          title={
            <div className="d-flex align-items-center weight-boldest">
              <Icon className="mr-1" Component={User} fill={"#12B576"} />
              UNIQUE USERS
            </div>
          }
          bordered={false}
        >
          <p className="connected-user">
            <span>Unique</span>
            <span className="user-count">{users_with_first_login ?? 0}</span>
          </p>
        </Card>
      </Col>
    </>
  );
}

export default Tiles;
