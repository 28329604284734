import { Button, Col, Form, Input, Row, Select } from "antd";
import React, { useMemo, useState } from "react";
import {
  generateNotificationBody,
  groupOptions,
  kwipMergeFieldOptionCreator,
  sanetizeNotificationBody,
} from "../../../../utils/functions";
import CONFIG from "../../../../utils/config";
import layoutDataSelector from "../../../../redux-toolkit/selectors/layoutData";
import { useSelector } from "react-redux";

const { TextArea } = Input;

function StepFour({
  kwipObject,
  notificationList,
  setKwipObject,
  setIsEdited,
}) {
  const { KWIPO_PROFILE_FIELD } = CONFIG;

  const { salesforceOrgDetails } = layoutDataSelector(useSelector);

  const { currencySymbol } = salesforceOrgDetails ?? { currencySymbol: "" };

  const [selectedMergeField, setSelectedMergeFields] = useState(null);
  const [cursorPosition, setCursorPosition] = useState({
    headline: 0,
    subHeadline: 0,
    body: 0,
  });

  const handleMergeFieldSelect = (_, data) => {
    setSelectedMergeFields(data);
  };

  const handleTextChange = ({
    target: { name, value, selectionStart, isInserted },
  }) => {
    setCursorPosition((prev) => ({
      ...prev,
      [name]: isInserted ? value.length : selectionStart,
    }));
    setIsEdited(true);
    setKwipObject((prev) => ({
      ...prev,
      [name]: sanetizeNotificationBody(value),
    }));
  };

  const handleInsertClick = (name) => {
    if (selectedMergeField) {
      let value = kwipObject[name];
      let modifiedValue = generateNotificationBody(
        value ?? "",
        selectedMergeField,
        cursorPosition[name],
        currencySymbol
      );

      handleTextChange({
        target: {
          name,
          value: sanetizeNotificationBody(modifiedValue),
          isInserted: true,
        },
      });
    }
  };

  const handleCursorChange = ({ target: { name, selectionStart } }) => {
    setCursorPosition((prev) => ({ ...prev, [name]: selectionStart }));
  };

  const mergeFieldOptions = useMemo(() => {
    let selectedNotification = notificationList.find(
      ({ id }) => id === kwipObject.notification
    );
    return selectedNotification.recognition_fields_object;
  }, [notificationList, kwipObject.notification]);

  return (
    <Form name="form_item_path" layout="vertical" onFinish={() => {}}>
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={24} md={20} lg={20} xl={20}>
          <Form.Item
            label="Select Merge Field"
            className="mb-0 no-form-item"
            children={null}
          />
          <Row gutter={[16, 16]}>
            <Col xs={10} sm={10} md={10} lg={10} xl={10}>
              <Select
                showSearch
                placeholder="Search to Select"
                popupMatchSelectWidth={true}
                style={{ width: "100%" }}
                options={groupOptions(
                  [
                    {
                      label: "Custom Profile Fields",
                      key: "kwipoProfileField",
                    },
                    {
                      label: "Salesforce Object Fields",
                      key: "salesforceProfileField",
                    },
                  ],
                  [
                    KWIPO_PROFILE_FIELD,
                    kwipMergeFieldOptionCreator(mergeFieldOptions),
                  ],
                  []
                )}
                onSelect={handleMergeFieldSelect}
                className="mr-3 d-flex"
              />
            </Col>
            <Col xs={14} sm={14} md={14} lg={14} xl={14}>
              <p className="mt-1">
                Place your cursor where you would like to insert the merge field
                into the title or message template
              </p>
            </Col>
          </Row>
        </Col>
        <Col xs={20} sm={16} md={12} lg={12} xl={12}></Col>
      </Row>

      <hr />

      <Row gutter={[16, 16]} className="mt-3">
        <Col md={3} lg={3} xl={3} className="d-flex align-items-center">
          Headline
        </Col>
        <Col xs={17} sm={17} md={17} lg={17} xl={17}>
          <Input
            name="headline"
            value={kwipObject.headline}
            maxLength={24}
            placeholder="Enter Headline of maximum length 24"
            onChange={handleTextChange}
            onClick={handleCursorChange}
          />
        </Col>
        <Col xs={4} sm={4} md={4} lg={4} xl={4}>
          <Button
            className="primary-outline"
            onClick={() => handleInsertClick("headline")}
          >
            Insert
          </Button>
        </Col>
      </Row>

      <Row gutter={[16, 16]} className="mt-3">
        <Col md={3} lg={3} xl={3} className="d-flex align-items-center">
          Sub Headline
        </Col>
        <Col xs={17} sm={17} md={17} lg={17} xl={17}>
          <Input
            name="subHeadline"
            value={kwipObject.subHeadline}
            maxLength={30}
            placeholder="Enter Sub Headline of maximum length 30"
            onChange={handleTextChange}
            onClick={handleCursorChange}
          />
        </Col>
        <Col xs={4} sm={4} md={4} lg={4} xl={4}>
          <Button
            className="primary-outline"
            onClick={() => handleInsertClick("subHeadline")}
          >
            Insert
          </Button>
        </Col>
      </Row>

      <Row gutter={[16, 16]} className="mt-3">
        <Col md={3} lg={3} xl={3} className="d-flex">
          Body
        </Col>
        <Col md={17} lg={17} xl={17}>
          <TextArea
            name="body"
            value={kwipObject.body}
            placeholder="Enter Body of maximum length 250"
            autoSize={{ minRows: 4, maxRows: 5 }}
            maxLength={250}
            onChange={handleTextChange}
            onClick={handleCursorChange}
          />
        </Col>
        <Col md={4} lg={4} xl={4} className="d-flex align-items-center">
          <Button
            className="primary-outline"
            onClick={() => handleInsertClick("body")}
          >
            Insert
          </Button>
        </Col>
      </Row>
    </Form>
  );
}

export default StepFour;
