import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Table, Row, Col, Button, Statistic } from "antd";

import CONFIG from "../../utils/config";

import userDataSelector from "../../redux-toolkit/selectors/userData";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteUser,
  editUser,
  fetchUsers,
  sendMail,
} from "../../redux-toolkit/slice/users/asyncThunks";
import {
  columnAndComponentFunctionReference,
  getEditUserPayload,
} from "../../utils/functions";
import { closeModal, openModal } from "../../redux-toolkit/slice/modal";
import layoutDataSelector from "../../redux-toolkit/selectors/layoutData";

function User() {
  const {
    USER_COLUMN,
    MODALS: { USER, CONFIRM, DELETE_SALESFORCE_USER, USER_SETTING },
  } = CONFIG;

  const toolkitDispatch = useDispatch();

  const { usersList } = userDataSelector(useSelector);
  const { adminDetails } = layoutDataSelector(useSelector);
  const [currentPage, setCurrentPage] = useState(1);

  const { role, allowedAppUsers } = adminDetails ?? {};

  const handleAddNewUser = () => {
    toolkitDispatch(openModal({ key: USER.key, props: {} }));
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const fetchUsersDetails = useCallback(() => {
    toolkitDispatch(fetchUsers());
  }, [toolkitDispatch]);

  const handleToggle = useCallback(
    (data, value) => {
      toolkitDispatch(
        editUser({
          payload: getEditUserPayload({ ...data, is_active: value }),
        })
      );
    },
    [toolkitDispatch]
  );

  const handleEdit = useCallback(
    (data) => {
      toolkitDispatch(openModal({ key: USER.key, props: { userData: data } }));
    },
    [USER, toolkitDispatch]
  );

  const handleDeleteConfirm = useCallback(
    (id, type) => {
      type === "Custom"
        ? toolkitDispatch(
            deleteUser({
              id,
              callback: () => {
                toolkitDispatch(closeModal());
              },
            })
          )
        : toolkitDispatch(
            openModal({ key: DELETE_SALESFORCE_USER.key, props: {} })
          );
    },
    [DELETE_SALESFORCE_USER, toolkitDispatch]
  );

  const handleDelete = useCallback(
    (id, type) => {
      toolkitDispatch(
        openModal({
          key: CONFIRM.key,
          props: {
            title: "Confirm User Delete",
            content: "Are you sure, you want to delete the particular user?",
            handleConfirm: () => handleDeleteConfirm(id, type),
          },
        })
      );
    },
    [CONFIRM, handleDeleteConfirm, toolkitDispatch]
  );

  const handleSetting = useCallback(
    (userData) => {
      toolkitDispatch(
        openModal({ key: USER_SETTING.key, props: { userData } })
      );
    },
    [USER_SETTING, toolkitDispatch]
  );

  const handleMailSend = useCallback(
    ({ name, email, id }) => {
      toolkitDispatch(
        sendMail({ payload: { name, email, id, wantsToSendEmail: true } })
      );
    },
    [toolkitDispatch]
  );

  const handleUserAppAccess = useCallback(
    (record) => {
      toolkitDispatch(
        editUser({
          payload: getEditUserPayload({ ...record }),
          callback: () => {
            record.isAppUser && handleMailSend(record);
          },
        })
      );
    },
    [handleMailSend, toolkitDispatch]
  );

  const allAllowedAppUsers = useMemo(() => {
    return usersList?.filter((user) => user.isAppUser);
  }, [usersList]);

  const tableColumn = useMemo(() => {
    const isUserAppAccessAllowed = allAllowedAppUsers?.length < allowedAppUsers;

    return columnAndComponentFunctionReference(USER_COLUMN, {
      role,
      currentPage,
      handleSetting,
      handleEdit,
      handleDelete,
      handleToggle,
      handleMailSend,
      handleUserAppAccess,
      isUserAppAccessAllowed,
    });
  }, [
    USER_COLUMN,
    currentPage,
    role,
    handleSetting,
    handleDelete,
    handleEdit,
    handleToggle,
    handleMailSend,
    allowedAppUsers,
    allAllowedAppUsers,
    handleUserAppAccess,
  ]);

  useEffect(() => {
    !usersList && fetchUsersDetails();
  }, [usersList, fetchUsersDetails]);

  return (
    <div className="userContainer">
      <Row className="filter-head">
        <Col span={12}>
          <h2>USERS</h2>
        </Col>
        <Col style={{ justifyContent: "end", display: "flex" }} span={12}>
          <div className="access-count">
            <Statistic
              className="d-flex align-items-center"
              title="Allowed Licensed Users"
              value={allAllowedAppUsers?.length ?? 0}
              suffix={`/ ${allowedAppUsers ?? 0}`}
            />
          </div>
          <Button className="primary-outline" onClick={handleAddNewUser}>
            Add User
          </Button>
        </Col>
      </Row>
      <Table
        className="cmn-table"
        columns={tableColumn}
        dataSource={usersList}
        scroll={{ y: "calc(70vh - 60px)" }}
        pagination={{
          currentPage,
          onChange: handlePageChange,
        }}
      />
    </div>
  );
}

export default User;
