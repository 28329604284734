import React, { useMemo } from "react";
import { Select } from "antd";
import { monthsArray } from "../../../../utils/functions";

function FiscalYear({ financialYear, setSettingState }) {
  const { month } = useMemo(() => {
    let [month] = financialYear?.split(",") || [];
    return { month };
  }, [financialYear]);

  const onChange = (fieldName, fieldValue) => {
    const updatedMonth = fieldName === "month" ? fieldValue : month;

    const newFinancialYear = `${updatedMonth}`;

    setSettingState((prevState) => ({
      ...prevState,
      financial_year: newFinancialYear,
    }));
  };

  return (
    <div className="fiscal-year">
      <Select
        placeholder="Month"
        options={monthsArray()}
        value={month}
        onChange={(value) => {
          onChange("month", value);
        }}
      />
    </div>
  );
}

export default FiscalYear;
