import { createSlice } from "@reduxjs/toolkit";

import INITIAL_STATE from "../../initialState";
import {
  fetchLeaderboard,
  deleteLeaderboard,
  editLeaderboard,
  createLeaderboard,
} from "./asyncThunk";

export const leaderboardSlice = createSlice({
  name: "leaderboard",
  initialState: INITIAL_STATE.METRICES,
  reducers: {
    clearLeaderboard: (state) => {
      state.leaderboardList = undefined;
      state.error = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchLeaderboard.fulfilled, (state, { payload }) => {
      state.error = false;
      state.leaderboardList = payload;
    });
    builder.addCase(fetchLeaderboard.rejected, (state, { error }) => {
      state.error = true;
    });
    builder.addCase(createLeaderboard.fulfilled, (state, { payload }) => {
      state.error = false;
      state.leaderboardList = [...(state.leaderboardList ?? []), payload];
    });
    builder.addCase(createLeaderboard.rejected, (state, { error }) => {
      state.error = true;
    });
    builder.addCase(editLeaderboard.fulfilled, (state, { payload }) => {
      state.error = false;
      state.leaderboardList = state.leaderboardList.reduce((result, data) => {
        if (data.id === payload.id) return [...result, payload];
        else return [...result, data];
      }, []);
    });
    builder.addCase(editLeaderboard.rejected, (state, { error }) => {
      state.error = true;
    });
    builder.addCase(deleteLeaderboard.fulfilled, (state, { payload }) => {
      state.error = false;
      state.leaderboardList = state.leaderboardList.filter(
        ({ id }) => id !== payload
      );
    });
    builder.addCase(deleteLeaderboard.rejected, (state, { error }) => {
      state.error = true;
    });
  },
});

export const { clearLeaderboard } = leaderboardSlice.actions;

export default leaderboardSlice.reducer;
