import { createAsyncThunk } from "@reduxjs/toolkit";

import CONFIG from "../../../utils/config";
import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from "../../../api/baseAPI";
import { beginAPICall, endAPICall } from "../loading";
import { closeModal } from "../modal";
import { errorNotification } from "../../../utils/functions";

export const fetchLeaderboard = createAsyncThunk(
  "leaderboard/fetchLeaderboard",
  async (_, { dispatch }) => {
    let {
      API_URLS: { FETCH_LEADERBOARD },
    } = CONFIG;
    try {
      dispatch(beginAPICall());
      let { data } = await getRequest(FETCH_LEADERBOARD, {
        service: "KPIS",
      });
      return data;
    } catch ({ response }) {
      const { data } = response;
      throw new Error(JSON.stringify(data));
    } finally {
      dispatch(endAPICall());
    }
  }
);

export const createLeaderboard = createAsyncThunk(
  "leaderboard/createLeaderboard",
  async ({ payload }, { dispatch }) => {
    let {
      API_URLS: { CREATE_LEADERBOARD },
    } = CONFIG;

    try {
      dispatch(beginAPICall());
      let { data } = await postRequest(
        CREATE_LEADERBOARD,
        { ...payload },
        { service: "KPIS" }
      );
      return data;
    } catch ({ response }) {
      const { data } = response;
      errorNotification(data);
      throw new Error();
    } finally {
      dispatch(endAPICall());
      dispatch(closeModal());
    }
  }
);

export const editLeaderboard = createAsyncThunk(
  "leaderboard/editLeaderboard",
  async ({ payload: { id, ...payload } }, { dispatch }) => {
    let {
      API_URLS: { EDIT_LEADERBOARD },
    } = CONFIG;

    try {
      dispatch(beginAPICall());
      let { data } = await putRequest(
        EDIT_LEADERBOARD + id + "/",
        { ...payload },
        {
          service: "KPIS",
        }
      );
      return data;
    } catch ({ response }) {
      const { data } = response;
      throw new Error(JSON.stringify(data));
    } finally {
      dispatch(endAPICall());
      dispatch(closeModal());
    }
  }
);

export const deleteLeaderboard = createAsyncThunk(
  "leaderboard/deleteLeaderboard",
  async ({ id }, { dispatch }) => {
    let {
      API_URLS: { DELETE_LEADERBOARD },
    } = CONFIG;

    try {
      dispatch(beginAPICall());
      await deleteRequest(DELETE_LEADERBOARD + id + "/", {
        service: "KPIS",
      });
      return id;
    } catch ({ response }) {
      const { data } = response;
      throw new Error(JSON.stringify(data));
    } finally {
      dispatch(endAPICall());
    }
  }
);
