import { Button, Modal, Row } from "antd";
import React from "react";
import { useDispatch } from "react-redux";
import { closeModal } from "../../../redux-toolkit/slice/modal";
import CONFIG from "../../../utils/config";
import "./style.scss";

function RestrictDelete({ modalData }) {
  const { RESTRICTED_DELETE_LABELS } = CONFIG;

  const { title, service, metadata } = modalData;

  const dispatch = useDispatch();

  const handleCloseModal = () => {
    dispatch(closeModal());
  };

  return (
    <Modal
      key={title}
      title={"Restricted Delete"}
      centered
      open={true}
      width={700}
      onCancel={handleCloseModal}
      footer={
        <div className="footer-btns">
          <Button className="primary-outline" onClick={handleCloseModal}>
            Cancel
          </Button>
        </div>
      }
    >
      <Row gutter={[16, 16]} className="service-container d-block">
        <p style={{ fontSize: "16px" }}>
          {title} {service} can't be deleted because it's being used with the
          following services:
        </p>
        {Object.keys(metadata).reduce(
          (result, key) =>
            metadata[key].length
              ? [
                  ...result,
                  <div key={key} className="ml-3">
                    <p className="service-label">
                      {RESTRICTED_DELETE_LABELS[key]}:{" "}
                    </p>
                    <ul className="service-list">
                      {metadata[key].map((item) => (
                        <li key={item}>{item}</li>
                      ))}
                    </ul>
                  </div>,
                ]
              : result,
          []
        )}
      </Row>
    </Modal>
  );
}

export default RestrictDelete;
