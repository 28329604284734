import React, { useCallback, useEffect, useRef } from "react";
import { Layout } from "antd";

import logo from "../../assets/images/logo.png";
import salesForce from "../../assets/images/sales-force.png";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import CONFIG from "../../utils/config";
import { loginSalesforceUser } from "../../redux-toolkit/slice/users/asyncThunks";

const { Header } = Layout;

function Redirect() {
  let {
    ROUTES: { LOGIN },
    LABELS: {
      LOGIN: { CODE },
    },
  } = CONFIG;

  const openElectron = useRef();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleAuthorisedCode = useCallback(
    async (code) => {
      try {
        dispatch(
          loginSalesforceUser({
            code,
            isElectron: true,
            callback: (access_token, refresh_token) => {
              if (access_token) {
                openElectron.current.href =
                  "kwipo-electron://" + access_token + "$$$" + refresh_token;
                openElectron.current.click();
              }
            },
          })
        );
      } catch (e) {
        navigate(LOGIN.path);
      }
    },
    [LOGIN, dispatch, navigate]
  );

  useEffect(() => {
    const authorizationCode = new URLSearchParams(window.location.search).get(
      CODE
    );
    if (authorizationCode) handleAuthorisedCode(authorizationCode);
  }, [CODE, handleAuthorisedCode]);

  return (
    <React.Fragment>
      <Header className="bg-white">
        <img src={logo} alt="logo" style={{ height: "50px" }} />
      </Header>
      <div className="redirect-page">
        <div>
          <img src={salesForce} alt="salesForce" />
          <h4>
            Processing Salesforce authentication
            <div className="snippet" data-title="dot-typing">
              <div className="stage">
                <div className="dot-typing"></div>
              </div>
            </div>
          </h4>
        </div>
      </div>
      <a
        ref={openElectron}
        href="kwipo-electron://code="
        style={{ visibility: "hidden" }}
        children={null}
      />
    </React.Fragment>
  );
}

export default Redirect;
