import { Button, Col, Form, Image, Input, Layout, Row } from "antd";
import kwipologo from "../../assets/images/kwipo_logo.svg";

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Overlay from "../../components/overlay";
import loadingDataSelector from "../../redux-toolkit/selectors/loadingData";
import Loader from "../../components/loader";
import CONFIG from "../../utils/config";
import { forgetPassword } from "../../redux-toolkit/slice/admin/asyncThunk";
import "./forgetPassword.scss";

function ForgotPassword() {
  const {
    ROUTES: { LOGIN },
  } = CONFIG;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { loading } = loadingDataSelector(useSelector);

  const [form] = Form.useForm();

  const [email, setEmail] = useState("");

  const handleSubmit = () => {
    form.validateFields(["email"]);
    if (email) {
      dispatch(forgetPassword({ payload: { email }, callback: handleCancel }));
    }
  };

  const handleCancel = () => {
    navigate(LOGIN.path);
  };

  const handleChange = ({ target: { value } }) => {
    setEmail(value);
  };

  return (
    <Layout className="bg-layout login-page">
      <Overlay show={loading}>
        <Loader />
      </Overlay>
      <Row justify="center" align="middle" className="login-form">
        <Col span={12}>
          <div className="login-form-content" style={{ width: "460px" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginBottom: "20px",
              }}
              className="image-container"
            >
              <Image
                src={kwipologo}
                alt="logo"
                className="logo"
                preview={false}
              />
              <h2>RESET PASSWORD</h2>
              <p className="mt-2" style={{ textAlign: "center" }}>
                Enter your email address in the form below and we will send you
                furthur instructions on how to reset your password
              </p>
            </div>
            <Form form={form} onSubmitCapture={handleSubmit}>
              <Form.Item
                name="email"
                rules={[
                  {
                    required: true,
                    message: "This cannot be blank",
                  },
                ]}
              >
                <Input
                  name="email"
                  value={email}
                  placeholder="Email address"
                  onChange={handleChange}
                />
              </Form.Item>
            </Form>
            <Row gutter={[16, 16]}>
              <Col xs={16}>
                <Button
                  className="login-button login-button-forgetPass"
                  block
                  htmlType="submit"
                  onClick={handleSubmit}
                >
                  Reset Password
                </Button>
              </Col>
              <Col xs={8}>
                <Button
                  className="cancel-button"
                  block
                  htmlType="submit"
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </Layout>
  );
}

export default ForgotPassword;
