import { Col, Form, Input, Row, Select } from "antd";
import React, { useMemo } from "react";
import {
  filterTimeframes,
  getTitleSuggestion,
  optionCreator,
} from "../../../../utils/functions";
import CONFIG from "../../../../utils/config";
import metricesDataSelector from "../../../../redux-toolkit/selectors/metricesData";
import { useSelector } from "react-redux";
import teamsDataSelector from "../../../../redux-toolkit/selectors/teamData";

function FirstStep({ modalData, setModalData, setSelectedMembers }) {
  const { LEADERBOARD_TIMEFRAME_OPTIONS } = CONFIG;

  const { metricesList } = metricesDataSelector(useSelector);
  const { teamsList } = teamsDataSelector(useSelector);

  const handleChange = (key, value) => {
    let newStateObj = { ...modalData, [key]: value };
    if (key === "metric_id") newStateObj.timeframe = undefined;
    if (key === "timeframe")
      newStateObj.title = getTitleSuggestion(selectedMetric.name, value);
    if (key === "team_id") {
      newStateObj.subtitle = teamsList.find(({ id }) => id === value)?.name;
      setSelectedMembers((prev) => {
        let filteredPrev = (prev || []).filter(({ id }) => id !== value);
        return [...filteredPrev, { id: value, type: "team", role: "member" }];
      });
    }
    setModalData(newStateObj);
  };

  const selectedMetric = useMemo(
    () => metricesList?.find(({ id }) => id === modalData?.metric_id),
    [metricesList, modalData?.metric_id]
  );

  return (
    <Row gutter={[16, 16]} className="leaderboard-container">
      <Col xs={8}>
        <Form.Item
          label="Based on Metric"
          className="mb-0 no-form-item"
          children={null}
          required
        />
        <Select
          options={optionCreator(metricesList, "name", "id")}
          className="ml-1 w-100"
          value={modalData?.metric_id}
          placeholder="Select Metric"
          onSelect={(key) => handleChange("metric_id", key)}
        />
      </Col>
      <Col xs={8}>
        <Form.Item
          label="Timeframe"
          className="mb-0 no-form-item"
          children={null}
          required
        />
        <Select
          options={filterTimeframes(
            LEADERBOARD_TIMEFRAME_OPTIONS,
            selectedMetric?.timeframes
          )}
          className="ml-1 w-100"
          placeholder="Select Timeframe"
          value={modalData?.timeframe}
          disabled={!modalData?.metric_id}
          onSelect={(key) => handleChange("timeframe", key)}
        />
      </Col>
      <Col xs={8}>
        <Form.Item
          label="Team"
          className="mb-0 no-form-item"
          children={null}
          required
        />
        <Select
          options={optionCreator(teamsList, "name", "id")}
          className="ml-1 w-100"
          placeholder="Select Team"
          value={modalData?.team_id}
          onSelect={(key) => handleChange("team_id", key)}
        />
      </Col>
      <Col xs={24} xl={24}>
        <hr />
      </Col>
      <Col xs={14} xl={14}>
        <Form.Item
          label="Title of Leaderboard"
          className="mb-0 no-form-item"
          children={null}
          required
        />
        <Input
          placeholder="Enter your Leaderboard title here. . ."
          value={modalData?.title}
          onChange={({ target: { value } }) => handleChange("title", value)}
        />
      </Col>
      <Col xs={14} xl={14}>
        <Form.Item
          label="Subtitle"
          className="mb-0 no-form-item"
          children={null}
        />
        <Input
          className="mb-2"
          placeholder="Enter your Leaderboard subtitle here. . ."
          value={modalData?.subtitle}
          onChange={({ target: { value } }) => handleChange("subtitle", value)}
        />
      </Col>
    </Row>
  );
}

export default FirstStep;
