export default function teamsDataSelector(func) {
  const {
    teamsReducer: { error: teamsError, ...teamsReducer },
    userReducer: { error: userError, ...userReducer },
    loadingReducer: { loading: isLoading },
  } = func((state) => state);

  return {
    ...teamsReducer,
    ...userReducer,
    isLoading,
    teamsError,
    userError,
  };
}
