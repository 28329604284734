import React, { useEffect, useState } from "react";
import CONFIG from "../../../utils/config";
import { Button, Form, Modal, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { closeModal } from "../../../redux-toolkit/slice/modal";
import {
  fetchSyncInterval,
  syncroniseInterval,
} from "../../../redux-toolkit/slice/groups/asyncThunk";
import groupDataSelector from "../../../redux-toolkit/selectors/groupsData";

function SyncIntervalModal() {
  const {
    SYNC_INTERVAL_OPTIONS,
    MODALS: { SYNC_INTERVAL },
    LABELS: {
      TEAMS: { SYNC_SALESFORCE_INTERVAL },
    },
  } = CONFIG;

  const dispatch = useDispatch();

  const { sync_interval } = groupDataSelector(useSelector);
  const [syncInterval, setSyncInterval] = useState("");

  const handleCloseModal = () => {
    dispatch(closeModal());
  };

  const handleSave = () => {
    syncInterval &&
      dispatch(
        syncroniseInterval({
          data: { sync_interval: syncInterval },
          callback: handleCloseModal,
        })
      );
  };

  const handleIntervalChange = (key) => {
    setSyncInterval(key);
  };

  useEffect(() => {
    !sync_interval && dispatch(fetchSyncInterval());
  }, [sync_interval, dispatch]);

  useEffect(() => {
    sync_interval && setSyncInterval(sync_interval);
  }, [sync_interval]);

  return (
    <Modal
      key={SYNC_INTERVAL}
      title={SYNC_SALESFORCE_INTERVAL}
      centered
      className="common-modal"
      open={true}
      width={570}
      onCancel={handleCloseModal}
      footer={
        <div className="footer-btns">
          <Button
            className="primary-solid"
            onClick={handleSave}
            disabled={!syncInterval}
          >
            Save
          </Button>
          <Button className="primary-outline" onClick={handleCloseModal}>
            Cancel
          </Button>
        </div>
      }
    >
      <Form>
        <Form.Item label="Time (in hours)">
          <Select
            placeholder="Select sync interval"
            style={{ width: "100%" }}
            value={syncInterval}
            options={SYNC_INTERVAL_OPTIONS}
            onChange={handleIntervalChange}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default SyncIntervalModal;
