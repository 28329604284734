import { Button, Col, Row, Table } from "antd";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import CONFIG from "../../utils/config";
import {
  columnAndComponentFunctionReference,
  errorNotification,
  getEditGroupPayload,
  getGroupList,
  successNotification,
  withKeyDataSet,
} from "../../utils/functions";
import { useDispatch, useSelector } from "react-redux";
import { closeModal, openModal } from "../../redux-toolkit/slice/modal";
import {
  deleteGroup,
  editGroup,
  fetchGroups,
  syncAllGroup,
} from "../../redux-toolkit/slice/groups/asyncThunk";
import teamsDataSelector from "../../redux-toolkit/selectors/teamData";
import { fetchTeams } from "../../redux-toolkit/slice/teams/asyncThunk";
import { fetchUsers } from "../../redux-toolkit/slice/users/asyncThunks";
import groupDataSelector from "../../redux-toolkit/selectors/groupsData";

function Groups() {
  const {
    GROUPS_COLUMN,
    MODALS: { GROUP, SYNC_INTERVAL, CONFIRM },
    LABELS: {
      ID,
      TEAMS: { SYNC_SALESFORCE_INTERVAL },
      GROUP: { SYNC_ALL_GROUPS, ADD_NEW_GROUP },
    },
  } = CONFIG;

  const dispatch = useDispatch();

  const { usersList, teamsList } = teamsDataSelector(useSelector);
  const { groupList: groupData } = groupDataSelector(useSelector);

  let groupList = useMemo(
    () => getGroupList(groupData ?? [], teamsList ?? [], usersList ?? []),
    [teamsList, groupData, usersList]
  );

  const [currentPage] = useState(1);

  const handleAddNewGroup = () => {
    dispatch(openModal({ key: GROUP.key, props: {} }));
  };

  const handleEdit = useCallback(
    (groupData) => {
      dispatch(openModal({ key: GROUP.key, props: { groupData } }));
    },
    [GROUP, dispatch]
  );

  const handleDeleteConfirm = useCallback(
    (id) => {
      dispatch(
        deleteGroup({ payload: { id }, callback: () => dispatch(closeModal()) })
      );
    },
    [dispatch]
  );

  const handleDelete = useCallback(
    (id) => {
      dispatch(
        openModal({
          key: CONFIRM.key,
          props: {
            title: "Salesforce Group Delete",
            content: "Are you sure, you want to delete Salesforce group?",
            handleConfirm: () => handleDeleteConfirm(id),
          },
        })
      );
    },
    [CONFIRM, dispatch, handleDeleteConfirm]
  );

  const handleSyncAll = () => {
    dispatch(syncAllGroup());
  };

  const handleSync = useCallback(
    (groupData) => {
      dispatch(
        editGroup({
          payload: getEditGroupPayload({ ...groupData }),
          errorMessage: () => {
            errorNotification({
              title: "API Error",
              message: "Failed to sync group",
            });
          },
          successMessage: () => {
            successNotification({
              title: "Sync Successfull",
              message: "Group Sync Successfull",
            });
          },
        })
      );
    },
    [dispatch]
  );

  const handleSyncInterval = useCallback(() => {
    dispatch(openModal({ key: SYNC_INTERVAL.key, props: {} }));
  }, [SYNC_INTERVAL, dispatch]);

  const tableColumn = useMemo(
    () =>
      columnAndComponentFunctionReference(GROUPS_COLUMN, {
        currentPage,
        handleSync,
        handleDelete,
        handleEdit,
        usersList,
        teamsList,
      }),
    [
      currentPage,
      GROUPS_COLUMN,
      usersList,
      teamsList,
      handleSync,
      handleDelete,
      handleEdit,
    ]
  );

  useEffect(() => {
    !teamsList && dispatch(fetchTeams());
  }, [teamsList, groupData, usersList, dispatch]);

  useEffect(() => {
    !usersList && dispatch(fetchUsers());
  }, [dispatch, usersList]);

  useEffect(() => {
    !groupData && dispatch(fetchGroups());
  }, [dispatch, groupData]);

  return (
    <div className="userContainer">
      <Row className="filter-head">
        <Col span={12}>
          <h2 style={{ fontSize: "20px" }}>User Provisioning Groups</h2>
        </Col>
        <Col style={{ justifyContent: "end", display: "flex" }} span={12}>
          <Button className="primary-outline  mr-1" onClick={handleSyncAll}>
            {SYNC_ALL_GROUPS}
          </Button>
          <Button className="primary-outline mr-1" onClick={handleSyncInterval}>
            {SYNC_SALESFORCE_INTERVAL}
          </Button>
          <Button className="primary-outline" onClick={handleAddNewGroup}>
            {ADD_NEW_GROUP}
          </Button>
        </Col>
      </Row>
      <Table
        className="cmn-table groups"
        columns={tableColumn}
        dataSource={withKeyDataSet(ID, groupList ?? [])}
        scroll={{ y: "calc(45vh - 48px)" }}
        pagination={false}
      />
    </div>
  );
}

export default Groups;
