/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { closeModal } from "../../../redux-toolkit/slice/modal";
import CONFIG from "../../../utils/config";

import {
  cloneTemplate,
  updateTemplate,
} from "../../../redux-toolkit/slice/template/asyncThunk";
import { templatePayload } from "../../../utils/functions";
import EditingModules from "./components/editingModules";
import SaveAs from "./components/saveAs";

function TemplateEdit({ componentKey, name, ...rest }) {
  const { TEMPLATE_PREVIEW } = CONFIG;

  let PreviewComponent = componentKey
    ? TEMPLATE_PREVIEW[componentKey]
    : () => {};

  const dispatch = useDispatch();

  const [editState, setEditState] = useState({});
  const [templateName, setTemplateName] = useState("");
  const [isChangeMade, setIsChangeMade] = useState(false);
  const [showTemplateName, setShowTempalteName] = useState(false);
  const [showTemplateNameError, setShowTemplateNameError] = useState(false);

  const handleCloseModal = (isPrev) => {
    isPrev ? setShowTempalteName(false) : dispatch(closeModal());
  };

  const handleCreateTemplate = () => {
    templateName
      ? dispatch(
          cloneTemplate({
            payload: templatePayload({
              name: templateName,
              componentKey,
              ...editState,
            }),
            callback: () => handleCloseModal(false),
          })
        )
      : setShowTemplateNameError(true);
  };

  const handleEdit = () => {
    if (rest.id) {
      isChangeMade
        ? dispatch(
            updateTemplate({
              payload: templatePayload({
                name,
                componentKey,
                ...rest,
                ...editState,
              }),
              callback: () => handleCloseModal(false),
            })
          )
        : handleCloseModal(false);
    } else {
      showTemplateName ? handleCreateTemplate() : setShowTempalteName(true);
    }
  };

  useEffect(() => {
    if (rest) setEditState(rest);
  }, []);

  return (
    <Modal
      key={name}
      title={rest.id ? name : "Create New Template"}
      centered
      className="common-modal edit-modal-outer"
      open={true}
      width={1000}
      onCancel={() => handleCloseModal(false)}
      footer={
        <div className="footer-btns">
          <Button className="primary-solid" onClick={handleEdit}>
            {rest.id || showTemplateName ? "Save" : "Save as"}
          </Button>
          <Button
            className="primary-outline"
            onClick={() => handleCloseModal(showTemplateName)}
          >
            {showTemplateName ? "Prev" : "Cancel"}
          </Button>
        </div>
      }
    >
      <div className="preview-container">
        <h2>Preview</h2>
        <PreviewComponent {...editState} />
      </div>
      {showTemplateName ? (
        <SaveAs
          templateName={templateName}
          setTemplateName={setTemplateName}
          showTemplateNameError={showTemplateNameError}
          setShowTemplateNameError={setShowTemplateNameError}
        />
      ) : (
        <EditingModules
          editState={editState}
          setEditState={setEditState}
          setIsChangeMade={setIsChangeMade}
        />
      )}
    </Modal>
  );
}

export default TemplateEdit;
