import { createAsyncThunk } from "@reduxjs/toolkit";
import CONFIG from "../../../utils/config";
import { beginAPICall, endAPICall } from "../loading";
import { postRequest } from "../../../api/baseAPI";
import { errorNotification } from "../../../utils/functions";

export const fetchKwipDashboardDetails = createAsyncThunk(
  "dashboard/fetchKwipDashboardDetails",
  async ({ payload }, { dispatch }) => {
    const {
      API_URLS: { KWIPS_DASHBOARD_DETAILS },
    } = CONFIG;

    try {
      dispatch(beginAPICall());
      let { data } = await postRequest(KWIPS_DASHBOARD_DETAILS, payload, {
        service: "KWIPS",
      });
      return data;
    } catch ({ response }) {
      errorNotification({
        title: "API Error",
        message: "Failed to fetch dashboard details",
      });
      throw new Error();
    } finally {
      dispatch(endAPICall());
    }
  }
);

export const fetchTickerDashboardDetails = createAsyncThunk(
  "dashboard/fetchTickerDashboardDetails",
  async ({ payload }, { dispatch }) => {
    const {
      API_URLS: { TICKER_DASHBOARD_DETAILS },
    } = CONFIG;

    try {
      dispatch(beginAPICall());
      let { data } = await postRequest(TICKER_DASHBOARD_DETAILS, payload, {
        service: "TICKERS",
      });
      return data;
    } catch ({ response }) {
      errorNotification({
        title: "API Error",
        message: "Failed to fetch dashboard details",
      });
      throw new Error();
    } finally {
      dispatch(endAPICall());
    }
  }
);

export const fetchKwipsLogs = createAsyncThunk(
  "dashboard/fetchKwipsLogs",
  async ({ payload }, { dispatch }) => {
    const {
      API_URLS: { KWIP_LOGS },
    } = CONFIG;

    try {
      dispatch(beginAPICall());
      let {
        data: { data },
      } = await postRequest(KWIP_LOGS, payload, { service: "KWIPS" });
      return data;
    } catch ({ response }) {
      errorNotification({
        title: "API Error",
        message: "Failed to fetch kwip logs",
      });
    } finally {
      dispatch(endAPICall());
    }
  }
);

export const fetchTickerLogs = createAsyncThunk(
  "dashboard/fetchTickerLogs",
  async ({ payload }, { dispatch }) => {
    const {
      API_URLS: { TICKER_LOGS },
    } = CONFIG;

    try {
      dispatch(beginAPICall());
      let {
        data: { data },
      } = await postRequest(TICKER_LOGS, payload, { service: "TICKERS" });
      return data;
    } catch ({ response }) {
      errorNotification({
        title: "API Error",
        message: "Failed to fetch ticker logs",
      });
    } finally {
      dispatch(endAPICall());
    }
  }
);
