import { createAsyncThunk } from "@reduxjs/toolkit";

import CONFIG from "../../../utils/config";
import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from "../../../api/baseAPI";
import { beginAPICall, endAPICall } from "../loading";
import {
  errorNotification,
  successNotification,
} from "../../../utils/functions";
import { fetchTeams } from "../teams/asyncThunk";
import { fetchUsers } from "../users/asyncThunks";

export const fetchFilteredUsers = createAsyncThunk(
  "groups/fetchFilteredUsers",
  async (
    { payload, successCallback = () => {}, callback = () => {} },
    { dispatch }
  ) => {
    let {
      API_URLS: { FETCH_FILTERED_USERS },
    } = CONFIG;
    try {
      dispatch(beginAPICall());
      let { data } = await postRequest(FETCH_FILTERED_USERS, payload, {
        service: "SALESFORCE",
      });
      successCallback();
      return data;
    } catch ({ response }) {
      const { data } = response;
      throw new Error(JSON.stringify(data));
    } finally {
      dispatch(endAPICall());
      callback();
    }
  }
);

export const fetchGroups = createAsyncThunk(
  "groups/fetchGroups",
  async (_, { dispatch }) => {
    let {
      API_URLS: { FETCH_CREATED_GROUPS },
    } = CONFIG;
    try {
      dispatch(beginAPICall());
      let { data } = await getRequest(FETCH_CREATED_GROUPS, {
        service: "SALESFORCE",
      });
      return data;
    } catch ({ response }) {
      errorNotification({
        title: "API Error",
        message: "Failed to fetch groups",
      });
      throw new Error();
    } finally {
      dispatch(endAPICall());
    }
  }
);

export const createGroup = createAsyncThunk(
  "groups/createGroup",
  async ({ payload, callback = () => {} }, { dispatch }) => {
    let {
      API_URLS: { CREATE_GROUP },
    } = CONFIG;
    try {
      dispatch(beginAPICall());
      await postRequest(CREATE_GROUP, payload, { service: "SALESFORCE" });
    } catch ({ response }) {
      errorNotification({
        title: "API Error",
        message: "Group creation failed",
      });
      throw new Error();
    } finally {
      dispatch(endAPICall());
      callback();
      dispatch(fetchGroups());
      dispatch(fetchTeams());
      dispatch(fetchUsers());
    }
  }
);

export const editGroup = createAsyncThunk(
  "groups/editGroup",
  async (
    {
      payload: { id, ...payload },
      callback,
      successMessage = () => {},
      errorMessage = () => {},
    },
    { dispatch }
  ) => {
    let {
      API_URLS: { EDIT_GROUP },
    } = CONFIG;
    try {
      dispatch(beginAPICall());
      await putRequest(EDIT_GROUP + id + "/", payload, {
        service: "SALESFORCE",
      });
    } catch ({ response }) {
      errorMessage();
      throw new Error();
    } finally {
      dispatch(endAPICall());
      callback();
      dispatch(fetchGroups());
      dispatch(fetchTeams());
      dispatch(fetchUsers());
    }
  }
);

export const deleteGroup = createAsyncThunk(
  "groups/deleteGroup",
  async ({ payload: { id }, callback = () => {} }, { dispatch }) => {
    let {
      API_URLS: { DELETE_GROUP },
    } = CONFIG;
    try {
      dispatch(beginAPICall());
      await deleteRequest(DELETE_GROUP + id + "/", { service: "SALESFORCE" });
    } catch ({ response }) {
      errorNotification({
        title: "API Error",
        message: "Group deletion failed",
      });
      throw new Error();
    } finally {
      dispatch(endAPICall());
      callback();
      dispatch(fetchGroups());
      dispatch(fetchTeams());
      dispatch(fetchUsers());
    }
  }
);

export const syncAllGroup = createAsyncThunk(
  "groups/syncAll",
  async (_, { dispatch }) => {
    let {
      API_URLS: { SYNC_ALL },
    } = CONFIG;

    try {
      dispatch(beginAPICall());
      await getRequest(SYNC_ALL, { service: "ACCOUNT" });
    } catch ({ response }) {
      errorNotification({
        title: "API failed",
        message: "Failed to sync all groups",
      });
    } finally {
      dispatch(endAPICall());
    }
  }
);

export const syncroniseInterval = createAsyncThunk(
  "groups/syncInterval",
  async ({ data: payload, callback }, { dispatch }) => {
    let {
      API_URLS: { SYNC_INTERVAL },
    } = CONFIG;

    try {
      dispatch(beginAPICall());
      let {
        data: { sync_interval },
      } = await postRequest(SYNC_INTERVAL, payload, {
        service: "SALESFORCE",
      });

      return sync_interval;
    } catch ({ response }) {
      errorNotification({
        title: "API failed",
        message: "Failed to set sync interval",
      });
    } finally {
      callback();
      dispatch(endAPICall());
    }
  }
);

export const fetchSyncInterval = createAsyncThunk(
  "groups/fetchSyncInterval",
  async (_, { dispatch }) => {
    let {
      API_URLS: { FETCH_SYNC_INTERVAL },
    } = CONFIG;

    try {
      dispatch(beginAPICall());
      let {
        data: { sync_interval },
      } = await getRequest(FETCH_SYNC_INTERVAL, {
        service: "SALESFORCE",
      });
      return sync_interval;
    } catch ({ response }) {
      errorNotification({
        title: "API failed",
        message: "Failed to set sync interval",
      });
    } finally {
      dispatch(endAPICall());
    }
  }
);
