import { Button, Col, DatePicker, Form, Row, Select } from "antd";
import React, { useState } from "react";
import {
  generateTimeArray,
  idGenerator,
  optionCreator,
} from "../../../../utils/functions";
import { MinusCircleFilled } from "@ant-design/icons";
import dayjs from "dayjs";
import CONFIG from "../../../../utils/config";

function TickerScheduler({ scheduleData, setScheduleData, setIsEdited }) {
  const {
    TICKER_SCHEDULE: { REPEAT, ALLOWED_END_DATE },
  } = CONFIG;

  const [dateAndTime, setDateAndTime] = useState({ date: null, time: null });

  const disabledDate = (current) => {
    return current < dayjs().subtract(1, "day").endOf("day");
  };

  const handleDateChange = (date) => {
    setDateAndTime({ ...dateAndTime, date });
  };

  const handleTimeChange = (time) => {
    setDateAndTime({ ...dateAndTime, time });
  };

  const handleAdd = () => {
    if (!dateAndTime.date || !dateAndTime.time) return;

    setScheduleData({
      ...scheduleData,
      scheduleList: [
        ...(scheduleData.scheduleList || []),
        { ...dateAndTime, id: idGenerator(), repeat: 0 },
      ],
    });
    setDateAndTime({ date: null, time: null });
    setIsEdited(true);
  };

  const handleDeselect = (filteredId) => {
    setIsEdited(true);
    setScheduleData((prev) => ({
      ...prev,
      scheduleList: prev.scheduleList.filter(({ id }) => id !== filteredId),
    }));
  };

  const handleEndDateSelect = (date, selectedId) => {
    let changedSchedule = scheduleData.scheduleList.find(
      ({ id }) => selectedId === id
    );

    changedSchedule.endDate = date;

    setIsEdited(true);
    setScheduleData((prev) => ({
      ...prev,
      scheduleList: prev.scheduleList.map(({ id, ...rest }) =>
        id === selectedId ? changedSchedule : { ...rest, id }
      ),
    }));
  };

  const handleRepeatChange = (value, selectedId) => {
    setIsEdited(true);
    setScheduleData((prev) => ({
      ...prev,
      scheduleList: prev.scheduleList.map(({ id, ...rest }) =>
        id === selectedId ? { id, ...rest, repeat: value } : { id, ...rest }
      ),
    }));
  };

  return (
    <Form name="form_item_path" layout="vertical" onFinish={() => {}}>
      <Row gutter={[16, 16]}>
        <Col xs={20} sm={4} md={6} lg={12} xl={8}>
          <Form.Item
            label="Date"
            className="mb-0 no-form-item"
            children={null}
            required
          />
          <DatePicker
            className="mb-3"
            value={dateAndTime.date}
            disabledDate={disabledDate}
            onChange={handleDateChange}
            format={"DD-MM-YYYY"}
          />
          <Form.Item
            label="Time"
            className="mb-0 no-form-item"
            required
            children={null}
          />
          <Select
            className="mb-3"
            style={{ width: "100%" }}
            value={dateAndTime.time}
            disabled={!dateAndTime.date}
            options={optionCreator(generateTimeArray())}
            onChange={handleTimeChange}
            placeholder="Select time"
          />
          <div className="w-100 d-flex justify-content-end">
            <Button className="primary-outline" onClick={handleAdd}>
              Add
            </Button>
          </div>
        </Col>
        <Col xs={20} sm={16} md={12} lg={12} xl={16}>
          <div className="create-notify-list">
            {scheduleData?.scheduleList?.map(
              ({ date, time, id, repeat, endDate }) => {
                return (
                  <div className="notify-list-items position-relative" key={id}>
                    <span className="ml-1 d-flex align-items-center">
                      {date.format("dddd, MMMM Do YYYY")} - {time}
                      <Select
                        className="ml-3 custom-width-150"
                        options={REPEAT}
                        value={repeat}
                        onChange={(value) => handleRepeatChange(value, id)}
                      />
                      {ALLOWED_END_DATE.includes(repeat) && (
                        <DatePicker
                          className="ml-2 custom-width-150"
                          value={endDate}
                          onChange={(date) => handleEndDateSelect(date, id)}
                          format={"DD-MM-YYYY"}
                          placeholder="Select end date"
                        />
                      )}
                    </span>
                    <MinusCircleFilled onClick={() => handleDeselect(id)} />
                  </div>
                );
              }
            )}
          </div>
        </Col>
      </Row>
    </Form>
  );
}

export default TickerScheduler;
