import { createSlice } from "@reduxjs/toolkit";

import INITIAL_STATE from "../../initialState";
import { createKwip, deleteKwip, editKwip, fetchKwips } from "./asyncThunk";

export const kwipSlice = createSlice({
  name: "kwips",
  initialState: INITIAL_STATE.KWIPS,
  reducers: {
    clearKwips: (state) => {
      state.kwipList = undefined;
      state.error = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchKwips.fulfilled, (state, { payload }) => {
      state.error = false;
      state.kwipList = payload.map(
        ({ kwip_metadata: { creditTo, template, trigger_id }, ...rest }) => ({
          ...rest,
          creditTo,
          template,
          trigger_id,
        })
      );
    });
    builder.addCase(fetchKwips.rejected, (state, { error }) => {
      let errorObject = JSON.parse(error.message);
      state.error = errorObject;
    });

    builder.addCase(createKwip.fulfilled, (state, { payload }) => {
      state.error = false;
      let {
        kwip_metadata: { creditTo, template, trigger_id },
        ...rest
      } = payload;
      state.kwipList.push({ ...rest, creditTo, template, trigger_id });
    });

    builder.addCase(createKwip.rejected, (state, { error }) => {
      let errorObject = JSON.parse(error.message);
      state.error = errorObject;
    });

    builder.addCase(editKwip.fulfilled, (state, { payload }) => {
      state.error = false;
      let {
        kwip_metadata: { creditTo, template, trigger_id },
        ...rest
      } = payload;

      let editedIndex = state.kwipList.findIndex(({ id }) => id === payload.id);

      state.kwipList[editedIndex] = { ...rest, creditTo, template, trigger_id };
    });

    builder.addCase(editKwip.rejected, (state, { error }) => {
      let errorObject = JSON.parse(error.message);
      state.error = errorObject;
    });

    builder.addCase(deleteKwip.fulfilled, (state, { payload }) => {
      state.error = false;
      let editedIndex = state.kwipList.findIndex(({ id }) => id === payload);
      state.kwipList.splice(editedIndex, 1);
    });

    builder.addCase(deleteKwip.rejected, (state, { error }) => {
      let errorObject = JSON.parse(error.message);
      state.error = errorObject;
    });
  },
});

export const { clearKwips } = kwipSlice.actions;

export default kwipSlice.reducer;
