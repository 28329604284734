import { createSlice } from "@reduxjs/toolkit";

import INITIAL_STATE from "../../initialState";
import {
  createMetrices,
  deleteMetrices,
  editMetrices,
  fetchMetrices,
  updateMetricPreview,
} from "./asyncThunk";

export const metricesSlice = createSlice({
  name: "metrices",
  initialState: INITIAL_STATE.METRICES,
  reducers: {
    clearMetrices: (state) => {
      state.metricesList = undefined;
      state.error = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchMetrices.fulfilled, (state, { payload }) => {
      state.error = false;
      state.metricesList = payload;
    });
    builder.addCase(fetchMetrices.rejected, (state, { error }) => {
      state.error = true;
    });
    builder.addCase(createMetrices.fulfilled, (state, { payload }) => {
      state.error = false;
      state.metricesList = [...(state.metricesList ?? []), payload];
    });
    builder.addCase(createMetrices.rejected, (state, { error }) => {
      state.error = true;
    });
    builder.addCase(editMetrices.fulfilled, (state, { payload }) => {
      state.error = false;
      state.metricesList = state.metricesList.reduce((result, data) => {
        if (data.id === payload.id) return [...result, payload];
        else return [...result, data];
      }, []);
    });
    builder.addCase(editMetrices.rejected, (state, { error }) => {
      state.error = true;
    });
    builder.addCase(deleteMetrices.fulfilled, (state, { payload }) => {
      state.error = false;
      state.metricesList = state.metricesList.filter(
        ({ id }) => id !== payload
      );
    });
    builder.addCase(deleteMetrices.rejected, (state, { error }) => {
      state.error = true;
    });
    builder.addCase(updateMetricPreview.fulfilled, (state, { payload }) => {
      state.error = false;
      state.metricesList = state.metricesList.reduce((result, data) => {
        if (data.id === payload.id) return [...result, payload];
        else return [...result, data];
      }, []);
    });
    builder.addCase(updateMetricPreview.rejected, (state, { error }) => {
      state.error = true;
    });
  },
});

export const { clearMetrices } = metricesSlice.actions;

export default metricesSlice.reducer;
