import React, { useEffect, useState } from "react";
import { Modal, Steps } from "antd";
import { useDispatch, useSelector } from "react-redux";

import CONFIG from "../../../utils/config";
import StepButton from "./components/stepButton";
import { closeModal } from "../../../redux-toolkit/slice/modal";
import notificationDataSelector from "../../../redux-toolkit/selectors/notification";
import {
  createNotification,
  editNotification,
  fetchFields,
} from "../../../redux-toolkit/slice/notification/asyncThunk";
import {
  createNotificationPayload,
  createNotificationState,
} from "../../../utils/functions";
import metricesDataSelector from "../../../redux-toolkit/selectors/metricesData";

export default function NotificationModal({ notificationData }) {
  const { NOTIFICATION_STEPS, DEFAULT_NOTIFICATION_OBJECT } = CONFIG;

  const [currentStep, setCurrent] = useState(0);
  const [notificationObject, setNotificationObject] = useState(
    DEFAULT_NOTIFICATION_OBJECT
  );
  const [isEdited, setIsEdited] = useState(false);
  const [isStepLoading, setStepLoading] = useState(false);
  const [isMetricChanged, setIsMetricChanged] = useState(false);

  const dispatch = useDispatch();

  const { objects, fields } = notificationDataSelector(useSelector);
  const { metricesList } = metricesDataSelector(useSelector);

  const handleFirstStepCallback = () => {
    setCurrent(1);
    setStepLoading(false);
  };

  const handleSave = () => {
    const payload = createNotificationPayload(notificationObject);
    notificationData
      ? isEdited
        ? dispatch(
            editNotification({
              payload: { ...payload, id: notificationData.id },
            })
          )
        : handleCloseModal()
      : dispatch(createNotification({ payload }));
  };

  const resetNotificationObject = () => {
    setNotificationObject({
      titleName: notificationObject.titleName,
      type: notificationObject.type,
      object: notificationObject.object,
      metric_id: notificationObject.metric_id,
      threshold_type: notificationObject.threshold_type,
      creditTo: notificationObject.creditTo,
      filterOptions:
        notificationObject?.filterOptions?.filter(
          ({ is_deletable }) => !(is_deletable ?? true)
        ) ?? [],
      ...DEFAULT_NOTIFICATION_OBJECT,
    });
  };

  const next = () => {
    switch (currentStep) {
      case 0: {
        setStepLoading(true);

        if (isMetricChanged) resetNotificationObject();

        setIsMetricChanged(false);

        dispatch(
          fetchFields({
            object: notificationObject.object,
            callback: handleFirstStepCallback,
          })
        );
        break;
      }
      case 2: {
        handleSave();
        break;
      }
      default:
        setCurrent(currentStep + 1);
    }
  };

  const prev = () => {
    setCurrent(currentStep - 1);
  };

  const handleCloseModal = () => {
    dispatch(closeModal());
  };

  const getModalTitle = () => {
    switch (currentStep) {
      case 0:
        return notificationData
          ? notificationData.event_name
          : "Define Threshold";
      case 1:
        return "Select Criteria";
      case 2:
        return "Recognition Data Fields";
      default:
        return "";
    }
  };

  useEffect(() => {
    if (notificationData)
      setNotificationObject(
        createNotificationState(notificationData, metricesList)
      );
  }, [notificationData, metricesList]);

  return (
    <Modal
      title={getModalTitle()}
      centered
      open={true}
      onOk={() => {}}
      onCancel={handleCloseModal}
      width={900}
      footer={
        <StepButton
          currentStep={currentStep}
          isStepLoading={isStepLoading}
          notificationObject={notificationObject}
          prev={prev}
          next={next}
          handleCancel={handleCloseModal}
        />
      }
    >
      <Steps
        current={currentStep}
        percent={60}
        labelPlacement="vertical"
        items={NOTIFICATION_STEPS({})}
      />
      <div className="steps-content">
        {
          NOTIFICATION_STEPS({
            fields,
            objects,
            notificationObject,
            setIsEdited,
            setIsMetricChanged,
            setNotificationObject,
          })[currentStep]?.content
        }
      </div>
      <div className="mb-3" />
    </Modal>
  );
}
