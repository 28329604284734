import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import Routes from "./routes";
import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import Modals from "./modal";

function App() {
  return (
    <BrowserRouter>
      <Routes />
      <Modals />
      <ToastContainer />
    </BrowserRouter>
  );
}

export default App;
