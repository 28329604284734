import { createAsyncThunk } from "@reduxjs/toolkit";

import CONFIG from "../../../utils/config";
import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from "../../../api/baseAPI";
import { beginAPICall, endAPICall } from "../loading";
import { closeModal, openModal } from "../modal";
import { errorNotification } from "../../../utils/functions";
import { fetchNotifications } from "../notification/asyncThunk";

export const fetchMetrices = createAsyncThunk(
  "metrices/fetchMetrices",
  async (_, { dispatch }) => {
    let {
      API_URLS: { FETCH_METRICES },
    } = CONFIG;
    try {
      dispatch(beginAPICall());
      let { data } = await getRequest(FETCH_METRICES, {
        service: "KPIS",
      });
      return data;
    } catch ({ response }) {
      const { data } = response;
      errorNotification({
        title: "API Error",
        message: "Failed to fetch metrics data",
      });
      throw new Error(JSON.stringify(data));
    } finally {
      dispatch(endAPICall());
    }
  }
);

export const createMetrices = createAsyncThunk(
  "metrices/createMetrices",
  async ({ payload }, { dispatch }) => {
    let {
      API_URLS: { CREATE_METRICES },
    } = CONFIG;

    try {
      dispatch(beginAPICall());
      let { data } = await postRequest(
        CREATE_METRICES,
        { ...payload },
        { service: "KPIS" }
      );
      return data;
    } catch ({ response }) {
      errorNotification({
        title: "API Error",
        message: "Failed to create metric",
      });
      throw new Error();
    } finally {
      dispatch(endAPICall());
      dispatch(closeModal());
    }
  }
);

export const editMetrices = createAsyncThunk(
  "metrices/editMetrices",
  async ({ payload: { id, ...payload } }, { dispatch }) => {
    let {
      API_URLS: { CREATE_METRICES },
    } = CONFIG;

    try {
      dispatch(beginAPICall());
      let { data } = await putRequest(
        CREATE_METRICES + id + "/",
        { ...payload },
        {
          service: "KPIS",
        }
      );
      return data;
    } catch ({ response }) {
      const { data } = response;
      errorNotification({
        title: "API Error",
        message: "Failed to edit metric",
      });
      throw new Error(JSON.stringify(data));
    } finally {
      dispatch(endAPICall());
      dispatch(closeModal());
      dispatch(fetchNotifications());
    }
  }
);

export const deleteMetrices = createAsyncThunk(
  "metrices/deleteMetrices",
  async ({ id, name }, { dispatch }) => {
    let {
      API_URLS: { DELETE_METRICES },
      MODALS: { RESTRICT_DELETE },
    } = CONFIG;

    try {
      dispatch(beginAPICall());
      await deleteRequest(DELETE_METRICES + id + "/", {
        service: "KPIS",
      });
      return id;
    } catch ({ response }) {
      const { data, status } = response;
      if (status === 403) {
        delete data.message;
        dispatch(
          openModal({
            key: RESTRICT_DELETE.key,
            props: {
              modalData: { title: name, metadata: data, service: "Metric" },
            },
          })
        );
      } else
        errorNotification({
          title: "API Error",
          message: "Failed to delete metric",
        });
      throw new Error(JSON.stringify(data));
    } finally {
      dispatch(endAPICall());
    }
  }
);

export const updateMetricPreview = createAsyncThunk(
  "metrices/updateMetricPreview",
  async ({ payload }, { dispatch }) => {
    let {
      API_URLS: { EDIT_METRIC_PREVIEW },
    } = CONFIG;

    try {
      dispatch(beginAPICall());
      let { data } = await postRequest(
        EDIT_METRIC_PREVIEW,
        { ...payload },
        { service: "KPIS" }
      );
      return data;
    } catch ({ response }) {
      const { data } = response;
      errorNotification({
        title: "API Error",
        message: "Failed to edit metric preview data",
      });
      throw new Error(JSON.stringify(data));
    } finally {
      dispatch(endAPICall());
      dispatch(closeModal());
    }
  }
);
