import { Button, Col, Row, Table } from "antd";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  columnAndComponentFunctionReference,
  withKeyDataSet,
} from "../../utils/functions";
import CONFIG from "../../utils/config";
import { useDispatch, useSelector } from "react-redux";
import { closeModal, openModal } from "../../redux-toolkit/slice/modal";
import {
  deleteTemplate,
  fetchTemplate,
} from "../../redux-toolkit/slice/template/asyncThunk";
import templateDataSelector from "../../redux-toolkit/selectors/templateData";

function Template() {
  const {
    TEMPLATE_COLUMN,
    MODALS: { TEMPLATE_PREVIEW, TEMPLATE_EDIT, TEMPLATE_CREATE, CONFIRM },
  } = CONFIG;

  const dispatch = useDispatch();

  const { templates } = templateDataSelector(useSelector);

  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleAddNewTemplate = () => {
    dispatch(openModal({ key: TEMPLATE_CREATE.key, props: { templates } }));
  };

  const handlePreview = useCallback(
    ({ componentKey, name, bgImage, ...rest }) => {
      dispatch(
        openModal({
          key: TEMPLATE_PREVIEW.key,
          props: { componentKey, name, bgImage, ...rest },
        })
      );
    },
    [TEMPLATE_PREVIEW, dispatch]
  );

  const handleEdit = useCallback(
    ({ componentKey, name, bgImage, ...rest }) => {
      dispatch(
        openModal({
          key: TEMPLATE_EDIT.key,
          props: { componentKey, name, bgImage, ...rest },
        })
      );
    },
    [TEMPLATE_EDIT, dispatch]
  );

  const handleDeleteConfirm = useCallback(
    (id) => {
      dispatch(deleteTemplate({ payload: { id } }));
      dispatch(closeModal());
    },
    [dispatch]
  );

  const handleDelete = useCallback(
    (id, instance_url) => {
      dispatch(
        openModal({
          key: CONFIRM.key,
          props: {
            title: "Template Delete",
            content: "Are you sure, you want to delete the Template?",
            handleConfirm: () => handleDeleteConfirm(id, instance_url),
          },
        })
      );
    },
    [CONFIRM, dispatch, handleDeleteConfirm]
  );

  const tableColumn = useMemo(
    () =>
      columnAndComponentFunctionReference(TEMPLATE_COLUMN, {
        currentPage,
        handlePreview,
        handleEdit,
        handleDelete,
      }),
    [TEMPLATE_COLUMN, currentPage, handlePreview, handleDelete, handleEdit]
  );

  useEffect(() => {
    !templates && dispatch(fetchTemplate());
  }, [templates, dispatch]);

  return (
    <div className="userContainer">
      <Row className="filter-head">
        <Col span={12}>
          <h2>Templates</h2>
        </Col>
        <Col style={{ justifyContent: "end", display: "flex" }} span={12}>
          <Button className="primary-outline" onClick={handleAddNewTemplate}>
            Add Template
          </Button>
        </Col>
      </Row>
      <Table
        className="cmn-table"
        columns={tableColumn}
        dataSource={withKeyDataSet("id", templates)}
        scroll={{ y: "calc(70vh - 55px)" }}
        pagination={{
          currentPage,
          onChange: handlePageChange,
        }}
      />
    </div>
  );
}

export default Template;
