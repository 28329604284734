/* eslint-disable import/no-anonymous-default-export */
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { CaretDownOutlined } from "@ant-design/icons";
import { Layout, Menu, Dropdown, Space } from "antd";
import { useDispatch, useSelector } from "react-redux";

import CONFIG from "../utils/config";
import layoutDataSelector from "../redux-toolkit/selectors/layoutData";
import Overlay from "../components/overlay";
import {
  filterMenuItem,
  filterNavItems,
  getPathKey,
  getToken,
  setToken,
} from "../utils/functions";
import {
  fetchAdminDetails,
  openStripeAccountPortal,
} from "../redux-toolkit/slice/admin/asyncThunk";

import logo from "../assets/images/logo.png";
import profilePic from "../assets/images/profile-pic.png";
import Loader from "../components/loader";
import { clearAdmin } from "../redux-toolkit/slice/admin";
import { clearDashboard } from "../redux-toolkit/slice/dashboard";
import { clearAll } from "../redux-toolkit/slice/groups";
import { clearKwips } from "../redux-toolkit/slice/kwips";
import { clearNotification } from "../redux-toolkit/slice/notification";
import { clearTeam } from "../redux-toolkit/slice/teams";
import { clearTicker } from "../redux-toolkit/slice/ticker";
import { clearUser } from "../redux-toolkit/slice/users";
import { clearLeaderboard } from "../redux-toolkit/slice/leaderboard";
import { clearMetrices } from "../redux-toolkit/slice/metrices";

const { Header, Content, Sider } = Layout;

export default function () {
  const {
    ALLOWED_ACCESS,
    MENU_ALLOWED_ACCESS,
    MENU_ITEMS,
    PROFILE_ITEMS,
    TOKEN: { ACCESS_TOKEN, REFRESH_TOKEN, SESSION_TIMEOUT },
    ROUTES: { LOGIN, UNAUTHORISED, PASSWORD },
  } = CONFIG;

  const accessToken = getToken(ACCESS_TOKEN);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { adminDetails, loading } = layoutDataSelector(useSelector);

  const [selectedMenu, setSelectedMenu] = useState(null);
  const [isSessionAllowed, setIsSessionAllowed] = useState(false);

  const { full_photo_url, name, profile_photo, role, isPasswordSecured } =
    adminDetails || {};

  const handleMenuSelect = ({ key }) => {
    setSelectedMenu(key);
    navigate(LOGIN.path + key);
  };

  const clearTokens = () => {
    setToken(ACCESS_TOKEN, "");
    setToken(REFRESH_TOKEN, "");
    setToken(SESSION_TIMEOUT, "");
    setToken(REFRESH_TOKEN, "");
    setToken("admminEmail", "");
    setToken("kwipo_user_id", "");
    setToken("adminId", "");
  };

  const handleLogOut = () => {
    clearReducer();
    clearTokens();
    navigate(LOGIN.path);
  };

  const clearReducer = () => {
    dispatch(clearAdmin());
    dispatch(clearDashboard());
    dispatch(clearAll());
    dispatch(clearKwips());
    dispatch(clearNotification());
    dispatch(clearTeam());
    dispatch(clearTicker());
    dispatch(clearUser());
    dispatch(clearLeaderboard());
    dispatch(clearMetrices());
  };

  const handleViewProfile = () => {
    let currentURL = window.location.href;
    openStripeAccountPortal(dispatch, { return_url: currentURL });
  };

  const handleOpenSupport = () => {};

  const fetchDetails = useCallback(() => {
    dispatch(fetchAdminDetails());
  }, [dispatch]);

  const sessionHander = useCallback(() => {
    let sessionTimeout = getToken(SESSION_TIMEOUT);
    if (sessionTimeout) {
      let currentTime = new Date().getTime();
      let isSessionAllowed = currentTime < sessionTimeout;
      if (isSessionAllowed) setIsSessionAllowed(true);
      else handleLogOut();
    } else setIsSessionAllowed(true);
  }, []);

  useEffect(() => {
    if (isSessionAllowed) {
      if (accessToken) {
        if (!adminDetails) fetchDetails();
      } else navigate(LOGIN.path);
    }
  }, [
    LOGIN,
    accessToken,
    adminDetails,
    isSessionAllowed,
    fetchDetails,
    navigate,
  ]);

  useEffect(() => {
    let key = getPathKey();
    if (role) {
      let isAccessAllowed = ALLOWED_ACCESS[role].includes(key);
      isAccessAllowed ? setSelectedMenu(key) : navigate(UNAUTHORISED.path);
    }
  }, [ALLOWED_ACCESS, UNAUTHORISED, role, navigate]);

  useEffect(() => {
    if (
      isPasswordSecured !== undefined &&
      isPasswordSecured !== null &&
      isPasswordSecured === false
    )
      navigate(PASSWORD.path);
  }, [PASSWORD, isPasswordSecured, navigate]);

  useEffect(() => {
    sessionHander();
  }, []);

  return (
    <Layout hasSider>
      <Overlay show={loading || !isSessionAllowed}>
        <Loader />
      </Overlay>
      {isSessionAllowed && (
        <>
          <Sider className="sidebar">
            <img className="logo" src={logo} alt="logo" />
            <div className="demo-logo-vertical" />
            <Menu
              className="menu sidebar-menu"
              selectedKeys={[selectedMenu]}
              mode="inline"
              items={filterNavItems(
                MENU_ITEMS(selectedMenu),
                ALLOWED_ACCESS[role]
              )}
              onSelect={handleMenuSelect}
            />
          </Sider>
          <Layout className="layout sider-margin site-layout">
            <Header>
              <div>
                <Dropdown
                  className="user-dropdown"
                  menu={{
                    items: filterMenuItem(
                      PROFILE_ITEMS({
                        handleViewProfile,
                        handleLogOut,
                        handleOpenSupport,
                      }),
                      MENU_ALLOWED_ACCESS[role]
                    ),
                  }}
                >
                  <div>
                    <span className="user-image">
                      <img
                        src={
                          (full_photo_url ||
                            profile_photo?.image ||
                            profile_photo?.find(({ is_active }) => is_active)
                              ?.image) ??
                          profilePic
                        }
                        alt="profilePic"
                      />
                    </span>
                    <Space>
                      {name ?? "Admin"}
                      <CaretDownOutlined />
                    </Space>
                  </div>
                </Dropdown>
              </div>
            </Header>
            {selectedMenu && (
              <Content>
                <Outlet />
              </Content>
            )}
          </Layout>
        </>
      )}
    </Layout>
  );
}
