import React, { useEffect, useMemo, useRef, useState } from "react";
import { Col, Row, Button, Image, Form, Input, Divider } from "antd";

import CONFIG from "../../utils/config";
import logo from "../../assets/images/logo.svg";
import salesforce from "../../assets/images/salesforce_logo.png";
import { getToken } from "../../utils/functions";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  cloneCustomer,
  forgetPassword,
  loginWithEmail,
} from "../../redux-toolkit/slice/admin/asyncThunk";
import Overlay from "../../components/overlay";
import Loader from "../../components/loader";
import loadingDataSelector from "../../redux-toolkit/selectors/loadingData";

const location = window.location;

function Login() {
  const {
    AUTHORISATION_URL,
    ROUTES: { DASHBOARD, FORGET_PASSWORD },
    TOKEN: { ACCESS_TOKEN },
    LABELS: {
      LOGIN: { WELCOME, LOGIN_NOW, USERNAME_OR_EMAIL, PASSWORD, SALESFORCE },
    },
  } = CONFIG;

  const [form] = Form.useForm();

  const access_token = getToken(ACCESS_TOKEN);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const customer_token = useMemo(
    () => new URLSearchParams(location.search).get("customer_token"),
    []
  );

  const newTokenFetched = useRef(false);

  const { loading } = loadingDataSelector(useSelector);
  const [loginState, setLoginState] = useState({ email: "", password: "" });
  const [emailValidateStatus, setEmailValidateStatus] = useState(false);

  const handleChange = ({ target: { name, value } }) => {
    setEmailValidateStatus(false);
    setLoginState({
      ...loginState,
      [name]: value,
    });
  };

  const handleLoginSubmit = () => {
    if (!loginState.email) {
      setEmailValidateStatus(true);
      return;
    }

    let isReady = Object.keys(loginState).every(
      (key) => loginState[key] !== ""
    );
    isReady &&
      dispatch(
        loginWithEmail({
          ...loginState,
          callback: () => {
            navigate(DASHBOARD.path);
          },
        })
      );
  };

  const handlesalesforceLogin = () => {
    window.location.href = AUTHORISATION_URL();
  };

  const handleForgetPassword = () => {
    navigate(FORGET_PASSWORD.path);
  };

  useEffect(() => {
    if (access_token)
      if (customer_token) {
        if (newTokenFetched.current) {
          navigate(DASHBOARD.path);
        } else newTokenFetched.current = true;
      } else navigate(DASHBOARD.path);
    else newTokenFetched.current = true;
  }, [access_token, customer_token, navigate, DASHBOARD]);

  useEffect(() => {
    form.validateFields(["email"]);
  }, [emailValidateStatus, form]);

  useEffect(() => {
    if (customer_token) dispatch(cloneCustomer({ customer_token }));
  }, [customer_token, dispatch]);

  return (
    <Row justify="center" align="middle" className="login-form">
      <Overlay show={loading}>
        <Loader />
      </Overlay>
      <Col span={12}>
        <div className="login-form-content">
          <div
            justify="center"
            align="middle"
            style={{
              marginBottom: "20px",
            }}
          >
            <Image
              src={logo}
              alt="logo"
              width={100}
              className="logo"
              preview={false}
            />
            <h2>{WELCOME}</h2>
            <h6>{LOGIN_NOW}</h6>
          </div>
          <Form form={form} onSubmitCapture={handleLoginSubmit}>
            <Form.Item
              name="email"
              rules={[
                {
                  required: emailValidateStatus,
                  message: "Please enter your username or email",
                },
              ]}
            >
              <Input
                name="email"
                value={loginState?.email}
                placeholder={USERNAME_OR_EMAIL}
                onChange={handleChange}
              />
            </Form.Item>

            <Form.Item>
              <Input.Password
                className="mt-1"
                name="password"
                value={loginState?.password}
                placeholder={PASSWORD}
                onChange={handleChange}
              />
              <span className="forget-password" onClick={handleForgetPassword}>
                Forgot Password?
              </span>
            </Form.Item>
            <Form.Item>
              <Button type="primary" block htmlType="submit">
                Continue
              </Button>
              <Divider>OR</Divider>
              <Button block onClick={handlesalesforceLogin}>
                <img src={salesforce} width={20} alt="salesforce" />{" "}
                {SALESFORCE}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Col>
    </Row>
  );
}

export default Login;
