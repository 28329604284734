import { Col, Form, Input, InputNumber, Row, Select } from "antd";
import React from "react";
import {
  filteredObject,
  groupOptions,
  isSubstring,
  optionCreator,
} from "../../../../../utils/functions";
import CONFIG from "../../../../../utils/config";

function FirstStep({
  metricesData,
  modalData,
  setModalData,
  objects,
  setIsEdited,
}) {
  const {
    METRICES_TYPES,
    METRICES_CURRENCY_TYPE,
    MOST_COMMON_OBJECTS,
    METRIC_TIMEFRAMES,
    METRICES_SOURCE,
  } = CONFIG;

  const handleChange = (key, value) => {
    setModalData({ ...modalData, [key]: value });
    setIsEdited(true);
  };

  const handleMultiSelect = (value) => {
    setModalData((prev) => ({
      ...prev,
      timeframes: [...(prev.timeframes ?? []), value],
    }));
    setIsEdited(true);
  };

  const handleMultiDeSelect = (value) => {
    setModalData((prev) => ({
      ...prev,
      timeframes: prev.timeframes.filter((item) => item !== value),
    }));
    setIsEdited(true);
  };

  return (
    <Form name="form_item_path" layout="vertical" onFinish={() => {}}>
      <Row gutter={[16, 16]}>
        <Col xs={20} sm={4} md={6} lg={8} xl={8}>
          <Form.Item
            label="Name of Metric (Display Name)"
            className="mb-0 no-form-item"
            children={null}
            required
          />
          <Input
            placeholder="Enter name for Metric here. . ."
            name="name"
            value={modalData.name}
            onChange={({ target: { value } }) => handleChange("name", value)}
          />
        </Col>
        <Col xs={20} sm={4} md={6} lg={10} xl={10}>
          <Form.Item
            label="Metric Source"
            className="mb-0 no-form-item"
            children={null}
            required
          />
          <Select
            showSearch
            placeholder="Search to Select source of Metric"
            optionFilterProp="children"
            style={{ width: "100%" }}
            filterOption={(input, option) => isSubstring(input, option.label)}
            options={METRICES_SOURCE}
            value={modalData.source}
            onSelect={(value) => handleChange("source", value)}
            disabled={metricesData ? true : false}
          />
        </Col>
        {modalData.source === "Salesforce" && (
          <Col xs={20} sm={16} md={12} lg={10} xl={10}>
            <Form.Item
              label="Salesforce Object"
              className="mb-0 no-form-item"
              children={null}
              required
            />
            <Select
              showSearch
              placeholder="Search to Select"
              optionFilterProp="children"
              style={{ width: "100%" }}
              value={modalData.object}
              filterOption={(input, option) => isSubstring(input, option.label)}
              options={groupOptions(
                [
                  { label: "Most Common", key: "Most Common" },
                  { label: "Objects", key: "Objects" },
                ],
                [
                  MOST_COMMON_OBJECTS,
                  optionCreator(
                    filteredObject(objects),
                    "label",
                    "associateParentEntity",
                    "Change Event"
                  ),
                ]
              )}
              onSelect={(value) => handleChange("object", value)}
            />
          </Col>
        )}
        {modalData.source && (
          <>
            <Col sm={24}>
              <hr />
            </Col>
            <Col xs={20} sm={4} md={6} lg={14} xl={14}>
              <Form.Item
                label="Metric Timeframes"
                className="mb-0 no-form-item"
                children={null}
                required
              />
              <Select
                mode="multiple"
                allowClear
                placeholder="Please select timeframes"
                style={{ width: "100%" }}
                options={METRIC_TIMEFRAMES}
                value={modalData.timeframes}
                onSelect={handleMultiSelect}
                onDeselect={handleMultiDeSelect}
              />
            </Col>
            {modalData.source !== "Salesforce" && (
              <Col xs={20} sm={4} md={6} lg={8} xl={8}>
                <Form.Item
                  label="Type"
                  className="mb-0 no-form-item"
                  children={null}
                  required
                />
                <Select
                  showSearch
                  placeholder="Select type of Metric"
                  optionFilterProp="children"
                  style={{ width: "100%" }}
                  options={METRICES_TYPES}
                  value={modalData.type}
                  onSelect={(value) => handleChange("type", value)}
                />
              </Col>
            )}
            {modalData.type === "Currency" &&
              modalData.source !== "Salesforce" && (
                <Col xs={20} sm={4} md={6} lg={8} xl={8}>
                  <Form.Item
                    label="Currency"
                    className="mb-0 no-form-item"
                    children={null}
                    required
                  />
                  <Select
                    showSearch
                    placeholder="Select Currency"
                    optionFilterProp="children"
                    style={{ width: "100%" }}
                    options={METRICES_CURRENCY_TYPE}
                    value={modalData.currency}
                    onSelect={(value) => handleChange("currency", value)}
                  />
                </Col>
              )}
            <Col xs={20} sm={4} md={6} lg={8} xl={8}>
              <Form.Item
                label="Decimal Places"
                className="mb-0 no-form-item"
                children={null}
                required
              />
              <InputNumber
                type="number"
                placeholder="Enter decimal places"
                style={{ width: "100%" }}
                value={modalData.decimal_places}
                onChange={(value) => handleChange("decimal_places", value)}
              />
            </Col>
          </>
        )}
      </Row>
    </Form>
  );
}

export default FirstStep;
