import { createAsyncThunk } from "@reduxjs/toolkit";
import CONFIG from "../../../utils/config";
import { beginAPICall, endAPICall } from "../loading";
import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from "../../../api/baseAPI";
import { errorNotification } from "../../../utils/functions";

const INTIAL_FETCH_OBJECT = {
  callback: () => {},
};

export const fetchTemplate = createAsyncThunk(
  "template/fetchTemplate",
  async ({ callback } = INTIAL_FETCH_OBJECT, { dispatch }) => {
    const {
      API_URLS: { FETCH_TEMPLATE },
    } = CONFIG;

    try {
      dispatch(beginAPICall());
      let { data } = await getRequest(FETCH_TEMPLATE, {
        service: "KWIPS",
      });
      return data;
    } catch (error) {
      errorNotification({
        title: "API Error",
        message: "Failed to fetch templates",
      });
    } finally {
      dispatch(endAPICall());
      callback();
    }
  }
);

export const updateTemplate = createAsyncThunk(
  "template/updateTemplate",
  async ({ payload: { id, ...payload }, callback }, { dispatch }) => {
    const {
      API_URLS: { UPDATE_TEMPLATE },
    } = CONFIG;

    try {
      dispatch(beginAPICall());
      let { data } = await putRequest(UPDATE_TEMPLATE + id + "/", payload, {
        service: "KWIPS",
      });
      return data;
    } catch (error) {
      errorNotification({
        title: "API Error",
        message: "Failed to update template",
      });
      throw new Error();
    } finally {
      callback();
      dispatch(endAPICall());
    }
  }
);

export const cloneTemplate = createAsyncThunk(
  "template/cloneTemplate",
  async ({ payload, callback }, { dispatch }) => {
    const {
      API_URLS: { CREATE_TEMPLATE },
    } = CONFIG;

    try {
      dispatch(beginAPICall());
      let {
        data: { data },
      } = await postRequest(CREATE_TEMPLATE, payload, {
        service: "KWIPS",
      });
      return data;
    } catch (error) {
      errorNotification({
        title: "API Error",
        message: "Failed to create template",
      });
      throw new Error();
    } finally {
      callback();
      dispatch(endAPICall());
    }
  }
);

export const deleteTemplate = createAsyncThunk(
  "template/deleteTemplate",
  async ({ payload: { id } }, { dispatch }) => {
    const {
      API_URLS: { DELETE_TEMPLATE },
    } = CONFIG;

    try {
      dispatch(beginAPICall());
      await deleteRequest(DELETE_TEMPLATE + id + "/", {
        service: "KWIPS",
      });
      return id;
    } catch (error) {
      errorNotification({
        title: "API Error",
        message: "Failed to delete template",
      });
      throw new Error();
    } finally {
      dispatch(endAPICall());
    }
  }
);
