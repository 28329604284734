import React, { useEffect, useRef, useState } from "react";
import CONFIG from "../../../utils/config";
import { EditOutlined } from "@ant-design/icons";
import profileUpload from "../../../assets/images/upload-pic.png";
import Validation from "../../../components/validation";
import ErrorMessage from "../../../components/validation/components/errorMessage";
import { Button, Modal, Row, Col, Form, Input } from "antd";
import {
  errorNotification,
  getCreateGroupPayload,
  getEditGroupPayload,
  isErrorPresent,
  successNotification,
} from "../../../utils/functions";
import { closeModal } from "../../../redux-toolkit/slice/modal";
import { useDispatch } from "react-redux";
import {
  createGroup,
  editGroup,
} from "../../../redux-toolkit/slice/groups/asyncThunk";
import { clearAll } from "../../../redux-toolkit/slice/groups";
import FilterExpression from "./components/filterExpression";

const INITIAL_FILTERS = { field: null, operator: null, value: "" };

function GroupModal({ groupData }) {
  const {
    LABELS: {
      GROUP: { NAME },
      VALIDATION: { REQUIRED: REQUIRED_RULE },
    },
    ERRORS: { REQUIRED },
  } = CONFIG;

  const VALIDATION_RULES = {
    name: [{ rule: REQUIRED_RULE, errorMessage: REQUIRED }],
    field: [{ rule: REQUIRED_RULE, errorMessage: REQUIRED }],
    operator: [{ rule: REQUIRED_RULE, errorMessage: REQUIRED }],
  };

  const dispatch = useDispatch();
  const profilePhotoRef = useRef(null);

  const [groupDetails, setGroupDetails] = useState({
    filters: [INITIAL_FILTERS],
  });

  const { name, profile, filters } = groupDetails || {};

  const handleSave = () => {
    groupData
      ? dispatch(
          editGroup({
            payload: getEditGroupPayload({ id: groupData.id, ...groupDetails }),
            errorMessage: () => {
              errorNotification({
                title: "API Error",
                message: "Failed to edit group",
              });
            },
            successMessage: () => {
              successNotification({
                title: "Edit Successfull",
                message: "Group is successfully edited",
              });
            },
            callback: handleCloseModal,
          })
        )
      : dispatch(
          createGroup({
            payload: getCreateGroupPayload(groupDetails),
            callback: handleCloseModal,
          })
        );
  };

  const handleProfilePhotoChange = ({ target: { files } }) => {
    let file = files[0];

    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onloadend = () => {
      let url = reader.result;
      setGroupDetails({ ...groupDetails, profile: { file, url } });
    };
  };

  const handleProfileClick = () => {
    profilePhotoRef.current.click();
  };

  const handleEditProfilePic = () => {
    setGroupDetails({ ...groupDetails, profile: null });
  };

  const handleCloseModal = () => {
    dispatch(clearAll());
    dispatch(closeModal());
  };

  const handleChange = ({ target: { name, value } }) => {
    setGroupDetails({ ...groupDetails, [name]: value });
  };

  useEffect(() => {
    if (groupData) {
      setGroupDetails({
        name: groupData.name,
        filters: groupData.filters,
        profile: { url: groupData.profile_photo },
      });
    }
  }, [groupData]);

  return (
    <Validation validationRules={VALIDATION_RULES} values={{ name, profile }}>
      {(errorObj) => {
        return (
          <Modal
            key={"Create New Group"}
            title={groupData ? groupData.name : "Create New Group"}
            centered
            className="common-modal"
            open={true}
            width={980}
            onCancel={handleCloseModal}
            footer={
              <div className="footer-btns">
                <Button
                  className="primary-solid"
                  disabled={isErrorPresent(errorObj)}
                  onClick={handleSave}
                >
                  Save
                </Button>
                <Button className="primary-outline" onClick={handleCloseModal}>
                  Cancel
                </Button>
              </div>
            }
          >
            <Form name="form_item_path" layout="vertical">
              <Row gutter={[8, 8]}>
                <Col xs={24}>
                  <Row gutter={[8, 8]} className="mb-1">
                    <Col xs={7} md={6} xl={5}>
                      {profile ? (
                        <div className="profile-pic">
                          <img src={profile.url} alt="profile" />
                          <EditOutlined
                            className="editIcon"
                            onClick={handleEditProfilePic}
                          />
                        </div>
                      ) : (
                        <div style={{ height: "100%" }}>
                          <img
                            width="90%"
                            height="90%"
                            src={profileUpload}
                            alt="profile"
                            onClick={handleProfileClick}
                          />
                          <input
                            ref={profilePhotoRef}
                            type="file"
                            style={{ display: "none" }}
                            onChange={handleProfilePhotoChange}
                          />
                        </div>
                      )}
                    </Col>
                    <Col xs={24} md={18} xl={19}>
                      <Form.Item
                        label="Group Name"
                        className="mb-0 no-form-item"
                        children={null}
                      />
                      <Input
                        placeholder="Enter your group name here. . ."
                        value={name}
                        name={NAME}
                        className="mb-2"
                        onChange={handleChange}
                      />
                      <ErrorMessage errorObject={errorObj} selector={NAME} />
                    </Col>
                    {filters && (
                      <FilterExpression
                        filters={filters}
                        groupData={groupData}
                        groupDetails={groupDetails}
                        setGroupDetails={setGroupDetails}
                      />
                    )}
                  </Row>
                </Col>
              </Row>
            </Form>
          </Modal>
        );
      }}
    </Validation>
  );
}

export default GroupModal;
