/* eslint-disable import/no-anonymous-default-export */
import React from "react";
import { Route, Routes } from "react-router-dom";

import Login from "../pages/login";
import RedirectURL from "../pages/redirectURL";
import CONFIG from "../utils/config";
import Users from "../pages/users";
import Layout from "../layout";
import Teams from "../pages/teams";
import Groups from "../pages/groups";
import Template from "../pages/template";
import PageNotFound from "../pages/notFound";
import Unauthorised from "../pages/unauthorised";
import KwipsList from "../pages/kwips";
import Dashboard from "../pages/dashboard";
import Password from "../pages/password";
import Ticker from "../pages/ticker";
import Billing from "../pages/billing";
import DataTriggers from "../pages/dataTriggers";
import Profile from "../pages/profile";
import Setting from "../pages/setting";
import Metrices from "../pages/metrices";
import Leaderboard from "../pages/leaderboard";
import Integration from "../pages/integrations";
import Applications from "../pages/apps";
import ForgotPassword from "../pages/forgetPassword";
import Redirect from "../pages/redirect";

export default function () {
  const {
    ROUTES: {
      LOGIN,
      AUTH_CALLBACK,
      USERS,
      TEAMS,
      NOTIFICATION_LIST,
      SALESFORCE_INTEGRATION,
      GROUPS,
      TEMPLATE,
      NOT_FOUND,
      UNAUTHORISED,
      DASHBOARD,
      PASSWORD,
      BILLING,
      TRIGGERS,
      PROFILE,
      SETTING,
      METRICES,
      LEADERBOARD,
      APPS,
      FORGET_PASSWORD,
      REDIRECT,
    },
  } = CONFIG;

  return (
    <Routes>
      <Route exact path={LOGIN.path} element={<Login />} />
      <Route exact path={PROFILE.path} element={<Profile />} />
      <Route exact path={FORGET_PASSWORD.path} element={<ForgotPassword />} />
      <Route exact path={AUTH_CALLBACK.path} element={<RedirectURL />} />
      <Route exact path={REDIRECT.path} element={<Redirect />} />
      <Route element={<Layout />}>
        <Route path={USERS.path} element={<Users />} />
        <Route path={TEAMS.path} element={<Teams />} />
        <Route path={TRIGGERS.path} element={<DataTriggers />} />
        <Route path={NOTIFICATION_LIST.path} element={<KwipsList />} />
        <Route path={GROUPS.path} element={<Groups />} />
        <Route path={TEMPLATE.path} element={<Template />} />
        <Route path={DASHBOARD.path} element={<Dashboard />} />
        <Route path={BILLING.path} element={<Billing />} />
        <Route path={SETTING.path} element={<Setting />} />
        <Route path={METRICES.path} element={<Metrices />} />
        <Route path={LEADERBOARD.path} element={<Leaderboard />} />
        <Route path={SALESFORCE_INTEGRATION.path} element={<Integration />} />
        <Route path={APPS.path} element={<Applications />} />
      </Route>
      <Route path={NOT_FOUND.path} element={<PageNotFound />} />
      <Route path={UNAUTHORISED.path} element={<Unauthorised />} />
      <Route path={PASSWORD.path} element={<Password />} />
    </Routes>
  );
}
