import {
  Avatar,
  Button,
  Checkbox,
  Col,
  Form,
  Row,
  Select,
  Tooltip,
} from "antd";
import React, { useEffect, useMemo, useState } from "react";
import {
  firstLetter,
  getDetails,
  groupOptions,
  isSubstring,
  optionCreator,
} from "../../../../utils/functions";
import { MinusCircleFilled } from "@ant-design/icons";

function SecondStep({
  selectedMembers,
  setSelectedMembers,
  usersList,
  groups,
}) {
  const [member, setMember] = useState({});
  const [allUsersSelected, setAllUsersSelected] = useState(false);

  const handleSelect = (id, { type }) => {
    setMember({ id, type, role: "member" });
  };

  const handleAdd = () => {
    if (member.id) setSelectedMembers([...selectedMembers, member]);
    setMember({});
  };

  const handleDeselect = (deletedKey) => {
    setSelectedMembers((prevValue) =>
      prevValue.filter(({ id }) => id !== deletedKey)
    );
  };

  const handleCheckbox = ({ target: { checked } }) => {
    setAllUsersSelected(checked);
    let filteredTeamMembers = selectedMembers.filter(
      ({ type }) => type === "team"
    );
    if (checked)
      setSelectedMembers([
        ...usersList.map(({ id }) => ({ id, type: "user", role: "member" })),
        ...filteredTeamMembers,
      ]);
    else setSelectedMembers(filteredTeamMembers);
  };

  const isCheckboxIndeterminate = useMemo(() => {
    let selectedUsers = selectedMembers.filter(({ type }) => type === "user");
    return selectedUsers.length > 0 && selectedUsers.length < usersList.length;
  }, [selectedMembers, usersList]);

  useEffect(() => {
    if (isCheckboxIndeterminate) setAllUsersSelected(false);
  }, [isCheckboxIndeterminate]);

  useEffect(() => {
    let userMembers =
      selectedMembers?.filter(({ type }) => type === "user") ?? [];
    if (userMembers.length === usersList.length) setAllUsersSelected(true);
  }, [selectedMembers, usersList]);

  return (
    <Row gutter={[16, 16]} className="leaderboard-container">
      <Col xs={24} sm={16} md={10} lg={10} xl={10}>
        <Form.Item
          label="Recipients"
          className="mb-0 no-form-item"
          children={null}
          required
        />
        <Row gutter={[16, 16]} className="mb-2">
          <Col xs={24}>
            <Select
              showSearch
              placeholder="Select Users or Teams"
              filterOption={(input, option) => isSubstring(input, option.label)}
              popupMatchSelectWidth={true}
              style={{ width: "100%" }}
              value={member.id}
              options={groupOptions(
                [
                  { label: "Users", key: "user" },
                  { label: "Teams", key: "team" },
                ],
                [
                  optionCreator(usersList, "name", "id"),
                  optionCreator(groups, "name", "id"),
                ],
                selectedMembers ?? []
              )}
              onSelect={handleSelect}
            />
          </Col>
          {/* <Col xs={2} className="d-flex">
            <Tooltip
              title={
                allUsersSelected ? "Deselect all Users" : "Select all Users"
              }
            >
              <Checkbox
                indeterminate={isCheckboxIndeterminate}
                checked={allUsersSelected}
                onChange={(details) => handleCheckbox(details)}
              />
            </Tooltip>
          </Col> */}
          <Col className="plus-icon mb-2" sm={4} md={4} xl={2}>
            <Button className="primary-solid" onClick={() => handleAdd()}>
              Add
            </Button>
          </Col>
        </Row>
      </Col>
      <Col xs={20} sm={16} md={14} lg={14} xl={14}>
        <div className="credit-list-scroll kwip-credit-scroll">
          {selectedMembers?.map(({ id }) => {
            let { name, profile_photo } = getDetails(
              [...(usersList ?? []), ...(groups ?? [])],
              "id",
              id
            );
            let image = Array.isArray(profile_photo)
              ? profile_photo?.find(({ is_active }) => is_active)?.image
              : profile_photo;
            return (
              <div className="credit-list creditTo" key={id}>
                <Avatar
                  size={30}
                  src={image}
                  children={image ? null : firstLetter(name)}
                />
                <span className="creditTo_field">{name}</span>
                <span className="close">
                  <MinusCircleFilled onClick={() => handleDeselect(id)} />
                </span>
              </div>
            );
          })}
        </div>
      </Col>
    </Row>
  );
}

export default SecondStep;
