import { createSlice } from "@reduxjs/toolkit";

import INITIAL_STATE from "../../initialState";
import {
  createUser,
  deleteUser,
  editUser,
  fetchMusicList,
  fetchUsers,
  uploadMusicList,
} from "./asyncThunks";

export const usersSlice = createSlice({
  name: "users",
  initialState: INITIAL_STATE.USERS,
  reducers: {
    clearUser: (state) => {
      state.usersList = undefined;
      state.error = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUsers.fulfilled, (state, { payload }) => {
      state.error = false;
      state.usersList = payload?.map((user) => ({ ...user, key: user.id }));
    });
    builder.addCase(fetchUsers.rejected, (state, { error }) => {
      let errorObject = JSON.parse(error.message);
      state.error = errorObject;
    });

    builder.addCase(createUser.fulfilled, (state, { payload }) => {
      state.error = false;
      state.usersList.push(payload);
    });

    builder.addCase(createUser.rejected, (state) => {
      state.error = true;
    });

    builder.addCase(editUser.fulfilled, (state, { payload }) => {
      state.error = false;
      let editedIndex = state.usersList.findIndex(
        ({ id }) => id === payload.id
      );
      state.usersList[editedIndex] = payload;
    });

    builder.addCase(editUser.rejected, (state) => {
      state.error = true;
    });

    builder.addCase(deleteUser.fulfilled, (state, { payload }) => {
      state.error = false;
      let editedIndex = state.usersList.findIndex(({ id }) => id === payload);
      state.usersList.splice(editedIndex, 1);
    });

    builder.addCase(deleteUser.rejected, (state) => {
      state.error = true;
    });

    builder.addCase(fetchMusicList.fulfilled, (state, { payload }) => {
      state.error = false;
      state.usersMusicList = {
        ...state.usersMusicList,
        [payload.id]: payload.data,
      };
    });
    builder.addCase(fetchMusicList.rejected, (state, { error }) => {
      state.error = true;
    });
    builder.addCase(uploadMusicList.fulfilled, (state, { payload }) => {
      state.error = false;
      state.usersMusicList = {
        ...state.usersMusicList,
        [payload.id]: payload.data.data,
      };
    });
    builder.addCase(uploadMusicList.rejected, (state, { error }) => {
      state.error = true;
    });
  },
});

export const { clearUser } = usersSlice.actions;

export default usersSlice.reducer;
