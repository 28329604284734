import { Col, Form, Input, Row, Select, Switch } from "antd";
import React, { useMemo, useRef } from "react";
import { EditOutlined } from "@ant-design/icons";
import profileUpload from "../../../../../assets/images/upload-pic.png";
import ErrorMessage from "../../../../../components/validation/components/errorMessage";
import CONFIG from "../../../../../utils/config";
import adminDataSelector from "../../../../../redux-toolkit/selectors/adminData";
import { useSelector } from "react-redux";
import { filterTeamTypes } from "../../../../../utils/functions";

function FirstStep({
  teamData,
  status,
  setStatus,
  errorObj,
  teamProfile,
  setTeamProfile,
  teamName,
  setTeamName,
  teamType,
  setTeamType,
}) {
  const {
    LABELS: {
      TEAMS: { TEAM_NAME, TEAM_PROFILE, TEAM_TYPE },
    },
    TEAM_TYPES,
  } = CONFIG;

  const { adminDetails } = adminDataSelector(useSelector);

  const { is_salesforce_authenticated } = useMemo(
    () => adminDetails || {},
    [adminDetails]
  );

  const profilePhotoRef = useRef(null);

  const handleChange = ({ target: { name, value } }) => {
    setTeamName(value);
  };

  const handleProfilePhotoChange = ({ target: { files } }) => {
    let file = files[0];

    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onloadend = () => {
      let url = reader.result;
      setTeamProfile({ file, url });
    };
  };

  const handleProfileClick = () => {
    profilePhotoRef.current.click();
  };

  const handleEditProfilePic = () => {
    setTeamProfile(null);
  };

  const handleSelect = (key) => {
    setTeamType(key);
  };

  const handleStatusChange = (value) => {
    setStatus(value);
  };

  return (
    <Form name="form_item_path" layout="vertical">
      <Row gutter={[8, 8]}>
        <Col xs={24}>
          <Row gutter={[12, 12]} className="mb-3 mt-2">
            <Col xs={6}>
              {teamProfile ? (
                <div className="profile-pic">
                  <img src={teamProfile.url} alt="profile" />
                  <EditOutlined
                    className="editIcon"
                    onClick={handleEditProfilePic}
                  />
                </div>
              ) : (
                <div>
                  <img
                    width="52%"
                    src={profileUpload}
                    alt="profile"
                    onClick={handleProfileClick}
                  />
                  <input
                    ref={profilePhotoRef}
                    type="file"
                    style={{ display: "none" }}
                    onChange={handleProfilePhotoChange}
                  />
                </div>
              )}
            </Col>
            <Col xs={18}>
              <Row gutter={[16, 16]}>
                <Col xs={24}>
                  <Form.Item
                    label="Team Name"
                    className="mb-0 no-form-item"
                    children={null}
                  />
                  <div className="mb-2">
                    <Input
                      placeholder="Enter team name here. . ."
                      value={teamName}
                      name={TEAM_NAME}
                      onChange={handleChange}
                    />
                    <ErrorMessage errorObject={errorObj} selector={TEAM_NAME} />
                  </div>
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                {/* <Col xs={teamData ? 20 : 24}> */}
                <Col xs={24}>
                  <Form.Item
                    label="Team Type"
                    className="mb-0 no-form-item"
                    children={null}
                  />
                  <div className="mb-2 team-select">
                    <Select
                      placeholder="Select team type here. . ."
                      options={filterTeamTypes(
                        TEAM_TYPES,
                        is_salesforce_authenticated
                      )}
                      value={teamType}
                      onChange={handleSelect}
                    />
                    <ErrorMessage errorObject={errorObj} selector={TEAM_TYPE} />
                  </div>
                </Col>
                {/* {teamData && (
                  <Col xs={4}>
                    <Form.Item
                      label="Team Status"
                      className="mb-0 no-form-item"
                      children={null}
                    />
                    <Switch
                      className="d-flex mt-1"
                      checkedChildren="Active"
                      unCheckedChildren="Inactive"
                      checked={status}
                      onChange={handleStatusChange}
                    />
                  </Col>
                )} */}
              </Row>
            </Col>
          </Row>
          <ErrorMessage errorObject={errorObj} selector={TEAM_PROFILE} />
        </Col>
      </Row>
    </Form>
  );
}

export default FirstStep;
