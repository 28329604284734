import { Button, Modal } from "antd";
import React from "react";
import { closeModal } from "../../../redux-toolkit/slice/modal";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import CONFIG from "../../../utils/config";

function CreateNotificationAlert() {
  const {
    ROUTES: { SALESFORCE_INTEGRATION },
  } = CONFIG;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleRedirect = () => {
    navigate(SALESFORCE_INTEGRATION.key);
    handleCloseModal();
  };

  const handleCloseModal = () => {
    dispatch(closeModal());
  };

  return (
    <Modal
      key={"Salesforce Unauthorised"}
      title={"Salesforce Unauthorised"}
      centered
      className="common-modal"
      open={true}
      width={510}
      onCancel={handleCloseModal}
      footer={
        <div className="footer-btns">
          <Button className="primary-solid" onClick={handleRedirect}>
            Redirect
          </Button>
          <Button className="primary-outline" onClick={handleCloseModal}>
            Cancel
          </Button>
        </div>
      }
    >
      You need to be authorised with salesforce in order to create a Kwip.
    </Modal>
  );
}

export default CreateNotificationAlert;
