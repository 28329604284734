import { Avatar, Tooltip } from "antd";
import React from "react";
import { firstLetter, getDetails } from "../../utils/functions";
import CONFIG from "../../utils/config";

function AvatarList({ maxCount, members, dataSet, onMemberClick = () => {} }) {
  const {
    LABELS: { ID },
  } = CONFIG;

  return (
    <Avatar.Group maxCount={maxCount}>
      {members?.map(({ id: memberId } = {}) => {
        let { name, id, profile_photo, image } = getDetails(
          dataSet,
          ID,
          memberId
        );

        let img = Array.isArray(profile_photo)
          ? profile_photo?.find(({ image, is_active }) => is_active && image)
          : {};

        return (
          <Tooltip title={name} key={id}>
            <Avatar
              src={image || img?.image || profile_photo}
              children={
                image || img?.image || profile_photo ? null : firstLetter(name)
              }
              onClick={onMemberClick}
            />
          </Tooltip>
        );
      })}
    </Avatar.Group>
  );
}

export default AvatarList;
