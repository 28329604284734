import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Col, Row, Table } from "antd";
import { useDispatch, useSelector } from "react-redux";

import CONFIG from "../../utils/config";
import {
  columnAndComponentFunctionReference,
  formatKwip,
  withKeyDataSet,
} from "../../utils/functions";
import { openModal } from "../../redux-toolkit/slice/modal";
import { fetchNotifications } from "../../redux-toolkit/slice/notification/asyncThunk";
import {
  deleteKwip,
  fetchKwips,
} from "../../redux-toolkit/slice/kwips/asyncThunk";
import kwipsDataSelector from "../../redux-toolkit/selectors/kwipsData";
import teamsDataSelector from "../../redux-toolkit/selectors/teamData";
import { fetchUsers } from "../../redux-toolkit/slice/users/asyncThunks";
import { fetchTeams } from "../../redux-toolkit/slice/teams/asyncThunk";
import notificationDataSelector from "../../redux-toolkit/selectors/notification";
import layoutDataSelector from "../../redux-toolkit/selectors/layoutData";
import { fetchSalesforceOrgDetails } from "../../redux-toolkit/slice/admin/asyncThunk";

function KwipsList() {
  const {
    LABELS: { ID },
    KWIP_LIST_COLUMN,
    MODALS: { KWIP, CONFIRM },
  } = CONFIG;

  const dispatch = useDispatch();

  const { usersList, teamsList } = teamsDataSelector(useSelector);
  const { kwipList } = kwipsDataSelector(useSelector);
  const { notificationList } = notificationDataSelector(useSelector);
  const { salesforceOrgDetails } = layoutDataSelector(useSelector);

  const [currentPage, setCurrentPage] = useState(1);

  const handleEdit = useCallback(
    (kwipData) => {
      dispatch(
        openModal({
          key: KWIP.key,
          props: {
            kwipData: formatKwip(kwipData),
            usersList,
            teamsList,
          },
        })
      );
    },
    [KWIP, teamsList, usersList, dispatch]
  );

  const handleKwipDeleteConfirm = useCallback(
    (id) => {
      dispatch(deleteKwip({ payload: { id } }));
    },
    [dispatch]
  );

  const handleCreateKwip = () => {
    dispatch(openModal({ key: KWIP.key, props: { usersList, teamsList } }));
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleKwipDelete = useCallback(
    (id) => {
      dispatch(
        openModal({
          key: CONFIRM.key,
          props: {
            title: "Kwip Delete",
            content: "Are you sure, you want to delete the Kwip?",
            handleConfirm: () => handleKwipDeleteConfirm(id),
          },
        })
      );
    },
    [CONFIRM, dispatch, handleKwipDeleteConfirm]
  );

  const tableColumn = useMemo(
    () =>
      columnAndComponentFunctionReference(KWIP_LIST_COLUMN, {
        currentPage,
        usersList,
        teamsList,
        handleEdit,
        handleKwipDelete,
      }),
    [
      KWIP_LIST_COLUMN,
      currentPage,
      usersList,
      teamsList,
      handleEdit,
      handleKwipDelete,
    ]
  );

  useEffect(() => {
    !kwipList && dispatch(fetchKwips());
  }, [kwipList, dispatch]);

  useEffect(() => {
    !usersList && dispatch(fetchUsers());
  }, [dispatch, usersList]);

  useEffect(() => {
    !teamsList && dispatch(fetchTeams());
  }, [dispatch, teamsList]);

  useEffect(() => {
    !notificationList && dispatch(fetchNotifications());
  }, [notificationList, dispatch]);

  useEffect(() => {
    !salesforceOrgDetails && dispatch(fetchSalesforceOrgDetails());
  }, [salesforceOrgDetails, dispatch]);

  return (
    <div className="userContainer">
      <Row className="filter-head">
        <Col span={12}>
          <h2>KWIPS</h2>
        </Col>
        <Col span={12} className="filter">
          <Button className="primary-outline" onClick={handleCreateKwip}>
            Add Kwip
          </Button>
        </Col>
      </Row>
      <div>
        <Table
          className="cmn-table"
          columns={tableColumn}
          dataSource={withKeyDataSet(ID, kwipList)}
          scroll={{ y: "calc(70vh - 55px)" }}
          pagination={{
            currentPage,
            onChange: handlePageChange,
          }}
        />
      </div>
    </div>
  );
}

export default KwipsList;
