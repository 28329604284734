import { Select } from "antd";
import { useEffect, useState } from "react";
import { optionCreator } from "../../../../utils/functions";

const Currency = ({ currency, setSettingState }) => {
  const [currencyList, setCurrencyList] = useState([]);

  const onChange = (value) => {
    setSettingState((prev) => ({ ...prev, currency: value }));
  };

  useEffect(() => {
    fetch("https://restcountries.com/v3.1/all")
      .then((response) => response.json())
      .then((data) => {
        const currencies = new Set();
        data.forEach((country) => {
          if (country.currencies) {
            Object.keys(country.currencies).forEach((code) => {
              const currency = country.currencies[code];
              currencies.add(`${currency.name} (${code})`);
            });
          }
        });
        setCurrencyList([...currencies]);
      })
      .catch((error) => console.error("Error fetching country data:", error));
  }, []);

  return (
    <Select
      showSearch
      placeholder="Search to Select"
      optionFilterProp="children"
      filterOption={(input, option) =>
        (option?.label.toLowerCase() ?? "").includes(input.toLowerCase())
      }
      filterSort={(optionA, optionB) =>
        (optionA?.label ?? "")
          .toLowerCase()
          .localeCompare((optionB?.label ?? "").toLowerCase())
      }
      options={optionCreator(currencyList)}
      value={currency}
      onChange={onChange}
    />
  );
};

export default Currency;
