import React from "react";
import CONFIG from "../../../utils/config";
import { Button, Modal } from "antd";
import { closeModal } from "../../../redux-toolkit/slice/modal";
import { useDispatch } from "react-redux";

function TemplatePreview({ componentKey, name, ...rest }) {
  const { TEMPLATE_PREVIEW } = CONFIG;

  let Component = componentKey ? TEMPLATE_PREVIEW[componentKey] : () => {};

  const dispatch = useDispatch();

  const handleCloseModal = () => {
    dispatch(closeModal());
  };

  return (
    <Modal
      key={name}
      title={name}
      centered
      className="common-modal template-modal-outer"
      open={true}
      width={800}
      onCancel={handleCloseModal}
      footer={
        <div className="footer-btns">
          <Button className="primary-outline" onClick={handleCloseModal}>
            Cancel
          </Button>
        </div>
      }
    >
      <Component {...rest} />
    </Modal>
  );
}

export default TemplatePreview;
