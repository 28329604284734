import { Col, Row } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { ReactComponent as Logo } from "../../assets/images/salesforce_cloud.svg";
import Icon from "../../components/icon";
import { RightOutlined, LeftOutlined } from "@ant-design/icons";
import CONFIG from "../../utils/config";
import SalesforceIntegration from "../salesforceIntegration";
import { useSearchParams } from "react-router-dom";

function Integration() {
  const {
    INTEGRATIONS: { SALESFORCE },
    LABELS: {
      LOGIN: { CODE },
    },
  } = CONFIG;

  const [searchParams] = useSearchParams();

  const [integration, setIntegration] = useState("");

  const handleIntegrationSelect = (key) => {
    setIntegration(key);
  };

  const renderSelectedIntegration = useMemo(() => {
    switch (integration) {
      case SALESFORCE:
        return <SalesforceIntegration />;
      default:
        return null;
    }
  }, [SALESFORCE, integration]);

  useEffect(() => {
    if (searchParams.get(CODE)) {
      setIntegration(SALESFORCE);
    }
  }, [searchParams, CODE, setIntegration, SALESFORCE]);

  return (
    <>
      {renderSelectedIntegration && (
        <div
          className="cursor-pointer font-2"
          onClick={() => {
            handleIntegrationSelect(null);
          }}
        >
          <LeftOutlined />
          Back
        </div>
      )}
      {renderSelectedIntegration ?? (
        <div className="userContainer">
          <Row className="filter-head mb-2">
            <Col span={12}>
              <h2>INTEGRATIONS</h2>
            </Col>
          </Row>
          <Row className="mt-3">
            <div className="horizontal-line" />
            <div className="d-flex align-items-center mt-1 mb-1 w-100">
              <Icon Component={Logo} fill="#479BD5" />
              <div className="ml-3 d-flex w-100 align-items-center justify-content-between">
                <div>
                  <h2>Salesforce</h2>
                  <p>
                    Manage users, performance data and celebrate wins directly
                    from Salesforce data.
                  </p>
                </div>
                <div
                  className="configure cursor-pointer"
                  onClick={() => handleIntegrationSelect(SALESFORCE)}
                >
                  <u>Configure</u> <RightOutlined />
                </div>
              </div>
            </div>
            <div className="horizontal-line" />
          </Row>
        </div>
      )}
    </>
  );
}

export default Integration;
