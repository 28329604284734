import { createAsyncThunk } from "@reduxjs/toolkit";

import CONFIG from "../../../utils/config";
import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from "../../../api/baseAPI";
import { beginAPICall, endAPICall } from "../loading";
import { errorNotification } from "../../../utils/functions";

let initialObject = {
  loadOrNot: true,
  callback: () => {},
};

export const loginSalesforceUser = createAsyncThunk(
  "userDetails/loginSalesforceUser",
  async ({ code, callback }) => {
    const {
      API_URLS: { LOGIN_SALESFORCE_USER },
    } = CONFIG;
    try {
      const {
        data: { access_token, refresh_token },
      } = await postRequest(
        LOGIN_SALESFORCE_USER,
        {
          code,
          login_type: "user",
        },
        { service: "SALESFORCE" }
      );
      callback(access_token, refresh_token);
    } catch (err) {
      errorNotification({
        title: "API Failed",
        message: "Failed to login user",
      });
    } finally {
      callback();
    }
  }
);

export const fetchUsers = createAsyncThunk(
  "users/fetchUsers",
  async ({ loadOrNot, callback } = initialObject, { dispatch }) => {
    let {
      API_URLS: { FETCH_ALL_USERS },
    } = CONFIG;
    try {
      loadOrNot && dispatch(beginAPICall());
      let { data } = await getRequest(FETCH_ALL_USERS, { service: "ACCOUNT" });
      return data;
    } catch ({ response }) {
      errorNotification({
        title: "API Error",
        message: "Failed to fetch users",
      });
    } finally {
      loadOrNot && dispatch(endAPICall());
      callback();
    }
  }
);

export const createUser = createAsyncThunk(
  "users/createUser",
  async ({ payload, callback = () => {} }, { dispatch }) => {
    let {
      API_URLS: { CREATE_USER },
    } = CONFIG;

    try {
      dispatch(beginAPICall());
      const {
        data: { data },
      } = await postRequest(CREATE_USER, payload, {
        service: "ACCOUNT",
      });
      return data;
    } catch ({ response }) {
      errorNotification({
        title: "API Error",
        message: "Failed to create user",
      });
    } finally {
      callback();
      dispatch(endAPICall());
    }
  }
);

export const editUser = createAsyncThunk(
  "users/editUser",
  async (
    { payload: { id, ...payload }, callback = () => {} },
    { dispatch }
  ) => {
    let {
      API_URLS: { EDIT_USER },
    } = CONFIG;

    try {
      dispatch(beginAPICall());
      const {
        data: { data },
      } = await putRequest(EDIT_USER + id + "/", payload, {
        service: "ACCOUNT",
      });
      return data;
    } catch ({ response }) {
      errorNotification({
        title: "API Error",
        message: "Failed to edit user",
      });
      throw new Error();
    } finally {
      callback();
      dispatch(endAPICall());
    }
  }
);

export const deleteUser = createAsyncThunk(
  "users/deleteUser",
  async ({ id, callback = () => {} }, { dispatch }) => {
    let {
      API_URLS: { DELETE_USER },
    } = CONFIG;

    try {
      dispatch(beginAPICall());
      await deleteRequest(DELETE_USER + id + "/", { service: "ACCOUNT" });
      return id;
    } catch ({ response }) {
      errorNotification({
        title: "API Error",
        message: "Failed to delete user",
      });
      throw new Error();
    } finally {
      dispatch(endAPICall());
      callback();
    }
  }
);

export const sendMail = createAsyncThunk(
  "users/sendMail",
  async (
    { payload: { id, ...payload }, callback = () => {} },
    { dispatch }
  ) => {
    let {
      API_URLS: { SEND_MAIL },
    } = CONFIG;

    try {
      dispatch(beginAPICall());
      await postRequest(SEND_MAIL + id + "/invite/", payload, {
        service: "ACCOUNT",
      });
    } catch ({ response }) {
      errorNotification({
        title: "API Error",
        message: "User Setup mail failed to sent",
      });
    } finally {
      callback();
      dispatch(endAPICall());
    }
  }
);

export const fetchMusicList = createAsyncThunk(
  "users/fetchMusicList",
  async ({ id }, { dispatch }) => {
    let {
      API_URLS: { FETCH_MUSIC_LIST },
    } = CONFIG;
    try {
      dispatch(beginAPICall());
      let {
        data: { data },
      } = await getRequest(FETCH_MUSIC_LIST, {
        service: "ACCOUNT",
        params: { user_id: id },
      });
      return { data, id };
    } catch (err) {
      errorNotification({
        title: "API Failed",
        message: "Failed to fetch user music details",
      });
    } finally {
      dispatch(endAPICall());
    }
  }
);

export const uploadMusicList = createAsyncThunk(
  "userDetails/uploadMusicList",
  async ({ payload, callback }, { dispatch }) => {
    let {
      API_URLS: { FETCH_MUSIC_LIST },
    } = CONFIG;
    try {
      dispatch(beginAPICall());
      const { data } = await postRequest(FETCH_MUSIC_LIST, payload, {
        service: "ACCOUNT",
      });
      return { id: payload.user_id, data };
    } catch (err) {
      errorNotification({
        title: "API Failed",
        message: "Failed to Upload Music",
      });
      throw new Error();
    } finally {
      dispatch(endAPICall());
      callback();
    }
  }
);
