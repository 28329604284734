import { Button, Modal, Table } from "antd";
import React, { useEffect, useState } from "react";
import { closeModal } from "../../../redux-toolkit/slice/modal";
import { useDispatch } from "react-redux";
import {
  filterTableColumn,
  leaderboardPreviewTableData,
  withKeyDataSet,
} from "../../../utils/functions";
import CONFIG from "../../../utils/config";
import userDataSelector from "../../../redux-toolkit/selectors/userData";
import { useSelector } from "react-redux";
import { fetchUsers } from "../../../redux-toolkit/slice/users/asyncThunks";

function LeaderboardPreview({ leaderboardData }) {
  const {
    LEADERBOARD_PREVIEW_COLUMN,
    LABELS: { ID },
  } = CONFIG;

  const dispatch = useDispatch();

  const { usersList } = userDataSelector(useSelector);

  const [tableData, setTableData] = useState([]);

  const handleCloseModal = () => {
    dispatch(closeModal());
  };

  useEffect(() => {
    !usersList && dispatch(fetchUsers());
  }, [usersList, dispatch]);

  useEffect(() => {
    usersList &&
      setTableData(
        leaderboardPreviewTableData(
          usersList,
          leaderboardData.leaderboard_metadata,
          true
        )
      );
  }, [leaderboardData, usersList]);

  return (
    <Modal
      key={leaderboardData.title}
      title={leaderboardData.title}
      centered
      className="common-modal"
      open={true}
      width={600}
      onCancel={handleCloseModal}
      footer={
        <div className="footer-btns">
          <Button className="primary-outline" onClick={handleCloseModal}>
            Cancel
          </Button>
        </div>
      }
    >
      <Table
        className="cmn-table"
        columns={filterTableColumn(LEADERBOARD_PREVIEW_COLUMN, [
          leaderboardData.timeframe,
        ])}
        dataSource={withKeyDataSet(ID, tableData || [])}
        scroll={{ y: "calc(70vh - 60px)" }}
        pagination={false}
      />
    </Modal>
  );
}

export default LeaderboardPreview;
