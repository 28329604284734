import { createSlice } from "@reduxjs/toolkit";

import INITIAL_STATE from "../../initialState";
import {
  deleteTicker,
  editTicker,
  fetchTicker,
  saveTicker,
  sendNow,
} from "./asyncThunk";
import { isFalse } from "../../../utils/functions";

export const tickerSlice = createSlice({
  name: "ticker",
  initialState: INITIAL_STATE.TICKER,
  reducers: {
    clearTicker: (state) => {
      state.tickerList = undefined;
      state.error = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTicker.fulfilled, (state, { payload }) => {
      state.error = false;
      state.tickerList = payload;
    });
    builder.addCase(fetchTicker.rejected, (state, { error }) => {
      state.error = true;
    });

    builder.addCase(saveTicker.fulfilled, (state, { payload }) => {
      state.error = false;
      state.tickerList.push(payload);
    });
    builder.addCase(saveTicker.rejected, (state, { error }) => {
      state.error = true;
    });

    builder.addCase(editTicker.fulfilled, (state, { payload }) => {
      state.error = false;
      let editedIndex = state.tickerList.findIndex(
        ({ id }) => id === payload.id
      );
      state.tickerList[editedIndex] = payload;
    });

    builder.addCase(editTicker.rejected, (state, { error }) => {
      state.error = true;
    });

    builder.addCase(deleteTicker.fulfilled, (state, { payload }) => {
      state.error = false;
      let editedIndex = state.tickerList.findIndex(({ id }) => id === payload);
      state.tickerList.splice(editedIndex, 1);
    });
    builder.addCase(deleteTicker.rejected, (state, { error }) => {
      state.error = true;
    });

    builder.addCase(sendNow.fulfilled, (state, { payload }) => {
      state.error = false;
      if (!isFalse(payload)) state.tickerList.push(payload);
    });
    builder.addCase(sendNow.rejected, (state, { error }) => {
      state.error = true;
    });
  },
});

export const { clearTicker } = tickerSlice.actions;

export default tickerSlice.reducer;
