import { Button, Modal, Steps } from "antd";
import React, { useEffect, useState } from "react";
import { closeModal } from "../../../redux-toolkit/slice/modal";
import { useDispatch } from "react-redux";
import CONFIG from "../../../utils/config";
import StepButton from "./components/stepButton";
import notificationDataSelector from "../../../redux-toolkit/selectors/notification";
import { useSelector } from "react-redux";
import { fetchFields } from "../../../redux-toolkit/slice/notification/asyncThunk";
import {
  convertMetricesDataToModalData,
  getMetricSavePayload,
} from "../../../utils/functions";
import {
  createMetrices,
  editMetrices,
} from "../../../redux-toolkit/slice/metrices/asyncThunk";

function MetricesModal({ metricesData }) {
  const { METRICES_STEPS } = CONFIG;

  const dispatch = useDispatch();

  const { objects, fields } = notificationDataSelector(useSelector);

  const [currentStep, setCurrent] = useState(0);
  const [isStepLoading, setStepLoading] = useState(false);
  const [modalData, setModalData] = useState({});
  const [prevObject, setPrevObject] = useState(
    metricesData?.metrics_metadata?.salesforce_object ?? ""
  );
  const [isEdited, setIsEdited] = useState(false);

  const handleCloseModal = () => {
    dispatch(closeModal());
  };

  const handleSave = () => {
    if (isEdited) {
      const payload = getMetricSavePayload(modalData);
      metricesData
        ? dispatch(
            editMetrices({ payload: { id: metricesData.id, ...payload } })
          )
        : dispatch(createMetrices({ payload }));
    } else handleCloseModal();
  };

  const resetNotificationObject = () => {
    setModalData({
      name: modalData.name,
      timeframes: modalData.timeframes,
      type: modalData.type,
      currency: modalData.currency,
      decimal_places: modalData.decimal_places,
      source: modalData.source,
      object: modalData.object,
      calculation: modalData.calculation,
      metricFieldValue: modalData.metricFieldValue,
    });
  };

  const handleFirstStepCallback = () => {
    setCurrent(1);
    setStepLoading(false);
  };

  const handleSalesforceMetric = () => {
    setStepLoading(true);

    if (prevObject !== modalData.object) resetNotificationObject();

    setPrevObject(modalData.object);

    dispatch(
      fetchFields({
        object: modalData.object,
        callback: handleFirstStepCallback,
      })
    );
  };

  const next = () => {
    switch (currentStep) {
      case 0: {
        if (modalData.source !== "Salesforce") {
          handleSave();
          break;
        } else handleSalesforceMetric();

        break;
      }
      case 1: {
        handleSave();
        break;
      }
      default:
        setCurrent(currentStep + 1);
    }
  };

  const prev = () => {
    setCurrent(currentStep - 1);
  };

  useEffect(() => {
    setModalData(
      metricesData ? convertMetricesDataToModalData(metricesData) : {}
    );
  }, [metricesData]);

  return (
    <Modal
      key={metricesData ? metricesData.name : "Create New Metric"}
      title={metricesData ? metricesData.name : "Create New Metric"}
      centered
      open={true}
      width={900}
      onCancel={handleCloseModal}
      footer={
        <StepButton
          currentStep={currentStep}
          isStepLoading={isStepLoading}
          metricesObject={modalData}
          prev={prev}
          next={next}
          handleCancel={handleCloseModal}
        />
      }
    >
      <Steps
        current={currentStep}
        percent={60}
        labelPlacement="vertical"
        items={METRICES_STEPS({})}
      />
      <div className="steps-content">
        {
          METRICES_STEPS({
            metricesData,
            modalData,
            objects,
            fields,
            setModalData,
            setIsEdited,
          })[currentStep]?.content
        }
      </div>
      <div className="mb-3" />
    </Modal>
  );
}

export default MetricesModal;
