import axios from "axios";
import { determineService, getToken, setToken } from "../utils/functions";
import CONFIG from "../utils/config";

const {
  REACT_APP_BASE_URL_ACCOUNT,
  REACT_APP_BASE_URL_SALESFORCE,
  REACT_APP_BASE_URL_KWIPS,
  REACT_APP_BASE_URL_KPIS,
  REACT_APP_BASE_URL_TICKER,
} = process.env;

const SERVICE_TYPES = {
  ACCOUNT: REACT_APP_BASE_URL_ACCOUNT,
  SALESFORCE: REACT_APP_BASE_URL_SALESFORCE,
  KWIPS: REACT_APP_BASE_URL_KWIPS,
  TICKERS: REACT_APP_BASE_URL_TICKER,
  KPIS: REACT_APP_BASE_URL_KPIS,
};

const api = axios.create({});

api.interceptors.request.use(async (config) => {
  const { service } = config;
  config.baseURL = SERVICE_TYPES[service];
  const token = getToken(CONFIG.TOKEN.ACCESS_TOKEN);
  if (token) {
    config.headers["Authorization"] = `${token}`;
  }
  return config;
});

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response && error.response.status === 401) {
      try {
        const response = await axios.post(
          REACT_APP_BASE_URL_ACCOUNT + CONFIG.API_URLS.GET_NEW_ACCESS_TOKEN_API,
          {
            refresh_token: getToken(CONFIG.TOKEN.REFRESH_TOKEN),
            user_id: getToken(CONFIG.TOKEN.USER_ID),
            type: "user",
          }
        );
        const { access_token, refresh_token } = response.data;
        setToken(CONFIG.TOKEN.ACCESS_TOKEN, access_token);
        setToken(CONFIG.TOKEN.REFRESH_TOKEN, refresh_token);

        const originalRequest = error.config;
        originalRequest.headers["Authorization"] = `${access_token}`;

        originalRequest.service = determineService(originalRequest.baseURL);
        return api(originalRequest);
      } catch (refreshError) {
        setToken(CONFIG.TOKEN.ACCESS_TOKEN, "");
        setToken(CONFIG.TOKEN.REFRESH_TOKEN, "");
        return Promise.reject(refreshError);
      }
    }
    return Promise.reject(error);
  }
);

export default api;
