import React from "react";
import { Col, Row } from "antd";
import user from "../../../../assets/images/dummyUser.png";

function OneUser({
  bgImage,
  bgColor,
  bgImageBlur = 4,
  titleBackgroundColor,
  titleColor,
  bodyBackgroundColor,
  bodyColor,
}) {
  return (
    <div
      className="template-modal"
      style={
        bgImage
          ? { backgroundImage: `url(${bgImage})` }
          : { backgroundColor: bgColor }
      }
    >
      <Row
        className="template-row"
        style={{ backdropFilter: `blur(${bgImageBlur}px)` }}
        gutter={[16, 16]}
      >
        <Col md={7}>
          <div className="tempalte-profile-pic-box" style={{ height: "180px" }}>
            <img
              className="template-profile"
              width="98%"
              src={user}
              alt="profile"
            />
          </div>
        </Col>
        <Col md={17}>
          <h3
            style={{
              backgroundColor: titleBackgroundColor,
              color: titleColor,
            }}
            className="title"
          >
            Title
          </h3>
          <p
            style={{
              backgroundColor: bodyBackgroundColor,
              color: bodyColor,
              height: "100px",
            }}
            className="description"
          >
            Message Text
          </p>
        </Col>
      </Row>
    </div>
  );
}

export default OneUser;
