import React from "react";
import { closeModal } from "../../../redux-toolkit/slice/modal";
import { useDispatch } from "react-redux";
import { Button, Modal } from "antd";

function ErrorModal({ content, heading }) {
  const dispatch = useDispatch();

  const handleCloseModal = () => {
    dispatch(closeModal());
  };

  return (
    <Modal
      key={heading}
      title={heading}
      centered
      className="common-modal"
      open={true}
      width={510}
      onCancel={handleCloseModal}
      footer={
        <div className="footer-btns">
          <Button className="primary-outline" onClick={handleCloseModal}>
            Cancel
          </Button>
        </div>
      }
    >
      {content}
    </Modal>
  );
}

export default ErrorModal;
