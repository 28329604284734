import {
  Avatar,
  Button,
  Checkbox,
  Col,
  Form,
  Row,
  Select,
  Tooltip,
} from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { MinusCircleFilled } from "@ant-design/icons";
import groupDataSelector from "../../../../../redux-toolkit/selectors/groupsData";
import { useSelector } from "react-redux";
import {
  firstLetter,
  getDetails,
  groupOptions,
  isSubstring,
  optionCreator,
  serialNumber,
} from "../../../../../utils/functions";

function ThirdStep({ selectedManagers, setSelectedManagers, usersList }) {
  const { fetchedUsers } = groupDataSelector(useSelector);

  const [manager, setManager] = useState({});
  const [allManagersSelected, setAllManagersSelected] = useState(false);

  const handleSelect = (id, { type }) => {
    setManager({ id, type, role: "manager" });
  };

  const handleAdd = () => {
    if (manager.id) setSelectedManagers([...selectedManagers, manager]);
    setManager({});
  };

  const handleDeselect = (deletedKey) => {
    setSelectedManagers((prevValue) =>
      prevValue.filter(({ id }) => id !== deletedKey)
    );
  };

  const handleCheckbox = ({ target: { checked } }) => {
    setAllManagersSelected(checked);
    let filteredTeamMembers = selectedManagers.filter(
      ({ type }) => type === "team"
    );
    if (checked)
      setSelectedManagers([
        ...allUsers.map(({ id }) => ({ id, type: "user", role: "manager" })),
        ...filteredTeamMembers,
      ]);
    else setSelectedManagers(filteredTeamMembers);
  };

  const allUsers = useMemo(
    () => [
      ...usersList,
      ...fetchedUsers.filter(
        ({ id }) => !usersList.some(({ source_id }) => id === source_id)
      ),
    ],
    [fetchedUsers, usersList]
  );

  const isManagerCheckboxIndeterminate = useMemo(() => {
    let selectedUsers = selectedManagers.filter(({ type }) => type === "user");
    return selectedUsers.length > 0 && selectedUsers.length < allUsers.length;
  }, [selectedManagers, allUsers]);

  useEffect(() => {
    if (isManagerCheckboxIndeterminate) setAllManagersSelected(false);
  }, [isManagerCheckboxIndeterminate]);

  useEffect(() => {
    let userMembers =
      selectedManagers?.filter(({ type }) => type === "user") ?? [];
    if (userMembers.length === allUsers.length) setAllManagersSelected(true);
  }, [selectedManagers, allUsers]);

  return (
    <Form name="form_item_path" layout="vertical">
      <Row gutter={[8, 8]}>
        <Col xs={24}>
          <h3 className="d-flex">Group Members</h3>
        </Col>
        <Col xs={24}>
          <div className="member">
            {fetchedUsers?.map(
              ({ id, profile_photo, name, username, red_flag }, index) => (
                <div
                  className={`member-list ${red_flag ? "flagged" : ""}`}
                  key={id.toString()}
                >
                  <span>
                    {red_flag && (
                      <Tooltip title="Users with another Customer" key={id}>
                        <span className="dot" />
                      </Tooltip>
                    )}
                    {serialNumber(index)}
                    <Avatar
                      size={20}
                      src={
                        profile_photo?.find(({ is_active }) => is_active)?.image
                      }
                      children={profile_photo ? null : firstLetter(name)}
                    />
                    {name} | {username}
                  </span>
                </div>
              )
            )}
          </div>
        </Col>
        <Col xs={24} sm={16} md={12} lg={12} xl={12}>
          <Form.Item
            label="Team Managers"
            className="mb-0 no-form-item"
            children={null}
          />
          <Row gutter={[16, 16]} className="mb-2">
            <Col xs={24}>
              <Select
                showSearch
                placeholder="Select Users or Teams"
                filterOption={(input, option) =>
                  isSubstring(input, option.label)
                }
                popupMatchSelectWidth={true}
                style={{ width: "100%" }}
                value={manager.id}
                options={groupOptions(
                  [{ label: "Users", key: "user" }],
                  [optionCreator(allUsers, "name", "id")],
                  selectedManagers ?? []
                )}
                onSelect={(id, details) => {
                  handleSelect(id, details, true);
                }}
              />
            </Col>
            {/* <Col xs={2} className="d-flex">
              <Tooltip
                title={
                  allManagersSelected
                    ? "Deselect all Users"
                    : "Select all Users"
                }
              >
                <Checkbox
                  indeterminate={isManagerCheckboxIndeterminate}
                  checked={allManagersSelected}
                  onChange={(details) => handleCheckbox(details, true)}
                />
              </Tooltip>
            </Col> */}
            <Col className="plus-icon mb-2" sm={4} md={4} xl={2}>
              <Button className="primary-solid" onClick={() => handleAdd(true)}>
                Add
              </Button>
            </Col>
          </Row>
        </Col>
        <Col xs={20} sm={16} md={12} lg={12} xl={12}>
          <div className="credit-list-scroll kwip-credit-scroll">
            {selectedManagers?.map(({ id }) => {
              let { name, profile_photo } = getDetails(
                [...(allUsers ?? [])],
                "id",
                id
              );
              let image = Array.isArray(profile_photo)
                ? profile_photo?.find(({ is_active }) => is_active)?.image
                : profile_photo;
              return (
                <div className="credit-list creditTo" key={id}>
                  <Avatar
                    size={30}
                    src={image}
                    children={image ? null : firstLetter(name)}
                  />
                  <span className="creditTo_field">{name}</span>
                  <span className="close">
                    <MinusCircleFilled
                      onClick={() => handleDeselect(id, true)}
                    />
                  </span>
                </div>
              );
            })}
          </div>
        </Col>
      </Row>
    </Form>
  );
}

export default ThirdStep;
