import {
  Avatar,
  Button,
  Checkbox,
  Col,
  Form,
  Row,
  Select,
  Tooltip,
} from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { MinusCircleFilled } from "@ant-design/icons";
import {
  firstLetter,
  getDetailsForParticipant,
  groupOptions,
  optionCreator,
} from "../../../../utils/functions";

function StepThree({
  kwipObject,
  usersList,
  teamsList,
  setKwipObject,
  setIsEdited,
}) {
  const [selectedRecipent, setSelectedRecipent] = useState({});
  const [allUsersSelected, setAllUsersSelected] = useState(false);

  const handleAddRecipent = () => {
    setIsEdited(true);
    setKwipObject((prev) => ({
      ...prev,
      participants: [...(prev.participants ?? []), selectedRecipent],
    }));
    setSelectedRecipent({});
  };

  const handleDeselect = (key) => {
    setIsEdited(true);
    setKwipObject((prev) => ({
      ...prev,
      participants:
        prev?.participants?.filter((item) => item.key !== key) ?? [],
    }));
  };

  const handleRecipentSelect = (_, { key, type }) => {
    setSelectedRecipent({ key, type });
  };

  const handleCheckbox = ({ target: { checked } }) => {
    setAllUsersSelected(checked);
    const { participants } = kwipObject;
    let filteredMembers = participants.filter(
      ({ type, isDeletable }) => type === "team" || isDeletable === false
    );
    if (checked)
      setKwipObject((prev) => {
        return {
          ...prev,
          participants: [
            ...filteredUsersList.map(({ id }) => ({ key: id, type: "user" })),
            ...filteredMembers,
          ],
        };
      });
    else
      setKwipObject((prev) => {
        return {
          ...prev,
          participants: filteredMembers,
        };
      });
  };

  const filteredUsersList = useMemo(() => {
    return (
      usersList?.filter(({ id: userId }) => {
        return !kwipObject?.creditTo?.some(({ id }) => userId === id) ?? false;
      }) ?? []
    );
  }, [usersList, kwipObject]);

  const isCheckboxIndeterminate = useMemo(() => {
    const { participants } = kwipObject;
    let selectedUsers = participants?.filter(
      ({ type, isDeletable }) => type === "user" && (isDeletable ?? true)
    );
    return (
      selectedUsers?.length > 0 &&
      selectedUsers?.length < filteredUsersList?.length
    );
  }, [kwipObject, filteredUsersList]);

  useEffect(() => {
    if (isCheckboxIndeterminate) setAllUsersSelected(false);
  }, [isCheckboxIndeterminate]);

  useEffect(() => {
    const { participants } = kwipObject;
    let userMembers =
      participants?.filter(
        ({ type, isDeletable }) => type === "user" && (isDeletable ?? true)
      ) ?? [];

    if (userMembers?.length === filteredUsersList?.length)
      setAllUsersSelected(true);
    else setAllUsersSelected(false);
  }, [kwipObject, filteredUsersList]);

  return (
    <Form name="form_item_path" layout="vertical" onFinish={() => {}}>
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={16} md={12} lg={12} xl={12}>
          <Form.Item
            label="Recipients"
            className="mb-0 no-form-item"
            children={null}
            required
          />
          <Row gutter={[16, 16]} className="mb-2">
            <Col xl={24}>
              <Select
                showSearch
                placeholder="Select Users or Teams"
                popupMatchSelectWidth={true}
                style={{ width: "100%" }}
                value={selectedRecipent.key}
                options={groupOptions(
                  [
                    { label: "Users", key: "user" },
                    { label: "Teams", key: "team" },
                  ],
                  [
                    optionCreator(usersList, "name", "id"),
                    optionCreator(teamsList, "name", "id"),
                  ],
                  [
                    ...(kwipObject?.creditTo ?? []),
                    ...(kwipObject?.participants ?? []),
                  ]
                )}
                onSelect={handleRecipentSelect}
              />
            </Col>
            {/* <Col xs={2} className="d-flex">
              <Tooltip
                title={
                  allUsersSelected ? "Deselect all Users" : "Select all Users"
                }
              >
                <Checkbox
                  indeterminate={isCheckboxIndeterminate}
                  checked={allUsersSelected}
                  onChange={handleCheckbox}
                />
              </Tooltip>
            </Col> */}
            <Col className="plus-icon mb-2" sm={4} md={4} xl={2}>
              <Button className="primary-solid" onClick={handleAddRecipent}>
                Add
              </Button>
            </Col>
          </Row>
        </Col>
        <Col xs={20} sm={16} md={12} lg={12} xl={12}>
          <div className="credit-list-scroll kwip-credit-scroll">
            {kwipObject?.participants?.map(({ key, type, isDeletable }) => {
              let { displayName, profile_photo } = getDetailsForParticipant(
                key,
                type,
                usersList,
                teamsList
              );
              return (
                <div className="credit-list creditTo" key={key}>
                  <Avatar
                    size={30}
                    src={profile_photo}
                    children={profile_photo ? null : firstLetter(displayName)}
                  />
                  <span className="creditTo_field">{displayName}</span>
                  <span className="close">
                    {(isDeletable ?? true) && (
                      <MinusCircleFilled onClick={() => handleDeselect(key)} />
                    )}
                  </span>
                </div>
              );
            })}
          </div>
        </Col>
      </Row>
    </Form>
  );
}

export default StepThree;
