import { Col, Input, Row } from "antd";
import React from "react";

function SaveAs({
  templateName,
  setTemplateName,
  showTemplateNameError,
  setShowTemplateNameError,
}) {
  const handleChange = ({ target: { value } }) => {
    setTemplateName(value);
    setShowTemplateNameError(false);
  };

  return (
    <Row gutter={[16, 16]}>
      <h3 className="mb-0 ml-1">Name of the Template</h3>
      <Col xl={24}>
        <Input
          placeholder="Write name"
          value={templateName}
          onChange={handleChange}
        />
        {showTemplateNameError && (
          <div className="errorMessage">Template name is required</div>
        )}
      </Col>
    </Row>
  );
}

export default SaveAs;
