import { Col, Row } from "antd";
import React, { useState } from "react";
import Heading from "./components/heading";
import Style from "./components/style";

function TickerModal({ contentDetails, setContentDetails }) {
  const [mode, setMode] = useState("heading");

  const handleModeChange = (value) => {
    setMode(value);
  };

  return (
    <Row>
      {mode === "heading" ? (
        <Heading
          mode={mode}
          contentDetails={contentDetails}
          setContentDetails={setContentDetails}
          handleModeChange={handleModeChange}
        />
      ) : (
        <Col xs={24}>
          <Style
            mode={mode}
            contentDetails={contentDetails}
            setContentDetails={setContentDetails}
            handleModeChange={handleModeChange}
          />
        </Col>
      )}
    </Row>
  );
}

export default TickerModal;
