import { createSlice } from "@reduxjs/toolkit";

import INITIAL_STATE from "../../initialState";
import {
  fetchFilteredUsers,
  fetchGroups,
  fetchSyncInterval,
  syncroniseInterval,
} from "./asyncThunk";

export const groupsSlice = createSlice({
  name: "groups",
  initialState: INITIAL_STATE.GROUPS,
  reducers: {
    clearAll: (state) => {
      state.fetchedUsers = null;
      state.error = null;
      state.groupList = undefined;
      state.sync_interval = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchFilteredUsers.fulfilled, (state, { payload }) => {
      state.error = false;
      state.fetchedUsers = payload ?? [];
    });

    builder.addCase(fetchFilteredUsers.rejected, (state, { error }) => {
      let errorObject = JSON.parse(error.message);
      state.error = errorObject;
    });

    builder.addCase(fetchGroups.fulfilled, (state, { payload }) => {
      state.error = false;
      state.groupList = payload;
    });

    builder.addCase(fetchGroups.rejected, (state, { error }) => {
      let errorObject = JSON.parse(error.message);
      state.error = errorObject;
    });

    builder.addCase(fetchSyncInterval.fulfilled, (state, { payload }) => {
      state.error = false;
      state.sync_interval = payload;
    });

    builder.addCase(fetchSyncInterval.rejected, (state, { error }) => {
      let errorObject = JSON.parse(error.message);
      state.error = errorObject;
    });

    builder.addCase(syncroniseInterval.fulfilled, (state, { payload }) => {
      state.error = false;
      state.sync_interval = payload;
    });

    builder.addCase(syncroniseInterval.rejected, (state, { error }) => {
      let errorObject = JSON.parse(error.message);
      state.error = errorObject;
    });
  },
});

export const { clearAll } = groupsSlice.actions;

export default groupsSlice.reducer;
