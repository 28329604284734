import {
  Avatar,
  Button,
  Checkbox,
  Col,
  Form,
  Row,
  Select,
  Tooltip,
} from "antd";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { MinusCircleFilled } from "@ant-design/icons";
import {
  firstLetter,
  getDetailsForParticipant,
  groupOptions,
  isFalse,
  isSubstring,
  optionCreator,
} from "../../../../utils/functions";
import { useSelector } from "react-redux";
import teamsDataSelector from "../../../../redux-toolkit/selectors/teamData";
import { useDispatch } from "react-redux";
import { fetchUsers } from "../../../../redux-toolkit/slice/users/asyncThunks";
import { fetchTeams } from "../../../../redux-toolkit/slice/teams/asyncThunk";

function TickerParticipants({ scheduleData, setScheduleData, setIsEdited }) {
  const dispatch = useDispatch();

  const { usersList, teamsList } = teamsDataSelector(useSelector);

  const [member, setMember] = useState({});
  const [isLoading, setIsLoading] = useState({ team: false, user: false });
  const [allUsersSelected, setAllUsersSelected] = useState(false);

  const handleSelect = (key, { type }) => {
    setMember({ key, type });
  };

  const handleAdd = () => {
    setIsEdited(true);
    setMember({});
    if (member.key)
      setScheduleData((prev) => ({
        ...prev,
        participants: [...(prev.participants ?? []), member],
      }));
  };

  const handleDeselect = (key) => {
    setIsEdited(true);
    setScheduleData((prev) => ({
      ...prev,
      participants:
        prev?.participants?.filter((item) => item.key !== key) ?? [],
    }));
  };

  const handleCheckbox = ({ target: { checked } }) => {
    const { participants } = scheduleData;
    setAllUsersSelected(checked);
    let filteredTeamMembers = participants.filter(
      ({ type }) => type === "team"
    );
    if (checked)
      setScheduleData((prev) => ({
        ...prev,
        participants: [
          ...usersList.map(({ id }) => ({
            key: id,
            type: "user",
            role: "member",
          })),
          ...filteredTeamMembers,
        ],
      }));
    else
      setScheduleData((prev) => ({
        ...prev,
        participants: filteredTeamMembers,
      }));
  };

  const loaderCallback = useCallback((type, value) => {
    setIsLoading((prev) => ({ ...prev, [type]: value }));
  }, []);

  const isCheckboxIndeterminate = useMemo(() => {
    const { participants } = scheduleData;
    let selectedUsers = participants.filter(({ type }) => type === "user");
    return (
      selectedUsers?.length > 0 && selectedUsers?.length < usersList?.length
    );
  }, [scheduleData, usersList]);

  useEffect(() => {
    if (isFalse(usersList)) {
      loaderCallback("user", true);
      dispatch(
        fetchUsers({
          loadOrNot: false,
          showNotification: false,
          callback: () => loaderCallback("user"),
        })
      );
    } else loaderCallback("user");
  }, [dispatch, loaderCallback, usersList]);

  useEffect(() => {
    if (isFalse(teamsList)) {
      loaderCallback("team", true);
      dispatch(
        fetchTeams({
          loadOrNot: false,
          showNotification: false,
          callback: () => loaderCallback("team"),
        })
      );
    } else loaderCallback("team");
  }, [dispatch, loaderCallback, teamsList]);

  useEffect(() => {
    if (isCheckboxIndeterminate) setAllUsersSelected(false);
  }, [isCheckboxIndeterminate]);

  useEffect(() => {
    const { participants } = scheduleData;
    let selectedUsers =
      participants.filter(({ type }) => type === "user") ?? [];
    if (selectedUsers?.length === usersList?.length) setAllUsersSelected(true);
  }, [scheduleData, usersList]);

  return (
    <Form name="form_item_path" layout="vertical" onFinish={() => {}}>
      <Row gutter={[16, 16]}>
        <Col xs={20} sm={4} md={6} lg={12} xl={12}>
          <Form.Item
            label="Recipients"
            className="mb-0 no-form-item"
            children={null}
            required
          />
          <Row gutter={[16, 16]}>
            <Col xs={24}>
              <Select
                showSearch
                placeholder="Select Users or Teams"
                filterOption={(input, option) =>
                  isSubstring(input, option.label)
                }
                popupMatchSelectWidth={true}
                style={{ width: "100%" }}
                loading={isLoading.team || isLoading.user}
                value={member?.key}
                options={groupOptions(
                  [
                    { label: "Users", key: "user" },
                    { label: "Teams", key: "team" },
                  ],
                  [
                    optionCreator(usersList, "name", "id"),
                    optionCreator(teamsList, "name", "id"),
                  ],
                  scheduleData.participants ?? []
                )}
                onSelect={handleSelect}
              />
            </Col>
            {/* <Col xs={2} className="d-flex">
              <Tooltip
                title={
                  allUsersSelected ? "Deselect all Users" : "Select all Users"
                }
              >
                <Checkbox
                  indeterminate={isCheckboxIndeterminate}
                  checked={allUsersSelected}
                  onChange={handleCheckbox}
                />
              </Tooltip>
            </Col> */}
          </Row>
          <div className="mt-2 d-flex">
            <Button className="primary-solid" onClick={handleAdd}>
              Add
            </Button>
          </div>
        </Col>
        <Col xs={20} sm={16} md={12} lg={12} xl={12}>
          <div className="create-notify-list">
            {scheduleData?.participants?.map(({ key, type }) => {
              let { displayName, profile_photo } = getDetailsForParticipant(
                key,
                type,
                usersList,
                teamsList
              );
              return (
                <div className="notify-list-items" key={key}>
                  <div>
                    <Avatar
                      size={30}
                      src={profile_photo}
                      children={profile_photo ? null : firstLetter(displayName)}
                    />
                    <span>{displayName}</span>
                  </div>
                  <MinusCircleFilled onClick={() => handleDeselect(key)} />
                </div>
              );
            })}
          </div>
        </Col>
      </Row>
    </Form>
  );
}

export default TickerParticipants;
