import React, { useCallback, useEffect, useMemo } from "react";
import { Table, Button, Row, Col, Tabs } from "antd";
import { useDispatch, useSelector } from "react-redux";

import CONFIG from "../../utils/config";
import teamsDataSelector from "../../redux-toolkit/selectors/teamData";
import {
  columnAndComponentFunctionReference,
  withKeyDataSet,
} from "../../utils/functions";
import { fetchUsers } from "../../redux-toolkit/slice/users/asyncThunks";
import {
  deleteTeams,
  fetchTeams,
  updateTeams,
} from "../../redux-toolkit/slice/teams/asyncThunk";
import { closeModal, openModal } from "../../redux-toolkit/slice/modal";
import { syncAllGroup } from "../../redux-toolkit/slice/groups/asyncThunk";
import adminDataSelector from "../../redux-toolkit/selectors/adminData";

function Teams() {
  const {
    TEAMS_COLUMN,
    MODALS: { TEAM, PREVIEW_TEAM, CONFIRM },
    LABELS: {
      GROUP: { SYNC_ALL_GROUPS },
      TEAMS: { ADD_NEW_TEAM },
      ID,
    },
  } = CONFIG;

  const dispatch = useDispatch();

  const { usersList, teamsList } = teamsDataSelector(useSelector);
  const { adminDetails } = adminDataSelector(useSelector);

  const { is_salesforce_authenticated } = useMemo(
    () => adminDetails || {},
    [adminDetails]
  );

  const handleEditTeam = useCallback(
    (teamData) => {
      dispatch(
        openModal({
          key: TEAM.key,
          props: { usersList, teamData, groups: teamsList },
        })
      );
    },
    [TEAM.key, usersList, teamsList, dispatch]
  );

  const handleAddTeam = useCallback(() => {
    dispatch(
      openModal({
        key: TEAM.key,
        props: { usersList, teamsList, groups: teamsList },
      })
    );
  }, [TEAM, usersList, teamsList, dispatch]);

  const handleTeamDeleteConfirm = useCallback(
    ({ id, instance_url, name }) => {
      dispatch(
        deleteTeams({
          data: { id, instance_url, name },
          callback: () => dispatch(closeModal()),
        })
      );
    },
    [dispatch]
  );

  const handleTeamDelete = useCallback(
    (record) => {
      dispatch(
        openModal({
          key: CONFIRM.key,
          props: {
            title: "Team Delete",
            content: "Are you sure, you want to delete the team?",
            handleConfirm: () => handleTeamDeleteConfirm(record),
          },
        })
      );
    },
    [CONFIRM, dispatch, handleTeamDeleteConfirm]
  );

  const handleTeamToggle = useCallback(
    (teamData, value) => {
      teamData.isactive = value;
      dispatch(updateTeams({ payload: teamData, isSynced: false }));
    },
    [dispatch]
  );

  const handleSync = useCallback(
    (record) => {
      dispatch(updateTeams({ payload: record, isSynced: true }));
    },
    [dispatch]
  );

  const handleSyncAll = useCallback(() => {
    is_salesforce_authenticated
      ? dispatch(syncAllGroup())
      : dispatch(
          openModal({
            key: CONFIRM.key,
            props: {
              title: "Restricted",
              content:
                "Salesforce connection is lost. Please re-connect to perform action.",
              handleConfirm: () => {
                dispatch(closeModal());
              },
            },
          })
        );
  }, [is_salesforce_authenticated, dispatch, CONFIRM]);

  const handleAvatarClick = useCallback(
    (members, dataSet) => {
      dispatch(
        openModal({
          key: PREVIEW_TEAM.key,
          props: {
            members,
            dataSet,
          },
        })
      );
    },
    [PREVIEW_TEAM, dispatch]
  );

  const tableColumn = useMemo(
    () =>
      columnAndComponentFunctionReference(TEAMS_COLUMN, {
        usersList,
        teamsList,
        handleAvatarClick,
        handleSync,
        handleEditTeam,
        handleTeamDelete,
        handleTeamToggle,
        is_salesforce_authenticated,
      }),
    [
      TEAMS_COLUMN,
      usersList,
      teamsList,
      handleAvatarClick,
      handleEditTeam,
      handleTeamDelete,
      handleSync,
      handleTeamToggle,
      is_salesforce_authenticated,
    ]
  );

  useEffect(() => {
    !teamsList &&
      dispatch(
        fetchTeams({
          showNotification: true,
          loadOrNot: true,
          callback: () => {},
        })
      );
  }, [teamsList, dispatch]);

  useEffect(() => {
    if (!usersList) dispatch(fetchUsers());
  }, [usersList, dispatch]);

  return (
    <div className="userContainer">
      <Row className="filter-head">
        <Col span={12}>
          <h2>TEAMS</h2>
        </Col>
        <Col style={{ justifyContent: "end", display: "flex" }} span={12}>
          <div>
            <div className="filter">
              <Button className="primary-outline  mr-1" onClick={handleSyncAll}>
                {SYNC_ALL_GROUPS}
              </Button>
              <Button className="primary-outline" onClick={handleAddTeam}>
                {ADD_NEW_TEAM}
              </Button>
            </div>
          </div>
        </Col>
      </Row>
      <Table
        className="cmn-table"
        columns={tableColumn}
        dataSource={withKeyDataSet(ID, teamsList) ?? []}
        rowClassName={({ source_type }) => {
          if (source_type === "Salesforce" && !is_salesforce_authenticated)
            return "disabled-row";
        }}
      />
    </div>
  );
}

export default Teams;
