import React from "react";
import moment from "moment";
import { toast } from "react-toastify";

import CONFIG from "../config";
import Notification from "../../components/notification";
import dayjs from "dayjs";

function sortArryOfObject(arr, selector) {
  arr.sort((a, b) => {
    const labelA = a[selector].toLowerCase();
    const labelB = b[selector].toLowerCase();
    if (labelA < labelB) return -1;
    if (labelA > labelB) return 1;
    return 0;
  });
  return arr;
}

export const idGenerator = () => {
  const timestamp = Date.now();
  const random = Math.random().toString(36).substring(2);
  const uniqueId = `${timestamp}-${random}`;
  return uniqueId;
};

export const isFalse = (value) => {
  if (Array.isArray(value)) return value.length === 0;

  switch (typeof value) {
    case "undefined":
      return true;
    case "object":
      if (value === null) return true;
      return Object.keys(value).length === 0;
    case "string":
      return value.trim() === "";
    case "number":
      return value === 0;
    case "boolean":
      return value === false;
    default:
      return false;
  }
};

export const getToken = (key) => {
  return localStorage.getItem(key);
};

export const setToken = (key, value) => {
  localStorage.setItem(key, value);
};

export const errorNotification = ({ title = "Error in API", message }) => {
  toast(
    <Notification
      src={CONFIG.NOTIFICATION_OBJECT.ERROR}
      title={title}
      message={message}
    />,
    {
      autoClose: 3000,
    }
  );
};

export const successNotification = ({
  title = "Success!",
  message = "Response submitted",
}) => {
  toast(
    <Notification
      src={CONFIG.NOTIFICATION_OBJECT.SUCCESS}
      title={title}
      message={message}
    />,
    {
      autoClose: 3000,
    }
  );
};

export const uppercaseFirstLetter = (str) => {
  return str.replace(/(^\s*\w|\. \w)/g, function (c) {
    return c.toUpperCase();
  });
};

export const underscoreSeparator = (str) => {
  if (str?.includes("_")) return str.replaceAll("_", " ");
  return str;
};

export const titleGenerator = (key) => {
  return [underscoreSeparator, uppercaseFirstLetter].reduce(
    (result, fnc) => fnc(result),
    key
  );
};

export const columnObjectGenerator = (key) => {
  switch (key) {
    case "serialNumber":
      return {
        key,
        dataIndex: key,
        title: "Sr.",
        width: 55,
        render: (_, __, index) => index + 1 + ".",
      };

    case "is_active":
      return {
        key,
        title: titleGenerator(key),
        width: 130,
        render: ({ is_active }) => {
          return (
            <div
              className={
                is_active ? "status status-active" : "status status-inactive"
              }
            >
              {is_active ? "Active" : "In-Active"}
            </div>
          );
        },
      };
    default:
      return {
        key,
        dataIndex: key,
        width: 300,
        title: titleGenerator(key),
        sorter: { compare: (a, b) => a[key]?.localeCompare(b[key]) ?? 0 },
      };
  }
};

export const columnGenerator = (filterKeys) => {
  let columns = filterKeys.reduce((result, key) => {
    let columnObj = columnObjectGenerator(key);
    return result.add(columnObj);
  }, new Set());

  return [columnObjectGenerator("serialNumber"), ...columns];
};

export const columnOptions = (data = []) => {
  return isFalse(data)
    ? []
    : Object.keys(data[0]).map((key) => ({
        key,
        value: key,
        label: titleGenerator(key),
      }));
};

export const offsetHeight = (ref) => {
  return ref?.current?.offsetHeight ?? 0;
};

export const columnAndComponentFunctionReference = (data, argObj) => {
  return data?.map(({ render: renderer, ...rest }) => {
    if (renderer) {
      return {
        ...rest,
        render: (value, data, index) => {
          return renderer(value, data, index, { ...argObj });
        },
      };
    } else return { ...rest };
  });
};

export const notificationTableData = (events, kwips) => {
  return events?.reduce((result, data) => {
    let kwipsData = kwips?.find(({ id }) => id === data.id) ?? {};
    return [...result, { ...data, kwipsUsed: kwipsData?.target_count ?? 0 }];
  }, []);
};

export const withKeyDataSet = (selector, data) => {
  return (
    data?.map((element) => ({ ...element, key: element?.[selector] })) ?? []
  );
};

export const groupOptions = (
  groupingLabel,
  groupingData = [],
  filterValueArr = []
) => {
  return groupingLabel.map(({ label, key }, index) => ({
    label,
    options: groupingData[index]?.reduce((acc, option) => {
      return filterValueArr?.some(({ id, key }) => (id ?? key) === option.value)
        ? acc
        : [...acc, { type: key, ...option }];
    }, []),
  }));
};

export const metricOptionCreator = (dataSet) => {
  return dataSet?.map(({ label, name, data_type }) => ({
    label,
    value: name,
    data_type,
    key: name,
  }));
};

export const thresholdUserRecognitionOption = (dataSet) => {
  return dataSet?.map(({ label, name, data_type }) => ({
    label,
    value: name,
    data_type,
    key: name,
  }));
};

export const kwipMergeFieldOptionCreator = (dataSet) => {
  return dataSet?.map(({ label, id, data_type }) => ({
    label,
    key: id,
    data_type,
    value: id,
  }));
};

export const optionCreator = (
  dataSet,
  labelSelector,
  valueSelector,
  subStringToRemove
) => {
  return (
    dataSet?.map?.((element) => ({
      label: labelSelector
        ? subStringToRemove
          ? removeSubString(element[labelSelector], subStringToRemove)
          : element[labelSelector]
        : element,
      value: valueSelector ? element[valueSelector] : element,
      key: valueSelector ? element[valueSelector] : element,
    })) ?? []
  );
};

export const getDetails = (dataSet, selector, comparingParameter) => {
  let obj = dataSet?.find((elem) => elem[selector] === comparingParameter) ?? {
    ...CONFIG.DEFAULT_PROFILE,
    id: idGenerator(),
  };
  return obj;
};

export const serialNumber = (index) => {
  return index + 1 + ". ";
};

export const isErrorPresent = (errorObject) => {
  return !Object.values(errorObject).every(({ errorMessage }) => !errorMessage);
};

export function isSubstring(substring, fullString) {
  const lowerCaseSubstring = substring.toLowerCase();
  const lowerCaseFullString = fullString.toLowerCase();
  return lowerCaseFullString.includes(lowerCaseSubstring);
}

export function getSubDataObject(obj, subObject) {
  return Object.keys(obj).reduce((result, key) => {
    if (subObject[key]) {
      return { ...result, [key]: obj[key] };
    }
    return result;
  }, {});
}

export function isCurrentStepDisabled(step, obj) {
  switch (step) {
    case 0: {
      let keys = ["titleName", "type", "object", "threshold_type"];
      return { next: !keys.every((key) => obj[key]), save: false };
    }
    case 1: {
      let next = obj.filterOptions
        ? obj.filterOptions.length && obj?.evaluation?.criteria
          ? false
          : true
        : true;
      return { next, save: false };
    }
    case 2: {
      let save = obj.recognitionFields?.length ? false : true;
      return { next: false, save };
    }
    default:
      return false;
  }
}

export function isCurrentMetricStepDisabled(step, obj) {
  switch (step) {
    case 0: {
      let keys = ["name", "decimal_places", "source"];
      if (obj.source === "Salesforce") keys.push("object");
      else {
        if (obj.type === "Currency") keys.push("currency");
        keys.push("type");
      }

      let allRequiredFieldsSatisfy = keys.every(
        (key) => obj[key] !== null && obj[key] !== "" && obj[key] !== undefined
      );
      let isTimeframePresent = Boolean(obj?.timeframes?.length);
      return {
        next: !(allRequiredFieldsSatisfy && isTimeframePresent),
        save: false,
      };
    }
    case 1: {
      let save =
        obj?.filterOptions &&
        obj?.filterOptions.length &&
        obj?.metricFieldValue &&
        obj?.creditTo &&
        obj?.timeframeFieldValue &&
        (obj.metricFieldValue === "record_count_only" ? true : obj?.calculation)
          ? false
          : true;
      return { next: false, save };
    }
    default:
      return false;
  }
}

export function isCurrentKwipStepDisabled(step, obj) {
  switch (step) {
    case 0: {
      let keys = ["name", "type", "notification"];
      return { next: !keys.every((key) => obj[key]), save: false };
    }
    case 1: {
      let next = obj?.creditTo && obj?.creditTo.length ? false : true;
      return { next, save: false };
    }
    case 2: {
      let next = obj?.participants && obj?.participants.length ? false : true;
      return { next, save: false };
    }
    default:
      return false;
  }
}

export function isCurrentLeaderboardStepDisabled(step, obj, additionalUsers) {
  switch (step) {
    case 0: {
      const stateKeys = [
        "title",
        "subtitle",
        "metric_id",
        "timeframe",
        "team_id",
      ];

      return { next: stateKeys.some((key) => !obj[key]), save: false };
    }
    case 1: {
      return {
        next: false,
        save: false,
      };
    }
    default:
      return false;
  }
}

export function isCurrentTeamStepDisabled(step, obj) {
  switch (step) {
    case 0: {
      let keys = ["teamName", "teamType", "teamProfile"];
      return { next: !keys.every((key) => obj[key]), save: false };
    }
    case 1: {
      if (obj.teamType === "Custom") {
        return { next: false, save: !obj.selectedMembers.length };
      } else if (obj.teamType === "Salesforce") {
        let length = obj.filterDetails.length;
        return {
          next: false,
          save: !length,
        };
      } else return { next: false, save: false };
    }
    default:
      return false;
  }
}

export function isCurrentTickerStepDisable(step, obj) {
  switch (step) {
    case 0: {
      return { next: !isTickerSaveAllowed(obj), save: false };
    }
    case 1: {
      let next = obj?.participants && obj?.participants.length ? false : true;
      let sendNow = next ? true : !!obj?.scheduleList.length;
      return { next, save: false, sendNow };
    }
    case 2: {
      let save = obj?.scheduleList && obj?.scheduleList.length ? false : true;
      return { next: save, save };
    }
    default:
      return false;
  }
}

export const getNotificationOperation = (
  operationList,
  selectedField,
  fieldList
) => {
  let { data_type, picklistValues } =
    fieldList?.find(({ name }) => name === selectedField) ?? {};

  return {
    ...(operationList.find(({ DATA_TYPES }) =>
      DATA_TYPES.includes(data_type?.toLowerCase())
    ) ?? {}),
    OPTIONS: picklistValues ?? [],
  };
};

export const booleanStringStatus = (value) => {
  if (value)
    if (value.toLowerCase() === "true" || value.toLowerCase() === "false") {
      return "success";
    } else if (
      "true".includes(value.toLowerCase()) ||
      "false".includes(value.toLowerCase())
    ) {
      return "warning";
    } else {
      return "error";
    }
  else return "primary";
};

export const updatedValidationForStepTwo = (obj, type) => {
  return { ...obj, value: [...obj.value] };
};

export const createId = (data, selector, index) => {
  return `${data[selector]}-${index}`;
};

export const getMappingText = (data, fields) => {
  let { label } = fields.find(({ name }) => name === data.field) ?? {};

  let value = data.value;

  if (typeof data.value === "object" && !Array.isArray(value))
    value = moment(new Date(data.value)).format("YYYY-MM-DD HH:mm");

  return {
    fieldText: label,
    operatorText: data.operator,
    valueText: value,
  };
};

export const getFilteredValue = (list, selectorType) => {
  return list.filter(({ type }) => type === selectorType).map(({ key }) => key);
};

export const getDetailsForParticipant = (key, type, users, teams) => {
  if (type === "user") {
    let { name, profile_photo } = users?.find(({ id }) => id === key) ?? {};
    let activeProfile = profile_photo?.find(({ is_active }) => is_active);
    return { displayName: name, profile_photo: activeProfile?.image };
  } else {
    let { name, profile_photo } = teams?.find(({ id }) => id === key) ?? {};
    return { displayName: name, profile_photo };
  }
};

export const firstLetter = (string = "") => {
  return string?.charAt(0)?.toUpperCase() ?? "";
};

export const generateNotificationBody = (
  string,
  { key, data_type },
  index,
  currency
) => {
  let recognitionField = "";

  switch (data_type) {
    case "percent": {
      recognitionField = `{${key}}%`;
      break;
    }
    case "currency": {
      recognitionField = `${currency}{${key}}`;
      break;
    }
    default:
      recognitionField = `{${key}}`;
  }

  let spacedRecognitionField = string
    ? ` ${recognitionField} `
    : `${recognitionField} `;

  return string.slice(0, index) + spacedRecognitionField + string.slice(index);
};

export const sanetizeNotificationBody = (string) => {
  let finalString = "";
  let openBracketIndex = null;

  for (let i = 0; i < string.length; i++) {
    if (string[i] === "{") openBracketIndex = i;
    else if (string[i] === "}")
      if (openBracketIndex !== null) {
        finalString += string.slice(openBracketIndex, i);
        openBracketIndex = null;
      } else continue;
    if (openBracketIndex === null) finalString += string[i];
  }
  return finalString;
};

export const createNotificationState = (object, metricesList) => {
  return {
    id: object.id,
    titleName: object.event_name,
    object: object.object_name,
    filterOptions: object.conditions.map((data) => {
      let metric = metricesList.find(({ id }) => id === object.metric_id);
      const is_metric_field = metric?.metrics_metadata?.field_criteria?.some(
        ({ field }) => field === data.field
      );
      return is_metric_field ? { ...data, is_deletable: false } : data;
    }),
    evaluation: object.evaluation,
    creditTo: object.creditTo,
    type: object.type,
    recognitionFields: object.recognition_fields_object,
    metric_id: object.metric_id,
    threshold_type: object.threshold_type,
  };
};

export const createNotificationPayload = (object) => {
  let payload = {
    event_name: object.titleName,
    object_name: object.object,
    conditions: object.filterOptions.map((data) => {
      if ("is_deletable" in data) delete data.is_deletable;
      return data;
    }),
    evaluation: object.evaluation,
    creditTo: object.creditTo,
    type: object.type,
    recognition_fields_object: object.recognitionFields,
    metric_id: object.metric_id,
    threshold_type: object.threshold_type,
  };
  if (object.id) payload.id = object.id;
  return payload;
};

export const onlyOnceNotificationDisabled = (obj) => {
  return obj?.evaluation?.criteria === "default" ? false : true;
};

export const filterObjectWithChangeEvent = (data) =>
  data.filter(
    ({ associateEntityType, triggerable }) =>
      associateEntityType === "ChangeEvent" && triggerable
  );

export const removeSubString = (string, subString) => {
  return string?.replace?.(subString, "") ?? "";
};

export const getTypeForMetric = (type) => {
  switch (type) {
    case "percent":
      return "Percentage";
    case "currency":
      return "Currency";
    default:
      return "Number";
  }
};

export const filterMetricFields = (data, type) =>
  data?.filter(({ data_type }) => {
    let arr = [];
    switch (type) {
      case "date": {
        arr = ["date", "time", "datetime"];
        break;
      }
      default: {
        arr = ["int", "double", "number", "percent", "currency"];
      }
    }
    return arr.includes(data_type);
  });

export const filterFields = (data) =>
  data?.filter(({ data_type }) =>
    CONFIG.CDC_ALLOWED_FIELDS.includes(data_type)
  ) ?? [];

export const mapChangedFieldType = (data) => {
  return data?.map(({ type, ...rest }) => ({
    ...rest,
    data_type: type,
  }));
};

export const filterFieldsForCreditTo = (data) =>
  data?.filter(
    ({ data_type, referenceTo }) =>
      data_type === "reference" && referenceTo.includes("User")
  );

export const getCreateTeamPayload = ({
  name,
  members,
  isactive,
  description,
  image,
  filters,
  type,
  managers,
}) => {
  let data = {
    name,
    members: type === "Custom" ? members : [],
    managers,
    isactive,
    description,
    profile_photo: getBase64Image(image?.url ?? ""),
    source_type: type,
    filters: type === "Salesforce" ? filters : [],
  };

  if (!image?.url) delete data.profile_photo;

  return data;
};

export const getUpdateTeamPayload = ({
  name,
  members,
  isactive,
  description,
  image,
  instance_url,
  id,
  filters,
  type,
  managers,
}) => {
  let data = {
    id,
    name,
    members: type === "Custom" ? members : [],
    managers,
    isactive,
    description,
    instance_url,
    filters: type === "Salesforce" ? filters : [],
    source_type: type,
    profile_photo: getBase64Image(image?.url ?? ""),
  };

  if (!image?.file) delete data.profile_photo;

  return data;
};

export const getMembersForNestedTable = ({ groups = [], users = [] }) => {
  let userArray = users?.map(({ id }) => ({ type: "user", key: id, id })) ?? [];
  let groupArray =
    groups?.map(({ id, name }) => ({ type: "group", key: id, id, name })) ?? [];

  return [...userArray, ...groupArray];
};

export const getNestedTableData = (data) => {
  if (!data.length) return [];
  else
    return data.reduce((result, { name, id, data }) => {
      let resultantObj = {
        key: idGenerator(),
        name,
        members: getMembersForNestedTable(data),
      };
      let children = getNestedTableData(data?.groups ?? []);
      if (children.length) resultantObj.children = children;
      return [...result, resultantObj];
    }, []);
};

export const getCreateUserPayload = ({
  firstName,
  lastName,
  alias,
  email,
  title,
  mobile,
  profile,
  is_active,
  role,
  isAppUser = false,
  anniversary,
  birthdayObj,
  selectedMusic,
}) => {
  let data = {
    name: firstName + " " + lastName,
    alias,
    first_name: firstName,
    last_name: lastName,
    email,
    title,
    mobile: mobile ? +mobile : null,
    is_active,
    isAppUser,
    role,
    music_settings: { music: selectedMusic?.source },
    anniversary,
  };

  if (birthdayObj.date && birthdayObj.month)
    data.birthday = birthdayObj.month + "-" + birthdayObj.date;

  data.profile_photo =
    profile?.map(({ url, is_active: profile_is_active }) => ({
      image: url.split(",")[1],
      is_active: profile_is_active,
    })) ?? [];

  return data;
};

export const getEditUserPayload = ({
  id,
  lastName,
  firstName,
  mobile,
  alias,
  email,
  title,
  profile,
  is_active,
  isAppUser,
  role,
  birthday,
  anniversary,
  birthdayObj,
  selectedMusic,
  music_settings,
  profile_photo,
  name,
}) => {
  let data = {
    id,
    name: name ?? firstName + " " + lastName,
    first_name: firstName,
    last_name: lastName,
    mobile: mobile ? +mobile : null,
    alias,
    email,
    title,
    is_active,
    isAppUser,
    role,
    anniversary,
    birthday: birthdayObj
      ? birthdayObj.month + "-" + birthdayObj.date
      : birthday,
    music_settings: selectedMusic
      ? { music: selectedMusic?.source }
      : music_settings,
  };

  if (birthdayObj)
    if (birthdayObj.date && birthdayObj.month)
      data.birthday = birthdayObj.month + "-" + birthdayObj.date;
    else data.birthday = birthday;
  else data.birthday = birthday;

  data.profile_photo =
    profile?.map(({ file, url, is_active: profile_is_active }) => ({
      image: file ? url.split(",")[1] : url,
      is_active: profile_is_active,
    })) ?? profile_photo;

  return data;
};

export const filteredMenu = (data, { is_salesforce_authenticated }) => {
  return data.map((obj) => {
    if (obj.key === "salesforce_integration") {
      let tempChildren = [...obj.children].map((child) => {
        return {
          ...child,
          children: child.children.filter((child) => {
            if (child.key === "groups") return is_salesforce_authenticated;
            else return child;
          }),
        };
      });
      obj.children = tempChildren;
      return obj;
    } else return obj;
  });
};

export const operatorDataForSelectedField = (list, selectedField) => {
  return list?.find(({ key }) => key === selectedField)?.operators ?? [];
};

export const showLogicOperator = (filter, index) => {
  let length = filter.length;
  return length > 1 && index !== length - 1;
};

export const filtersValidationValue = (filters) => {
  let length = filters ? filters.length : null;
  let { field, operator } = length ? filters[length - 1] : {};
  return { field, operator };
};

export const getBase64Image = (url) => {
  return url.includes(",") ? url.split(",")[1] : url;
};

export const getCreateGroupPayload = ({ name, alias, profile, filters }) => {
  let data = {
    name,
    alias,
    profile_photo: getBase64Image(profile?.url ?? ""),
    filters,
  };
  if (!profile?.url) delete data.profile_photo;
  return data;
};

export const getEditGroupPayload = ({ id, name, alias, profile, filters }) => {
  let data = {
    id,
    name,
    alias,
    filters,
    profile_photo: getBase64Image(profile?.url ?? ""),
  };
  if (!profile?.file) delete data.profile_photo;
  return data;
};

export const getPathKey = () => {
  return window.location.pathname.split("/")[1];
};

export const getRefInitialValue = (data) => {
  if (data) {
    return data.profile_photo.map((_) => React.createRef());
  } else return [];
};

export const isGroupNotEmpty = (members, data = []) => {
  return members?.every(({ type, id: groupId }) => {
    if (type === "group") {
      return data?.find(({ id }) => id === groupId)?.members?.length;
    } else return true;
  });
};

export const filteredObject = (data) => {
  const { MOST_COMMON_OBJECTS } = CONFIG;
  return data?.filter(
    ({ associateParentEntity }) =>
      !MOST_COMMON_OBJECTS.some(({ key }) => key === associateParentEntity)
  );
};

export const filterUserRecognitions = (data) =>
  data?.filter(
    ({ data_type, referenceTo }) =>
      data_type !== "reference" && !referenceTo.includes("User")
  );

export const filterCreditToOption = (data, valueArray, selector) => {
  return data.filter(
    ({ key }) => !valueArray?.some((obj) => obj[selector] === key)
  );
};

export const filterMenuItem = (arr = [], allowedAccess = []) => {
  return arr.filter(({ key }) =>
    allowedAccess.some((allowedKey) => allowedKey === key)
  );
};

export const filterNavItems = (arr = [], allowedAccess = []) => {
  return arr.reduce((result, navItem) => {
    const { children, key } = navItem;
    if (children) {
      let tempChildren = filterNavItems(children, allowedAccess);
      return tempChildren.length
        ? [...result, { ...navItem, children: tempChildren }]
        : result;
    } else return allowedAccess.includes(key) ? [...result, navItem] : result;
  }, []);
};

export const templatePayload = ({
  id,
  name,
  count,
  type,
  componentKey,
  bgImage,
  bgColor,
  bgImageBlur,
  titleBackgroundColor,
  titleColor,
  bodyBackgroundColor,
  bodyColor,
}) => {
  let data = {
    name,
    count,
    type,
    componentKey,
    bgColor,
    bgImageBlur,
    titleBackgroundColor,
    titleColor,
    bodyBackgroundColor,
    bodyColor,
  };

  let tempBgImage = getBase64Image(bgImage ?? "");

  if (tempBgImage) data.bgImage = tempBgImage;
  else data.bgImage = bgImage;

  if (id) data.id = id;

  return data;
};

export const kwipPayload = ({
  id,
  creditTo,
  name,
  notification,
  participants,
  type,
  headline,
  body,
  subHeadline,
}) => {
  let data = {
    name,
    body,
    headline,
    subHeadline,
    kwip_metadata: { creditTo, trigger_id: notification },
    kwip_type: type,
    participants: participants.reduce(
      (result, { key, type, isDeletable }) =>
        isDeletable === false ? result : [...result, { id: key, type }],
      []
    ),
  };

  if (id) data.id = id;

  return data;
};

export const filterTemplateCount = (arr, comparingArr) => {
  let length = comparingArr.length;
  return arr?.filter(({ count }) => parseInt(count) === length);
};

export const filterGroup = (data, valueArr) => {
  return data.filter(({ value }) => !valueArr.some(({ id }) => id === value));
};

export const formatKwip = ({
  id,
  creditTo,
  name,
  trigger_id,
  participants,
  kwip_type,
  body,
  headline,
  subHeadline,
}) => {
  let data = {
    id,
    name,
    creditTo,
    body,
    headline,
    subHeadline,
    type: kwip_type,
    notification: trigger_id,
    participants: participants.map(({ id, type }) => ({ key: id, type })),
  };
  return data;
};

export const getKwipSendData = (logs, type) => {
  let tempArr = logs?.reduce(
    (
      result,
      { notification_sent_count, notification_received_count, created_at }
    ) => [
      ...result,
      {
        date: created_at,
        count: parseInt(notification_sent_count),
        category: `${type} Sent`,
      },
      {
        date: created_at,
        count: parseInt(notification_received_count),
        category: `${type} Received`,
      },
    ],
    []
  );

  tempArr?.sort(
    ({ date: firstDate }, { date: secondDate }) => firstDate - secondDate
  );

  return tempArr;
};

export const kwipsSentFilterPayload = (dateArray) => {
  if (!dateArray.length) return {};

  return {
    start_timestamp: dateArray[0].toISOString(),
    end_timestamp: dateArray[1].endOf("day").minute(59).toISOString(),
  };
};

export const isTickerSaveAllowed = ({
  title1,
  image,
  bgColor,
  alwaysOnTop,
  position,
  headlineList,
}) => {
  if (
    title1 &&
    image &&
    bgColor &&
    alwaysOnTop &&
    position &&
    headlineList?.length
  )
    return true;
  return false;
};

export const tickerPayload = ({
  title1,
  title2,
  color,
  logoColor,
  bgColor,
  headlineList,
  image,
  id,
  alwaysOnTop,
  position,
}) => {
  let data = {
    title_1: title1,
    title_2: title2,
    image,
    bg_colour: bgColor,
    alwaysOnTop,
    position,
    color: color ? "#000000" : "#ffffff",
    logoColor,
    list: headlineList.map(({ message }, index) => ({
      message,
      position: index,
    })),
  };

  if (id) data.id = id;

  return data;
};

export const contentDataConversion = (data) => {
  let {
    title_1,
    title_2,
    bg_colour,
    image,
    list,
    alwaysOnTop,
    position,
    color,
    logoColor,
  } = data || {};

  if (data)
    return {
      title1: title_1,
      title2: title_2,
      image,
      color: color === "#ffffff" ? false : true,
      headline: null,
      bgColor: bg_colour,
      alwaysOnTop,
      position,
      logoColor,
      headlineList: list.map((item) => ({ ...item, id: idGenerator() })),
    };
  else
    return {
      title1: null,
      title2: null,
      headline: null,
      headlineList: null,
      image: null,
      bgColor: "#000000",
      alwaysOnTop: true,
      position: "top",
      color: false,
      logoColor: "#ffffff",
    };
};

export function generateTimeArray() {
  const times = [];

  for (let h = 0; h < 24; h++) {
    for (let m = 0; m < 60; m += 15) {
      const hour = h === 0 ? 12 : h <= 12 ? h : h - 12;
      const paddedHour = hour.toString().padStart(2, "0");
      const period = h < 12 ? "AM" : "PM";
      const time = `${paddedHour}:${m === 0 ? "00" : m} ${period}`;
      times.push(time);
    }
  }

  return times;
}

export function splitArrayIntoSegments(arr, segmentSize) {
  const result = [];
  for (let i = 0; i < arr.length; i += segmentSize) {
    result.push(arr.slice(i, i + segmentSize));
  }
  return result;
}

export function convertWeeklyOutput(input) {
  const day = input.day;
  const timeList = input.timeList;

  if (!day || !timeList.length) return {};

  const output = {
    key: 0,
    label: day,
    children: (
      <div className="weekly-time">
        {timeList.map((time) => (
          <div className="week-time" key={time}>
            <h6>{time}</h6>
          </div>
        ))}
      </div>
    ),
  };

  return output;
}

export function doesDateExists(array, targetDate) {
  return array?.some(
    (date) => date.format("DD-MM-YYYY") === targetDate.format("DD-MM-YYYY")
  );
}

export function manageEndDates(dateArray, targetDate) {
  let result = [...dateArray];

  const existingDateIndex = dateArray.findIndex(
    (date) => date.format("DD-MM-YYYY") === targetDate.format("DD-MM-YYYY")
  );

  if (existingDateIndex === -1) result = [...result, targetDate];
  else result.splice(existingDateIndex, 1);

  return result;
}

export function getUTCTime(date, time) {
  return (
    moment(date?.format("DD-MM-YYYY") + " " + time, "DD-MM-YYYY hh:mm a")
      ?.toISOString()
      ?.split("T") ?? []
  );
}

export function convertDateFormat(originalDate) {
  const [year, month, day] = originalDate?.split("-");
  const formattedDate = `${year}-${month}-${day}`;
  return formattedDate;
}

export function getDayJsDate(date, time) {
  return dayjs(moment.utc(date + "T" + time));
}

export function getLocalTime(utc) {
  return utc.format("hh:mm a");
}

export function tickerSchedulePayload({ participants, scheduleList, ...rest }) {
  let payload = {
    ...tickerPayload(rest),
    participants: participants?.map(({ key, type }) => ({ id: key, type })),
    schedule_list:
      scheduleList?.map(({ date, endDate, repeat, time }) => {
        let [utcDate, utcTime] = getUTCTime(date, time);
        return {
          date: utcDate,
          time: utcTime,
          repeat,
          day: date.day(),
          endDate: getUTCTime(endDate, time)[0],
        };
      }) ?? [],
  };

  return payload;
}

export function tickerScheduleState(obj) {
  if (obj?.participants && obj?.schedule_list)
    return {
      ...contentDataConversion(obj),
      participants:
        obj?.participants?.map(({ id, type }) => ({ key: id, type })) ?? [],
      scheduleList: obj?.schedule_list.map(
        ({ date, time, endDate, repeat }) => {
          let localDateTime = getDayJsDate(date, time);
          return {
            id: idGenerator(),
            date: localDateTime,
            time: getLocalTime(localDateTime),
            repeat: parseInt(repeat),
            endDate: endDate ? getDayJsDate(endDate, time) : null,
          };
        }
      ),
    };
  else
    return {
      ...contentDataConversion(obj),
      participants: [],
      scheduleList: [],
    };
}

export const modifiedParticipants = (arr = [], creditArr) => {
  let extraParticipants =
    arr?.filter(
      ({ key }) =>
        !creditArr.some(({ id: _id, key: _key }) => (_id ?? _key) === key)
    ) ?? [];

  return [
    ...creditArr.map(({ id, key, type }) => ({
      type,
      key: id ?? key,
      isDeletable: false,
    })),
    ...extraParticipants,
  ];
};

export const modifiedKwipData = (obj) => {
  return {
    ...obj,
    participants: modifiedParticipants(obj.participants, obj.creditTo),
  };
};

export const isVerfiedEmail = (string) => {
  let emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailPattern.test(string);
};

export const getGroupList = (group, teams, users) => {
  if (teams.length === 0 || users.length === 0 || group.length === 0) return [];

  return group.reduce((result, data) => {
    let { members } = teams.find(({ source_id }) => data.id === source_id) || {
      members: [],
    };

    let updatedMember = members.map(({ id: userId }) => {
      return users.find(({ id }) => id === userId);
    });

    return [...result, { ...data, members: updatedMember }];
  }, []);
};

export const getMemberInfo = (dataObj = {}, users) => {
  let { members } = dataObj;
  return (
    members?.map(({ id: userId }) => {
      return users.find(({ id }) => id === userId);
    }) ?? []
  );
};

export const filterTeamTypes = (arr, condition) => {
  return arr.filter(({ key }) => (key === "Salesforce" ? condition : true));
};

export const getFilterExpressionMappingText = (obj, list) => {
  const { field, value, operator } = obj;

  const fieldStructure = list.find((item) => item.key === field);

  if (fieldStructure) {
    const { label: fieldName, operators } = fieldStructure;

    // Find the operator label that matches the given operator
    const operatorLabelObj = operators.find((op) => op.key === operator);

    if (operatorLabelObj) {
      const operatorName = operatorLabelObj.label;

      return {
        fieldText: fieldName,
        operatorText: operatorName,
        valueText: value,
      };
    }
  }
};

export const getSelectedOptionValue = (id, arr) => {
  let tempArr = arr.map(({ options }) => options).flat();
  return tempArr.find(({ value }) => value === id);
};

export function dateArray() {
  const array = [];
  for (let i = 1; i <= 31; i++) {
    array.push({ label: `${i}`, value: `${i}` });
  }
  return array;
}

export function monthsArray() {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  return months.map((month) => ({
    label: month,
    value: month,
  }));
}

export function yearArray() {
  let currentYear = new Date().getFullYear();
  return [currentYear - 1, currentYear, currentYear + 1].map((year) => ({
    label: year,
    value: year,
  }));
}

export function getSettingPayload(data) {
  let payload = { ...data };

  if (
    new Date(payload.timezone) instanceof Date &&
    !isNaN(new Date(payload.timezone))
  ) {
    payload.timezone = null;
  }

  return payload;
}

export function determineService(baseURL) {
  const serviceMap = {
    accounts: "ACCOUNT",
    salesforce: "SALESFORCE",
    kwips: "KWIPS",
    tickers: "TICKERS",
  };

  // Iterate over the keys of the serviceMap
  for (const key in serviceMap) {
    if (baseURL.includes(key)) {
      return serviceMap[key];
    }
  }
  // Default service if none of the keys match
  return "KPIS";
}

function mergeCountsByTimeRange(data) {
  const timeRanges = [
    "12am-8am",
    "8am-12pm",
    "12pm-2pm",
    "2pm-5pm",
    "5pm-12am",
  ];
  let categories = new Set(
    (data.length
      ? data
      : [{ category: "Kwips Sent" }, { category: "Kwips Received" }]
    ).map((item) => item.category)
  );
  const summary = {};

  // Initialize summary with all combinations of time ranges and categories, count set to 0
  timeRanges.forEach((timeRange) => {
    categories.forEach((category) => {
      const key = `${timeRange}|${category}`;
      summary[key] = {
        date: timeRange,
        count: 0,
        category: category,
      };
    });
  });

  data.forEach((item) => {
    const hour = moment(item.date).hour(); // Use .hour() to get the hour directly
    const timeRange = getTimeRange(hour); // Assuming getTimeRange function is the one provided or similar
    const key = `${timeRange}|${item.category}`;

    if (summary[key]) {
      // This check is technically unnecessary now but kept for clarity
      summary[key].count += item.count;
    }
  });

  return Object.values(summary);
}

function getTimeRange(hour) {
  if (hour >= 0 && hour < 8) return "12am-8am";
  if (hour >= 8 && hour < 12) return "8am-12pm";
  if (hour >= 12 && hour < 14) return "12pm-2pm";
  if (hour >= 14 && hour < 17) return "2pm-5pm";
  return "5pm-12am"; // Covers the remaining hours
}

function aggregateSortAndCompleteDataByWeekday(data) {
  // Initialize the result with all days and categories set to zero counts
  const dayOrder = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];
  const categories = ["Kwips Sent", "Kwips Received"];
  let result = {};

  dayOrder.forEach((day) => {
    categories.forEach((category) => {
      const key = `${day}-${category}`;
      result[key] = { date: day, count: 0, category: category };
    });
  });

  // Helper function to get the day of the week from a date string
  function getDayOfWeek(dateString) {
    const date = new Date(dateString);
    const days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    return days[date.getUTCDay()];
  }

  // Aggregate data
  data.forEach(({ date, count, category }) => {
    const dayOfWeek = getDayOfWeek(date);
    const key = `${dayOfWeek}-${category}`;

    if (result[key]) {
      result[key].count += count;
    }
  });

  // Since the initial result structure already guarantees the order and inclusion of all categories and days,
  // we just need to convert it back into an array.
  return Object.values(result);
}

function aggregateDataByWeek(data) {
  // Helper function to format date as "DD MMM"
  function formatDate(date) {
    const options = { day: "numeric", month: "short" }; // e.g., "13 Mar"
    return date.toLocaleDateString("en-US", options);
  }

  // Calculate the end date (today) and start date (30 days ago)
  const endDate = new Date();
  endDate.setHours(23, 59, 59, 999); // Ensure end date is the very end of today
  const startDate = new Date(endDate.getTime() - 30 * 24 * 60 * 60 * 1000);

  // Generate week labels from start date to end date
  let current = new Date(startDate);
  const weekLabels = [];
  while (current <= endDate) {
    const weekStart = new Date(current);
    let weekEnd = new Date(weekStart);
    weekEnd.setDate(weekEnd.getDate() + 6);
    if (weekEnd > endDate) weekEnd = endDate;
    weekLabels.push(`${formatDate(weekStart)} - ${formatDate(weekEnd)}`);
    current.setDate(current.getDate() + 7);
  }

  // Aggregate data by the generated week labels
  const aggregatedData = data.reduce((acc, { date, count, category }) => {
    const dateObj = new Date(date);
    // Find the correct week label for this date
    const weekLabel = weekLabels.find((label) => {
      const [startStr, endStr] = label.split(" - ");
      const [startDay, startMonth] = startStr.split(" ");
      const [endDay, endMonth] = endStr.split(" ");
      const year = dateObj.getFullYear();
      const start = new Date(`${startMonth} ${startDay}, ${year}`);
      const end = new Date(`${endMonth} ${endDay}, ${year}`);
      return dateObj >= start && dateObj <= end;
    });

    if (weekLabel) {
      const key = `${weekLabel}_${category}`;
      if (!acc[key]) {
        acc[key] = { date: weekLabel, count: 0, category };
      }
      acc[key].count += count;
    }

    return acc;
  }, {});

  // Prepare the final aggregated data
  const finalData = [];
  weekLabels.forEach((label) => {
    (data.length
      ? data
      : [{ category: "Kwips Sent" }, { category: "Kwips Received" }]
    ).forEach(({ category }) => {
      const key = `${label}_${category}`;
      if (aggregatedData[key]) {
        finalData.push(aggregatedData[key]);
      } else {
        // Ensure every category is represented in each week, even if count is 0
        finalData.push({ date: label, count: 0, category });
      }
    });
  });

  // Remove duplicate entries (since we looped over all data categories for each label)
  return finalData.filter(
    (value, index, self) =>
      index ===
      self.findIndex(
        (t) => t.date === value.date && t.category === value.category
      )
  );
}

function filterDataByDateRange(dataArray, startDate, endDate) {
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  // Get start and end month indices
  let startMonthIndex = startDate.getMonth(); // This will give the month index directly
  let endMonthIndex = endDate.getMonth(); // This will give the month index directly

  const filteredData = dataArray.map((item) => {
    if (item.date === monthNames[startMonthIndex]) {
      return {
        date: `${item.date} ${startDate.getDate()}-${new Date(
          startDate.getFullYear(),
          startDate.getMonth() + 1,
          0
        ).getDate()}`,
        count: item.count,
        category: item.category,
      };
    } else if (item.date === monthNames[endMonthIndex]) {
      return {
        date: `${item.date} 1-${endDate.getDate()}`,
        count: item.count,
        category: item.category,
      };
    } else return item;
  });

  return filteredData;
}

function aggregateDataByQuarterMonth(data) {
  // Helper function to get month names
  function getMonthName(monthIndex) {
    return new Date(0, monthIndex, 1).toLocaleString("default", {
      month: "long",
    });
  }

  // Calculate the start month and end month
  const endDate = new Date(); // Today
  const startDate = new Date(new Date().setDate(endDate.getDate() - 90));
  let currentMonth = startDate.getMonth();
  const endMonth = endDate.getMonth();

  // Prepare the list of months involved in the last 90 days
  let months = [];
  while (currentMonth !== endMonth) {
    months.push(getMonthName(currentMonth));
    currentMonth = (currentMonth + 1) % 12;
  }
  months.push(getMonthName(endMonth)); // Include the end month

  const aggregatedData = data.reduce((acc, { date, count, category }) => {
    const dateObj = new Date(date);
    const monthName = getMonthName(dateObj.getMonth());
    const categoryKey = `${monthName}_${category}`;

    if (!acc[categoryKey]) {
      acc[categoryKey] = { date: monthName, count: 0, category };
    }

    acc[categoryKey].count += count;

    return acc;
  }, {});

  // Ensure all months and categories are represented
  const categories = [
    ...new Set(
      (data.length
        ? data
        : [{ category: "Kwips Sent" }, { category: "Kwips Received" }]
      ).map((item) => item.category)
    ),
  ];
  const finalData = [];

  categories.forEach((category) => {
    months.forEach((month) => {
      const key = `${month}_${category}`;
      if (aggregatedData[key]) {
        finalData.push(aggregatedData[key]);
      } else {
        finalData.push({ date: month, count: 0, category });
      }
    });
  });

  return filterDataByDateRange(finalData, startDate, endDate);
}

function formatDate(date) {
  return `${date.toLocaleString("default", {
    month: "short",
  })} ${date.getDate()}, ${date.getFullYear().toString().substr(-2)}`;
}

function aggregateDataBy90DayPeriodsWithYear(data) {
  // Helper function to add days to a date
  function addDays(date, days) {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  // Calculate the 90-day period start dates within the last 365 days
  const periodStartDates = [];
  let startDate = addDays(new Date(), -365); // Start from 365 days ago
  while (startDate <= new Date()) {
    periodStartDates.push(new Date(startDate)); // Add start date of each period
    startDate = addDays(startDate, 90); // Move to next period
  }

  // Aggregate data into the periods
  const aggregatedData = data.reduce((acc, { date, count, category }) => {
    const dateObj = new Date(date);
    // Find the period this date falls into
    const periodIndex = periodStartDates.findIndex((startDate, index) => {
      const endDate = addDays(startDate, 89);
      return dateObj >= startDate && dateObj <= endDate;
    });
    if (periodIndex === -1) return acc; // Skip if not found, though this shouldn't happen

    const periodStartDate = periodStartDates[periodIndex];
    const periodEndDate = addDays(periodStartDate, 89);
    const periodKey = `${formatDate(periodStartDate)} - ${formatDate(
      periodEndDate
    )}`;
    const categoryKey = `${periodKey}_${category}`;

    if (!acc[categoryKey]) {
      acc[categoryKey] = { date: periodKey, count: 0, category };
    }

    acc[categoryKey].count += count;

    return acc;
  }, {});

  // Convert aggregated data into a list, ensuring all categories are represented in each period
  const categories = [
    ...new Set(
      (data.length
        ? data
        : [{ category: "Kwips Sent" }, { category: "Kwips Received" }]
      ).map((item) => item.category)
    ),
  ];
  const finalData = [];

  periodStartDates.forEach((periodStartDate, index) => {
    const periodEndDate = addDays(periodStartDate, 89);
    const periodKey = `${formatDate(periodStartDate)} - ${formatDate(
      periodEndDate
    )}`;

    categories.forEach((category) => {
      const key = `${periodKey}_${category}`;
      if (aggregatedData[key]) {
        finalData.push(aggregatedData[key]);
      } else {
        // If no data for this category in this period, add a placeholder
        finalData.push({ date: periodKey, count: 0, category });
      }
    });
  });

  return finalData;
}

function customDataFormatter(data) {
  return data
    .sort((a, b) => new Date(a.date) - new Date(b.date))
    .map((item) => ({
      ...item,
      date: formatDate(new Date(item.date)),
    }));
}

export function evaluateDateDifference(dates) {
  if (dates.length === 0) return null;

  // Calculate the absolute difference in days
  const diffDays = dates[1].diff(dates[0], "days") + 1;

  // Return value based on difference
  if (diffDays < 1) return 0;
  if (diffDays <= 7) return 2;
  if (diffDays <= 30) return 3;
  if (diffDays <= 90) return 4;
  if (diffDays <= 365) return 5;

  return 6;
}

export const formatChartData = (data, filter, custom) => {
  switch (custom ?? filter) {
    case 0:
    case 1: {
      return mergeCountsByTimeRange(data ?? []);
    }
    case 2: {
      return aggregateSortAndCompleteDataByWeekday(data ?? []);
    }
    case 3: {
      return aggregateDataByWeek(data ?? []);
    }
    case 4: {
      return aggregateDataByQuarterMonth(data ?? []);
    }
    case 5: {
      return aggregateDataBy90DayPeriodsWithYear(data ?? []);
    }
    case 6: {
      return customDataFormatter(data ?? []);
    }
    default:
      return data;
  }
};

export const getMetricSavePayload = ({
  name,
  timeframes,
  type,
  currency,
  decimal_places,
  source,
  calculation,
  object,
  metricFieldValue,
  filterOptions,
  creditTo,
  timeframeFieldValue,
}) => {
  let obj = {
    name,
    type,
    timeframes,
    currency: type === "Currency" ? currency : null,
    decimal_places,
    source,
  };

  if (source === "Salesforce") {
    obj.metrics_metadata = {
      calculation,
      salesforce_object: object,
      field_name: metricFieldValue,
      field_criteria: filterOptions,
      creditTo,
      timeframe_field_value: timeframeFieldValue,
    };
  }

  return obj;
};

export const convertMetricesDataToModalData = (data) => {
  const { id, name, timeframes, type, currency, decimal_places, source } = data;

  if (source === "Salesforce") {
    const {
      metrics_metadata: {
        salesforce_object,
        field_name,
        field_criteria,
        calculation,
        creditTo,
        timeframe_field_value,
      },
    } = data;

    return {
      id,
      name,
      timeframes,
      type,
      currency,
      decimal_places,
      source,
      creditTo,
      calculation,
      object: salesforce_object,
      metricFieldValue: field_name,
      filterOptions: field_criteria,
      timeframeFieldValue: timeframe_field_value,
    };
  }
  return { id, name, timeframes, type, currency, decimal_places, source };
};

export const checkCalculation = (metricesList, id) => {
  const metric = metricesList.find((metric) => metric.id === id);
  return metric && metric.metrics_metadata.field_name === "record_count_only";
};

export const generateHoursArray = () => {
  var array = [];
  for (var i = 1; i <= 12; i++) {
    var obj = {
      label: i,
      key: i,
      value: i,
    };
    array.push(obj);
  }
  return array;
};

export const filterTableColumn = (column, timeframes) => {
  let updateTimeframes = [...timeframes, "name", "last_updated", "action"];
  return column.filter(({ dataIndex }) => updateTimeframes.includes(dataIndex));
};

export function formatDateAgo(inputDate) {
  const momentDate = moment.utc(inputDate).local();
  const now = moment();

  const diffInMinutes = now.diff(momentDate, "minutes");
  const diffInHours = now.diff(momentDate, "hours");
  const diffInDays = now.diff(momentDate, "days");
  const diffInWeeks = now.diff(momentDate, "weeks");
  const yearsDiff = now.diff(momentDate, "year");
  const monthsDiff = now.diff(momentDate, "month");

  if (diffInMinutes < 1) {
    return "just now";
  } else if (diffInMinutes < 60) {
    return `${diffInMinutes} ${diffInMinutes === 1 ? "minute" : "minutes"} ago`;
  } else if (diffInHours < 24) {
    return `${diffInHours} ${diffInHours === 1 ? "hour" : "hours"} ago`;
  } else if (diffInDays < 7) {
    return `${diffInDays} ${diffInDays === 1 ? "day" : "days"} ago`;
  } else if (diffInWeeks < 4) {
    return `${diffInWeeks} ${diffInWeeks === 1 ? "week" : "weeks"} ago`;
  } else if (yearsDiff > 0) {
    return `${yearsDiff} ${yearsDiff === 1 ? "year" : "years"} ago`;
  } else {
    return `${monthsDiff} ${monthsDiff === 1 ? "month" : "months"} ago`;
  }
}

export function metricPreviewTableData(
  userList,
  { leaderboard_metadata, type, currency }
) {
  return (
    userList?.reduce((result, { id, name }) => {
      let { via, last_updated, ...userRecord } = leaderboard_metadata[id] ?? {};

      if (via && last_updated) {
        let tempObj = Object.keys(userRecord).reduce((result, key) => {
          return { ...result, [key]: userRecord[key].value };
        }, {});

        return [
          ...result,
          {
            id,
            name,
            via,
            last_updated,
            type,
            currency,
            ...tempObj,
          },
        ];
      } else return result;
    }, []) ?? []
  );
}

export function leaderboardPreviewTableData(userList, metadata) {
  let tempArr = Object.values(metadata).flat();

  return tempArr?.reduce((result, dataObj) => {
    let [userId] = Object.keys(dataObj);
    let userDetail = userList?.find(({ id }) => id === userId);
    if (userDetail) {
      let value = dataObj[userId]?.value;
      return [...result, { id: userId, value, name: userDetail?.name ?? "" }];
    } else return result;
  }, []);
}

export function generateMetricPreviewPayload(data, { id }) {
  return {
    metric_id: id,
    leaderboard_metadata: data.reduce(
      (result, { id, isEditedViaWeb, via, last_updated, name, ...rest }) => {
        let userIdData = Object.keys(rest).reduce(
          (result, key) => ({ ...result, [key]: { value: rest[key] } }),
          {}
        );

        return {
          ...result,
          [id]: isEditedViaWeb
            ? { ...userIdData, isEditedViaWeb }
            : { ...userIdData, via, last_updated },
        };
      },
      {}
    ),
  };
}

export function isPreviewValueChangeAllowed(inputString, decimalPlaces) {
  // If inputString is empty, null, or undefined, return true
  if (!inputString && inputString !== 0) {
    return true;
  }

  // Split the inputString by the decimal point
  const parts = inputString.toString().split(".");

  // If there are more than 2 parts or any part is not a valid number, return false
  if (parts.length > 2 || parts.some((part) => isNaN(Number(part)))) {
    return false;
  }

  // If decimalPlaces is 0 and inputString contains a decimal point, return false
  if (decimalPlaces === 0 && parts.length > 1) {
    return false;
  }

  // If decimalPlaces is provided and the number of decimal places exceeds the specified value, return false
  if (parts[1] && parts[1].length > decimalPlaces) {
    return false;
  }

  // Otherwise, return true
  return true;
}

export function filterTimeframes(timeframes, allowedValues = []) {
  return timeframes.filter(({ key }) => allowedValues.includes(key));
}

export function getTitleSuggestion(name, selectedTimeframe) {
  let timeFrame = CONFIG.LEADERBOARD_TITLE_SUGGESTION.find(
    ({ key }) => key === selectedTimeframe
  );

  return `${name} - ${timeFrame.label}`;
}

export function filterMetricListForThreshold(list, type) {
  return list.filter(({ source }) => source === type);
}

export function extractNumberFromString(string) {
  let number = "";
  let foundNumber = false;

  for (let i = 0; i < string.length; i++) {
    const char = string.charAt(i);

    if ((char >= "0" && char <= "9") || char === ".") {
      if (!foundNumber) {
        foundNumber = true;
      }
      number += char;
    } else {
      if (foundNumber) {
        break;
      }
    }
  }
  return number;
}

export function getSpecialCharacterObject(type, currency) {
  switch (type) {
    case "Percentage": {
      return { startSpecial: "", endSpecial: "%" };
    }
    case "Currency": {
      return { startSpecial: currency.slice(0, 1), endSpecial: "" };
    }
    default: {
      return { startSpecial: "", endSpecial: "" };
    }
  }
}

export function extraFiltersForValueField(data, object) {
  switch (object) {
    case "Task":
      return data.filter(({ label }) => ["Call Duration"].includes(label));
    default:
      return data;
  }
}

export function extraFiltersForFilterCriteria(data, object) {
  switch (object) {
    case "Task":
      return sortArryOfObject(
        data.reduce((result, { label, ...rest }) => {
          let changeAbleFields = [
            { prev: "Description", new: "Comments" },
            { prev: "Due Date Only", new: "Due Date" },
          ];

          if (label) {
            let changeableObj = changeAbleFields.find(
              ({ prev }) => prev === label
            );
            if (changeableObj) label = changeableObj.new;
          }

          let isFieldAllowed = [
            "Call Duration",
            "Call Result",
            "Call Type",
            "Completed Date/Time",
            "Created Date",
            "Comments",
            "Due Date",
            "Priority",
            "Status",
          ].includes(label);

          return isFieldAllowed ? [...result, { label, ...rest }] : result;
        }, []),
        "label"
      );
    default:
      return data;
  }
}

export const generateMultiSelectTitle = (textArry) => {
  return (
    <div className="d-flex flex-column cursor pointer">
      {textArry.map((key) => (
        <span key={key}>{key}</span>
      ))}
    </div>
  );
};

export const getFilteredAudioFiles = (files, selectedFiles) => {
  if (selectedFiles.length === 5) return "skip";

  const filteredFiles = Array.from(files).filter((file) =>
    ["audio/mpeg", "audio/wav", "audio/x-wav", "audio/aac"].includes(file.type)
  );
  return filteredFiles.slice(0, 5 - selectedFiles.length);
};

export const getUploadMusicPayload = (files, user_id) => {
  return {
    user_id,
    music: files.map(({ file, label, audio }) => ({
      label,
      source: file ? audio.split(",")[1] : audio,
    })),
  };
};

export const extractNumberFromPx = (pixel) => {
  let [num] = pixel.split("px");
  return num;
};

export function canvasToBlob(canvas) {
  return new Promise((resolve, reject) => {
    if (!canvas) {
      reject(new Error("Canvas reference not found"));
      return;
    }
    canvas.toBlob((blob) => {
      if (blob) {
        resolve(blob);
      } else {
        reject(new Error("Canvas to Blob conversion failed"));
      }
    }, "image/jpeg"); // You can choose your preferred format
  });
}

export function blobToBase64(blob, callback) {
  const reader = new FileReader();
  reader.readAsDataURL(blob);
  reader.onload = function () {
    let dataUrl = reader.result;
    let base64 = dataUrl.split(",")[1]; // Split to get the Base64 part
    callback(base64);
  };
  reader.onerror = function (error) {
    console.error("Error: ", error);
  };
}

export const generateSessionTimeoutTime = (hours = 1) => {
  let localTimeStamp = moment().add(hours, "hours").toISOString();
  return moment(localTimeStamp).valueOf();
};

export const setSessionTimeout = (hours = 1) => {
  let prevSessionTimeout = localStorage.getItem("session_timeout");

  !prevSessionTimeout &&
    localStorage.setItem("session_timeout", generateSessionTimeoutTime(hours));
};
