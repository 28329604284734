import { Modal, Steps } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { closeModal } from "../../../redux-toolkit/slice/modal";
import CONFIG from "../../../utils/config";
import StepButton from "./components/stepButton";
import {
  tickerSchedulePayload,
  tickerScheduleState,
} from "../../../utils/functions";
import {
  editTicker,
  saveTicker,
  sendNow,
} from "../../../redux-toolkit/slice/ticker/asyncThunk";

function TickerScheduleModal({ tickerData }) {
  const { TICKER_SCHEDULE_STEPS } = CONFIG;

  const dispatch = useDispatch();

  const isFirstRenderDone = useRef(false);

  const [currentStep, setCurrent] = useState(0);
  const [isEdited, setIsEdited] = useState(false);
  const [scheduleData, setScheduleData] = useState(() =>
    tickerScheduleState(tickerData)
  );

  const handleSave = () => {
    const payload = {
      ...tickerData,
      ...tickerSchedulePayload(scheduleData),
    };

    if (isEdited) {
      payload.id
        ? dispatch(
            editTicker({
              payload,
              callback: () => {
                handleCloseModal();
              },
            })
          )
        : dispatch(saveTicker({ payload, callback: handleCloseModal }));
    } else handleCloseModal();
  };

  const next = () => {
    switch (currentStep) {
      case 2: {
        handleSave();
        break;
      }
      default:
        setCurrent(currentStep + 1);
    }
  };

  const prev = () => {
    setCurrent(currentStep - 1);
  };

  const sendNowTicker = () => {
    const payload = {
      ...tickerData,
      ...tickerSchedulePayload(scheduleData),
    };

    dispatch(sendNow({ payload }));
  };

  const handleCloseModal = () => {
    dispatch(closeModal());
  };

  const getModalTitle = () => {
    switch (currentStep) {
      case 0:
        return tickerData ? tickerData.name : "Create Ticker";
      case 1:
        return "Select Recipients";
      case 2:
        return "Schedule Ticker";
      default:
        return "";
    }
  };

  useEffect(() => {
    if (isFirstRenderDone.current) setIsEdited(true);
    else isFirstRenderDone.current = true;
  }, [scheduleData]);

  return (
    <Modal
      key={"Create Ticker"}
      title={getModalTitle()}
      centered
      className="common-modal ticker-modal"
      open={true}
      width={1300}
      onCancel={handleCloseModal}
      footer={
        <StepButton
          sendNow={sendNowTicker}
          handleCancel={handleCloseModal}
          scheduleData={scheduleData}
          currentStep={currentStep}
          isStepLoading={false}
          prev={prev}
          next={next}
        />
      }
    >
      <Steps
        current={currentStep}
        percent={60}
        labelPlacement="vertical"
        items={TICKER_SCHEDULE_STEPS({})}
      />
      <div className="steps-content ticker-content">
        {
          TICKER_SCHEDULE_STEPS({
            tickerData,
            scheduleData,
            setIsEdited,
            setScheduleData,
          })[currentStep]?.content
        }
      </div>
    </Modal>
  );
}

export default TickerScheduleModal;
