import {
  Avatar,
  Button,
  Checkbox,
  Col,
  Form,
  Row,
  Select,
  Tooltip,
} from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { MinusCircleFilled } from "@ant-design/icons";
import {
  firstLetter,
  getDetails,
  groupOptions,
  modifiedParticipants,
  optionCreator,
} from "../../../../utils/functions";

function StepTwo({
  kwipObject,
  usersList,
  teamsList,
  setKwipObject,
  setIsEdited,
}) {
  const [allUsersSelected, setAllUsersSelected] = useState(false);
  const [creditTo, setCreditTo] = useState({
    id: null,
    type: null,
  });

  const handleAddCredit = () => {
    const isAllowed = creditTo.id;
    if (isAllowed) {
      setKwipObject((prev) => {
        const newCreditTo = prev.creditTo
          ? [...prev.creditTo, creditTo]
          : [creditTo];
        return {
          ...prev,
          creditTo: newCreditTo,
          participants: modifiedParticipants(prev.participants, newCreditTo),
        };
      });
      setCreditTo({ type: null, id: null });
      setIsEdited(true);
    }
  };

  const handleCreditDelete = (deletedKey, index) => {
    setIsEdited(true);
    setKwipObject((prev) => ({
      ...prev,
      creditTo: prev.creditTo.filter((_, idx) => idx !== index),
      participants: prev.participants.filter(({ key }) => key !== deletedKey),
    }));
  };

  const handleCreditToChange = (value, key, type) => {
    let obj = type ? { type, [key]: value } : { [key]: value };
    setCreditTo({ ...creditTo, ...obj });
  };

  const handleCheckbox = ({ target: { checked } }) => {
    setAllUsersSelected(checked);
    const { creditTo } = kwipObject;
    let filteredTeamMembers =
      creditTo?.filter(({ type }) => type === "team") ?? [];
    if (checked)
      setKwipObject((prev) => {
        let newCreditTo = [
          ...usersList.map(({ id }) => ({ id, type: "user" })),
          ...filteredTeamMembers,
        ];
        return {
          ...prev,
          creditTo: newCreditTo,
          participants: modifiedParticipants(prev.participants, newCreditTo),
        };
      });
    else
      setKwipObject((prev) => {
        let newCreditTo = filteredTeamMembers;
        return {
          ...prev,
          creditTo: newCreditTo,
          participants: modifiedParticipants(prev.participants, newCreditTo),
        };
      });
  };

  const isCheckboxIndeterminate = useMemo(() => {
    const { creditTo } = kwipObject;
    let selectedUsers = creditTo?.filter(({ type }) => type === "user") ?? [];
    return (
      selectedUsers?.length > 0 && selectedUsers?.length < usersList?.length
    );
  }, [kwipObject, usersList]);

  useEffect(() => {
    if (isCheckboxIndeterminate) setAllUsersSelected(false);
  }, [isCheckboxIndeterminate]);

  useEffect(() => {
    const { creditTo } = kwipObject;
    let userMembers = creditTo?.filter(({ type }) => type === "user") ?? [];
    if (userMembers?.length === usersList?.length) setAllUsersSelected(true);
  }, [kwipObject, usersList]);

  return (
    <Form name="form_item_path" layout="vertical" onFinish={() => {}}>
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={16} md={12} lg={12} xl={12}>
          <Form.Item
            label="Users to Celebrate"
            className="mb-0 no-form-item"
            children={null}
            required
          />
          <Row gutter={[16, 16]} className="mb-2">
            <Col xs={24}>
              <Select
                showSearch
                placeholder="Select Users or Teams"
                popupMatchSelectWidth={true}
                style={{ width: "100%" }}
                value={creditTo.id}
                options={groupOptions(
                  [
                    { label: "Users", key: "user" },
                    { label: "Teams", key: "team" },
                  ],
                  [
                    optionCreator(usersList, "name", "id"),
                    optionCreator(teamsList, "name", "id"),
                  ],
                  kwipObject?.creditTo ?? []
                )}
                onSelect={(key, { type }) =>
                  handleCreditToChange(key, "id", type)
                }
              />
            </Col>
            {/* <Col xs={2} className="d-flex">
              <Tooltip
                title={
                  allUsersSelected ? "Deselect all Users" : "Select all Users"
                }
              >
                <Checkbox
                  indeterminate={isCheckboxIndeterminate}
                  checked={allUsersSelected}
                  onChange={handleCheckbox}
                />
              </Tooltip>
            </Col> */}
            <Col className="plus-icon mb-2" sm={4} md={4} xl={2}>
              <Button className="primary-solid" onClick={handleAddCredit}>
                Add
              </Button>
            </Col>
          </Row>
        </Col>
        <Col xs={20} sm={16} md={12} lg={12} xl={12}>
          <div className="credit-list-scroll kwip-credit-scroll">
            {kwipObject?.creditTo?.map(({ id }, index) => {
              let { name, profile_photo } = getDetails(
                [...(usersList ?? []), ...(teamsList ?? [])],
                "id",
                id
              );
              let image = Array.isArray(profile_photo)
                ? profile_photo?.find(({ is_active }) => is_active)?.image
                : profile_photo;
              return (
                <div className="credit-list creditTo" key={id}>
                  <Avatar
                    size={30}
                    src={image}
                    children={image ? null : firstLetter(name)}
                  />
                  <span className="creditTo_field">{name}</span>
                  <span className="close">
                    <MinusCircleFilled
                      onClick={() => handleCreditDelete(id, index)}
                    />
                  </span>
                </div>
              );
            })}
          </div>
        </Col>
      </Row>
    </Form>
  );
}

export default StepTwo;
