import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Col, Row, Table } from "antd";
import { useDispatch, useSelector } from "react-redux";

import { closeModal, openModal } from "../../redux-toolkit/slice/modal";
import CONFIG from "../../utils/config";
import {
  deleteNotification,
  fetchNotifications,
  fetchObjects,
} from "../../redux-toolkit/slice/notification/asyncThunk";
import notificationDataSelector from "../../redux-toolkit/selectors/notification";
import {
  columnAndComponentFunctionReference,
  notificationTableData,
  withKeyDataSet,
} from "../../utils/functions";
import layoutDataSelector from "../../redux-toolkit/selectors/layoutData";
import { useNavigate } from "react-router-dom";
import metricesDataSelector from "../../redux-toolkit/selectors/metricesData";
import { fetchMetrices } from "../../redux-toolkit/slice/metrices/asyncThunk";

function NotificationList() {
  const {
    ROUTES: { SALESFORCE_INTEGRATION },
    MODALS: { NOTIFICATION, CREATE_NOTIFICATION_ALERT, CONFIRM },
    LABELS: { ID },
    NOTIFICATION_LIST_COLUMN,
  } = CONFIG;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { objects, notificationList } = notificationDataSelector(useSelector);
  const { metricesList } = metricesDataSelector(useSelector);

  const { adminDetails } = layoutDataSelector(useSelector);

  const { is_salesforce_authenticated } = adminDetails || {};

  const [currentPage] = useState(1);

  const handleCreateNotification = () => {
    if (!is_salesforce_authenticated) {
      dispatch(
        openModal({
          key: CONFIRM.key,
          props: {
            title: "Salesforce Authentication Required",
            content:
              "To access this feature, please authenticate with Salesforce. Confirm to redirect to salesforce integration page.",
            handleConfirm: () => {
              navigate(SALESFORCE_INTEGRATION.path);
              dispatch(closeModal());
            },
          },
        })
      );
      return;
    }

    if (!objects) {
      dispatch(
        openModal({
          key: CONFIRM.key,
          props: {
            title: "Object Not Fetched",
            content:
              "Salesforce Objects were failed to fetch. Please refersh to fetch the Objects again.",
            handleConfirm: () => {
              window.location.reload();
            },
          },
        })
      );
      return;
    }

    is_salesforce_authenticated
      ? dispatch(openModal({ key: NOTIFICATION.key, props: {} }))
      : dispatch(openModal({ key: CREATE_NOTIFICATION_ALERT.key, props: {} }));
  };

  const handleEditNotification = useCallback(
    (notificationData) => {
      dispatch(
        openModal({ key: NOTIFICATION.key, props: { notificationData } })
      );
    },
    [NOTIFICATION, dispatch]
  );

  const handleDeleteNotification = useCallback(
    (payload) => {
      dispatch(deleteNotification({ payload }));
    },
    [dispatch]
  );

  const tableColumn = useMemo(
    () =>
      columnAndComponentFunctionReference(NOTIFICATION_LIST_COLUMN, {
        notificationList,
        currentPage,
        metricesList,
        handleEditNotification,
        handleDeleteNotification,
      }),
    [
      NOTIFICATION_LIST_COLUMN,
      currentPage,
      metricesList,
      handleDeleteNotification,
      handleEditNotification,
      notificationList,
    ]
  );

  useEffect(() => {
    !metricesList && dispatch(fetchMetrices());
  }, [dispatch, metricesList]);

  useEffect(() => {
    !notificationList && dispatch(fetchNotifications());
  }, [dispatch, notificationList]);

  useEffect(() => {
    is_salesforce_authenticated && !objects && dispatch(fetchObjects());
  }, [dispatch, objects, is_salesforce_authenticated]);

  return (
    <div className="userContainer">
      <Row className="filter-head">
        <Col span={12}>
          <h2>THRESHOLDS</h2>
        </Col>
        <Col span={12} className="filter">
          <Button
            className="primary-outline notification"
            onClick={handleCreateNotification}
          >
            Add Threshold
          </Button>
        </Col>
      </Row>
      <div>
        <Table
          className="cmn-table"
          columns={tableColumn}
          dataSource={
            withKeyDataSet(ID, notificationTableData(notificationList)) ?? []
          }
          scroll={{ y: "calc(70vh - 60px)" }}
          pagination={false}
        />
      </div>
    </div>
  );
}

export default NotificationList;
