import React, { useEffect, useState } from "react";
import { Col, Form } from "antd";
import greenplay from "../../../../assets/images/play-circle.png";
import greenpause from "../../../../assets/images/pause-circle.png";
import musicPlay from "../../../../assets/images/music-play.png";
import music from "../../../../assets/images/music.png";
import { CaretDownOutlined } from "@ant-design/icons";
import ReactHowler from "react-howler";
import CONFIG from "../../../../utils/config";
import UploadSongModal from "../../uploadSong";

function SongList({
  user_id,
  music_settings,
  selectedMusic,
  setSelectedMusic,
  setIsEdited,
  musicList,
}) {
  const { PRELOADED_MUSIC } = CONFIG;

  const [isListOpen, setIsListOpen] = useState(false);
  const [uploadModal, setUploadModal] = useState(null);

  const handleUpload = () => {
    setUploadModal(true);
  };

  const handlePlay = (event) => {
    event.stopPropagation();
    setSelectedMusic({ ...selectedMusic, playing: !selectedMusic.playing });
  };

  const handleStop = () => {
    setSelectedMusic({ ...selectedMusic, playing: false });
  };

  const handleListOpen = () => {
    setIsListOpen(!isListOpen);
  };

  const handleMusicSelect = (data) => {
    setSelectedMusic({ ...data, playing: false });
    setIsListOpen(false);
    setIsEdited(true);
  };

  const handleListClose = () => {
    setIsListOpen(false);
  };

  useEffect(() => {
    if (music_settings && musicList?.length) {
      let musicObj = musicList.find(
        (music) => music.source === music_settings.music
      );
      setSelectedMusic({ ...musicObj, playing: false });
    }
  }, [music_settings, musicList, setSelectedMusic]);

  return (
    <Col sm={15} className="mb-3">
      {isListOpen && <div className="overlay" onClick={handleListClose}></div>}
      <Form.Item label="Hype Song" className="mb-0 p-0 no-form-item" />
      <h6 className="song-sub-title">
        The default song you want to play when people view your Kwips
      </h6>
      {selectedMusic?.source && (
        <ReactHowler
          src={selectedMusic.source}
          playing={selectedMusic.playing}
          preload
          onEnd={handleStop}
        />
      )}
      <div className="hype-song-dropdown">
        <div
          className="hype-song-dropdown-input cursor-pointer"
          onClick={handleListOpen}
        >
          <div className="hype-song-dropdown-input-text">
            {selectedMusic && (
              <img
                src={selectedMusic?.playing ? greenpause : greenplay}
                alt="play"
                onClick={handlePlay}
              />
            )}
            <span>{selectedMusic?.label}</span>
          </div>
          <span className="caret-down-icon">
            <CaretDownOutlined />
          </span>
        </div>
        {isListOpen && (
          <div className="hype-song-menu-outer">
            <div className="hype-song-menu">
              {(musicList?.length ? musicList : PRELOADED_MUSIC)?.map(
                ({ label, source }) => {
                  const isSongActive = selectedMusic?.label === label;
                  return (
                    <div
                      className={`hype-song-menu-item ${
                        isSongActive ? "active" : ""
                      }`}
                      key={source}
                      onClick={() => handleMusicSelect({ label, source })}
                    >
                      {isSongActive ? (
                        <img src={musicPlay} alt="musicPlay" />
                      ) : (
                        <img src={music} alt="music" />
                      )}
                      {label}
                    </div>
                  );
                }
              )}
            </div>
            <div className="upload-song" onClick={handleUpload}>
              Manage Song List...
            </div>
          </div>
        )}
      </div>
      {uploadModal && (
        <UploadSongModal
          user_id={user_id}
          musicList={musicList}
          handleCloseModal={() => setUploadModal(null)}
        />
      )}
    </Col>
  );
}

export default SongList;
