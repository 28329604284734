import { createSlice } from "@reduxjs/toolkit";

import INITIAL_STATE from "../../initialState";

export const modalSlice = createSlice({
  name: "modal",
  initialState: INITIAL_STATE.MODAL,
  reducers: {
    openModal: (state, { payload }) => {
      state.key = payload.key;
      state.props = payload.props;
    },
    closeModal: (state, { payload }) => {
      state.key = null;
      state.props = null;
      if (payload?.callback) payload.callback();
    },
  },
});

export const { openModal, closeModal } = modalSlice.actions;

export default modalSlice.reducer;
