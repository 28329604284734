import React from "react";
import CONFIG from "../../../utils/config";
import { useDispatch } from "react-redux";
import { closeModal } from "../../../redux-toolkit/slice/modal";
import { Button, Modal, Form, Col, Row, Collapse } from "antd";
import { CaretRightOutlined } from "@ant-design/icons";

function PreviewTeam({ members, dataSet }) {
  let profileDetails = dataSet.reduce(
    (result, data) => ({ ...result, [data.id]: data }),
    {}
  );

  const {
    MODALS: { PREVIEW_TEAM },
  } = CONFIG;

  const dispatch = useDispatch();

  const handleCloseModal = () => {
    dispatch(closeModal());
  };

  const getItems = () =>
    members.reduce((result, { type, id }) => {
      if (type === "group") {
        let { name, profile_photo, members } = profileDetails[id];
        return [
          ...result,
          {
            key: id,
            label: (
              <h3>
                <img src={profile_photo} alt="profile" /> {name}
              </h3>
            ),
            children: members.length ? (
              members?.map(({ name, profile_photo }) => {
                let activeProfile = profile_photo?.find(
                  ({ image, is_active }) => is_active && image
                );
                return (
                  <h3 key={name}>
                    <img src={activeProfile?.image} alt="profile" /> {name}
                  </h3>
                );
              })
            ) : (
              <span className="no-member">
                This group doesn't contain any members
              </span>
            ),
          },
        ];
      } else return result;
    }, []);

  return (
    <Modal
      key={PREVIEW_TEAM}
      centered
      className="common-modal teams"
      open={true}
      width={670}
      onCancel={handleCloseModal}
      footer={
        <div className="footer-btns">
          <Button className="primary-outline" onClick={handleCloseModal}>
            Cancel
          </Button>
        </div>
      }
    >
      <Form name="form_item_path" layout="vertical">
        <Row className="teams-row">
          <Col xs={12}>
            <h2>Users</h2>
            <div className="team-list">
              {members?.reduce((result, { type, id }) => {
                if (type === "user") {
                  let { name, profile_photo } = profileDetails[id];
                  let activeProfile = profile_photo.find(
                    ({ is_active }) => is_active
                  );
                  return [
                    ...result,
                    <h3 key={id}>
                      <img src={activeProfile.image} alt="profile" />
                      {name}
                    </h3>,
                  ];
                } else return result;
              }, [])}
            </div>
          </Col>
          <Col xs={12}>
            <h2>Groups</h2>
            <Collapse
              bordered={false}
              className="team-list"
              expandIconPosition="end"
              expandIcon={({ isActive }) => (
                <CaretRightOutlined rotate={isActive ? 90 : 0} />
              )}
              items={getItems()}
            />
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

export default PreviewTeam;
