import { createSlice } from "@reduxjs/toolkit";

import INITIAL_STATE from "../../initialState";
import {
  cloneTemplate,
  deleteTemplate,
  fetchTemplate,
  updateTemplate,
} from "./asyncThunk";

export const templateSlice = createSlice({
  name: "template",
  initialState: INITIAL_STATE.TEMPLATES,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchTemplate.fulfilled, (state, { payload }) => {
      state.error = false;
      state.templates = payload;
    });

    builder.addCase(fetchTemplate.rejected, (state, { error }) => {
      state.error = error;
    });

    builder.addCase(cloneTemplate.fulfilled, (state, { payload }) => {
      state.error = false;
      state.templates.push(payload);
    });

    builder.addCase(cloneTemplate.rejected, (state, { error }) => {
      state.error = error;
    });

    builder.addCase(updateTemplate.fulfilled, (state, { payload }) => {
      state.error = false;
      let editedIndex = state.templates.findIndex(
        ({ id }) => id === payload.id
      );

      state.templates[editedIndex] = payload;
    });

    builder.addCase(updateTemplate.rejected, (state, { error }) => {
      state.error = error;
    });

    builder.addCase(deleteTemplate.fulfilled, (state, { payload }) => {
      state.error = false;
      let editedIndex = state.templates.findIndex(({ id }) => id === payload);

      state.templates.splice(editedIndex, 1);
    });

    builder.addCase(deleteTemplate.rejected, (state, { error }) => {
      state.error = error;
    });
  },
});

export default templateSlice.reducer;
