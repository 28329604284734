import Icon from "../../components/icon";
import { ReactComponent as List } from "../../assets/images/list.svg";
import { ReactComponent as User } from "../../assets/images/user.svg";
import { ReactComponent as Dashboard } from "../../assets/images/dashboard.svg";
import { ReactComponent as Team } from "../../assets/images/teams.svg";
import { ReactComponent as Template } from "../../assets/images/template.svg";
import { ReactComponent as Ticker } from "../../assets/images/ticker.svg";
import { ReactComponent as Trigger } from "../../assets/images/trigger.svg";
import { ReactComponent as Setting } from "../../assets/images/settings.svg";
import { ReactComponent as Leaderboard } from "../../assets/images/leaderboard.svg";

import { ReactComponent as Accelarator } from "../../assets/images/tickerLogo/accelrator.svg";
import { ReactComponent as DollarBag } from "../../assets/images/tickerLogo/dollarBag.svg";
import { ReactComponent as Cheers } from "../../assets/images/tickerLogo/cheers.svg";
import { ReactComponent as Clap } from "../../assets/images/tickerLogo/clap.svg";
import { ReactComponent as DartBoard } from "../../assets/images/tickerLogo/dartBoard.svg";
import { ReactComponent as Finger } from "../../assets/images/tickerLogo/finger.svg";
import { ReactComponent as FirstPrize } from "../../assets/images/tickerLogo/firstPrize.svg";
import { ReactComponent as Flag } from "../../assets/images/tickerLogo/flag.svg";
import { ReactComponent as FlyingStar } from "../../assets/images/tickerLogo/flyingStar.svg";
import { ReactComponent as Gift } from "../../assets/images/tickerLogo/gift.svg";
import { ReactComponent as Heart } from "../../assets/images/tickerLogo/heart.svg";
import { ReactComponent as Like } from "../../assets/images/tickerLogo/like.svg";
import { ReactComponent as PrizePodium } from "../../assets/images/tickerLogo/prizePodium.svg";
import { ReactComponent as TwoMen } from "../../assets/images/tickerLogo/twoMen.svg";
import { ReactComponent as Trophy } from "../../assets/images/tickerLogo/trophy.svg";
import { ReactComponent as ThreeMen } from "../../assets/images/tickerLogo/threeMen.svg";
import { ReactComponent as StarPrize } from "../../assets/images/tickerLogo/starPrize.svg";
import { ReactComponent as StarMedal } from "../../assets/images/tickerLogo/starMedal.svg";

import {
  MailOutlined,
  AreaChartOutlined,
  InsertRowBelowOutlined,
  CheckCircleOutlined,
  EyeOutlined,
  AuditOutlined,
  SisternodeOutlined,
  AppstoreAddOutlined,
} from "@ant-design/icons";

import alert from "../../assets/images/alert.png";
import eye from "../../assets/images/eye.svg";
import tick from "../../assets/images/tick.png";
import info from "../../assets/images/info.png";
import sync from "../../assets/images/sync.svg";
import NotificationModal from "../../modal/components/notification";
import TeamModal from "../../modal/components/team";
import AvatarList from "../../components/avatar";
import { Avatar, Tag, Tooltip } from "antd";

import edit from "../../assets/images/edit.png";
import trash from "../../assets/images/delete.png";
import lock from "../../assets/images/lock.png";
import unlock from "../../assets/images/unlock.png";
import FirstStep from "../../modal/components/notification/components/firstStep";
import TeamsFirstStep from "../../modal/components/team/components/firstStep";
import SecondStep from "../../modal/components/notification/components/secondStep";
import TeamsSecondStep from "../../modal/components/team/components/secondStep";
import TeamsThirdStep from "../../modal/components/team/components/stepThree";
import ForthStep from "../../modal/components/notification/components/forthStep";
import SyncIntervalModal from "../../modal/components/syncInterval";
import UserModal from "../../modal/components/user";
import GroupModal from "../../modal/components/group";
import SalesforceUserDelete from "../../modal/components/userDelete";
import ErrorModal from "../../modal/components/error";
import PreviewTeam from "../../modal/components/previewTeam";
import ConfirmModal from "../../modal/components/confirm";
import {
  extractNumberFromString,
  formatDateAgo,
  getSpecialCharacterObject,
  uppercaseFirstLetter,
} from "../functions";
import CreateNotificationAlert from "../../modal/components/createNotificationAlert";
import UserSetting from "../../modal/components/userSetting";
import OneUser from "../../modal/components/templatePreview/components/oneUser";
import TwoUser from "../../modal/components/templatePreview/components/twoUser";
import ThreeUser from "../../modal/components/templatePreview/components/threeUser";
import FourUser from "../../modal/components/templatePreview/components/fourUser";
import FiveUser from "../../modal/components/templatePreview/components/fiveUser";
import TemplatePreview from "../../modal/components/templatePreview";
import landscape from "../../assets/images/landscape.png";
import liveStatus from "../../assets/images/live-status.png";
import TemplateEdit from "../../modal/components/templateEdit";
import KwipModal from "../../modal/components/kwip";
import CreateTemplateModal from "../../modal/components/templateCreate";
import StepOne from "../../modal/components/kwip/components/stepOne";
import StepTwo from "../../modal/components/kwip/components/stepTwo";
import StepThree from "../../modal/components/kwip/components/stepThree";
import TickerModal from "../../modal/components/ticker";
import TickerScheduleModal from "../../modal/components/tickerSchedule";
import TickerParticipants from "../../modal/components/tickerSchedule/components/tickerParticipants";
import TickerScheduler from "../../modal/components/tickerSchedule/components/tickerScheduler";
import StepFour from "../../modal/components/kwip/components/stepFour";
import MetricesModal from "../../modal/components/metrices";
import FirstMetricesStep from "../../modal/components/metrices/components/firstStep";
import SecondMetricesStep from "../../modal/components/metrices/components/secondStep";
import LeaderboardModal from "../../modal/components/leaderboard";
import MetricPreview from "../../modal/components/metricsPreview";
import LeaderboardPreview from "../../modal/components/leaderboardPreview";
import RestrictDelete from "../../modal/components/restrictDelete";

import FirstLeaderboardStep from "../../modal/components/leaderboard/components/firstStep";
import SecondLeaderboardStep from "../../modal/components/leaderboard/components/secondStep";

const {
  REACT_APP_REDIRECT_URI,
  REACT_APP_SALESFORCE_CONSUMER_KEY,
  REACT_APP_INTEGRATION_REDIRECT_URI,
} = process.env;

const CONFIG = {
  ROUTES: {
    LOGIN: { path: "/" },
    PROFILE: { path: "/profile" },
    SETTING: { path: "/setting" },
    FORGET_PASSWORD: { path: "/forget" },
    AUTH_CALLBACK: { path: "/salesforce-auth-callback" },
    USERS: { path: "/users" },
    TEAMS: { path: "/teams" },
    NOTIFICATION_LIST: { path: "/kwips" },
    SIGN_UP: { path: "/signup" },
    GROUPS: { path: "/groups" },
    TRIGGERS: { path: "/threshold" },
    APPS: { path: "/apps" },
    REDIRECT: { path: "/redirect" },
    SALESFORCE_INTEGRATION: {
      path: "/integration",
      key: "integration",
    },
    TEMPLATE: {
      path: "/template",
    },
    NOT_FOUND: {
      path: "*",
    },
    UNAUTHORISED: {
      path: "/unauthorised",
    },
    DASHBOARD: {
      path: "/dashboard",
    },
    PASSWORD: {
      path: "/password",
    },
    TICKER: {
      path: "/ticker",
    },
    BILLING: {
      path: "/billing",
    },
    METRICES: {
      path: "/metrics",
    },
    LEADERBOARD: {
      path: "/leaderboard",
    },
  },
  ALLOWED_ACCESS: {
    user: ["dashboard"],
    super_admin: [
      "users",
      "teams",
      "kwips",
      "ticker",
      "integration",
      "dashboard",
      "billing",
      "threshold",
      "setting",
      "metrics",
      "leaderboard",
      "scorecards",
      "apps",
    ],
    admin: [
      "users",
      "teams",
      "kwips",
      "ticker",
      "dashboard",
      "threshold",
      "setting",
      "metrics",
      "leaderboard",
    ],
  },
  MENU_ALLOWED_ACCESS: {
    user: ["logout"],
    super_admin: ["support", "profile", "logout"],
    admin: ["logout"],
  },
  AUTHORISATION_URL: (redirectTo) =>
    `https://login.salesforce.com/services/oauth2/authorize?response_type=code&client_id=${REACT_APP_SALESFORCE_CONSUMER_KEY}&redirect_uri=${
      redirectTo === "integration"
        ? REACT_APP_INTEGRATION_REDIRECT_URI
        : REACT_APP_REDIRECT_URI
    }`,
  API_URLS: {
    OPEN_STRIPE_ACCOUNT_PORTAL: "stripe/session/create/",
    SEND_ADMIN_TOKEN_API: "oauth2/token/",
    GET_NEW_ACCESS_TOKEN_API: "oauth/refresh/",
    FETCH_ADMIN_DETAILS: "user/connection/data/",
    FETCH_SALESFORCE_ORG_DETAILS: "organisation/",
    FETCH_ALL_USERS: "users/",
    FETCH_ALL_TEAMS: "teams/",
    CREATE_TEAM: "teams/",
    DELETE_TEAM: "teams/",
    UPDATE_TEAM: "teams/",
    FETCH_NOTIFICATION_OBJECTS: "objects/",
    CREATE_NOTIFICATION: "thresholds/",
    FETCH_NOTIFICATION_FIELDS: "objects/fields/",
    FETCH_NOTIFICATIONS: "thresholds/",
    EDIT_NOTIFICATION: "thresholds/",
    DELETE_NOTIFICATION: "thresholds/",
    KWIPS_DATA: "notify/kwiplogs/",
    SYNC_SALESFORCE_GROUPS: "groups/sync/",
    FETCH_GROUPS: "groups/list/",
    SIGN_UP_ADMIN: "user/admin/create/",
    LOGIN_ADMIN_WITH_EMAIL: "oauth/token/",
    CREATE_USER: "users/",
    EDIT_USER: "users/",
    DELETE_USER: "users/",
    SEND_MAIL: "user/",
    FETCH_FILTERED_USERS: "users/fetch/",
    CREATE_GROUP: "groups/",
    FETCH_CREATED_GROUPS: "groups/",
    EDIT_GROUP: "groups/",
    DELETE_GROUP: "groups/",
    SYNC_ALL: "teams/groups/sync/",
    SYNC_INTERVAL: "interval/",
    FETCH_SYNC_INTERVAL: "interval/",
    FETCH_TEMPLATE: "templates/",
    UPDATE_TEMPLATE: "templates/",
    CREATE_TEMPLATE: "templates/",
    DELETE_TEMPLATE: "templates/",
    FETCH_KWIPS: "",
    CREATE_KWIP: "",
    EDIT_KWIP: "",
    DELETE_KWIP: "",
    FETCH_METRICES: "metrics/",
    CREATE_METRICES: "metrics/",
    EDIT_METRICES: "metrics/",
    DELETE_METRICES: "metrics/",
    EDIT_METRIC_PREVIEW: "leaderboard/modify/",
    FETCH_LEADERBOARD: "leaderboard/",
    CREATE_LEADERBOARD: "leaderboard/",
    EDIT_LEADERBOARD: "leaderboard/",
    DELETE_LEADERBOARD: "leaderboard/",
    DASHBOARD_DETAILS: "dashboard/data/",
    KWIPS_DASHBOARD_DETAILS: "admin/dashboard/",
    TICKER_DASHBOARD_DETAILS: "admin/dashboard/",
    KWIP_LOGS: "admin/logs/filter/",
    TICKER_LOGS: "admin/logs/filter/",
    SET_PASSWORD: "users/",
    CHECK_PASSWORD: "users/",
    FORGET_PASSWORD: "forget_password/",
    CREATE_TICKER: "",
    FETCH_TICKER: "",
    EDIT_TICKER: "",
    DELETE_TICKER: "",
    SEND_NOW_TICKER: "admin/send/",
    CLONE_CUSTOMER: "customer/oauth/",
    FETCH_MUSIC_LIST: "music/",
    FETCH_SALESFORCE_API_CREDIT_DATA: "customer/credit/",
    EDIT_SALESFORCE_API_CREDIT_DATA: "customer/credit/",
    LOGIN_SALESFORCE_USER: "oauth2/token/",
  },
  ERRORS: {
    AUTH_API: {
      "expired authorization code": {
        message: "Auth API Failed",
        description: "Access Token is expired",
      },
      "invalid authorization code": {
        message: "Auth API Failed",
        description: "Invalid authorization code.",
      },
    },
    REQUIRED: "Required field - Please provide value",
    EMAIL: "Please enter the correct mail",
    REQUIRED_IMAGE: "Required field - Please provide profile image",
    BOOLEAN_VALUE: "Please provide boolean value only",
    MERGE_FIELD: "Please provide valid merge field",
  },
  TOKEN: {
    ACCESS_TOKEN: "access_token",
    REFRESH_TOKEN: "refresh_token",
    SESSION_TIMEOUT: "session_timeout",
    USER_ID: "kwipo_user_id",
  },
  LABELS: {
    ID: "id",
    VALIDATION: {
      REQUIRED: "required",
      STRING_STATUS: "stringStatus",
      EMAIL_VALIDATION: "email",
    },
    LOGIN: {
      WELCOME: "Welcome",
      LOGIN_NOW: "Please login to continue",
      TITLE: "Login",
      USERNAME_OR_EMAIL: "Username or Email",
      PASSWORD: "Password",
      CONTINUE: "Continue",
      SIGNUP: "Sign Up",
      SALESFORCE: "Continue with Salesforce",
      SUB_TITLE: "Acceess with your Salesforce Account",
      IMAGE_TEXT_FIRST: "Good works are waiting for you",
      IMAGE_TEXT_SECOND: "Login Now!!!",
      CODE: "code",
      ADMIN_EMAIL: "adminEmail",
      ADMIN_ID: "adminId",
    },
    REDIRECT_URL: {
      TITLE: "Processing Salesforce authentication",
    },
    TEAMS: {
      TITLE: "Teams",
      ADD_NEW_TEAM: "Add Team",
      SYNC_SALESFORCE_INTERVAL: "Sync Interval",
      CREATE_TEAM: "Create Team",
      EDIT_TEAM: "Edit Team",
      TEAM_NAME: "teamName",
      TEAM_DESCRIPTION: "teamDescription",
      SELECTED_MEMBERS: "selectedMembers",
      TEAM_PROFILE: "teamProfile",
      TEAM_TYPE: "teamType",
      PREVIEW_TEAM_MEMBERS: "Preview Team Members",
    },
    NOTIFICATIONS: {
      PREV: "Prev",
      NEXT: "Next",
      CANCEL: "Cancel",
      SAVE: "Save",
      PREVIEW: "Preview",
      SEND_NOW: "Send Now",
      SCHEDULE: "Schedule",
    },
    USER: {
      NAME: "name",
      FIRST_NAME: "firstName",
      LAST_NAME: "lastName",
      ALIAS: "alias",
      EMAIL: "email",
      TITLE: "title",
      USERNAME: "username",
      MOBILE: "mobile",
      ADMIN: "Admin",
      SUPER_ADMIN: "Super Admin",
      USER: "User",
    },
    GROUP: {
      NAME: "name",
      ALIAS: "alias",
      FILTERS: "filters",
      FIELD: "field",
      OPERATOR: "operator",
      VALUE: "value",
      LABEL: "label",
      KEY: "key",
      SYNC_ALL_GROUPS: "Sync Now",
      ADD_NEW_GROUP: "Add Group",
    },
  },
  MENU_ITEMS: (activeKey) => [
    {
      label: "Dashboard",
      key: "dashboard",
      icon: (
        <Icon
          Component={Dashboard}
          fill={activeKey === "dashboard" ? "white" : "black"}
        />
      ),
    },
    {
      label: "CONTENT",
      key: "content",
      type: "group",
      children: [
        {
          icon: (
            <Icon
              Component={List}
              fill={activeKey === "kwips" ? "white" : "black"}
            />
          ),
          label: "Kwips",
          key: "kwips",
        },
        {
          icon: (
            <Icon
              Component={Leaderboard}
              fill={activeKey === "leaderboard" ? "white" : "black"}
            />
          ),
          label: "Leaderboards",
          key: "leaderboard",
        },
        {
          icon: (
            <Icon
              Component={AuditOutlined}
              fill={activeKey === "scorecards" ? "white" : "black"}
            />
          ),
          label: "Scorecards",
          key: "scorecards",
          disabled: true,
        },
        {
          icon: (
            <Icon
              Component={Template}
              fill={activeKey === "template" ? "white" : "black"}
            />
          ),
          label: "Templates",
          key: "template",
        },
        {
          icon: (
            <Icon
              Component={Ticker}
              fill={activeKey === "ticker" ? "#bfbfbf" : "#bfbfbf"}
            />
          ),
          label: "Ticker Feed",
          key: "ticker",
          disabled: true,
        },
      ],
    },
    {
      label: "DATA",
      key: "data",
      type: "group",
      className: "data-menu-item",
      children: [
        {
          icon: (
            <Icon
              Component={AreaChartOutlined}
              fill={activeKey === "metrics" ? "white" : "black"}
            />
          ),
          label: "Metrics",
          key: "metrics",
        },
        {
          icon: (
            <Icon
              Component={Trigger}
              fill={activeKey === "threshold" ? "white" : "black"}
            />
          ),
          label: "Thresholds",
          key: "threshold",
        },
        {
          icon: (
            <Icon
              Component={SisternodeOutlined}
              fill={activeKey === "integration" ? "white" : "black"}
            />
          ),
          label: "Integrations",
          key: "integration",
        },
      ],
    },
    {
      label: "PEOPLE",
      key: "userManagement",
      type: "group",
      children: [
        {
          icon: (
            <Icon
              Component={User}
              fill={activeKey === "users" ? "white" : "black"}
            />
          ),
          label: "Users",
          key: "users",
        },
        {
          icon: (
            <Icon
              Component={Team}
              fill={activeKey === "teams" ? "white" : "black"}
            />
          ),
          label: "Teams",
          key: "teams",
        },
      ],
    },
    {
      label: "SETTINGS",
      key: "settings",
      type: "group",
      children: [
        {
          label: "General",
          key: "setting",
          icon: (
            <Icon
              Component={Setting}
              fill={activeKey === "setting" ? "white" : "black"}
            />
          ),
        },
        {
          icon: (
            <Icon
              Component={AppstoreAddOutlined}
              fill={activeKey === "apps" ? "white" : "black"}
            />
          ),
          label: "Apps",
          key: "apps",
        },
      ],
    },
  ],
  PROFILE_ITEMS: ({ handleViewProfile, handleLogOut, handleOpenSupport }) => [
    {
      key: "profile",
      label: <div onClick={handleViewProfile}>Account</div>,
    },
    {
      key: "support",
      label: <div onClick={handleOpenSupport}>Support</div>,
    },
    {
      key: "logout",
      danger: true,
      label: <div onClick={handleLogOut}>Log out</div>,
    },
  ],
  USER_INITIAL_COLUMNS: [
    "username",
    "name",
    "is_active",
    "alias",
    "title",
    "email",
  ],
  NOTIFICATION_OBJECT: {
    ERROR: alert,
    SUCCESS: tick,
    PENDING: info,
  },
  MODALS: {
    NOTIFICATION: {
      key: "NOTIFICATION",
      component: NotificationModal,
    },
    TEAM: {
      key: "TEAM",
      component: TeamModal,
    },
    SYNC_INTERVAL: {
      key: "SYNC_INTERVAL",
      component: SyncIntervalModal,
    },
    USER: {
      key: "USER",
      component: UserModal,
    },
    GROUP: {
      key: "GROUP",
      component: GroupModal,
    },
    DELETE_SALESFORCE_USER: {
      key: "DELETE_SALESFORCE_USER",
      component: SalesforceUserDelete,
    },
    ERROR: {
      key: "ERROR",
      component: ErrorModal,
    },
    PREVIEW_TEAM: {
      key: "PREVIEW_TEAM",
      component: PreviewTeam,
    },
    CONFIRM: {
      key: "CONFIRM",
      component: ConfirmModal,
    },
    CREATE_NOTIFICATION_ALERT: {
      key: "CREATE_NOTIFICATION_ALERT",
      component: CreateNotificationAlert,
    },
    USER_SETTING: {
      key: "USER_SETTING",
      component: UserSetting,
    },
    TEMPLATE_PREVIEW: {
      key: "TEMPLATE_PREVIEW",
      component: TemplatePreview,
    },
    TEMPLATE_EDIT: {
      key: "TEMPLATE_EDIT",
      component: TemplateEdit,
    },
    TEMPLATE_CREATE: {
      key: "TEMPLATE_CREATE",
      component: CreateTemplateModal,
    },
    KWIP: {
      key: "KWIP",
      component: KwipModal,
    },
    TICKER: {
      key: "TICKER",
      component: TickerModal,
    },
    TICKER_SCHEDULE: {
      key: "TICKER_SCHEDULE",
      component: TickerScheduleModal,
    },
    METRICES: {
      key: "METRICES",
      component: MetricesModal,
    },
    LEADERBOARD: {
      key: "LEADERBOARD",
      component: LeaderboardModal,
    },
    METRIC_PREVIEW: {
      key: "METRIC_PREVIEW",
      component: MetricPreview,
    },
    LEADERBOARD_PREVIEW: {
      key: "LEADERBOARD_PREVIEW",
      component: LeaderboardPreview,
    },
    RESTRICT_DELETE: {
      key: "RESTRICT_DELETE",
      component: RestrictDelete,
    },
  },
  METRIC_PREVIEW_COLUMN: [
    {
      title: "Name",
      dataIndex: "name",
      width: 300,
      sorter: {
        compare: (a, b) => a.name.localeCompare(b.name),
      },
    },
    {
      title: "Today",
      dataIndex: "TODAY",
      width: 150,
      sorter: {
        compare: (a, b) => a.TODAY.localeCompare(b.TODAY),
      },
      render: (
        value,
        { id, type },
        index,
        { editColumnId, handleTableEntryChange }
      ) => {
        if (editColumnId === id) {
          let number = extractNumberFromString(value);
          let { startSpecial, endSpecial } = getSpecialCharacterObject(
            type,
            value
          );

          return (
            <div className="d-flex align-items-center">
              {startSpecial}
              <input
                className="w-100 outline-none"
                value={number}
                onChange={({ target: { value } }) =>
                  handleTableEntryChange(
                    { startSpecial, number: value, endSpecial },
                    "TODAY",
                    index
                  )
                }
              />
              {endSpecial}
            </div>
          );
        } else return value;
      },
    },
    {
      title: "This Week",
      dataIndex: "THIS_WEEK",
      width: 150,
      sorter: {
        compare: (a, b) => a.THIS_WEEK.localeCompare(b.THIS_WEEK),
      },
      render: (
        value,
        { id, type },
        index,
        { editColumnId, handleTableEntryChange }
      ) => {
        if (editColumnId === id) {
          let number = extractNumberFromString(value);
          let { startSpecial, endSpecial } = getSpecialCharacterObject(
            type,
            value
          );

          return (
            <div className="d-flex align-items-center">
              {startSpecial}
              <input
                className="w-100 outline-none"
                value={number}
                onChange={({ target: { value } }) =>
                  handleTableEntryChange(
                    { startSpecial, number: value, endSpecial },
                    "THIS_WEEK",
                    index
                  )
                }
              />
              {endSpecial}
            </div>
          );
        } else return value;
      },
    },
    {
      title: "This Month",
      dataIndex: "THIS_MONTH",
      width: 150,
      sorter: {
        compare: (a, b) => a.THIS_MONTH.localeCompare(b.THIS_MONTH),
      },
      render: (
        value,
        { id, type },
        index,
        { editColumnId, handleTableEntryChange }
      ) => {
        if (editColumnId === id) {
          let number = extractNumberFromString(value);
          let { startSpecial, endSpecial } = getSpecialCharacterObject(
            type,
            value
          );

          return (
            <div className="d-flex align-items-center">
              {startSpecial}
              <input
                className="w-100 outline-none"
                value={number}
                onChange={({ target: { value } }) =>
                  handleTableEntryChange(
                    { startSpecial, number: value, endSpecial },
                    "THIS_MONTH",
                    index
                  )
                }
              />
              {endSpecial}
            </div>
          );
        } else return value;
      },
    },
    {
      title: "This Quarter",
      dataIndex: "THIS_QUARTER",
      width: 150,
      sorter: {
        compare: (a, b) => a.THIS_QUARTER.localeCompare(b.THIS_QUARTER),
      },
      render: (
        value,
        { id, type },
        index,
        { editColumnId, handleTableEntryChange }
      ) => {
        if (editColumnId === id) {
          let number = extractNumberFromString(value);
          let { startSpecial, endSpecial } = getSpecialCharacterObject(
            type,
            value
          );

          return (
            <div className="d-flex align-items-center">
              {startSpecial}
              <input
                className="w-100 outline-none"
                value={number}
                onChange={({ target: { value } }) =>
                  handleTableEntryChange(
                    { startSpecial, number: value, endSpecial },
                    "THIS_QUARTER",
                    index
                  )
                }
              />
              {endSpecial}
            </div>
          );
        } else return value;
      },
    },
    {
      title: "This Year",
      dataIndex: "THIS_YEAR",
      width: 150,
      sorter: {
        compare: (a, b) => a.THIS_YEAR.localeCompare(b.THIS_YEAR),
      },
      render: (
        value,
        { id, type },
        index,
        { editColumnId, handleTableEntryChange }
      ) => {
        if (editColumnId === id) {
          let number = extractNumberFromString(value);
          let { startSpecial, endSpecial } = getSpecialCharacterObject(
            type,
            value
          );

          return (
            <div className="d-flex align-items-center">
              {startSpecial}
              <input
                className="w-100 outline-none"
                value={number}
                onChange={({ target: { value } }) =>
                  handleTableEntryChange(
                    { startSpecial, number: value, endSpecial },
                    "THIS_YEAR",
                    index
                  )
                }
              />
              {endSpecial}
            </div>
          );
        } else return value;
      },
    },
    {
      title: "Last Updated",
      dataIndex: "last_updated",
      width: 300,
      render: (_, { last_updated, via }) => {
        if (!last_updated) return "";
        let timePeriodSpent = formatDateAgo(last_updated);
        return `${timePeriodSpent} via ${via}`;
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      width: 150,
      render: (
        _,
        record,
        __,
        { editColumnId, handleSaveRecord, handleEdit }
      ) => (
        <span
          style={{ display: "flex", justifyContent: "center", color: "grey" }}
        >
          {editColumnId === record.id ? (
            <CheckCircleOutlined
              style={{
                height: 16,
                width: 16,
                marginRight: 8,
                cursor: "pointer",
              }}
              onClick={handleSaveRecord}
            />
          ) : (
            <img
              src={edit}
              style={{
                height: 16,
                width: 16,
                marginRight: 8,
                cursor: "pointer",
              }}
              alt="edit"
              onClick={() => handleEdit(record)}
            />
          )}
        </span>
      ),
    },
  ],
  LEADERBOARD_PREVIEW_COLUMN: [
    {
      title: "Name",
      dataIndex: "name",
      width: 300,
      sorter: {
        compare: (a, b) => a.name.localeCompare(b.name),
      },
    },
    {
      title: "Today",
      dataIndex: "TODAY",
      width: 150,
      sorter: {
        compare: (a, b) => {
          let firstNumber = extractNumberFromString(a.value);
          let secondNumber = extractNumberFromString(b.value);
          return firstNumber - secondNumber;
        },
      },
      render: (_, { value }) => value,
    },
    {
      title: "This Week",
      dataIndex: "THIS_WEEK",
      width: 150,
      sorter: {
        compare: (a, b) => {
          let firstNumber = extractNumberFromString(a.value);
          let secondNumber = extractNumberFromString(b.value);
          return firstNumber - secondNumber;
        },
      },
      render: (_, { value }) => value,
    },
    {
      title: "This Month",
      dataIndex: "THIS_MONTH",
      width: 150,
      sorter: {
        compare: (a, b) => {
          let firstNumber = extractNumberFromString(a.value);
          let secondNumber = extractNumberFromString(b.value);
          return firstNumber - secondNumber;
        },
      },
      render: (_, { value }) => value,
    },
    {
      title: "This Quarter",
      dataIndex: "THIS_QUARTER",
      width: 150,
      sorter: {
        compare: (a, b) => {
          let firstNumber = extractNumberFromString(a.value);
          let secondNumber = extractNumberFromString(b.value);
          return firstNumber - secondNumber;
        },
      },
      render: (_, { value }) => value,
    },
    {
      title: "This Year",
      dataIndex: "THIS_YEAR",
      width: 150,
      sorter: {
        compare: (a, b) => {
          let firstNumber = extractNumberFromString(a.value);
          let secondNumber = extractNumberFromString(b.value);
          return firstNumber - secondNumber;
        },
      },
      render: (_, { value }) => value,
    },
  ],
  METRICES_COLUMN: [
    {
      title: "Name",
      dataIndex: "name",
      width: 300,
      sorter: {
        compare: (a, b) => a.name.localeCompare(b.name),
      },
    },
    {
      title: "Type",
      dataIndex: "type",
      width: 200,
      sorter: {
        compare: (a, b) => a.type.localeCompare(b.type),
      },
    },
    {
      title: "Source",
      dataIndex: "source",
      width: 200,
      sorter: {
        compare: (a, b) => a.source.localeCompare(b.source),
      },
    },
    {
      title: "Last Updated",
      dataIndex: "last_updated",
      width: 300,
      render: (_, { last_updated, via }) => {
        if (!last_updated) return "";
        let timePeriodSpent = formatDateAgo(last_updated);
        return `${timePeriodSpent} via ${via}`;
      },
    },
    {
      title: "Actions",
      dataIndex: "action",
      width: 150,
      render: (
        _,
        record,
        __,
        { handlePreview, handleDeleteMetrices, handleEditMetric }
      ) => (
        <span
          style={{ display: "flex", justifyContent: "center", color: "grey" }}
        >
          <InsertRowBelowOutlined
            className="metric-preview"
            onClick={() => handlePreview(record)}
          />
          <img
            src={edit}
            style={{ height: 16, width: 16, marginRight: 8, cursor: "pointer" }}
            alt="edit"
            onClick={() => handleEditMetric(record)}
          />
          <img
            src={trash}
            style={{ height: 16, width: 16, marginRight: 8, cursor: "pointer" }}
            alt="trash"
            onClick={() => handleDeleteMetrices(record)}
          />
        </span>
      ),
    },
  ],
  LEADERBOARD_COLUMN: [
    {
      title: "Title",
      dataIndex: "title",
      width: 250,
      sorter: {
        compare: (a, b) => a.title.localeCompare(b.title),
      },
    },
    {
      title: "Related Metric",
      dataIndex: "metric_id",
      width: 200,
      render: (metric_id, _, __, { metricesList }) => {
        let metric = metricesList?.find(({ id }) => id === metric_id) ?? {};
        return metric.name;
      },
    },
    {
      title: "Timeframe",
      dataIndex: "timeframe",
      width: 200,
      render: (timeframe, _, __, { timeframeList }) => {
        let timeframeObj =
          timeframeList?.find(({ value }) => value === timeframe) ?? {};
        return timeframeObj.label;
      },
    },
    {
      title: "Related Team",
      dataIndex: "team_id",
      width: 200,
      render: (team_id, _, __, { teamsList }) => {
        let team = teamsList?.find(({ id }) => id === team_id) ?? {};
        return team.name;
      },
    },
    {
      title: "Actions",
      dataIndex: "action",
      width: 100,
      render: (_, record, __, { handlePreview, handleDelete, handleEdit }) => (
        <span style={{ display: "flex", justifyContent: "center" }}>
          <EyeOutlined
            className="leaderboard-preview"
            onClick={() => handlePreview(record)}
          />
          <img
            src={edit}
            style={{ height: 16, width: 16, marginRight: 8, cursor: "pointer" }}
            alt="edit"
            onClick={() => handleEdit(record)}
          />
          <img
            src={trash}
            style={{ height: 16, width: 16, marginRight: 8, cursor: "pointer" }}
            alt="trash"
            onClick={() => handleDelete(record)}
          />
        </span>
      ),
    },
  ],
  GROUPS_COLUMN: [
    {
      title: "Group Name",
      dataIndex: "name",
      width: 300,
      sorter: {
        compare: (a, b) => a.name.localeCompare(b.name),
      },
    },
    {
      title: "Group Members",
      dataIndex: "members",
      render: (members, _, __, { usersList, teamsList }) => {
        return (
          <AvatarList
            members={members}
            maxCount={6}
            dataSet={[...(usersList ?? []), ...(teamsList ?? [])]}
          />
        );
      },
    },
    {
      title: "Actions",
      dataIndex: "action",
      width: 150,
      render: (_, record, index, { handleEdit, handleSync, handleDelete }) => (
        <span style={{ display: "flex", justifyContent: "center" }}>
          <img
            src={sync}
            style={{ height: 16, width: 16, marginRight: 8 }}
            alt="trash"
            onClick={() => {
              handleSync({ ...record, index });
            }}
          />
          <img
            src={trash}
            style={{ height: 16, width: 16, marginRight: 8 }}
            alt="trash"
            onClick={() => {
              handleDelete(record.id);
            }}
          />
          <img
            src={edit}
            style={{ height: 16, width: 16, marginRight: 8 }}
            alt="edit"
            onClick={() => {
              handleEdit({ ...record, index });
            }}
          />
        </span>
      ),
    },
  ],
  USER_COLUMN: [
    {
      title: "Profile",
      dataIndex: "live_count",
      width: 100,
      sorter: {
        compare: (a, b) =>
          Boolean(b.live_count)
            .toString()
            .localeCompare(Boolean(a.live_count).toString()),
      },
      render: (live_count, { profile_photo }) => {
        let activeImage = profile_photo?.find(({ is_active }) => is_active);
        return (
          <div className="position-relative">
            <Avatar
              size={48}
              src={activeImage?.image}
              className={`${Boolean(live_count) ? "live-user" : ""}`}
            />
            {Boolean(live_count) && (
              <img src={liveStatus} alt="live" className="live-status" />
            )}
          </div>
        );
      },
    },
    {
      title: "Name",
      dataIndex: "name",
      width: 300,
      sorter: {
        compare: (a, b) => a.name.localeCompare(b.name),
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      width: 350,
      sorter: {
        compare: (a, b) => a.email.localeCompare(b.email),
      },
    },
    {
      title: "Role",
      dataIndex: "role",
      width: 150,
      sorter: {
        compare: (a, b) => a.role.localeCompare(b.role),
      },
      render: (role) => {
        switch (role) {
          case "super_admin":
            return "Super Admin";
          case "admin":
            return "Admin";
          default:
            return "User";
        }
      },
    },
    {
      title: "User Type",
      dataIndex: "source_type",
      width: 150,
      sorter: {
        compare: (a, b) => a.source_type.localeCompare(b.source_type),
      },
      render: (type) => {
        return type;
      },
    },
    {
      title: "Licensed User",
      dataIndex: "isAppUser",
      width: 180,
      sorter: {
        compare: (a, b) => {
          let firstValue = a.isAppUser ? "Yes" : "No";
          let secondValue = b.isAppUser ? "Yes" : "No";
          return firstValue.localeCompare(secondValue);
        },
      },
      render: (value) => {
        return (
          <Tag color={value ? "green" : "red"}>{value ? "Yes" : "No"}</Tag>
        );
      },
    },
    {
      title: "Actions",
      dataIndex: "action",
      width: 180,
      render: (
        _,
        record,
        index,
        {
          role,
          handleMailSend,
          handleEdit,
          handleDelete,
          handleUserAppAccess,
          isUserAppAccessAllowed,
        }
      ) => (
        <span style={{ display: "flex", justifyContent: "center" }}>
          {role === "super_admin" && (
            <>
              <Tooltip
                key={`userApp-${record.id}`}
                title={
                  isUserAppAccessAllowed
                    ? record.isAppUser
                      ? "Revoke access from User App"
                      : "Allow access to User App"
                    : "Access Limit Reached for User App"
                }
              >
                <img
                  className="cursor-pointer"
                  src={record.isAppUser ? lock : unlock}
                  style={{ height: 16, width: 16, marginRight: 8 }}
                  alt="edit"
                  onClick={() => {
                    isUserAppAccessAllowed &&
                      handleUserAppAccess({
                        ...record,
                        isAppUser: !record.isAppUser,
                      });
                  }}
                />
              </Tooltip>
              {record.source_type === "Custom" && (
                <Tooltip
                  key={`mail-${record.id}`}
                  title={
                    record.isAppUser
                      ? "Send Credential for User App"
                      : "Allow User App access to send Mail"
                  }
                >
                  <MailOutlined
                    style={{ color: "#868685" }}
                    className="mr-1"
                    onClick={() => record.isAppUser && handleMailSend(record)}
                  />
                </Tooltip>
              )}
            </>
          )}
          <Tooltip key={`update-${record.id}`} title="Edit">
            <img
              className="cursor-pointer"
              src={edit}
              style={{ height: 16, width: 16, marginRight: 8 }}
              alt="edit"
              onClick={() => {
                handleEdit({ ...record, index });
              }}
            />
          </Tooltip>

          <Tooltip key={`delete-${record.id}`} title="Delete">
            <img
              className="cursor-pointer"
              src={trash}
              style={{ height: 16, width: 16, marginRight: 8 }}
              alt="trash"
              onClick={() => {
                handleDelete(record.id, record.source_type);
              }}
            />
          </Tooltip>
        </span>
      ),
    },
  ],
  TEAMS_COLUMN: [
    {
      title: "Profile",
      dataIndex: "profile_photo",
      width: 50,
      render: (image, { id, members }) => {
        return (
          <div className="d-flex align-items-center">
            {members.length === 0 && (
              <Tooltip
                title="The current filter expression hasn't filtered any users for the Team. Adjustments may be needed."
                key={id}
              >
                <span className="dot dot-yellow" />
              </Tooltip>
            )}
            <Avatar size={48} src={image} />
          </div>
        );
      },
    },
    {
      title: "Team Name",
      dataIndex: "name",
      sorter: {
        compare: (a, b) => a.name.localeCompare(b.name),
      },
    },
    {
      title: "Participants",
      dataIndex: "members",
      sorter: {
        compare: (a, b) => a.name.localeCompare(b.name),
      },
      render: (members, _, __, { usersList, teamsList, handleAvatarClick }) => {
        return (
          <AvatarList
            members={members}
            maxCount={6}
            dataSet={[...(usersList ?? []), ...(teamsList ?? [])]}
            onMemberClick={() =>
              handleAvatarClick(members, [
                ...(usersList ?? []),
                ...(teamsList ?? []),
              ])
            }
          />
        );
      },
    },
    {
      title: "Team Type",
      dataIndex: "source_type",
      width: 200,
      sorter: {
        compare: (a, b) => a.source_type.localeCompare(b.source_type),
      },
      render: (type) => {
        return type;
      },
    },
    {
      title: "Actions",
      dataIndex: "action",
      width: 150,
      render: (
        _,
        record,
        index,
        {
          is_salesforce_authenticated,
          handleEditTeam,
          handleSync,
          handleTeamDelete,
        }
      ) => (
        <span style={{ display: "flex", justifyContent: "center" }}>
          {record.source_type === "Salesforce" && (
            <img
              className="cursor-pointer"
              src={sync}
              style={{ height: 16, width: 16, marginRight: 8 }}
              alt="trash"
              onClick={() => {
                if (
                  !is_salesforce_authenticated &&
                  record.source_type === "Salesforce"
                )
                  return;
                handleSync({ ...record, index });
              }}
            />
          )}
          <img
            className="cursor-pointer"
            src={trash}
            style={{ height: 16, width: 16, marginRight: 8 }}
            alt="trash"
            onClick={() => {
              if (
                !is_salesforce_authenticated &&
                record.source_type === "Salesforce"
              )
                return;
              handleTeamDelete(record);
            }}
          />
          <img
            className="cursor-pointer"
            src={edit}
            style={{ height: 16, width: 16, marginRight: 8 }}
            alt="edit"
            onClick={() => {
              if (
                !is_salesforce_authenticated &&
                record.source_type === "Salesforce"
              )
                return;
              handleEditTeam({ ...record, index });
            }}
          />
        </span>
      ),
    },
  ],
  NOTIFICATION_LIST_COLUMN: [
    {
      title: "Sr.",
      width: 70,
      dataIndex: "serialNumber",
      render: (_, __, index, { currentPage }) => {
        return (currentPage - 1) * 10 + index + 1 + ".";
      },
    },
    {
      title: "Title",
      dataIndex: "event_name",
      sorter: {
        compare: (a, b) => a?.event_name?.localeCompare(b?.event_name),
      },
    },
    {
      title: "Type",
      dataIndex: "threshold_type",
      sorter: {
        compare: (a, b) => a?.threshold_type?.localeCompare(b?.threshold_type),
      },
    },
    {
      title: "Metric name",
      dataIndex: "metric_id",
      render: (metricId, _, __, { metricesList }) => {
        let metric = metricesList?.find(({ id }) => id === metricId) ?? {};
        return metric.name;
      },
    },
    {
      title: "Actions",
      dataIndex: "action",
      width: 150,
      render: (
        _,
        record,
        __,
        { handleEditNotification, handleDeleteNotification }
      ) => (
        <span style={{ display: "flex", justifyContent: "center" }}>
          <img
            src={trash}
            style={{ height: 16, width: 16, marginRight: 8 }}
            alt="trash"
            onClick={() => {
              handleDeleteNotification({ ...record });
            }}
          />
          <img
            src={edit}
            style={{ height: 16, width: 16, marginRight: 8 }}
            alt="edit"
            onClick={() => {
              handleEditNotification({ ...record });
            }}
          />
        </span>
      ),
    },
  ],
  KWIP_LIST_COLUMN: [
    {
      title: "Name",
      dataIndex: "name",
      sorter: {
        compare: (a, b) => a?.name?.localeCompare(b?.name),
      },
    },
    {
      title: "Type",
      dataIndex: "kwip_type",
      sorter: {
        compare: (a, b) => a?.type?.localeCompare(b?.type),
      },
      render: (value) => uppercaseFirstLetter(value),
    },
    {
      title: "Recipients",
      dataIndex: "participants",
      width: 500,
      sorter: {
        compare: (a, b) => a?.members?.length.localeCompare(b?.members?.length),
      },
      render: (members, { creditTo }, __, { usersList, teamsList }) => {
        return (
          <AvatarList
            members={[...members, ...creditTo]}
            maxCount={6}
            dataSet={[...(usersList ?? []), ...(teamsList ?? [])]}
          />
        );
      },
    },
    {
      title: "Actions",
      dataIndex: "action",
      width: 150,
      render: (_, record, index, { handleEdit, handleKwipDelete }) => (
        <span style={{ display: "flex", justifyContent: "center" }}>
          <img
            src={trash}
            style={{ height: 16, width: 16, marginRight: 8 }}
            alt="trash"
            onClick={() => {
              handleKwipDelete(record.id, record.instance_url);
            }}
          />
          <img
            src={edit}
            style={{ height: 16, width: 16, marginRight: 8 }}
            alt="edit"
            onClick={() => {
              handleEdit({ ...record, index });
            }}
          />
        </span>
      ),
    },
  ],
  TEMPLATE_COLUMN: [
    {
      title: "Sr.",
      width: 55,
      dataIndex: "serialNumber",
      render: (_, __, index, { currentPage }) => {
        return (currentPage - 1) * 10 + index + 1 + ".";
      },
    },
    {
      title: "Name",
      dataIndex: "name",
      width: 300,
      sorter: {
        compare: (a, b) => a.name.localeCompare(b.name),
      },
    },
    {
      title: "No. of targeted users",
      dataIndex: "count",
      width: 300,
      sorter: {
        compare: (a, b) => a.count.localeCompare(b.count),
      },
    },
    {
      title: "Type",
      dataIndex: "type",
      width: 300,
      sorter: {
        compare: (a, b) => a.type.localeCompare(b.type),
      },
    },
    {
      title: "Actions",
      dataIndex: "type",
      width: 150,
      render: (
        type,
        record,
        index,
        { handlePreview, handleEdit, handleDelete }
      ) => (
        <span style={{ display: "flex", justifyContent: "center" }}>
          <img
            src={eye}
            style={{ height: 16, width: 16, marginRight: 8 }}
            alt="preview"
            onClick={() => {
              handlePreview({ ...record, index });
            }}
          />
          {type === "Custom" && (
            <img
              src={edit}
              style={{ height: 16, width: 16, marginRight: 8 }}
              alt="edit"
              onClick={() => {
                handleEdit({ ...record, index });
              }}
            />
          )}
          {type === "Custom" && (
            <img
              src={trash}
              style={{ height: 16, width: 16, marginRight: 8 }}
              alt="trash"
              onClick={() => {
                handleDelete(record.id, record.type);
              }}
            />
          )}
        </span>
      ),
    },
  ],
  DEFAULT_PROFILE: {
    name: "Annonymous",
  },
  CDC_ALLOWED_FIELDS: [
    "reference",
    "string",
    "textarea",
    "email",
    "url",
    "text",
    "int",
    "id",
    "double",
    "currency",
    "percent",
    "number",
    "autonumber",
    "boolean",
    "checkbox",
    "date",
    "time",
    "datetime",
    "picklist",
  ],
  NOTIFICATION_OPERATION: [
    {
      TYPE: "text",
      DATA_TYPES: ["reference", "string", "textarea", "text", "email", "url"],
      OPERATORS: [
        "Equals",
        "Not Equals",
        "Less Than",
        "Greater Than",
        "Less or Equal",
        "Greater or Equal",
        "Contains",
        "Starts With",
        "Is Blank",
        "Is Not Blank",
      ],
    },
    {
      TYPE: "number",
      DATA_TYPES: [
        "int",
        "id",
        "double",
        "currency",
        "percent",
        "number",
        "autonumber",
      ],
      OPERATORS: [
        "Equals",
        "Not Equals",
        "Less Than",
        "Greater Than",
        "Less or Equal",
        "Greater or Equal",
        "Is Blank",
        "Is Not Blank",
      ],
    },
    {
      TYPE: "boolean",
      DATA_TYPES: ["boolean", "checkbox"],
      OPERATORS: ["Equals"],
    },
    {
      TYPE: "date",
      DATA_TYPES: ["date", "time", "datetime"],
      OPERATORS: [
        "Equals",
        "Not Equals",
        "Less Than",
        "Greater Than",
        "Less or Equal",
        "Greater or Equal",
        "Is Blank",
        "Is Not Blank",
      ],
    },
    {
      TYPE: "select",
      DATA_TYPES: ["picklist"],
      OPERATORS: ["Equals", "Not Equals", "Is Blank", "Is Not Blank"],
    },
  ],
  METRICES_STEPS: ({
    metricesData,
    modalData,
    setModalData,
    objects,
    fields,
    setIsEdited,
  }) => [
    {
      content: (
        <FirstMetricesStep
          metricesData={metricesData}
          modalData={modalData}
          setModalData={setModalData}
          objects={objects}
          setIsEdited={setIsEdited}
        />
      ),
    },
    {
      content: (
        <SecondMetricesStep
          modalData={modalData}
          setModalData={setModalData}
          fields={fields}
          setIsEdited={setIsEdited}
        />
      ),
    },
  ],
  NOTIFICATION_STEPS: ({
    objects,
    fields,
    notificationObject,
    setIsEdited,
    setNotificationObject,
    setIsMetricChanged,
  }) => [
    {
      content: (
        <FirstStep
          objects={objects}
          setIsEdited={setIsEdited}
          notificationObject={notificationObject}
          setIsMetricChanged={setIsMetricChanged}
          setNotificationObject={setNotificationObject}
        />
      ),
    },
    {
      content: (
        <SecondStep
          fields={fields}
          notificationObject={notificationObject}
          setIsEdited={setIsEdited}
          setNotificationObject={setNotificationObject}
        />
      ),
    },
    {
      content: (
        <ForthStep
          fields={fields}
          notificationObject={notificationObject}
          setIsEdited={setIsEdited}
          setNotificationObject={setNotificationObject}
        />
      ),
    },
  ],
  TEAMS_STEPS: ({
    selectedMembers,
    setSelectedMembers,
    selectedManagers,
    setSelectedManagers,
    usersList,
    groups,
    errorObj,
    teamProfile,
    setTeamProfile,
    teamName,
    setTeamName,
    teamType,
    setTeamType,
    filterDetails,
    setFilterDetails,
    teamData,
    status,
    setStatus,
  }) => [
    {
      content: (
        <TeamsFirstStep
          status={status}
          setStatus={setStatus}
          teamData={teamData}
          errorObj={errorObj}
          teamProfile={teamProfile}
          setTeamProfile={setTeamProfile}
          teamName={teamName}
          setTeamName={setTeamName}
          teamType={teamType}
          setTeamType={setTeamType}
        />
      ),
    },
    {
      content: (
        <TeamsSecondStep
          selectedMembers={selectedMembers}
          setSelectedMembers={setSelectedMembers}
          usersList={usersList}
          groups={groups}
          errorObj={errorObj}
          teamType={teamType}
          filterDetails={filterDetails}
          setFilterDetails={setFilterDetails}
          teamData={teamData}
          selectedManagers={selectedManagers}
          setSelectedManagers={setSelectedManagers}
        />
      ),
    },
    {
      content: (
        <TeamsThirdStep
          selectedManagers={selectedManagers}
          setSelectedManagers={setSelectedManagers}
          usersList={usersList}
        />
      ),
    },
  ],
  TEAM_TYPES: [
    { label: "Custom", key: "Custom", value: "Custom" },
    { label: "Salesforce", key: "Salesforce", value: "Salesforce" },
  ],
  TICKER_SCHEDULE_STEPS: ({
    tickerData,
    scheduleData,
    setIsEdited,
    setScheduleData,
  }) => [
    {
      content: (
        <TickerModal
          tickerData={tickerData}
          contentDetails={scheduleData}
          setContentDetails={setScheduleData}
          setIsEdited={setIsEdited}
        />
      ),
    },
    {
      content: (
        <TickerParticipants
          setIsEdited={setIsEdited}
          scheduleData={scheduleData}
          setScheduleData={setScheduleData}
        />
      ),
    },
    {
      content: (
        <TickerScheduler
          setIsEdited={setIsEdited}
          scheduleData={scheduleData}
          setScheduleData={setScheduleData}
        />
      ),
    },
  ],
  KWIP_STEPS: ({
    kwipObject,
    notificationList,
    setKwipObject,
    usersList,
    teamsList,
    templates,
    setIsEdited,
  }) => [
    {
      content: (
        <StepOne
          kwipObject={kwipObject}
          setKwipObject={setKwipObject}
          notificationList={notificationList}
          setIsEdited={setIsEdited}
        />
      ),
    },
    {
      content: (
        <StepTwo
          kwipObject={kwipObject}
          usersList={usersList}
          teamsList={teamsList}
          templates={templates}
          notificationList={notificationList}
          setKwipObject={setKwipObject}
          setIsEdited={setIsEdited}
        />
      ),
    },
    {
      content: (
        <StepThree
          kwipObject={kwipObject}
          usersList={usersList}
          teamsList={teamsList}
          templates={templates}
          notificationList={notificationList}
          setKwipObject={setKwipObject}
          setIsEdited={setIsEdited}
        />
      ),
    },
    {
      content: (
        <StepFour
          kwipObject={kwipObject}
          notificationList={notificationList}
          setKwipObject={setKwipObject}
          setIsEdited={setIsEdited}
        />
      ),
    },
  ],
  DEFAULT_NOTIFICATION_OBJECT: {
    evaluation: { criteria: "default", onlyOnce: true },
  },
  GROUP_FILTERS: [
    {
      label: "Active",
      key: "IsActive",
      operators: [
        {
          label: "Equals",
          key: "equals",
        },
        {
          label: "Not Equals to",
          key: "notEqualsTo",
        },
      ],
    },
    {
      label: "City",
      key: "City",
      operators: [
        {
          label: "Equals",
          key: "equals",
        },
        {
          label: "Not Equals to",
          key: "notEqualsTo",
        },
        {
          label: "Starts With",
          key: "startsWith",
        },
        {
          label: "Contains",
          key: "contains",
        },
        {
          label: "Does not Contains",
          key: "doesNotContains",
        },
      ],
    },
    {
      label: "Company",
      key: "CompanyName",
      operators: [
        {
          label: "Equals",
          key: "equals",
        },
        {
          label: "Not Equals to",
          key: "notEqualsTo",
        },
        {
          label: "Starts With",
          key: "startsWith",
        },
        {
          label: "Contains",
          key: "contains",
        },
        {
          label: "Does not Contains",
          key: "doesNotContains",
        },
      ],
    },
    {
      label: "Country",
      key: "Country",
      operators: [
        {
          label: "Equals",
          key: "equals",
        },
        {
          label: "Not Equals to",
          key: "notEqualsTo",
        },
        {
          label: "Starts With",
          key: "startsWith",
        },
        {
          label: "Contains",
          key: "contains",
        },
        {
          label: "Does not Contains",
          key: "doesNotContains",
        },
      ],
    },
    {
      label: "Department",
      key: "Department",
      operators: [
        {
          label: "Equals",
          key: "equals",
        },
        {
          label: "Not Equals to",
          key: "notEqualsTo",
        },
        {
          label: "Starts With",
          key: "startsWith",
        },
        {
          label: "Contains",
          key: "contains",
        },
        {
          label: "Does not Contains",
          key: "doesNotContains",
        },
      ],
    },
    {
      label: "Division",
      key: "Division",
      operators: [
        {
          label: "Equals",
          key: "equals",
        },
        {
          label: "Not Equals to",
          key: "notEqualsTo",
        },
        {
          label: "Starts With",
          key: "startsWith",
        },
        {
          label: "Contains",
          key: "contains",
        },
        {
          label: "Does not Contains",
          key: "doesNotContains",
        },
      ],
    },
    {
      label: "Email",
      key: "Email",
      operators: [
        {
          label: "Equals",
          key: "equals",
        },
        {
          label: "Not Equals to",
          key: "notEqualsTo",
        },
        {
          label: "Starts With",
          key: "startsWith",
        },
        {
          label: "Contains",
          key: "contains",
        },
        {
          label: "Does not Contains",
          key: "doesNotContains",
        },
      ],
    },
    {
      label: "Full Name",
      key: "Name",
      operators: [
        {
          label: "Equals",
          key: "equals",
        },
        {
          label: "Not Equals to",
          key: "notEqualsTo",
        },
        {
          label: "Starts With",
          key: "startsWith",
        },
        {
          label: "Contains",
          key: "contains",
        },
        {
          label: "Does not Contains",
          key: "doesNotContains",
        },
      ],
    },
    {
      label: "Language",
      key: "LanguageLocaleKey",
      operators: [
        {
          label: "Equals",
          key: "equals",
        },
        {
          label: "Not Equals to",
          key: "notEqualsTo",
        },
        {
          label: "Starts With",
          key: "startsWith",
        },
        {
          label: "Contains",
          key: "contains",
        },
        {
          label: "Does not Contains",
          key: "doesNotContains",
        },
      ],
    },
    {
      label: "Locale",
      key: "LocaleSidKey",
      operators: [
        {
          label: "Equals",
          key: "equals",
        },
        {
          label: "Not Equals to",
          key: "notEqualsTo",
        },
        {
          label: "Starts With",
          key: "startsWith",
        },
        {
          label: "Contains",
          key: "contains",
        },
        {
          label: "Does not Contains",
          key: "doesNotContains",
        },
      ],
    },
    {
      label: "Manager",
      key: "Manager.Name",
      operators: [
        {
          label: "Equals",
          key: "equals",
        },
        {
          label: "Not Equals to",
          key: "notEqualsTo",
        },
        {
          label: "Starts With",
          key: "startsWith",
        },
        {
          label: "Contains",
          key: "contains",
        },
        {
          label: "Does not Contains",
          key: "doesNotContains",
        },
      ],
    },
    {
      label: "Nickname",
      key: "CommunityNickname",
      operators: [
        {
          label: "Equals",
          key: "equals",
        },
        {
          label: "Not Equals to",
          key: "notEqualsTo",
        },
        {
          label: "Starts With",
          key: "startsWith",
        },
        {
          label: "Contains",
          key: "contains",
        },
        {
          label: "Does not Contains",
          key: "doesNotContains",
        },
      ],
    },
    {
      label: "Profile",
      key: "Profile.Name",
      operators: [
        {
          label: "Equals",
          key: "equals",
        },
        {
          label: "Not Equals to",
          key: "notEqualsTo",
        },
        {
          label: "Starts With",
          key: "startsWith",
        },
        {
          label: "Contains",
          key: "contains",
        },
        {
          label: "Does not Contains",
          key: "doesNotContains",
        },
      ],
    },
    {
      label: "Role",
      key: "UserRole.Name",
      operators: [
        {
          label: "Equals",
          key: "equals",
        },
        {
          label: "Not Equals to",
          key: "notEqualsTo",
        },
        {
          label: "Starts With",
          key: "startsWith",
        },
        {
          label: "Contains",
          key: "contains",
        },
        {
          label: "Does not Contains",
          key: "doesNotContains",
        },
      ],
    },
    {
      label: "Time Zone",
      key: "TimeZoneSidKey",
      operators: [
        {
          label: "Equals",
          key: "equals",
        },
        {
          label: "Not Equals to",
          key: "notEqualsTo",
        },
        {
          label: "Starts With",
          key: "startsWith",
        },
        {
          label: "Contains",
          key: "contains",
        },
        {
          label: "Does not Contains",
          key: "doesNotContains",
        },
      ],
    },
    {
      label: "Title",
      key: "Title",
      operators: [
        {
          label: "Equals",
          key: "equals",
        },
        {
          label: "Not Equals to",
          key: "notEqualsTo",
        },
        {
          label: "Starts With",
          key: "startsWith",
        },
        {
          label: "Contains",
          key: "contains",
        },
        {
          label: "Does not Contains",
          key: "doesNotContains",
        },
      ],
    },
  ],
  SYNC_INTERVAL_OPTIONS: [
    { label: "15 minutes", value: "15 minutes", key: "15 minutes" },
    { label: "30 minutes", value: "30 minutes", key: "30 minutes" },
    { label: "1 hours", value: "1 hour", key: "1 hour" },
    { label: "6 hours", value: "6 hours", key: "6 hours" },
    { label: "12 hours", value: "12 hours", key: "12 hours" },
    { label: "1 day", value: "1 day", key: "1 day" },
    { label: "30 days", value: "30 days", key: "30 days" },
    { label: "90 days", value: "90 days", key: "90 days" },
  ],
  TRIGGER_TYPES: [
    { label: "Salesforce", value: "Salesforce", key: "Salesforce" },
  ],
  METRICES_TYPES: [
    { label: "Currency", value: "Currency", key: "Currency" },
    { label: "Number", value: "Number", key: "Number" },
    { label: "Percentage", value: "Percentage", key: "Percentage" },
  ],
  METRICES_CURRENCY_TYPE: [
    { label: "Dollars", value: "Dollars", key: "Dollars" },
    { label: "Euros", value: "Euros", key: "Euros" },
    { label: "Pounds", value: "Pounds", key: "Pounds" },
  ],
  METRICES_SOURCE: [
    { label: "Custom", value: "Custom", key: "Custom" },
    { label: "Salesforce", value: "Salesforce", key: "Salesforce" },
  ],
  MOST_COMMON_OBJECTS: [
    { label: "Account", value: "Account", key: "Account" },
    { label: "Case", value: "Case", key: "Case" },
    { label: "Lead", value: "Lead", key: "Lead" },
    { label: "Opportunity", value: "Opportunity", key: "Opportunity" },
    { label: "Task", value: "Task", key: "Task" },
  ],
  KWIPO_PROFILE_FIELD: [
    {
      label: "Custom First Name",
      key: "kwipo_first_name",
      value: "kwipo_first_name",
      data_type: "string",
    },
    {
      label: "Custom Last Name",
      key: "kwipo_last_name",
      value: "kwipo_last_name",
      data_type: "string",
    },
    {
      label: "Custom Full Name",
      key: "kwipo_full_name",
      value: "kwipo_full_name",
      data_type: "string",
    },
    {
      label: "Custom Nick Name",
      key: "kwipo_nick_name",
      value: "kwipo_nick_name",
      data_type: "string",
    },
    {
      label: "Custom Title",
      key: "kwipo_title",
      value: "kwipo_title",
      data_type: "string",
    },
  ],
  CREDIT_TYPE: [
    { label: "Primary", key: "Primary", value: "Primary" },
    { label: "Secondary", key: "Secondary", value: "Secondary" },
    { label: "Tertiary", key: "Tertiary", value: "Tertiary" },
  ],
  TEMPLATE: [
    {
      label: "Template1",
      key: "Template1",
      value: "Template1",
    },
    {
      label: "Template2",
      key: "Template2",
      value: "Template2",
    },
    {
      label: "Template3",
      key: "Template3",
      value: "Template3",
    },
    {
      label: "Template4",
      key: "Template4",
      value: "Template4",
    },
    {
      label: "Template5",
      key: "Template5",
      value: "Template5",
    },
  ],
  PRE_DEFINED_TEMPLATES: [
    {
      name: "Template1",
      count: 1,
      type: "Pre-defined",
      componentKey: "oneUser",
      bgImage: landscape,
      titleBackgroundColor: "rgba(255, 255, 255, 0.87)",
      titleColor: "#000",
      bodyBackgroundColor: "rgba(255, 255, 255, 0.87)",
      bodyColor: "#000",
    },
    {
      name: "Template2",
      count: 2,
      type: "Pre-defined",
      componentKey: "twoUser",
      bgImage: landscape,
      titleBackgroundColor: "rgba(255, 255, 255, 0.87)",
      titleColor: "#000",
      bodyBackgroundColor: "rgba(255, 255, 255, 0.87)",
      bodyColor: "#000",
    },
    {
      name: "Template3",
      count: 3,
      type: "Pre-defined",
      componentKey: "threeUser",
      bgImage: landscape,
      titleBackgroundColor: "rgba(255, 255, 255, 0.87)",
      titleColor: "#000",
      bodyBackgroundColor: "rgba(255, 255, 255, 0.87)",
      bodyColor: "#000",
    },
    {
      name: "Template4",
      count: 4,
      type: "Pre-defined",
      componentKey: "fourUser",
      bgImage: landscape,
      titleBackgroundColor: "rgba(255, 255, 255, 0.87)",
      titleColor: "#000",
      bodyBackgroundColor: "rgba(255, 255, 255, 0.87)",
      bodyColor: "#000",
    },
    {
      name: "Template5",
      count: 5,
      type: "Pre-defined",
      componentKey: "fiveUser",
      bgImage: landscape,
      titleBackgroundColor: "rgba(255, 255, 255, 0.87)",
      titleColor: "#000",
      bodyBackgroundColor: "rgba(255, 255, 255, 0.87)",
      bodyColor: "#000",
    },
  ],
  TEMPLATE_PREVIEW: {
    oneUser: OneUser,
    twoUser: TwoUser,
    threeUser: ThreeUser,
    fourUser: FourUser,
    fiveUser: FiveUser,
  },
  TICKER_SCHEDULE: {
    WEEKS: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
    REPEAT: [
      { label: "No Repeat", value: 0, key: 0 },
      { label: "Daily", value: 1, key: 1 },
      { label: "Weekly", value: 2, key: 2 },
      { label: "Every weekday", value: 3, key: 3 },
      { label: "Monthly", value: 4, key: 4 },
      { label: "Annually", value: 5, key: 5 },
    ],
    ALLOWED_END_DATE: [1, 2, 3, 4, 5],
  },
  TICKER_POSITION_OPTIONS: [
    { label: "Top of the Screen", value: "top" },
    { label: "Bottom of the Screen", value: "bottom" },
  ],
  TICKER_LOGO_COLOR: ["#ffffff", "#000000", "#FFBC2E", "#12B576"],
  TICKER_LOGOS: {
    Accelarator,
    DollarBag,
    Cheers,
    Clap,
    DartBoard,
    Finger,
    FirstPrize,
    Flag,
    FlyingStar,
    Gift,
    Heart,
    Like,
    PrizePodium,
    TwoMen,
    Trophy,
    ThreeMen,
    StarPrize,
    StarMedal,
  },
  DASHBOARD_FILTERS: [
    { label: "Today", key: 0 },
    { label: "Yesterday", key: 1 },
    { label: "Last 7 days", key: 2 },
    { label: "Last 30 days", key: 3 },
    { label: "Last 90 days", key: 4 },
    { label: "Last 365 days", key: 5 },
    { label: "Custom", key: 6 },
  ],
  SALESFORCE_DETAILS_ARRAY: [
    { label: "Organization ID", selector: "Id" },
    { label: "Organization Name", selector: "Name" },
    {
      label: "Primary Contact",
      children: [
        { label: "Name", selector: "PrimaryContact" },
        { label: "Email" },
      ],
    },
    { label: "Division", selector: "Division" },
    { label: "Default Locale", selector: "DefaultLocaleSidKey" },
    {
      label: "Address",
      children: [
        { label: "Street", selector: "Street" },
        { label: "City", selector: "City" },
        { label: "State", selector: "State" },
        { label: "Country", selector: "Country" },
      ],
    },
    { label: "Default Language", selector: "LanguageLocaleKey" },
    { label: "Fiscal Year Starts In", selector: "FiscalYearStartMonth" },
    { label: "Default Time Zone", selector: "TimeZoneSidKey" },
    { label: "Activate Multiple Currencies", selector: "multiCurrency" },
    { label: "Currency Locale", selector: "defaultCurrencyIsoCode" },
    { label: "Organisation Edition", selector: "OrganizationType" },
    {
      label: "Streaming API Events, Last 24 Hours",
      selector: "published_events_last_24_hrs",
    },
  ],
  MONTHS: {
    1: "January",
    2: "February",
    3: "March",
    4: "April",
    5: "May",
    6: "June",
    7: "July",
    8: "August",
    9: "September",
    10: "October",
    11: "November",
    12: "December",
  },
  PRELOADED_MUSIC: [
    {
      label: "Final Countdown Instrumental",
      source:
        "https://kwipo-bucket.s3.us-east-2.amazonaws.com/Final+Countdown+Instrumental.m4a",
      kwipo_song: true,
    },
    {
      label: "Fight Song",
      source: "https://kwipo-bucket.s3.us-east-2.amazonaws.com/Fight+Song.m4a",
      kwipo_song: true,
    },
    {
      label: "We Will Rock You",
      source:
        "https://kwipo-bucket.s3.us-east-2.amazonaws.com/We+Will+Rock+You.m4a",
      kwipo_song: true,
    },
    {
      label: "Free Falling",
      source:
        "https://kwipo-bucket.s3.us-east-2.amazonaws.com/Free+Falling.m4a",
      kwipo_song: true,
    },
    {
      label: "Shake it off",
      source:
        "https://kwipo-bucket.s3.us-east-2.amazonaws.com/Shake+it+off.m4a",
      kwipo_song: true,
    },
    {
      label: "Welcome to the Jungle",
      source:
        "https://kwipo-bucket.s3.us-east-2.amazonaws.com/Welcome+to+the+Jungle.m4a",
      kwipo_song: true,
    },
    {
      label: "Celebrate",
      source: "https://kwipo-bucket.s3.us-east-2.amazonaws.com/Celebrate.m4a",
      kwipo_song: true,
    },
  ],
  SESSION_TIMEOUT_OPTIONS: [
    { label: "Allow 12 hrs Session", value: 12 },
    { label: "Allow 1 day Session", value: 24 },
    { label: "Allow 2 day Session", value: 48 },
    { label: "Allow 1 Week Session", value: 168 },
    { label: "Allow 1 Month Session", value: 720 },
    { label: "Allow Infinite Sessions", value: 87600 },
  ],
  LEADERBOARD_CALCULATION_OPTIONS: [
    { label: "Count", key: "COUNT", value: "COUNT" },
    { label: "Sum", key: "SUM", value: "SUM" },
    { label: "Average", key: "AVG", value: "AVG" },
    { label: "Min", key: "MIN", value: "MIN" },
    { label: "Max", key: "MAX", value: "MAX" },
  ],
  LEADERBOARD_TIMEFRAME_OPTIONS: [
    { label: "Today", key: "TODAY", value: "TODAY" },
    { label: "Week to Date (WTD)", key: "THIS_WEEK", value: "THIS_WEEK" },
    { label: "Month to Date (MTD)", key: "THIS_MONTH", value: "THIS_MONTH" },
    {
      label: "Quarter to Date (QTD)",
      key: "THIS_QUARTER",
      value: "THIS_QUARTER",
    },
    { label: "Year to Date (YTD)", key: "THIS_YEAR", value: "THIS_YEAR" },
  ],
  MERIDIAN_LIST: [
    { label: "AM", value: "AM" },
    { label: "PM", value: "PM" },
  ],
  METRIC_TIMEFRAMES: [
    { label: "Daily", key: "TODAY", value: "TODAY" },
    { label: "Weekly", key: "THIS_WEEK", value: "THIS_WEEK" },
    { label: "Monthly", key: "THIS_MONTH", value: "THIS_MONTH" },
    { label: "Quarterly", key: "THIS_QUARTER", value: "THIS_QUARTER" },
    { label: "Annually", key: "THIS_YEAR", value: "THIS_YEAR" },
  ],
  LEADERBOARD_TITLE_SUGGESTION: [
    { label: "Today", key: "TODAY" },
    { label: "WTD", key: "THIS_WEEK" },
    { label: "MTD", key: "THIS_MONTH" },
    {
      label: "QTD",
      key: "THIS_QUARTER",
    },
    { label: "YTD", key: "THIS_YEAR" },
  ],
  RESTRICTED_DELETE_LABELS: {
    leaderboard_names: "Leaderboard Service",
    threshold_names: "Threshold Service",
    kwips_names: "Kwips Service",
    ticker_names: "Ticker Service",
    team_names: "Teams Service",
  },
  INTEGRATIONS: {
    SALESFORCE: "salesforce",
  },
  LEADERBOARD_STEPS: ({
    modalData,
    setModalData,
    usersList,
    groups,
    additionalUsers,
    setAdditionalUsers,
  }) => [
    {
      content: (
        <FirstLeaderboardStep
          modalData={modalData}
          setModalData={setModalData}
          setSelectedMembers={setAdditionalUsers}
        />
      ),
    },
    {
      content: (
        <SecondLeaderboardStep
          usersList={usersList}
          groups={groups}
          selectedMembers={additionalUsers}
          setSelectedMembers={setAdditionalUsers}
        />
      ),
    },
  ],
};

export default CONFIG;
