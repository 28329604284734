import { Checkbox, Col, Form, Input, Row, Select } from "antd";
import React, { useState } from "react";
import {
  createId,
  getFilterExpressionMappingText,
  operatorDataForSelectedField,
  optionCreator,
} from "../../../../utils/functions";
import ErrorMessage from "../../../../components/validation/components/errorMessage";
import Validation from "../../../../components/validation";
import CONFIG from "../../../../utils/config";
import {
  PlusCircleFilled,
  DeleteOutlined,
  MinusCircleFilled,
} from "@ant-design/icons";

function FilterExpression({ filters, setGroupDetails }) {
  const {
    GROUP_FILTERS,
    LABELS: {
      GROUP: { FIELD, OPERATOR, VALUE, LABEL, KEY },
      VALIDATION: { REQUIRED: REQUIRED_RULE },
    },
    ERRORS: { REQUIRED },
  } = CONFIG;

  const VALIDATION_RULES = {
    value: [{ rule: REQUIRED_RULE, errorMessage: REQUIRED }],
    field: [{ rule: REQUIRED_RULE, errorMessage: REQUIRED }],
    operator: [{ rule: REQUIRED_RULE, errorMessage: REQUIRED }],
  };

  const [controlledShowError, setControlledShowError] = useState({});
  const [reIntialize, setReInitialize] = useState(false);
  const [currentFilterExpression, setCurrentFilterExpression] = useState({});
  const [selectedCheckbox, setSelectedCheckbox] = useState([]);
  const { fieldShowError, operatorShowError, valueShowError } =
    controlledShowError || {};

  const handleFilterChange = (key, name) => {
    setCurrentFilterExpression((prev) => ({ ...prev, [name]: key }));
    setControlledShowError({});
  };

  const handleAddMoreFilter = (errorObj) => {
    let isAddAllowed =
      !errorObj.field.errorMessage &&
      !errorObj.operator.errorMessage &&
      !errorObj.value.errorMessage;
    if (isAddAllowed) {
      setGroupDetails((prev) => ({
        ...prev,
        filters: [...(prev?.filters ?? []), currentFilterExpression],
      }));
      setCurrentFilterExpression({});
      setReInitialize(true);
    } else
      setControlledShowError({
        fieldShowError: !!errorObj.field.errorMessage,
        operatorShowError: !!errorObj.operator.errorMessage,
        valueShowError: !!errorObj.value.errorMessage,
      });
  };

  const handleOperationDelete = (id) => {
    setGroupDetails((prev) => ({
      ...prev,
      filters: prev.filters.filter(
        ({ field, operator, value }) => !id.includes(field + operator + value)
      ),
    }));
    setSelectedCheckbox((prev) =>
      prev.filter((prevItem) => !id.includes(prevItem))
    );
  };

  const handleCheckboxSelection = (isChecked, item) => {
    if (isChecked) setSelectedCheckbox((prev) => [...prev, item]);
    else
      setSelectedCheckbox((prev) =>
        prev.filter((prevItem) => prevItem !== item)
      );
  };

  return (
    <Validation
      validationRules={VALIDATION_RULES}
      reIntialize={reIntialize}
      setReInitialize={setReInitialize}
      values={{ ...currentFilterExpression }}
    >
      {(errorObj) => (
        <Col xs={24}>
          <h3 className="d-flex">Group Filter Criteria</h3>
          <Row gutter={[16, 16]} className="custom-height-100">
            <Col
              xs={12}
              sm={10}
              md={12}
              lg={8}
              xl={8}
              className="custom-height-100"
            >
              <Form.Item name="Field" label="Field" className="mb-0" required>
                <Select
                  placeholder="Select field here..."
                  style={{ width: "100%" }}
                  value={currentFilterExpression.field}
                  onChange={(key) => handleFilterChange(key, FIELD)}
                  options={optionCreator(GROUP_FILTERS, LABEL, KEY)}
                />
                <ErrorMessage
                  errorObject={errorObj}
                  selector={"field"}
                  controlledShowError={fieldShowError}
                />
              </Form.Item>
            </Col>
            <Col
              xs={12}
              sm={10}
              md={12}
              lg={8}
              xl={8}
              className="custom-height-100"
            >
              <Form.Item
                name="Operator"
                label="Operator"
                className="mb-0"
                required
              >
                <Select
                  placeholder="Select operator here..."
                  style={{ width: "100%" }}
                  value={currentFilterExpression.operator}
                  disabled={!currentFilterExpression.field}
                  onChange={(key) => handleFilterChange(key, OPERATOR)}
                  options={optionCreator(
                    operatorDataForSelectedField(
                      GROUP_FILTERS,
                      currentFilterExpression.field
                    ),
                    LABEL,
                    KEY
                  )}
                />
                <ErrorMessage
                  errorObject={errorObj}
                  selector={"operator"}
                  controlledShowError={operatorShowError}
                />
              </Form.Item>
            </Col>
            <Col
              xs={12}
              sm={10}
              md={12}
              lg={7}
              xl={7}
              className="custom-height-100"
            >
              <Form.Item name="Value" label="Value" className="mb-0" required>
                <div>
                  {currentFilterExpression.field === "IsActive" ? (
                    <Select
                      placeholder="Select your value here..."
                      value={currentFilterExpression.value}
                      onChange={(val) => {
                        handleFilterChange(val, VALUE);
                      }}
                      options={[
                        { label: "True", value: "true" },
                        { label: "False", value: "false" },
                      ]}
                    />
                  ) : (
                    <Input
                      placeholder="Enter your value here..."
                      value={currentFilterExpression.value}
                      name={VALUE}
                      onChange={({ target: { value } }) =>
                        handleFilterChange(value, VALUE)
                      }
                      disabled={
                        !currentFilterExpression.field ||
                        !currentFilterExpression.operator
                      }
                    />
                  )}
                </div>
                <ErrorMessage
                  errorObject={errorObj}
                  selector={"value"}
                  controlledShowError={valueShowError}
                />
              </Form.Item>
            </Col>
            <Col className="plus-icon custom-height-100" xl={1}>
              <Form.Item>
                <label style={{ height: "22px", display: "inline-block" }} />
                <PlusCircleFilled
                  onClick={() => handleAddMoreFilter(errorObj)}
                />
                <p className="no-errorMessage"></p>
              </Form.Item>
            </Col>
          </Row>
          <div className="notification-fields mb-3">
            <h4>
              {filters?.length ?? 0} Filter Expressions
              {selectedCheckbox.length ? (
                <DeleteOutlined
                  className="cursor-pointer"
                  onClick={() => handleOperationDelete(selectedCheckbox)}
                />
              ) : null}
            </h4>
            <div className="notification-fields-list">
              {filters?.map((data, index) => {
                const { fieldText, operatorText, valueText } =
                  getFilterExpressionMappingText(data, GROUP_FILTERS);
                return (
                  <Row gutter={[16, 16]} key={createId(data, "field", index)}>
                    <Col xs={10} sm={16} md={12} lg={8} xl={8}>
                      <Checkbox
                        checked={selectedCheckbox.includes(
                          data.field + data.operator + data.value
                        )}
                        onChange={({ target: { checked } }) => {
                          handleCheckboxSelection(
                            checked,
                            data.field + data.operator + data.value
                          );
                        }}
                      >
                        <span>{fieldText}</span>
                      </Checkbox>
                    </Col>
                    <Col xs={10} sm={16} md={12} lg={8} xl={8}>
                      <span>{operatorText}</span>
                    </Col>
                    <Col xs={10} sm={16} md={12} lg={7} xl={7}>
                      <span>{valueText}</span>
                    </Col>
                    <Col xs={1} sm={1} md={1} lg={1} xl={1}>
                      <MinusCircleFilled
                        onClick={() =>
                          handleOperationDelete(
                            data.field + data.operator + data.value
                          )
                        }
                      />
                    </Col>
                  </Row>
                );
              })}
            </div>
          </div>
        </Col>
      )}
    </Validation>
  );
}

export default FilterExpression;
