import { Button, Col, Row, Table } from "antd";
import React, { useCallback, useEffect, useMemo } from "react";
import CONFIG from "../../utils/config";
import {
  columnAndComponentFunctionReference,
  withKeyDataSet,
} from "../../utils/functions";
import { useDispatch } from "react-redux";
import { openModal } from "../../redux-toolkit/slice/modal";
import { fetchObjects } from "../../redux-toolkit/slice/notification/asyncThunk";
import notificationDataSelector from "../../redux-toolkit/selectors/notification";
import { useSelector } from "react-redux";
import layoutDataSelector from "../../redux-toolkit/selectors/layoutData";
import metricesDataSelector from "../../redux-toolkit/selectors/metricesData";
import {
  deleteMetrices,
  fetchMetrices,
} from "../../redux-toolkit/slice/metrices/asyncThunk";

function Metrices() {
  const {
    LABELS: { ID },
    METRICES_COLUMN,
    MODALS: { METRICES, METRIC_PREVIEW },
  } = CONFIG;

  const dispatch = useDispatch();

  const { metricesList } = metricesDataSelector(useSelector);

  const { objects } = notificationDataSelector(useSelector);

  const { adminDetails } = layoutDataSelector(useSelector);

  const { is_salesforce_authenticated } = adminDetails || {};

  const handleAddNewMetrices = () => {
    dispatch(openModal({ key: METRICES.key, props: {} }));
  };

  const handleDeleteMetrices = useCallback(
    ({ id, name }) => {
      dispatch(deleteMetrices({ id, name }));
    },
    [dispatch]
  );

  const handleEditMetric = useCallback(
    (metricesData) => {
      dispatch(openModal({ key: METRICES.key, props: { metricesData } }));
    },
    [METRICES, dispatch]
  );

  const handlePreview = useCallback(
    (record) => {
      dispatch(
        openModal({ key: METRIC_PREVIEW.key, props: { metricData: record } })
      );
    },
    [METRIC_PREVIEW, dispatch]
  );

  const tableColumn = useMemo(
    () =>
      columnAndComponentFunctionReference(METRICES_COLUMN, {
        handlePreview,
        handleEditMetric,
        handleDeleteMetrices,
      }),
    [METRICES_COLUMN, handlePreview, handleDeleteMetrices, handleEditMetric]
  );

  useEffect(() => {
    is_salesforce_authenticated && !objects && dispatch(fetchObjects());
  }, [dispatch, objects, is_salesforce_authenticated]);

  useEffect(() => {
    !metricesList && dispatch(fetchMetrices());
  }, [dispatch, metricesList]);

  return (
    <div className="userContainer">
      <Row className="filter-head">
        <Col span={12}>
          <h2>METRICS</h2>
        </Col>
        <Col style={{ justifyContent: "end", display: "flex" }} span={12}>
          <Button className="primary-outline" onClick={handleAddNewMetrices}>
            Add Metric
          </Button>
        </Col>
      </Row>
      <Table
        className="cmn-table"
        columns={tableColumn}
        dataSource={withKeyDataSet(ID, metricesList || [])}
        scroll={{ y: "calc(70vh - 60px)" }}
        pagination={{}}
      />
    </div>
  );
}

export default Metrices;
