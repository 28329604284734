import React from "react";
import { Select, Input, DatePicker } from "antd";

import { isSubstring, optionCreator } from "../../../../../../utils/functions";

function ValueSelector({
  type,
  options,
  selectorValue,
  handleValueChange,
  handleDeselect,
}) {
  const handleInputChange = ({ target: { value } }) => {
    handleValueChange(value);
  };

  switch (type) {
    case "text":
      return (
        <Input
          type="text"
          placeholder="Enter text value"
          value={selectorValue}
          onChange={handleInputChange}
        />
      );
    case "number":
      return (
        <Input
          type="number"
          placeholder="Enter number value only"
          value={selectorValue}
          onChange={handleInputChange}
        />
      );
    case "date":
      return (
        <DatePicker
          showTime
          format="YYYY-MM-DD HH:mm"
          value={selectorValue}
          onChange={handleValueChange}
        />
      );
    case "boolean":
      return (
        <Select
          placeholder="Select a boolean value"
          value={selectorValue}
          options={[
            { label: "True", value: "true" },
            { label: "False", value: "false" },
          ]}
          onSelect={handleValueChange}
        />
      );
    case "select":
      return (
        <Select
          mode="multiple"
          showSearch
          placeholder="Search to Select"
          optionFilterProp="children"
          filterOption={(input, option) => isSubstring(input, option.label)}
          value={selectorValue}
          options={optionCreator(options, "label", "value")}
          onSelect={handleValueChange}
          onDeselect={handleDeselect}
          maxTagCount={0}
          maxTagPlaceholder={<>{`${selectorValue?.length} selected values`}</>}
        />
      );
    default:
      return <Input type="text" disabled />;
  }
}

export default ValueSelector;
