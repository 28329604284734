import { Dropdown, Layout, Space } from "antd";
import { CaretDownOutlined } from "@ant-design/icons";
import React, { useCallback, useEffect } from "react";
import profilePic from "../../assets/images/profile-pic.png";
import { getToken, setToken } from "../../utils/functions";
import CONFIG from "../../utils/config";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import layoutDataSelector from "../../redux-toolkit/selectors/layoutData";
import { useSelector } from "react-redux";
import { clearAdmin } from "../../redux-toolkit/slice/admin";
import { fetchAdminDetails } from "../../redux-toolkit/slice/admin/asyncThunk";

import "./profile.scss";
import Overlay from "../../components/overlay";
import Loader from "../../components/loader";

const { Header } = Layout;

function Profile() {
  const {
    PROFILE_ITEMS,
    TOKEN: { ACCESS_TOKEN, REFRESH_TOKEN },
    ROUTES: { LOGIN, DASHBOARD },
  } = CONFIG;

  const accessToken = getToken(ACCESS_TOKEN);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { adminDetails, loading } = layoutDataSelector(useSelector);

  const { full_photo_url, name, profile_photo } = adminDetails || {};

  const handleLogOut = () => {
    dispatch(clearAdmin());
    navigate(LOGIN.path);
    setToken(ACCESS_TOKEN, "");
    setToken(REFRESH_TOKEN, "");
  };

  const handleViewProfile = () => {};

  const handleHome = () => {
    navigate(DASHBOARD.path);
  };

  const fetchDetails = useCallback(() => {
    dispatch(fetchAdminDetails());
  }, [dispatch]);

  useEffect(() => {
    if (accessToken) {
      if (!adminDetails) fetchDetails();
    } else navigate(LOGIN.path);
  }, [LOGIN, accessToken, adminDetails, fetchDetails, navigate]);

  return (
    <Layout>
      <Layout className="layout site-layout">
        <Overlay show={loading}>
          <Loader />
        </Overlay>
        <Header>
          <div>
            <Dropdown
              className="user-dropdown"
              menu={{
                items: PROFILE_ITEMS({
                  handleViewProfile,
                  handleLogOut,
                  handleHome,
                }),
              }}
            >
              <div>
                <span className="user-image">
                  <img
                    src={
                      (full_photo_url ||
                        profile_photo?.image ||
                        profile_photo?.find(({ is_active }) => is_active)
                          ?.image) ??
                      profilePic
                    }
                    alt="profilePic"
                  />
                </span>
                <Space>
                  {name ?? "Admin"}
                  <CaretDownOutlined />
                </Space>
              </div>
            </Dropdown>
          </div>
        </Header>
        <div id="profile-embed" className="w-100 h-100 profile-embed" />
      </Layout>
    </Layout>
  );
}

export default Profile;
