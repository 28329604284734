import { Col, Form, Row } from "antd";
import React from "react";
import Marquee from "../../../../components/marquee";
import CONFIG from "../../../../utils/config";
import Icon from "../../../../components/icon";

function Style({ mode, contentDetails, handleModeChange }) {
  const { TICKER_LOGOS } = CONFIG;

  return (
    <Form layout="vertical" autoComplete="off">
      <Row className="mb-3">
        <Col md={12} className="d-flex"></Col>
        <Col md={12}>
          <div className="ticker-buttons">
            <div
              className={`ticker-buttons-item ${
                mode === "heading" ? "active" : ""
              }`}
              onClick={() => handleModeChange("heading")}
            >
              Heading
            </div>
            <div
              className={`ticker-buttons-item ${
                mode === "style" ? "active" : ""
              }`}
              onClick={() => handleModeChange("style")}
            >
              Style
            </div>
          </div>
        </Col>
      </Row>

      <Row className="mb-3 marquee-header">
        <Col md={4} className="d-flex">
          <div
            className="marquee-inner-left"
            style={{ background: contentDetails.bgColor }}
          >
            <Icon
              Component={TICKER_LOGOS[contentDetails?.image]}
              fill={contentDetails.logoColor}
            />
            <h2>
              {contentDetails.title1} <br /> {contentDetails?.title2}
            </h2>
          </div>
        </Col>
        <Col md={20}>
          <div className="marquee-inner-right">
            <div className="marque-content">
              <Marquee messages={contentDetails.headlineList} />
            </div>
          </div>
        </Col>
      </Row>
    </Form>
  );
}

export default Style;
