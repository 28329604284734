import React, { useEffect, useState } from "react";
import { Modal, Steps } from "antd";
import { useDispatch } from "react-redux";
import CONFIG from "../../../utils/config";
import { closeModal } from "../../../redux-toolkit/slice/modal";
import {
  createTeams,
  updateTeams,
} from "../../../redux-toolkit/slice/teams/asyncThunk";
import {
  getCreateTeamPayload,
  getUpdateTeamPayload,
} from "../../../utils/functions";
import Validation from "../../../components/validation";
import StepButton from "./components/stepButton";
import { fetchFilteredUsers } from "../../../redux-toolkit/slice/groups/asyncThunk";

function TeamModal({ usersList, teamData, groups }) {
  const {
    TEAMS_STEPS,
    LABELS: {
      TEAMS: { CREATE_TEAM, EDIT_TEAM },
      VALIDATION: { REQUIRED: REQUIRED_RULE },
    },
    ERRORS: { REQUIRED },
  } = CONFIG;

  const VALIDATION_RULES = {
    teamName: [{ rule: REQUIRED_RULE, errorMessage: REQUIRED }],
    selectedMembers: [{ rule: REQUIRED_RULE, errorMessage: REQUIRED }],
  };

  const dispatch = useDispatch();

  const [currentStep, setCurrent] = useState(0);
  const [teamName, setTeamName] = useState("");
  const [teamProfile, setTeamProfile] = useState({
    url: "https://kwipo-bucket.s3.us-east-2.amazonaws.com/team_default.png",
  });
  const [teamType, setTeamType] = useState("");
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [selectedManagers, setSelectedManagers] = useState([]);
  const [status, setStatus] = useState(true);
  const [filterDetails, setFilterDetails] = useState({
    filters: [],
  });

  const handleCloseModal = () => {
    dispatch(closeModal());
  };

  const handleSave = async () => {
    teamData
      ? dispatch(
          updateTeams({
            payload: getUpdateTeamPayload({
              ...teamData,
              name: teamName,
              members: selectedMembers,
              managers: selectedManagers,
              image: teamProfile,
              filters: filterDetails.filters,
              type: teamType,
              isactive: status,
            }),
            isSynced: false,
          })
        )
      : dispatch(
          createTeams(
            getCreateTeamPayload({
              name: teamName,
              members: selectedMembers,
              isactive: status,
              image: teamProfile,
              filters: filterDetails.filters,
              type: teamType,
              managers: selectedManagers,
            })
          )
        );
  };

  const next = () => {
    switch (currentStep) {
      case 1: {
        handleSave();
        break;
      }
      case 2: {
        handleSave();
        break;
      }
      default:
        setCurrent(currentStep + 1);
    }
  };

  const prev = () => {
    setCurrent(currentStep - 1);
  };

  const preview = () => {
    dispatch(
      fetchFilteredUsers({
        payload: { filters: filterDetails.filters },
        successCallback: () => {
          setCurrent(currentStep + 1);
        },
      })
    );
  };

  useEffect(() => {
    if (teamData) {
      setTeamName(teamData.name);
      setSelectedMembers(teamData.members);
      setTeamProfile({ url: teamData.profile_photo });
      setFilterDetails({ filters: teamData.filters });
      setTeamType(teamData.source_type);
      setStatus(teamData.isactive);
      setSelectedManagers(teamData.managers);
    }
  }, [teamData]);

  return (
    <Validation
      validationRules={VALIDATION_RULES}
      values={{ teamName, selectedMembers, teamProfile }}
    >
      {(errorObj) => {
        return (
          <Modal
            key={teamData ? EDIT_TEAM : CREATE_TEAM}
            title={teamData ? teamData.name : CREATE_TEAM}
            centered
            open={true}
            width={980}
            onCancel={handleCloseModal}
            footer={
              <StepButton
                currentStep={currentStep}
                teamObject={{
                  teamName,
                  teamProfile,
                  teamType,
                  filterDetails: filterDetails.filters,
                  selectedMembers,
                }}
                prev={prev}
                next={next}
                preview={preview}
                handleCancel={handleCloseModal}
              />
            }
          >
            <Steps
              current={currentStep}
              percent={60}
              labelPlacement="vertical"
              items={TEAMS_STEPS({})}
            />
            <div className="mb-3" />
            <div className="team-step-content">
              {
                TEAMS_STEPS({
                  errorObj,
                  selectedMembers,
                  setSelectedMembers,
                  selectedManagers,
                  setSelectedManagers,
                  usersList,
                  groups,
                  teamProfile,
                  setTeamProfile,
                  teamName,
                  setTeamName,
                  teamType,
                  setTeamType,
                  filterDetails,
                  setFilterDetails,
                  teamData,
                  status,
                  setStatus,
                })[currentStep]?.content
              }
            </div>
          </Modal>
        );
      }}
    </Validation>
  );
}

export default TeamModal;
