import {
  Avatar,
  Button,
  Checkbox,
  Col,
  Form,
  Row,
  Select,
  Tooltip,
} from "antd";
import React, { useEffect, useMemo, useState } from "react";
import {
  firstLetter,
  getDetails,
  getMemberInfo,
  groupOptions,
  isSubstring,
  optionCreator,
} from "../../../../../utils/functions";
import { MinusCircleFilled } from "@ant-design/icons";
import FilterExpression from "../../../group/components/filterExpression";

function SecondStep({
  selectedMembers,
  setSelectedMembers,
  selectedManagers,
  setSelectedManagers,
  usersList,
  groups,
  teamType,
  filterDetails,
  setFilterDetails,
  teamData,
}) {
  const [member, setMember] = useState({});
  const [manager, setManager] = useState({});

  const [allUsersSelected, setAllUsersSelected] = useState(false);
  const [allManagersSelected, setAllManagersSelected] = useState(false);

  const handleSelect = (id, { type }, isManager) => {
    isManager
      ? setManager({ id, type, role: "manager" })
      : setMember({ id, type, role: "member" });
  };

  const handleAdd = (isManager) => {
    if (isManager) {
      if (manager.id) setSelectedManagers([...selectedManagers, manager]);
      setManager({});
    } else {
      if (member.id) setSelectedMembers([...selectedMembers, member]);
      setMember({});
    }
  };

  const handleDeselect = (deletedKey, isManager) => {
    isManager
      ? setSelectedManagers((prevValue) =>
          prevValue.filter(({ id }) => id !== deletedKey)
        )
      : setSelectedMembers((prevValue) =>
          prevValue.filter(({ id }) => id !== deletedKey)
        );
  };

  const handleCheckbox = ({ target: { checked } }, isManager) => {
    if (isManager) {
      setAllManagersSelected(checked);
      let filteredTeamMembers = selectedManagers.filter(
        ({ type }) => type === "team"
      );
      if (checked)
        setSelectedManagers([
          ...usersList.map(({ id }) => ({ id, type: "user", role: "manager" })),
          ...filteredTeamMembers,
        ]);
      else setSelectedManagers(filteredTeamMembers);
    } else {
      setAllUsersSelected(checked);
      let filteredTeamMembers = selectedMembers.filter(
        ({ type }) => type === "team"
      );
      if (checked)
        setSelectedMembers([
          ...usersList.map(({ id }) => ({ id, type: "user", role: "member" })),
          ...filteredTeamMembers,
        ]);
      else setSelectedMembers(filteredTeamMembers);
    }
  };

  const members = useMemo(() => {
    return getMemberInfo(teamData, usersList);
  }, [teamData, usersList]);

  const isCheckboxIndeterminate = useMemo(() => {
    let selectedUsers = selectedMembers.filter(({ type }) => type === "user");
    return selectedUsers.length > 0 && selectedUsers.length < usersList.length;
  }, [selectedMembers, usersList]);

  const isManagerCheckboxIndeterminate = useMemo(() => {
    let selectedUsers = selectedManagers.filter(({ type }) => type === "user");
    return selectedUsers.length > 0 && selectedUsers.length < usersList.length;
  }, [selectedManagers, usersList]);

  useEffect(() => {
    if (isCheckboxIndeterminate) setAllUsersSelected(false);
  }, [isCheckboxIndeterminate]);

  useEffect(() => {
    if (isManagerCheckboxIndeterminate) setAllManagersSelected(false);
  }, [isManagerCheckboxIndeterminate]);

  useEffect(() => {
    let userMembers =
      selectedMembers?.filter(({ type }) => type === "user") ?? [];
    if (userMembers.length === usersList.length) setAllUsersSelected(true);
  }, [selectedMembers, usersList]);

  useEffect(() => {
    let userMembers =
      selectedManagers?.filter(({ type }) => type === "user") ?? [];
    if (userMembers.length === usersList.length) setAllManagersSelected(true);
  }, [selectedManagers, usersList]);

  return (
    <Form name="form_item_path" layout="vertical">
      <Row gutter={[8, 8]}>
        {teamType === "Custom" && (
          <>
            <Col xs={24} sm={16} md={12} lg={12} xl={12}>
              <Form.Item
                label="Team Members"
                className="mb-0 no-form-item"
                children={null}
                required
              />
              <Row gutter={[16, 16]} className="mb-2">
                <Col xs={24}>
                  <Select
                    showSearch
                    placeholder="Select Users or Teams"
                    filterOption={(input, option) =>
                      isSubstring(input, option.label)
                    }
                    popupMatchSelectWidth={true}
                    style={{ width: "100%" }}
                    value={member.id}
                    options={groupOptions(
                      [
                        { label: "Users", key: "user" },
                        { label: "Teams", key: "team" },
                      ],
                      [
                        optionCreator(usersList, "name", "id"),
                        optionCreator(groups, "name", "id"),
                      ],
                      selectedMembers ?? []
                    )}
                    onSelect={handleSelect}
                  />
                </Col>
                {/* <Col xs={2} className="d-flex">
                  <Tooltip
                    title={
                      allUsersSelected
                        ? "Deselect all Users"
                        : "Select all Users"
                    }
                  >
                    <Checkbox
                      indeterminate={isCheckboxIndeterminate}
                      checked={allUsersSelected}
                      onChange={(details) => handleCheckbox(details)}
                    />
                  </Tooltip>
                </Col> */}
                <Col className="plus-icon mb-2" sm={4} md={4} xl={2}>
                  <Button className="primary-solid" onClick={() => handleAdd()}>
                    Add
                  </Button>
                </Col>
              </Row>
            </Col>
            <Col xs={20} sm={16} md={12} lg={12} xl={12}>
              <div className="credit-list-scroll kwip-credit-scroll">
                {selectedMembers?.map(({ id }) => {
                  let { name, profile_photo } = getDetails(
                    [...(usersList ?? []), ...(groups ?? [])],
                    "id",
                    id
                  );
                  let image = Array.isArray(profile_photo)
                    ? profile_photo?.find(({ is_active }) => is_active)?.image
                    : profile_photo;
                  return (
                    <div className="credit-list creditTo" key={id}>
                      <Avatar
                        size={30}
                        src={image}
                        children={image ? null : firstLetter(name)}
                      />
                      <span className="creditTo_field">{name}</span>
                      <span className="close">
                        <MinusCircleFilled onClick={() => handleDeselect(id)} />
                      </span>
                    </div>
                  );
                })}
              </div>
            </Col>
            <Col xs={24} sm={16} md={12} lg={12} xl={12}>
              <Form.Item
                label="Team Managers"
                className="mb-0 no-form-item"
                children={null}
              />
              <Row gutter={[16, 16]} className="mb-2">
                <Col xs={24}>
                  <Select
                    showSearch
                    placeholder="Select Users or Teams"
                    filterOption={(input, option) =>
                      isSubstring(input, option.label)
                    }
                    popupMatchSelectWidth={true}
                    style={{ width: "100%" }}
                    value={manager.id}
                    options={groupOptions(
                      [{ label: "Users", key: "user" }],
                      [optionCreator(usersList, "name", "id")],
                      selectedManagers ?? []
                    )}
                    onSelect={(id, details) => {
                      handleSelect(id, details, true);
                    }}
                  />
                </Col>
                {/* <Col xs={2} className="d-flex">
                  <Tooltip
                    title={
                      allManagersSelected
                        ? "Deselect all Users"
                        : "Select all Users"
                    }
                  >
                    <Checkbox
                      indeterminate={isManagerCheckboxIndeterminate}
                      checked={allManagersSelected}
                      onChange={(details) => handleCheckbox(details, true)}
                    />
                  </Tooltip>
                </Col> */}
                <Col className="plus-icon mb-2" sm={4} md={4} xl={2}>
                  <Button
                    className="primary-solid"
                    onClick={() => handleAdd(true)}
                  >
                    Add
                  </Button>
                </Col>
              </Row>
            </Col>
            <Col xs={20} sm={16} md={12} lg={12} xl={12}>
              <div className="credit-list-scroll kwip-credit-scroll">
                {selectedManagers?.map(({ id }) => {
                  let { name, profile_photo } = getDetails(
                    [...(usersList ?? []), ...(groups ?? [])],
                    "id",
                    id
                  );
                  let image = Array.isArray(profile_photo)
                    ? profile_photo?.find(({ is_active }) => is_active)?.image
                    : profile_photo;
                  return (
                    <div className="credit-list creditTo" key={id}>
                      <Avatar
                        size={30}
                        src={image}
                        children={image ? null : firstLetter(name)}
                      />
                      <span className="creditTo_field">{name}</span>
                      <span className="close">
                        <MinusCircleFilled
                          onClick={() => handleDeselect(id, true)}
                        />
                      </span>
                    </div>
                  );
                })}
              </div>
            </Col>
          </>
        )}
        {teamType === "Salesforce" && (
          <Col xs={24}>
            <FilterExpression
              filters={filterDetails.filters}
              members={members}
              groupDetails={filterDetails}
              setGroupDetails={setFilterDetails}
            />
          </Col>
        )}
      </Row>
    </Form>
  );
}

export default SecondStep;
