import React from "react";
import { Col, Row, Button, Layout } from "antd";
import noAccess from "../../assets/images/no-access.jpg";
import logo from "../../assets/images/logo.svg";
import { useNavigate } from "react-router-dom";
import CONFIG from "../../utils/config";
const { Header } = Layout;

function Unauthorised() {
  const {
    ROUTES: { USERS },
  } = CONFIG;

  const navigate = useNavigate();

  const handleRedirect = () => {
    navigate(USERS.path);
  };

  return (
    <React.Fragment>
      <Header>
        <img src={logo} alt="logo" />
      </Header>
      <Row className="unauthorised-page">
        <Col className="gutter-row" xs={8}>
          <h1>Sorry!</h1>
          <h3>You are not authorized to access.</h3>
          <Button className="primary-solid" onClick={handleRedirect}>
            Go to User Managment
          </Button>
        </Col>
        <Col className="gutter-row" xs={8}>
          <img src={noAccess} alt="noAccess" />
        </Col>
      </Row>
    </React.Fragment>
  );
}

export default Unauthorised;
