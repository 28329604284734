import React from "react";
import NotificationList from "../notification";

function DataTriggers() {
  return (
    <React.Fragment>
      <div className="group_container">
        <NotificationList />
      </div>
    </React.Fragment>
  );
}

export default DataTriggers;
