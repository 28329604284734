import { createAsyncThunk } from "@reduxjs/toolkit";
import CONFIG from "../../../utils/config";
import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from "../../../api/baseAPI";
import { beginAPICall, endAPICall } from "../loading";
import { errorNotification } from "../../../utils/functions";
import { closeModal } from "../modal";

export const fetchTicker = createAsyncThunk(
  "ticker/fetchTicker",
  async (_, { dispatch }) => {
    let {
      API_URLS: { FETCH_TICKER },
    } = CONFIG;

    try {
      dispatch(beginAPICall());
      let { data } = await getRequest(FETCH_TICKER, { service: "TICKERS" });
      return data;
    } catch ({ response }) {
      errorNotification({
        title: "API Error",
        message: "Failed to fetch tickers",
      });
    } finally {
      dispatch(endAPICall());
    }
  }
);

export const saveTicker = createAsyncThunk(
  "ticker/saveTicker",
  async ({ payload, callback }, { dispatch }) => {
    let {
      API_URLS: { CREATE_TICKER },
    } = CONFIG;
    try {
      dispatch(beginAPICall());
      let { data } = await postRequest(CREATE_TICKER, payload, {
        service: "TICKERS",
      });
      return data;
    } catch ({ response }) {
      errorNotification({
        title: "API Error",
        message: "Failed to create ticker",
      });
      throw new Error();
    } finally {
      dispatch(endAPICall());
      callback();
    }
  }
);

export const editTicker = createAsyncThunk(
  "ticker/editTicker",
  async (
    { payload: { id, ...payload }, callback, isScheduled },
    { dispatch }
  ) => {
    let {
      API_URLS: { EDIT_TICKER },
    } = CONFIG;
    try {
      dispatch(beginAPICall());
      let { data } = await putRequest(EDIT_TICKER + id + "/", payload, {
        service: "TICKERS",
      });
      return data;
    } catch ({ response }) {
      isScheduled
        ? errorNotification({
            title: "API Error",
            message: "Failed to schedule ticker",
          })
        : errorNotification({
            title: "API Error",
            message: "Failed to edit ticker",
          });
      throw new Error();
    } finally {
      dispatch(endAPICall());
      callback();
    }
  }
);

export const deleteTicker = createAsyncThunk(
  "ticker/deleteTicker",
  async ({ payload: { id } }, { dispatch }) => {
    let {
      API_URLS: { DELETE_TICKER },
    } = CONFIG;
    try {
      dispatch(beginAPICall());
      await deleteRequest(DELETE_TICKER + id + "/", { service: "TICKERS" });
      return id;
    } catch ({ response }) {
      errorNotification({
        title: "API Error",
        message: "Failed to delete ticker",
      });
      throw new Error();
    } finally {
      dispatch(endAPICall());
      dispatch(closeModal());
    }
  }
);

export const sendNow = createAsyncThunk(
  "ticker/sendNow",
  async ({ payload }, { dispatch }) => {
    let {
      API_URLS: { SEND_NOW_TICKER },
    } = CONFIG;
    try {
      dispatch(beginAPICall());
      let {
        data: { data },
      } = await postRequest(SEND_NOW_TICKER, payload, {
        service: "TICKERS",
      });

      return payload?.id ? {} : data;
    } catch ({ response }) {
      errorNotification({
        title: "API Error",
        message: "Failed to send ticker",
      });
      throw new Error();
    } finally {
      dispatch(endAPICall());
      dispatch(closeModal());
    }
  }
);
