import { Button, Modal } from "antd";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { closeModal, openModal } from "../../../redux-toolkit/slice/modal";
import CONFIG from "../../../utils/config";
import CloneTemplate from "./components/cloneTemplate";

function CreateTemplateModal({ templates }) {
  const {
    MODALS: { TEMPLATE_EDIT },
  } = CONFIG;

  const dispatch = useDispatch();

  const [selectedTemplate, setSelectedTemplate] = useState(null);

  const handleCloseModal = () => {
    dispatch(closeModal());
  };

  const handleChange = (key) => {
    setSelectedTemplate(key);
  };

  const handleSave = () => {
    let templateData = {
      ...templates.find(({ id }) => id === selectedTemplate),
    };
    templateData.type = "Custom";
    delete templateData.id;

    dispatch(
      openModal({
        key: TEMPLATE_EDIT.key,
        props: { ...templateData },
      })
    );
  };

  return (
    <Modal
      key={"Create New Template"}
      title={"Create New Template"}
      centered
      className="common-modal"
      open={true}
      width={510}
      onCancel={handleCloseModal}
      footer={
        <div className="footer-btns">
          <Button className="primary-solid" onClick={handleSave}>
            Save
          </Button>
          <Button className="primary-outline" onClick={handleCloseModal}>
            Cancel
          </Button>
        </div>
      }
    >
      <CloneTemplate
        templates={templates}
        selectedTemplate={selectedTemplate}
        handleChange={handleChange}
      />
    </Modal>
  );
}

export default CreateTemplateModal;
