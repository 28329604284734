import { Col, Row, Select } from "antd";
import React from "react";
import { optionCreator } from "../../../../utils/functions";

function CloneTemplate({ templates, selectedTemplate, handleChange }) {
  return (
    <Row gutter={[16, 16]}>
      Select the template which you want to clone.
      <Col xl={24}>
        <Select
          className="mb-2"
          style={{ width: "100%" }}
          filterSort={({ label: firstLabel }, { label: secondLabel }) =>
            firstLabel.localeCompare(secondLabel)
          }
          value={selectedTemplate}
          options={optionCreator(templates, "name", "id")}
          onChange={handleChange}
        />
      </Col>
    </Row>
  );
}

export default CloneTemplate;
